import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitQueryListScroll = ({
  index,
  queryKey,
  options,
}) => {
  emitCustomEvent(CustomEvents.QueryListScroll, { index, queryKey, options });
};
