import {
  useEffect,
  useState,
} from 'react';

export const useCustomScrollParent = ({ selector, maxAttempts = 50 }) => {
  const [_customScrollParent, setCustomScrollParent] = useState(null);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (!_customScrollParent) {
      const el = document.querySelector(selector);
      if (el) setCustomScrollParent(el);
      else if (attempts < maxAttempts) setAttempts(prev => prev + 1);
    }
  }, [selector, _customScrollParent, attempts]);

  return _customScrollParent;
};
