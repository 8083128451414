/* eslint-disable react/function-component-definition */
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SourceTypes } from '../../lib/constants';

import state from './atom';
import { TopicModel } from './model';
import { PrivateTopicModel } from '../privateTopic/model';
import { useFetchOnMount } from '../utils/useFetchOnMount';
import { useLoadFromShortId } from './useLoadFromShortId';
import { useLoadIfNeeded } from '../utils/useLoadIfNeeded';

export const useTopic = (id, {
  loadIfNeeded = false,
  sourceType,
  loadFromShortId = false,
  shortId = null,
  fetchOnMount = false,
} = { }) => {
  const [_error, setError] = useState(null);

  const {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
  } = useRecoilValue(state(id || 'false'));

  const ModelInstance = useMemo(() => {
    if (!sourceType && !data?.type) return null;
    if (sourceType === SourceTypes.Topic || data?.type === 'public') return new TopicModel(id);
    if (sourceType === SourceTypes.Message || data?.type === 'private') return new PrivateTopicModel(id);
  }, [id, sourceType]);

  useLoadIfNeeded({
    loadIfNeeded,
    data,
    hasLoaded,
    loading,
    ModelInstance,
    id,
  });

  useLoadFromShortId({
    shortId,
    loadFromShortId,
    data,
    loading,
    setError,
  });

  useFetchOnMount({
    fetch: ModelInstance?.fetch,
    fetching,
    fetchOnMount,
    loading,
  });

  return {
    data,
    loading,
    error: _error || error,
    fetching,
    hasLoaded,
    model: ModelInstance,
    fetch: ModelInstance?.fetch,
    reload: ModelInstance?.reload,
    setData: ModelInstance?.setData,
    loadIfNeeded: ModelInstance?.loadIfNeeded,
  };
};
