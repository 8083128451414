import { setRecoil } from 'recoil-nexus';
import { QueryKeys, SocketEffects, SocketUpdateActions } from '../../lib/constants';
import { NotificationModel } from '../../models/notification/model';
import { invalidateCurrentTopicQueries } from '../../lib/queryUtils';
import unseenNotificationsCountState from '../../models/unseenNotificationsCount/atom';
import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';
import { handleGlobalEffects } from './handleGlobalEffects';

const handleNotificationEffects = (res) => {
  const { effects } = res;
  if (effects?.length < 1) return;
  for (const effect of effects) {
    switch (effect) {
      case SocketEffects.InvalidateQueries:
        invalidateCurrentTopicQueries();
        break;
      default:
        break;
    }
  }
};

export const handleNotification = (res) => {
  const { action, data } = res;
  switch (action) {
    case SocketUpdateActions.New: {
      const notification = new NotificationModel(data.notification._id);
      notification.setData(data.notification);
      if (data.notification.status === 'unseen') setRecoil(unseenNotificationsCountState, prev => prev + 1);
      emitInvalidateQueries({ queryKey: QueryKeys.Notifications });
      break;
    }
    case SocketUpdateActions.Update: {
      console.log('update notification', data);
      break;
    }
    case SocketUpdateActions.Delete: {
      console.log('delete notification', data);
      break;
    }
    default: {
      console.log('unknown notification action', action);
    }
  }
  handleNotificationEffects(res);
  handleGlobalEffects(res);
};
