import { useRecoilState } from 'recoil';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import TopicIcon from '@mui/icons-material/Topic';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import GavelIcon from '@mui/icons-material/Gavel';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import StoreIcon from '@mui/icons-material/Store';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useNavigate } from 'react-router-dom';
import PaletteIcon from '@mui/icons-material/Palette';
import LoginIcon from '@mui/icons-material/Login';
import { siteNav } from '../../models/navigation/selectors';
import { CustomEvents, UserRoles } from '../../lib/constants';
import buildList from '../../lib/buildList';
import useAuthStatus from '../../hooks/useAuthStatus';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';

const SiteNav = () => {
  // Hooks
  const navigate = useNavigate();
  const { unauthenticated } = useAuthStatus();

  // Global State
  const [open, toggleOpen] = useRecoilState(siteNav);

  // Local State
  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    toggleOpen();
  };

  // Handlers
  const goToPath = (path) => {
    // TODO: verify this is a good experience across all browswers and connections
    // setting timeout to show click ripple
    setTimeout(() => {
      toggleOpen();
      navigate(path);
    }, 100);
  };

  let listData = [
    { handler: () => goToPath('/'), Icon: HomeIcon, text: 'Home' },
    { handler: () => goToPath('/store'), Icon: StoreIcon, text: 'Store' },
    { handler: () => goToPath('/all'), Icon: TopicIcon, text: 'All' },
    { handler: () => goToPath('/categories'), Icon: CategoryIcon, text: 'Categories' },
    { handler: () => goToPath('/games'), Icon: SportsBasketballIcon, text: 'Games' },
    { handler: () => goToPath('/users'), Icon: PeopleIcon, text: 'Users' },

  ];

  if (unauthenticated) {
    listData = [
      ...listData,
      { handler: () => goToPath('/login'), Icon: LoginIcon, text: 'Login' },
      { handler: () => goToPath('/register'), Icon: HowToRegIcon, text: 'Register' },
    ];
  }

  listData = [
    ...listData,
    { divider: true },
    { Icon: PaletteIcon, text: 'Site Theme', handler: () => emitCustomEvent(CustomEvents.OpenThemesModal) },
    {
      isProtected: true,
      handler: () => goToPath('/admin'),
      Icon: AdminPanelSettingsIcon,
      text: 'Admin',
      requirements: { roles: [UserRoles.Admin, UserRoles.SuperAdmin] },
    },
    { handler: () => goToPath('/terms-of-use'), Icon: GavelIcon, text: 'Terms of Use' },
    { handler: () => goToPath('/privacy'), Icon: PrivacyTipIcon, text: 'Privacy' },
  ];

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={toggleDrawer}
    >
      <Box className='w-[250px] tablet:w-[300px]'>
        {buildList({ listData })}
      </Box>
    </Drawer>
  );
};

export default SiteNav;
