import { useEffect } from 'react';

export const useLoadIfNeeded = ({
  loadIfNeeded = false,
  data,
  hasLoaded,
  loading,
  ModelInstance,
  id,
}) => {
  useEffect(() => {
    if (loadIfNeeded && !data && !hasLoaded && !loading) ModelInstance.loadIfNeeded();
  }, [loadIfNeeded, id, data, loading, hasLoaded, ModelInstance]);
};
