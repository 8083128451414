import { Card } from '@mui/material';

const PostCardLoader = ({ height }) => {
  const isImage = height > 112;
  const contentHeight = isImage ? 'calc(100% - 112px)' : '100%';
  return (
    <div className='post-card-loader bg-surface width-[100%] py-1 px-1 relative'>
      <Card sx={{ padding: '5px 8px', width: '100%', backgroundColor: 'var(--color-s003dp)' }}>
        <div className='flex justify-between'>
          <div className='flex'>
            <div style={{ borderRadius: '100%' }} className='avatar-loader h-[30px] w-[30px] bg-s04dp' />
            <div className='space-y-1 ml-3'>
              <div className='rounded bg-s04dp w-[100px] h-[12px]' />
              <div className='rounded bg-s04dp w-[100px] h-[8px]' />
            </div>
          </div>
          <div style={{ borderRadius: '100%' }} className='h-[20px] w-[20px] bg-s04dp' />
        </div>
        <div style={{ height: height || contentHeight }}>
          <div className='rounded bg-s04dp w-[250px] h-[16px] mt-4' />
          { !isImage && <div className='rounded bg-s04dp w-[250px] h-[26px] mt-2' /> }
          { isImage && <div className='rounded bg-s04dp w-[300px] h-[250px] mt-2' /> }
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex space-x-3 mt-3 pb-2'>
            <div style={{ borderRadius: '100%' }} className='h-[20px] w-[20px] bg-s04dp' />
            <div style={{ borderRadius: '100%' }} className='h-[20px] w-[20px] bg-s04dp' />
            <div style={{ borderRadius: '100%' }} className='h-[20px] w-[20px] bg-s04dp' />
          </div>
          <div className='flex space-x-2 mt-3 pb-2'>
            <div style={{ borderRadius: '100%' }} className='h-[20px] w-[20px] bg-s04dp' />
            <div style={{ borderRadius: '100%' }} className='h-[20px] w-[20px] bg-s04dp' />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PostCardLoader;
