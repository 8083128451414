import { SocketUpdateActions } from '../../lib/constants';
import { emitRedirect } from '../../lib/emitters/emitRedirect';
import { handleGlobalEffects } from './handleGlobalEffects';

const handleApplicationEffects = (res) => {
};

export const handleApplication = (res) => {
  const { action, data } = res;
  switch (action) {
    case SocketUpdateActions.Reload: {
      window.location.reload();
      break;
    }
    case SocketUpdateActions.Redirect: {
      const { to } = data;
      if (!to) break;
      emitRedirect({ to });
      break;
    }
    default: {
      console.log('unknown application action', action);
    }
  }
  handleApplicationEffects(res);
  handleGlobalEffects(res);
};
