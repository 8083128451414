import { getRecoil } from 'recoil-nexus';
import { TkuQueryClient } from '../../providers/query';
import { authenticatedUserSelector } from '../../models/authenticatedUser/selectors';

export const getOwner = (topic) => topic.usersPermitted.find((user) => user.role === 'owner');

export const getIsOwnerBlocked = (topic) => {
  const authenticatedUser = getRecoil(authenticatedUserSelector);
  const owner = getOwner(topic);
  const isOwnerBlocked = !!authenticatedUser.blocked.find((user) => user._id === owner.user._id);
  return isOwnerBlocked;
};

window.TkuQueryClient = TkuQueryClient;
