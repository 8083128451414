import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  searchQuerySelector,
} from './selectors';
import { useSearch } from './useSearch';

export const useSearchOnQueryChange = (debounceTime = 250) => {
  const searchQuery = useRecoilValue(searchQuerySelector);

  const search = useSearch(debounceTime);

  useEffect(() => {
    if (!searchQuery) return;
    search(searchQuery);
  }, [searchQuery]);
};
