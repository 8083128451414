import base from './base';
import dark from './dark';
import pulseFusion from './pulseFusion';
import vividEdge from './vividEdge';

export const ThemeData = {
  Dark: {
    value: 'dark',
    displayName: '2KU Dark',
    icon: '🌙',
    mode: 'dark',
    theme: dark,
  },
  Base: {
    value: 'base',
    displayName: '2KU Light',
    icon: '☀️',
    mode: 'light',
    theme: base,
  },
  PulseFusion: {
    value: 'pulseFusion',
    displayName: 'Pulse Fusion',
    icon: '🍏',
    mode: 'dark',
    theme: pulseFusion,
  },
  VividEdge: {
    value: 'vividEdge',
    displayName: 'Vivid Edge',
    icon: '🍊',
    mode: 'dark',
    theme: vividEdge,
  },
};

export const themeNames = Object.keys(ThemeData).reduce((acc, key) => {
  acc.push(ThemeData[key].value);
  return acc;
}, []);

let _DEFAULT_THEME = ThemeData.PulseFusion.value;

if (localStorage.getItem('DEFAULT_THEME') && themeNames.includes(localStorage.getItem('DEFAULT_THEME'))) _DEFAULT_THEME = localStorage.getItem('DEFAULT_THEME');
// eslint-disable-next-line prefer-destructuring
else _DEFAULT_THEME = themeNames[0];

export const DEFAULT_THEME = _DEFAULT_THEME;

export const themes = {
  base,
  dark,
  pulseFusion,
  vividEdge,
};
