import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import {
  navSearchActiveSelector,
  searchResultsSelector,
  searchQuerySelector,
  searchStatusSelector,
  lastSearchQuerySelector,
} from '../../models/search/selectors';
import { useLatestQueries } from '../../models/search/useLatestQueries';
import { Content, Wrapper } from '../Containers';
import UniverseLoader from '../Loaders/UniverseLoader';
import CategoryCard from '../CategoryCard';
import UserAvatar from '../UserAvatar';
import TopicContent from '../TopicContent';
import ErrorBoundary from '../ErrorBoundary';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import PostPreview from '../PostPreview';

const SearchPopper = () => {
  const [navSearchActive, setNavSearchActive] = useRecoilState(navSearchActiveSelector);
  const searchResults = useRecoilValue(searchResultsSelector);
  const [searchQuery, setSearchQuery] = useRecoilState(searchQuerySelector);
  const {
    latestQueries,
    deleteAllSearchQueries,
    deleteQuery,
  } = useLatestQueries({ limit: 10 });
  const { loading, error } = useRecoilValue(searchStatusSelector);
  const lastSearchQuery = useRecoilValue(lastSearchQuerySelector);
  const location = useLocation();

  // FOR DEBUGGING
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const anchorEl = useMemo(() => document.querySelector('nav.primary '));

  useEffect(() => {
    setNavSearchActive(false);
  }, [location]);

  const latestQueriesSection = useMemo(() => {
    if (!latestQueries.length || loading) return null;
    if (!!searchQuery && !!lastSearchQuery && (lastSearchQuery === searchQuery)) return null;
    const handleListItemClick = (query) => () => setSearchQuery(query);
    return (
      <>
        <div className='px-4 pt-2 flex justify-between items-center'>
          <h1>Recent Searches</h1>
          <Button
            color='textPrimary'
            size='small'
            onClick={deleteAllSearchQueries}
          >
            Clear All
          </Button>
        </div>
        <List>
          {latestQueries.map(query => (
            <ListItem
              dense
              disablePadding
              key={query._id}
              secondaryAction={(
                <IconButton
                  edge='end'
                  size='small'
                  onClick={() => deleteQuery(query)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            >
              <ListItemButton
                onClick={handleListItemClick(query._id)}
                dense
              >
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={query._id} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    );
  }, [latestQueries, searchQuery, lastSearchQuery, loading, deleteQuery, deleteAllSearchQueries]);

  const searchResultsSection = useMemo(() => {
    if (!searchQuery) return null;

    if (loading && lastSearchQuery !== searchQuery) {
      return (
        <div className='relative py-3 px-3 h-[300px] flex items-center justify-center'>
          <UniverseLoader boxType='fullContainer' size={100} showLogo={false} />
        </div>
      );
    }

    if (!loading && (lastSearchQuery !== searchQuery)) return null;

    const handleLinkClick = () => setNavSearchActive(false);

    const {
      topics,
      users,
      categories,
      posts,
    } = searchResults;

    let _categories;
    let _topics;
    let _users;
    let _posts;

    if (!topics.length && !users.length && !categories.length && !posts.length) {
      return (
        <div className='py-3 px-3 h-[300px] flex items-center justify-center'>
          <h1>No results found</h1>
        </div>
      );
    }

    const divisionClass = 'px-3 py-1';
    const sectionClass = '';

    const header = (
      <h1 className='px-3 pt-2 text-center'>{`results for "${searchQuery}"`}</h1>
    );

    if (categories.length) {
      _categories = (
        <div className={divisionClass}>
          <h4 className={sectionClass}>categories</h4>
          { categories.map(category => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <CategoryCard containerClass='text-primary-text flex items-center font-roboto justify-between my-2 text-[15px]' linkClickCallback={handleLinkClick} avatarSize='26px' {...category} key={category._id} />
          ))}
        </div>
      );
    }

    if (topics.length) {
      _topics = (

        <div className={divisionClass}>
          <h4 className={sectionClass}>topics</h4>
          {topics.map((topic, i) => (
            <React.Fragment key={topic._id}>
              <Link onClick={handleLinkClick} to={`/topic/${topic.shortId}/${topic.slug}`}>
                <div className='my-2'>
                  <TopicContent content={topic.title} />
                  <div className='flex space-x-2'>
                    <div className='flex space-x-2 items-center'>
                      <UserAvatar avatarSize='16px' username={topic.category.name} color={topic.category.color} avatar={topic.category.image} />
                      <span className='text-xs'>{topic.category.name}</span>
                    </div>
                    <span className='text-xs'>{format(new Date(topic.createdOn), 'MM/dd/yy')}</span>
                    <span className='text-xs'>{`by ${topic.author.username}`}</span>
                  </div>
                </div>
              </Link>
              { i !== topics.length - 1 && <Divider /> }
            </React.Fragment>
          ))}
        </div>
      );
    }

    if (users.length) {
      _users = (
        <div className={divisionClass}>
          <h4 className={sectionClass}>users</h4>
          {users.map(user => (
            <Link onClick={handleLinkClick} key={user._id} className='flex space-x-3 py-1' to={`users/${user.username}`}>
              <UserAvatar _id={user._id} avatarSize='22px' showOnline />
              <span className='text-[15px]'>{user.username}</span>
            </Link>
          ))}
        </div>
      );
    }

    if (posts.length) {
      _posts = (
        <div className={divisionClass}>
          <h4 className={sectionClass}>posts</h4>
          {posts.map((post, i) => (
            <React.Fragment key={post._id}>
              <Link onClick={handleLinkClick} key={post._id} to={`/topic/${post.topic.shortId}/${post.topic.slug}?pid=${post._id}`}>
                <PostPreview post={post} />
              </Link>
              { (posts.length > 1 && i !== posts.length - 1) && <Divider /> }
            </React.Fragment>
          ))}
        </div>
      );
    }

    return (
      <ErrorBoundary>
        { header }
        { _topics }
        { _categories }
        { _users }
        { _posts }
      </ErrorBoundary>
    );
  }, [searchResults, searchQuery, loading, lastSearchQuery]);

  if (navSearchActive) {
    return (
      <Popper
        style={{
          zIndex: 100,
          width: '100vw',
          backgroundColor: 'transparent',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 200px)',
        }}
        modifiers={[
          { name: 'offset', options: { offset: [0, 0] } },
          { name: 'flip', enabled: false },
        ]}
        placement='bottom-start'
        id='search-results-container'
        open
        anchorEl={anchorEl}
      >
        <Wrapper applyBreakpoints className='bg-surface text-primary-text font-roboto prevent-clickaway-search-results'>
          <Content className='bg-s04dp rounded'>
            { latestQueriesSection }
            { searchResultsSection }
          </Content>
        </Wrapper>
      </Popper>
    );
  }

  return null;
};

export default SearchPopper;
