import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ApiClientInstance from '../../clients/api';
import { UserModel } from '../user/model';
import {
  searchStatusSelector,
  searchResultsSelector,
  lastSearchQuerySelector,
  searchQuerySelector,
} from './selectors';
import debounce from '../../lib/debounce';

export const useSearch = (debounceTime = 250) => {
  const setResults = useSetRecoilState(searchResultsSelector);
  const setSearchStateStatus = useSetRecoilState(searchStatusSelector);
  const setLastSearchQuery = useSetRecoilState(lastSearchQuerySelector);
  const searchQuery = useRecoilValue(searchQuerySelector);

  const search = useCallback(debounce(async (query) => {
    setSearchStateStatus({ loading: true });
    const res = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: '/search',
      queryParams: { query },
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) {
      setResults(res.data);
      setLastSearchQuery(query);
      const { users } = res.data;
      if (users?.length) for (const user of users) UserModel.setUserPartial(user?._id, user);
      setSearchStateStatus({ loading: false, error: false });
    }
    if (res.error) {
      setSearchStateStatus({ loading: false, error: true });
    }
  }, debounceTime), []);

  useEffect(() => {
    if (!searchQuery) return;
    search(searchQuery);
  }, [searchQuery]);

  return search;
};
