import {
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import { Content, Wrapper } from '../../Containers';
import { emitCustomEvent } from '../../../hooks/useCustomEventListener';
import { CustomEvents } from '../../../lib/constants';

const TopicListEmptyComponent = ({ category }) => {
  const { authenticated, unauthenticated } = useAuthenticatedUser();
  const handleTopicCreateClick = useCallback(() => emitCustomEvent(CustomEvents.OpenTopicCreateModal, { category }), [category]);
  const navigate = useNavigate();

  return (
    <Wrapper className='topic-list-empty'>
      <Content className='text-primary-text relative flex justify-center tablet:items-center font-roboto'>
        <div className='py-16 desktop:py-0 flex flex-col items-center'>
          <h3 className='text-lg mt-0.5'>It looks like there aren&#39;t any topics here 😑</h3>
          <h3 className='text-lg mt-0.5'>You should fix this</h3>
          { authenticated && (
          <Button
            size='large'
            variant='contained'
            sx={{
              marginTop: '1rem',
            }}
            onClick={handleTopicCreateClick}
          >
            Create New Topic
          </Button>
          ) }
          { unauthenticated && (
          <Button
            size='large'
            color='secondary'
            variant='contained'
            sx={{
              marginTop: '1rem',
            }}
            onClick={() => navigate('/register')}
          >
            Sign Up
          </Button>
          ) }
        </div>
      </Content>
    </Wrapper>
  );
};

export default TopicListEmptyComponent;
