import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  useSetRecoilState,
} from 'recoil';
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ApiClient from '../../clients/api';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { Wrapper, Content } from '../../components/Containers';
import { checkEmail, checkPassword, passwordChecks } from '../../lib/validateUser';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { SignUpFormClasses } from '../../lib/constants';
import PasswordInput from '../../components/PasswordInput';

const checkUsername = str => str.length > 3 && str.length < 17;

const defaultPasswordHelperText = '8 character minimum: at least one uppercase, one lowercase, one number, and one special character.';
const getPasswordHelperText = (str) => {
  if (!str) return defaultPasswordHelperText;
  return checkPassword(str) ? defaultPasswordHelperText : passwordChecks(str).message;
};

const Register = () => {
  // Hooks
  const navigate = useNavigate();
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  // Global State
  const { authenticated, loading } = useAuthenticatedUser();
  const [registrationError, setRegistrationError] = useState(null);

  // Local State
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [passwordHelperText, setPasswordHelperText] = useState(defaultPasswordHelperText);

  // Guard Clause: User is signed in
  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated]);

  // Validation
  useEffect(() => {
    if (registrationError) {
      setRegistrationError(false);
    }
    if (checkUsername(username) && checkEmail(email) && checkPassword(password) && privacy && terms) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [email, password, username, terms, privacy]);

  useEffect(() => {
    setPasswordHelperText(getPasswordHelperText(password));
  }, [password]);

  // Handlers
  const handleUsername = useCallback((e) => setUsername(e.target.value), []);
  const handleEmail = useCallback((e) => setEmail(e.target.value), []);
  const handlePassword = useCallback((e) => setPassword(e.target.value), []);
  const handleRegister = useCallback(async () => {
    const res = await ApiClient.register({ email, username, password });
    if (!res?.success) addSnackbar({ message: res.error, severity: 'error' });
  }, [username, email, password]);

  if (loading) return null;

  return (
    <Wrapper className='register'>
      <Content className={SignUpFormClasses.Content}>
        <div className={SignUpFormClasses.MainDiv}>
          <h1 className='font-bebas text-4xl text-primary-text text-center'>Join the best.</h1>
          <form className={SignUpFormClasses.Form}>
            <TextField
              autoComplete='username'
              error={registrationError}
              sx={{ mt: 2 }}
              onChange={handleUsername}
              value={username}
              fullWidth
              label='username'
              helperText='4 character minimum. 16 character maximum.'
            />

            <TextField autoComplete='email' error={registrationError} sx={{ mt: 2 }} onChange={handleEmail} value={email} fullWidth label='email' helperText={'You\'ll be asked to verify your email after signup.'} />

            <PasswordInput
              autoComplete='new-password'
              error={registrationError}
              handleChange={handlePassword}
              value={password}
              fullWidth
              label='password'
              helperText={passwordHelperText}
              sx={{ mt: 2 }}
            />

            <FormGroup className='flex space-y-2 my-4'>
              <FormControlLabel
                sx={{ color: 'var(--color-text-primary)' }}
                control={<Checkbox onChange={() => setTerms(!terms)} checked={terms} />}
                label={(
                  <span className='text-sm opacity-70 leading-2'>
                    I certify that I am at least 13 years of age and that I have read and accept the 2KUniverse.com
                    <Link className='ml-1 underline' to='/terms-of-use'>Terms of Use</Link>
                  </span>
                  )}
              />
              <FormControlLabel
                sx={{ color: 'var(--color-text-primary)' }}
                control={<Checkbox onChange={() => setPrivacy(!privacy)} checked={privacy} />}
                label={(
                  <span className='text-sm opacity-70'>
                    I certify that I have read and accept the 2KUniverse.com
                    <Link className='ml-1 underline' to='/privacy'>Privacy Statement</Link>
                  </span>
                  )}
              />
            </FormGroup>

            <Button size='large' sx={{ mt: 2 }} disabled={!isValid || registrationError} onClick={handleRegister} fullWidth variant='contained'>Create Account</Button>
          </form>
        </div>
      </Content>
    </Wrapper>
  );
};

export default Register;
