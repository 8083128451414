import {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {
  useSetRecoilState,
  useRecoilState,
} from 'recoil';
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
  TextField,
  InputAdornment,
  Popper,
  Chip,
  Avatar,
  Button,
  ClickAwayListener,
  Badge,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';

import ApiClientInstance from '../../clients/api';

import {
  ProductSortValues,
} from '../../lib/constants';
import debounce from '../../lib/debounce';

import storeProductSortKeyState from '../../models/storeProductsSortKey/atom';
import storeProductCategoriesState from '../../models/storeProductCategories/atom';
import storeProductSelectedCategoriesState from '../../models/storeProductSelectedCategories/atom';
import storeProductSearchState from '../../models/storeProductSearch/atom';

const StoreBadgesToolbar = () => {
  // sort state
  const [sort, setSort] = useState(ProductSortValues[0].label);
  const setGlobalSort = useSetRecoilState(storeProductSortKeyState);

  // search state
  const [searchQuery, setSearchQuery] = useState('');
  const setStoreProductSearchState = useSetRecoilState(storeProductSearchState);

  // filter state
  const [filterActive, setFilterActive] = useState(false);
  const [storeProductSelectedCategories, setStoreProductSelectedCategories] = useRecoilState(storeProductSelectedCategoriesState);

  const [categories, setCategories] = useRecoilState(storeProductCategoriesState);

  useEffect(() => {
    let isMounted = true;
    const getCategories = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'GET',
        path: '/store/product-categories',
        catchErorr: true,
      });
      if (res.success && isMounted) {
        const state = {
          tier1: [],
          tier2: [],
          tier3: [],
        };
        for (const category of res.data) {
          if (category.tier === 1) state.tier1.push(category);
          if (category.tier === 2) state.tier2.push(category);
          if (category.tier === 3) state.tier3.push(category);
        }
        setCategories(state);
      }
    };
    getCategories();
    return () => { isMounted = false; };
  }, []);

  const handleSortChange = useCallback((e) => {
    setSort(e.target.value);
    const sortValue = ProductSortValues.find(item => item.label === e.target.value);
    setGlobalSort(sortValue.value);
  }, []);

  const sortElement = useMemo(() => (
    <div>
      <FormControl
        sx={{ width: '150px' }}
        size='small'
      >
        <InputLabel id='sort-select'>sort</InputLabel>
        <Select
          labelId='product-sort-select'
          id='product-sort-select'
          value={sort}
          label='sort'
          onChange={handleSortChange}
        >
          { ProductSortValues.map(item => (
            <MenuItem
              key={item.label}
              value={item.label}
              dense
            >
              {item.label}
            </MenuItem>
          )) }
        </Select>
      </FormControl>
    </div>
  ), [sort]);

  const setStoreProductSearchStateDebounced = useCallback(debounce((value) => setStoreProductSearchState(value), 500), []);

  useEffect(() => {
    setStoreProductSearchStateDebounced(searchQuery);
  }, [searchQuery, setStoreProductSearchStateDebounced]);

  const handleCategoryClick = useCallback((category) => {
    setStoreProductSelectedCategories(prev => {
      const includes = prev.includes(category);
      if (!includes) return [...prev, category];
      return prev.filter(item => item !== category);
    });
  }, []);

  const handleFilterClickAway = useCallback((e) => {
    if (e.target === document.body) return null;
    if (e.target.closest('.store-badges-filter-button') !== null) return null;
    setFilterActive(false);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setStoreProductSearchState('');
  }, []);

  const handleClearFilter = useCallback(() => {
    setStoreProductSelectedCategories([]);
    setSearchQuery('');
    setStoreProductSearchState('');
  }, []);

  const renderFilterChip = useCallback((item) => {
    const selected = storeProductSelectedCategories.includes(item._id);
    return (
      <div key={item._id}>
        <Chip
          color={selected ? 'primary' : 'default'}
          size='large'
          avatar={item.image ? <Avatar src={item.image} /> : null}
          label={item.name}
          onClick={() => handleCategoryClick(item._id)}
        />
      </div>
    );
  }, [storeProductSelectedCategories]);

  const badgeCount = useMemo(() => {
    let count = 0;
    if (storeProductSelectedCategories.length > 0) count += storeProductSelectedCategories.length;
    if (searchQuery.length > 0) count += 1;
    return count;
  }, [storeProductSelectedCategories, searchQuery]);

  const filterElement = useMemo(() => (
    <div className='store-badges-filter-button'>
      <div className='flex items-center justify-between'>
        <IconButton
          onClick={() => setFilterActive(prev => !prev)}
        >
          <Badge badgeContent={badgeCount} color='primaryLight'>
            <TuneIcon color={filterActive ? 'primaryLight' : 'primaryText'} />
          </Badge>
        </IconButton>
        <span
          role='button'
          tabIndex={0}
          onKeyDown={() => setFilterActive(prev => !prev)}
          onClick={() => setFilterActive(prev => !prev)}
          className={`${filterActive ? 'text-primary-light' : 'text-primaryText'}`}
        >
          Search/Filter
        </span>
      </div>
      <Popper
        className='rounded px-2'
        style={{ zIndex: 1000, overflow: 'auto' }}
        modifiers={[{ name: 'offset', options: { offset: [-8, 0] } }]}
        placement='bottom-start'
        id='filters-container'
        open={filterActive}
        anchorEl={() => document.querySelector('.store-badges-filter-button')}
        keepMounted={false}
      >
        <ClickAwayListener onClickAway={handleFilterClickAway}>
          <div className='bg-surface text-primary-text font-roboto min-w-[300px] rounded'>
            <div className='py-2 bg-s06dp px-2 rounded'>
              <TextField
                size='small'
                placeholder='search products'
                value={searchQuery}
                fullWidth
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon
                        className='opacity-80'
                        color='primaryText'
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position='end'
                    >
                      <CloseIcon
                        color='primaryText'
                        sx={{
                          cursor: 'pointer',
                          height: '20px',
                          opacity: '.8',
                        }}
                        disabled={!searchQuery}
                        onClick={handleClearSearch}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <div className='text-lg mb-1 mt-3'>Categories</div>
              <div style={{ gap: '5px' }} className='inline-flex flex-wrap'>
                { categories.tier1.length > 0 && categories.tier1.map(renderFilterChip)}
              </div>
              <div className='text-lg mt-3 mb-1'>Collections</div>
              <div style={{ gap: '5px' }} className='inline-flex flex-wrap'>
                { categories.tier2.length > 0 && categories.tier2.map(renderFilterChip)}
              </div>
              <div className='text-lg mt-3 mb-1'>Tags</div>
              <div style={{ gap: '5px' }} className='inline-flex flex-wrap'>
                { categories.tier3.length > 0 && categories.tier3.map(renderFilterChip)}
              </div>
              <div className='mt-2'>
                <Button
                  fullWidth
                  variant='outlined'
                  style={{ marginTop: '10px' }}
                  color='primaryLight'
                  disabled={storeProductSelectedCategories.length === 0 && searchQuery === ''}
                  onClick={handleClearFilter}
                >
                  Clear Selections
                </Button>
                <Button
                  fullWidth
                  variant='contained'
                  style={{ marginTop: '10px' }}
                  color='primary'
                  onClick={() => setFilterActive(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  ), [filterActive, categories, storeProductSelectedCategories, searchQuery, badgeCount]);

  return (
    <div className='h-[50px] tablet:h-[50px] w-[100%] rounded mt-2 text-primary-text pl-2 pr-2 flex items-center justify-between font-roboto'>
      <div className='space-x-1 flex'>
        { filterElement }
      </div>
      <div className='flex space-x-1'>
        { sortElement }
      </div>
    </div>
  );
};

export default StoreBadgesToolbar;
