import { getRecoil, setRecoil } from 'recoil-nexus';
import * as TopicApi from './api';
import { defaultLoadableAtom } from '../../lib/constants';

import { BaseModel } from '../BaseModel';
import { UserModel } from '../user/model';

import state from './atom';
import topicShortIdMappingState from '../topicShortIdMapping/atom';
import postState from '../post/atom';
import lastSeenPostsInTopicState from '../lastSeenPostsInTopic/atom';

const getTime = () => new Date().getTime();

export class TopicModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => TopicModel.requestTopic(id);
    super(id, state, request);
    this.id = id;
  }

  static setTopic(id, data) {
    if (!id || !data) return;
    if (data?.lastSeenPost) setRecoil(lastSeenPostsInTopicState(id), data.lastSeenPost);
    TopicModel.setMainPostInState(data);
    if (data?.shortId) TopicModel.setShortIdMapping(data.shortId, id);
    if (data.author?.username && data.author?._id) UserModel.setUserPartial(data.author._id, data.author);
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  onSetState = (topicState, prevTopicState) => {
    if (topicState?.data?.lastSeenPost) setRecoil(lastSeenPostsInTopicState(this.id), topicState.data.lastSeenPost);
    if (!topicState?.data?.shortId) return;
    TopicModel.setMainPostInState(topicState.data);
    TopicModel.setShortIdMapping(topicState?.data?.shortId, this.id);
    if (topicState.data.author?.username && topicState.data.author?._id) UserModel.setUserPartial(topicState.data.author._id, topicState.data.author);
  };

  static setShortIdMapping = (shortId, topicId) => setRecoil(topicShortIdMappingState(shortId), topicId);

  static setMainPostInState(data) {
    if (!data) return;
    const post = { ...data?.mainPost, author: data?.author };
    const topic = { ...data, mainPost: post._id };
    post.topic = topic;
    return setRecoil(postState(post._id), { ...defaultLoadableAtom, data: post, lastModified: getTime() });
  }

  static setTopicWithoutMainPost(id, data) {
    if (!id || !data) return;
    delete data?.mainPost;
    setRecoil(state(id), prev => ({ ...prev, data: { ...prev.data, ...data }, lastModified: getTime() }));
  }

  static updateLastPostSeenInTopic = async (id, lastPostSeen) => TopicApi.updateLastPostSeenInTopic(id, lastPostSeen);

  static getTopic(id) {
    if (!id) return;
    return getRecoil(state(id)).data;
  }

  static requestTopic(id) {
    if (!id) return;
    return TopicApi.requestTopic(id);
  }

  static hideTopic(id) {
    if (!id) return;
    return TopicApi.hideTopic(id);
  }

  static unhideTopic(id) {
    if (!id) return;
    return TopicApi.unhideTopic(id);
  }

  static unsaveTopic(id) {
    if (!id) return;
    return TopicApi.unsaveTopic(id);
  }

  static saveTopic(id) {
    if (!id) return;
    return TopicApi.saveTopic(id);
  }

  static requestTopicFromShortId(shortId) {
    if (!shortId) return;
    return TopicApi.requestTopicFromShortId(shortId);
  }

  unhide = () => {
    if (!this.id) return;
    return TopicApi.unhideTopic(this.id);
  };

  hide = () => {
    if (!this.id) return;
    return TopicApi.hideTopic(this.id);
  };

  unsave = () => {
    if (!this.id) return;
    return TopicApi.unsaveTopic(this.id);
  };

  save = () => {
    if (!this.id) return;
    return TopicApi.saveTopic(this.id);
  };

  lock = () => {
    if (!this.id) return;
    return TopicApi.lockTopic(this.id);
  };

  unlock = () => {
    if (!this.id) return;
    return TopicApi.unlockTopic(this.id);
  };

  pin = ({ type, expires, active }) => {
    if (!this.id) return;
    return TopicApi.updateTopicPin(this.id, { type, expires, active });
  };
}
