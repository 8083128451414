import ApiClientInstance from '../../clients/api';

export const requestPost = (id) => ApiClientInstance.sendRequest({
  method: 'get',
  path: `/post/id/${id}`,
  catchError: true,
});

export const vote = (id, delta) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/post/vote/id/${id}`,
  data: { delta },
  catchError: true,
});

export const save = (id) => ApiClientInstance.sendRequest({
  path: `/post/${id}/save`,
  method: 'put',
  catchError: true,
});

export const unsave = (id) => ApiClientInstance.sendRequest({
  path: `/post/${id}/unsave`,
  method: 'put',
  catchError: true,
});

export const getReplies = (id) => ApiClientInstance.sendRequest({
  path: `/post/${id}/replies`,
  method: 'get',
  catchError: true,
});

export const getVotes = (id, voteType) => ApiClientInstance.sendRequest({
  path: `/post/votes/${id}`,
  method: 'GET',
  catchError: true,
  queryParams: {
    voteType,
    postType: 'public',
  },
});
