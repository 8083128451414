import { useMemo, memo } from 'react';
import {
  DisplaySourceTypes,
  CardHeaderContentDetailTypes,
} from '../../../lib/constants';
import { usePost } from '../../../models/post/usePost';
import { CardHeaderContainer } from '../../PostCard/CardHeaderContainer';
import { CardHeaderContent } from '../../PostCard/CardHeaderContent';
import { CardPostContent } from '../../PostCard/CardPostContent';
import { CardActions } from '../../PostCard/CardActions';

import { CardContainer } from '../../TopicCard/CardContainer';

const detailsType = CardHeaderContentDetailTypes.User;

const PostCardPreview = ({
  _id,
  sourceType,
}) => {
  const { data, loading } = usePost(_id);
  const cardContent = useMemo(() => {
    if (!data || loading) return null;
    return (
      <CardContainer sourceType={sourceType} author={data?.author} isDeleted={data?.isDeleted}>
        <CardHeaderContainer
          isDeleted={false}
          pinned={false}
          replyToPid={data?.replyToPid}
          includeReply={false}
          includeEditMenu={false}
          _id={_id}
          sourceType={sourceType}
        >
          <CardHeaderContent
            author={data?.author}
            createdOn={data?.createdOn}
            detailsType={detailsType}
            sourceType={sourceType}
            topicAuthor={data?.topic?.author}
            showUserHighlight
          />
        </CardHeaderContainer>
        <CardPostContent displaySource={DisplaySourceTypes.Simple} sourceType={sourceType} _id={_id} />
        <CardActions
          topicId={data.topic._id}
          postId={_id}
          showRepliesCount={false}
          showEditorActions={false}
        />
      </CardContainer>
    );
  }, [JSON.stringify(data), sourceType, _id, loading, detailsType]);

  return (
    <article
      data-post-id={_id}
      className={`post-card font-roboto flex flex-col space-y-2 py-1 px-1 post-${_id}`}
    >
      { cardContent }
    </article>
  );
};

export default memo(PostCardPreview);
