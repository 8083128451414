import { atomFamily } from 'recoil';
import { defaultLoadableAtom } from '../../lib/constants';

const userState = atomFamily({
  key: 'userState_v2',
  default: {
    ...defaultLoadableAtom,
  },
});

export default userState;
