import {
  createElement,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

/* eslint-disable no-useless-concat */
const LexicalComposerContext = require('@lexical/react/LexicalComposerContext');
const utils = require('@lexical/utils');
const lexical = require('lexical');
const React = require('react');

const CAN_USE_DOM = typeof window !== 'undefined' && typeof window.document !== 'undefined' && typeof window.document.createElement !== 'undefined';

const useLayoutEffectImpl = CAN_USE_DOM ? React.useLayoutEffect : React.useEffect;
const useLayoutEffect = useLayoutEffectImpl;

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';

class TypeaheadOption {
  constructor(key) {
    this.key = key;
    this.ref = {
      current: null,
    };
    this.setRefElement = this.setRefElement.bind(this);
  }

  setRefElement(element) {
    this.ref = {
      current: element,
    };
  }
}

const scrollIntoViewIfNeeded = target => {
  const container = document.getElementById('typeahead-menu');

  if (container) {
    const containerRect = container.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    if (targetRect.bottom > containerRect.bottom) {
      target.scrollIntoView(false);
    } else if (targetRect.top < containerRect.top) {
      target.scrollIntoView();
    }
  }
};

function getTextUpToAnchor(selection) {
  const { anchor } = selection;

  if (anchor.type !== 'text') {
    return null;
  }

  const anchorNode = anchor.getNode();

  if (!anchorNode.isSimpleText()) {
    return null;
  }

  const anchorOffset = anchor.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
}

function tryToPositionRange(leadOffset, range) {
  const domSelection = window.getSelection();

  if (domSelection === null || !domSelection.isCollapsed) {
    return false;
  }

  const { anchorNode } = domSelection;
  const startOffset = leadOffset;
  const endOffset = domSelection.anchorOffset;

  if (anchorNode == null || endOffset == null) {
    return false;
  }

  try {
    range.setStart(anchorNode, startOffset);
    range.setEnd(anchorNode, endOffset);
  } catch (error) {
    return false;
  }

  return true;
}

function getQueryTextForSearch(editor) {
  let text = null;
  editor.getEditorState().read(() => {
    const selection = lexical.$getSelection();

    if (!lexical.$isRangeSelection(selection)) {
      return;
    }

    text = getTextUpToAnchor(selection);
  });
  return text;
}
/**
  * Walk backwards along user input and forward through entity title to try
  * and replace more of the user's text with entity.
  */

function getFullMatchOffset(documentText, entryText, offset) {
  let triggerOffset = offset;

  for (let i = triggerOffset; i <= entryText.length; i++) {
    if (documentText.substr(-i) === entryText.substr(0, i)) {
      triggerOffset = i;
    }
  }

  return triggerOffset;
}
/**
  * Split Lexica TextNode and return a new TextNode only containing matched text.
  * Common use cases include: removing the node, replacing with a new node.
  */

function splitNodeContainingQuery(editor, match) {
  const selection = lexical.$getSelection();

  if (!lexical.$isRangeSelection(selection) || !selection.isCollapsed()) {
    return null;
  }

  const { anchor } = selection;

  if (anchor.type !== 'text') {
    return null;
  }

  const anchorNode = anchor.getNode();

  if (!anchorNode.isSimpleText()) {
    return null;
  }

  const selectionOffset = anchor.offset;
  const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
  const characterOffset = match.replaceableString.length;
  const queryOffset = getFullMatchOffset(textContent, match.matchingString, characterOffset);
  const startOffset = selectionOffset - queryOffset;

  if (startOffset < 0) {
    return null;
  }

  let newNode;

  if (startOffset === 0) {
    [newNode] = anchorNode.splitText(selectionOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
  }

  return newNode;
}

function isSelectionOnEntityBoundary(editor, offset) {
  if (offset !== 0) {
    return false;
  }

  return editor.getEditorState().read(() => {
    const selection = lexical.$getSelection();

    if (lexical.$isRangeSelection(selection)) {
      const { anchor } = selection;
      const anchorNode = anchor.getNode();
      const prevSibling = anchorNode.getPreviousSibling();
      return lexical.$isTextNode(prevSibling) && prevSibling.isTextEntity();
    }

    return false;
  });
}

const ShortcutTypeahead = ({
  close,
  editor,
  resolution,
  options,
  menuRenderFn,
  onSelectOption,
}) => {
  const [selectedIndex, setHighlightedIndex] = React.useState(null);
  const anchorElementRef = useRef(document.createElement('div'));

  useEffect(() => {
    setHighlightedIndex(0);
  }, [resolution.match.matchingString]);

  useEffect(() => {
    const rootElement = editor.getRootElement();
    const positionMenu = () => {
      const containerDiv = anchorElementRef.current;
      containerDiv.setAttribute('aria-label', 'Typeahead menu');
      containerDiv.setAttribute('id', 'typeahead-menu');
      containerDiv.setAttribute('role', 'listbox');
      if (rootElement !== null) {
        const { range } = resolution;
        const {
          left,
          top,
          height,
        } = range.getBoundingClientRect();
        containerDiv.style.top = `${top + height + window.pageYOffset}px`;
        containerDiv.style.left = `${left + window.pageXOffset}px`;
        containerDiv.style.display = 'block';
        containerDiv.style.position = 'absolute';
        if (!containerDiv.isConnected) document.body.append(containerDiv);
        anchorElementRef.current = containerDiv;
        rootElement.setAttribute('aria-controls', 'typeahead-menu');
      }
    };

    positionMenu();
    window.addEventListener('resize', positionMenu);

    return () => {
      window.removeEventListener('resize', positionMenu);
      if (rootElement !== null) rootElement.removeAttribute('aria-controls');
    };
  }, [editor, resolution, options]);

  const selectOptionAndCleanUp = useCallback(async selectedEntry => {
    editor.update(() => {
      const textNodeContainingQuery = splitNodeContainingQuery(editor, resolution.match);
      onSelectOption(selectedEntry, textNodeContainingQuery, close, resolution.match.matchingString);
    });
  }, [close, editor, resolution.match, onSelectOption]);

  const updateSelectedIndex = useCallback(index => {
    const rootElem = editor.getRootElement();

    if (rootElem !== null) {
      rootElem.setAttribute('aria-activedescendant', `typeahead-item-${index}`);
      setHighlightedIndex(index);
    }
  }, [editor]);

  useEffect(() => () => {
    const rootElem = editor.getRootElement();

    if (rootElem !== null) rootElem.removeAttribute('aria-activedescendant');
  }, [editor]);

  useLayoutEffect(() => {
    if (options === null) {
      setHighlightedIndex(null);
    } else if (selectedIndex === null) {
      updateSelectedIndex(0);
    }
  }, [options, selectedIndex, updateSelectedIndex]);

  useEffect(() => utils.mergeRegister(editor.registerCommand(lexical.KEY_ARROW_DOWN_COMMAND, payload => {
    const event = payload;

    if (options !== null && selectedIndex !== null) {
      const newSelectedIndex = selectedIndex !== options.length - 1 ? selectedIndex + 1 : 0;
      updateSelectedIndex(newSelectedIndex);
      const option = options[newSelectedIndex];

      if (option.ref != null && option.ref.current) {
        scrollIntoViewIfNeeded(option.ref.current);
      }

      event.preventDefault();
      event.stopImmediatePropagation();
    }

    return true;
  }, lexical.COMMAND_PRIORITY_LOW), editor.registerCommand(lexical.KEY_ARROW_UP_COMMAND, payload => {
    const event = payload;

    if (options !== null && selectedIndex !== null) {
      const newSelectedIndex = selectedIndex !== 0 ? selectedIndex - 1 : options.length - 1;
      updateSelectedIndex(newSelectedIndex);
      const option = options[newSelectedIndex];

      if (option.ref != null && option.ref.current) {
        scrollIntoViewIfNeeded(option.ref.current);
      }

      event.preventDefault();
      event.stopImmediatePropagation();
    }

    return true;
  }, lexical.COMMAND_PRIORITY_LOW), editor.registerCommand(lexical.KEY_ESCAPE_COMMAND, payload => {
    const event = payload;

    if (options === null || selectedIndex === null) {
      return false;
    }

    event.preventDefault();
    event.stopImmediatePropagation();
    close();
    return true;
  }, lexical.COMMAND_PRIORITY_LOW), editor.registerCommand(lexical.KEY_TAB_COMMAND, payload => {
    const event = payload;

    if (options === null || selectedIndex === null || options[selectedIndex] == null) {
      return false;
    }

    event.preventDefault();
    event.stopImmediatePropagation();
    selectOptionAndCleanUp(options[selectedIndex]);
    return true;
  }, lexical.COMMAND_PRIORITY_LOW), editor.registerCommand(lexical.KEY_ENTER_COMMAND, event => {
    if (options === null || selectedIndex === null || options[selectedIndex] == null) {
      return false;
    }

    if (event !== null) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    selectOptionAndCleanUp(options[selectedIndex]);
    return true;
  }, lexical.COMMAND_PRIORITY_LOW)), [selectOptionAndCleanUp, close, editor, options, selectedIndex, updateSelectedIndex]);

  const listItemProps = useMemo(() => ({
    selectOptionAndCleanUp,
    selectedIndex,
    setHighlightedIndex,
  }), [selectOptionAndCleanUp, selectedIndex]);

  return menuRenderFn(anchorElementRef.current, listItemProps, resolution.match.matchingString);
};

export const LexicalTypeaheadMenuPlugin = ({
  options,
  onQueryChange,
  onSelectOption,
  menuRenderFn,
  triggerFn,
  editorName,
}) => {
  const [editor] = LexicalComposerContext.useLexicalComposerContext();
  const [resolution, setResolution] = useState(null);

  useEffect(() => {
    let activeRange = document.createRange();
    let previousText = null;

    const updateListener = () => {
      editor.getEditorState().read(() => {
        const range = activeRange;
        const selection = lexical.$getSelection();
        const text = getQueryTextForSearch(editor);

        if (!lexical.$isRangeSelection(selection) || !selection.isCollapsed() || text === previousText || text === null || range === null) {
          setResolution(null);
          return;
        }

        previousText = text;
        const match = triggerFn(text);
        onQueryChange(match ? match.matchingString : null);

        if (match !== null && !isSelectionOnEntityBoundary(editor, match.leadOffset)) {
          const isRangePositioned = tryToPositionRange(match.leadOffset, range);

          if (isRangePositioned !== null) {
            setResolution({
              match,
              range,
            });
            return;
          }
        }

        setResolution(null);
      });
    };

    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      activeRange = null;
      removeUpdateListener();
    };
  }, [editor, triggerFn, onQueryChange, resolution]);

  const closeTypeahead = useCallback(() => {
    setResolution(null);
  }, []);

  return resolution === null || editor === null ? null : /* #__PURE__ */createElement(ShortcutTypeahead, {
    close: closeTypeahead,
    resolution,
    editor,
    options,
    menuRenderFn,
    onSelectOption,
  });
};
