/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useMemo } from 'react';
import Lock from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import {
  Breakpoints, Platforms, UserRoles,
} from '../../../lib/constants';
import PostNavigator from '../../../components/PostNavigator';
import { editorIsActiveSelector } from '../../../models/editor/selectors';
import { breakpointNameSelector, isPwaSelector, platformSelector } from '../../../models/settings/selectors';
import { useTopic } from '../../../models/topic/useTopic';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import topicSourceTypState from '../../../models/topicSourceType/atom';

const bottomToolbarStyles = {
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  bottom: 0,
  zIndex: 1000,
  width: '100%',
  maxWidth: '980px',
};

const TopicBottomToolbar = ({ _id }) => {
  const { data: user, verified, unauthenticated } = useAuthenticatedUser();
  const [editorIsActive, setEditorIsActive] = useRecoilState(editorIsActiveSelector(_id));
  const breakpointName = useRecoilValue(breakpointNameSelector);
  const topicSourceType = useRecoilValue(topicSourceTypState(_id));
  const isPwa = useRecoilValue(isPwaSelector);
  const platform = useRecoilValue(platformSelector);
  const isIos = platform === Platforms.iOS;
  const navigate = useNavigate();

  const { data: topicData } = useTopic(_id, { sourceType: topicSourceType });

  const showReply = useMemo(() => {
    if (!user || !topicData) return false;
    if ([UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator].includes(user?.role)) return true;
    return !topicData?.isLocked;
  }, [topicData?.isLocked, user, verified]);

  const pwaStyles = isPwa && isIos ? 'px-3 pb-6' : 'px-3';

  if (editorIsActive || !topicData) return null;

  return (
    <div className='bg-surface rounded' style={bottomToolbarStyles}>
      <div
        style={{
          marginLeft: ![Breakpoints.Mobile, Breakpoints.Tablet].includes(breakpointName) ? '4px' : '',
        }}
        className={`flex items-center justify-between  bg-s01dp py-2 ${pwaStyles} desktop:pr-3 desktop:pl-1 rounded`}
      >
        <PostNavigator topicId={topicData?._id} />
        { showReply && <Button disabled={!verified} onClick={() => setEditorIsActive(true)} variant='contained' endIcon={<SendIcon />}>Reply</Button> }
        { (!showReply && topicData?.isLocked) && <Button onClick={() => setEditorIsActive(true)} disabled variant='contained' endIcon={<Lock />}>Locked</Button> }
        { (!showReply && !topicData?.isLocked && unauthenticated) && <Button onClick={() => navigate('/register')} variant='contained' color='secondary'>Sign Up</Button> }
      </div>
    </div>
  );
};

export default TopicBottomToolbar;
