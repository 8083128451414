/* eslint-disable react/jsx-props-no-spreading */
import {
  Dialog,
} from '@mui/material';
import {
  useCallback,
  useState,
} from 'react';
import {
  CustomEvents,
} from '../../lib/constants';
import { useCustomEvent } from '../../hooks/useCustomEventListener';

const ComponentDialog = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ maxWidth: true, fullWidth: true });
  const [text, setText] = useState('');
  const [component, setComponent] = useState(null);
  const [handler, setHandler] = useState(() => {});

  useCustomEvent(CustomEvents.TakeoverDialog, ({
    text: _text,
    handler: _handler,
    data: _data,
    component: _component,
  }) => {
    setOpen(true);
    if (_component) setComponent(() => _component);
    if (_text) setText(_text);
    if (_data) setData(_data);
    setHandler(() => _handler);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setText('');
    setHandler(() => {});
    setData({ maxWidth: true, fullWidth: true });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={data?.fullWidth !== undefined ? data?.fullWidth : true}
    >
      {!!component && component({
        handleCancel: handleClose,
        handleConfirm: handler,
        data,
        text,
      })}
    </Dialog>
  );
};

export default ComponentDialog;
