import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import SocketClientInstance from '../clients/socket';

import { AuthenticatedUserModel } from '../models/authenticatedUser/model';
import { NotificationModel } from '../models/notification/model';
import { PrivateTopicModel } from '../models/privateTopic/model';
import { connectionIdSelector } from '../models/settings/selectors';
import socketRoomsState from '../models/socketRooms/atom';

const reconnectToSocketRooms = () => {
  const rooms = getRecoil(socketRoomsState);
  for (const roomStr of rooms) {
    const [path, room] = roomStr.split('/');
    SocketClientInstance.join(path, room);
  }
};

const loadNotificationCounts = () => NotificationModel.loadNotificationCounts();

const loadMessageCounts = () => PrivateTopicModel.loadUnreadCounts();

export const useSocketConnectionSync = () => {
  const connectionId = useRecoilValue(connectionIdSelector);
  const connectionCount = useRef(0);

  useEffect(async () => {
    const authenticatedUserModel = new AuthenticatedUserModel();
    const { data, loading } = authenticatedUserModel.getState();
    if (!connectionId || !data) return;
    if (connectionCount.current === 0) {
      connectionCount.current = 1;
      return;
    }
    if (!data && !loading) await authenticatedUserModel.reload();
    reconnectToSocketRooms();
    loadNotificationCounts();
    loadMessageCounts();
  }, [connectionId]);
};
