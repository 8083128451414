export default ({ requirements, user, unauthenticated }) => {
  if (unauthenticated) return false;

  if ((!requirements?.self && !!requirements?.roles?.length && !requirements.roles.find(r => user?.role === r))) return false;

  if (!!requirements?.groups?.length && !requirements.groups.find(g => user?.groups.find(rg => rg.name === g))) return false;

  if (!!requirements?.self && !(requirements.self === user?.username)) return false;

  if (!!requirements?.notSelf && (requirements.notSelf === user?.username)) return false;

  return true;
};
