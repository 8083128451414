import { selectorFamily } from 'recoil';
import state from './atom';

export const userProductSelector = selectorFamily({
  key: 'userProductSelector',
  get: (id) => ({ get }) => {
    const userProductState = get(state(id));
    return userProductState?.data;
  },
  set: (id) => ({ set, get }, data) => {
    const _state = state(id);
    const userProductState = get(_state);
    set(_state, {
      ...userProductState,
      data,
    });
  },
});
