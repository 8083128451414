import { extend } from '../utils/extend';

import base from './base';

const surface = '#121212';
const overlay = '255,255,255';

const darkColors = {
  textPrimaryBrandLarge: '#D778FC',
  textSecondaryBrandLarge: '#F56BB7',
  textPrimaryBrandSmall: '#E7B0FC',
  textSecondaryBrandSmall: '#F9AED7',
  textPrimary: '#fff',
  cardShadingPrimary: '#1f0033',
  cardShadingSecondary: '#0f0008',
};

export const darkBase = {
  surface,
  s001dp: `rgba(${overlay},0.01)`,
  s0015dp: `rgba(${overlay},0.015)`,
  s002dp: `rgba(${overlay},0.02)`,
  s0025dp: `rgba(${overlay},0.025)`,
  s003dp: `rgba(${overlay},0.03)`,
  s01dp: `rgba(${overlay},0.05)`,
  s02dp: `rgba(${overlay},0.07)`,
  s03dp: `rgba(${overlay},0.08)`,
  s04dp: `rgba(${overlay},0.09)`,
  s06dp: `rgba(${overlay},0.11)`,
  s08dp: `rgba(${overlay},0.12)`,
  s12dp: `rgba(${overlay},0.14)`,
  s16dp: `rgba(${overlay},0.15)`,
  s24dp: `rgba(${overlay},0.16)`,
  disabled: 'rgb(77, 77, 77)',
  disabledText: '#7A7A7A',
  mode: 'dark',
};

export default extend(base, {
  ...darkColors,
  ...darkBase,
});
