import { Button, IconButton, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useCallback, useMemo } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GifBoxIcon from '@mui/icons-material/GifBox';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Visibility from '@mui/icons-material/Visibility';
import LocalPolice from '@mui/icons-material/LocalPolice';

import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { setRecoil } from 'recoil-nexus';

import {
  Breakpoints, CustomEvents, SourceTypes, UserRoles,
} from '../../lib/constants';
import { Content, Wrapper } from '../Containers';
import CategorySelect from '../CategorySelect';
import LexicalComposer from '../LexicalComposer';
import { CustomEmojiNode } from '../LexicalComposer/Nodes/CustomEmojiNode';

import ApiClientInstance from '../../clients/api';

import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';

import { TopicModel } from '../../models/topic/model';
import { PostModel } from '../../models/post/model';
import postSourceType from '../../models/postSourceType/atom';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useLexicalEditor } from '../../models/editor/useLexicalEditor';
import { breakpointNameSelector } from '../../models/settings/selectors';

const modalStyle = {
  top: '0',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minWidth: '100%',
  minHeight: '100%',
  height: '100%',
  width: '100vw',
};

const POST_EDITOR_NAME = 'new-topic-post';
const TOPIC_EDITOR_NAME = 'new-topic';

const TopicCreateModal = () => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingId, setEditingId] = useState('');
  const breakpoint = useRecoilValue(breakpointNameSelector);
  const navigate = useNavigate();
  const { data: user } = useAuthenticatedUser();

  const postFns = useLexicalEditor(({
    editorName: POST_EDITOR_NAME,
    sourceType: SourceTypes.Post,
  }));

  const {
    getEditorContents: getPostEditor,
    getEditorTextContent: getPostEditorTextContent,
    isEmpty: isPostEmpty,
    handleClearEditor: handlePostClear,
    isLoading: postLoading,
    toggleReadOnly: togglePostReadOnly,
    handlePreviewClick,
  } = postFns;

  const topicFns = useLexicalEditor(({
    editorName: TOPIC_EDITOR_NAME,
    sourceType: SourceTypes.Post,
  }));

  const {
    getEditorContents: getTopicEditor,
    getEditorTextContent: getTopicEditorTextContent,
    isEmpty: isTopicEmpty,
    handleClearEditor: handleTopicClear,
    isLoading: topicLoading,
    toggleReadOnly: toggleTopicReadOnly,
  } = topicFns;

  const isEmpty = useMemo(() => !category && isPostEmpty && isTopicEmpty, [isPostEmpty, isTopicEmpty, category]);

  const isLoading = useMemo(() => loading || postLoading || topicLoading, [loading, postLoading, topicLoading]);

  const canSend = useMemo(() => !isPostEmpty && !isTopicEmpty && category, [isPostEmpty, category, isTopicEmpty, loading]);

  const handleClear = useCallback(() => {
    handlePostClear();
    handleTopicClear();
    setEditing(false);
    setEditingId('');
    setCategory(null);
  }, []);

  const handleOpen = useCallback((data) => {
    if (data?.category) setCategory(data.category);
    setOpen(true);
  }, []);

  const handleEditOpen = useCallback(async ({ _id }) => {
    if (!_id) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: `/topic/id/${_id}`,
      catchError: true,
    });
    if (res.success) {
      setOpen(true);
      setEditing(true);
      setEditingId(_id);
      emitCustomEvent(CustomEvents.ReplaceEditorState, { editorName: TOPIC_EDITOR_NAME, data: { editorContents: res.data.title } });
      emitCustomEvent(CustomEvents.ReplaceEditorState, { editorName: POST_EDITOR_NAME, data: { editorContents: res.data.mainPost.content } });
      setCategory(res.data.category);
      postFns.setIsModeratorPost(res.data.mainPost.isModeratorPost);
    }
  }, [postFns.setIsModeratorPost]);

  const handleClose = useCallback(() => {
    setOpen(false);
    handleClear();
  }, []);

  const handleCloseButtonClick = useCallback(() => {
    if (isEmpty) return handleClose();
    const handler = handleClose;
    const text = 'Discard current content';
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text });
  }, [isEmpty]);

  const toggleReadOnly = useCallback(() => {
    togglePostReadOnly();
    toggleTopicReadOnly();
  }, [togglePostReadOnly, toggleTopicReadOnly]);

  const handleSend = useCallback(async () => {
    if (!canSend) return;
    if (editing && !editingId) return;
    toggleReadOnly();
    setLoading(true);
    const postContent = getPostEditor();
    const topicContent = getTopicEditor();
    const method = editing ? 'put' : 'post';
    const path = editing ? `/topic/update/${editingId}` : '/topic';
    const data = {
      post: {
        content: postContent,
        isModeratorPost: postFns.isModeratorPost,
      },
      topic: {
        title: topicContent,
      },
      category: category._id,
    };
    const res = await ApiClientInstance.sendRequest({
      method,
      path,
      data,
      snackbarError: 'errorMessage',
      catchError: true,
    });
    if (res.success) {
      setLoading(false);
      handleClear();
      handleClose();
      toggleReadOnly();
      setRecoil(postSourceType(res.data._id), SourceTypes.Post);
      const model = new TopicModel(res.data._id);
      model.setData(res.data);
      const postModel = new PostModel(res.data.mainPost._id);
      postModel.setData(res.data.mainPost);
      navigate(`/topic/${res.data.shortId}/${res.data.slug}`);
    }
    setLoading(false);
    toggleReadOnly();
  }, [getTopicEditorTextContent, getPostEditorTextContent, canSend, getPostEditor, getTopicEditor, category, toggleReadOnly, editing, editingId, postFns.isModeratorPost]);

  useCustomEvent(CustomEvents.OpenTopicCreateModal, handleOpen);
  useCustomEvent(CustomEvents.CloseTopicCreateModal, handleClose);
  useCustomEvent(CustomEvents.EditTopic, handleEditOpen);

  const header = useMemo(() => (
    <div className='flex justify-between items-center py-2 px-3'>
      <h1 style={{ fontSize: '30px' }} className='font-bebas'>{editing ? 'Edit Topic' : 'Create New Topic'}</h1>
      <IconButton
        color='textPrimary'
        size='small'
        onClick={handleCloseButtonClick}
        type='button'
      >
        <CancelIcon />
      </IconButton>
    </div>
  ), [editing, handleCloseButtonClick]);

  const categorySelect = useMemo(() => (
    <div className='mt-3'>
      <h2 className='font-bebas text-xl my-1'>Category</h2>
      <CategorySelect
        category={category}
        setCategory={setCategory}
        disabled={loading}
      />
    </div>
  ), [category, loading]);

  const topicEditor = useMemo(() => (
    <div className='mt-3'>
      <h2 className='font-bebas text-xl my-1'>Topic Content</h2>
      <LexicalComposer
        editorName='new-topic'
        outerContainerClassName='bg-s01dp'
        editorClassName='editor-root h-[56px]'
        excludePlugins={{
          mentions: true,
          embeds: true,
          markdown: true,
        }}
        excludedNodes={[CustomEmojiNode]}
      />
    </div>
  ), [topicFns]);

  const clearAndSendButtons = useMemo(() => (
    <div className='space-x-2'>
      <Button variant='outlined' endIcon={<ClearIcon />} color='disabledText' disabled={isEmpty || isLoading} onClick={handleClear} size='small'>Clear</Button>
      <Button variant='contained' endIcon={<SendIcon />} color='primary' disabled={!canSend || isLoading} onClick={handleSend} size='small' edge='start'>
        { editing ? 'Update' : 'Create' }
      </Button>
    </div>
  ), [postFns, topicFns, handleClear, handleSend, isEmpty, isLoading, editing]);

  const isModeratorPostButton = useMemo(() => {
    if (!user || ![UserRoles.Admin, UserRoles.SuperAdmin, UserRoles.Moderator].includes(user.role)) return null;
    return (
      <IconButton disabled={isLoading} onClick={postFns.handleIsModeratorPost} size='small' edge='start'>
        <LocalPolice sx={{ color: !postFns.isModeratorPost ? 'var(--color-s24dp)' : 'var(--color-primary-light)' }} />
      </IconButton>
    );
  }, [user, postFns.handleIsModeratorPost, postFns.isModeratorPost, isLoading]);

  const toolbar = useMemo(() => {
    const {
      handleUploadClick,
      handleGiphyClick,
      handleFontFormat,
    } = postFns;
    return (
      <div className='flex items-center justify-between mt-1'>
        <div>
          <IconButton disabled={isLoading} onClick={handleUploadClick} size='small' edge='start'><AddCircleIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          <IconButton disabled={isLoading} onClick={handleGiphyClick} size='small' edge='start'><GifBoxIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          <IconButton disabled={isLoading} onClick={handleFontFormat} size='small' edge='start'><TextFormatIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          <IconButton disabled={isLoading} onClick={handlePreviewClick} size='small' edge='start'><Visibility sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          { isModeratorPostButton }
        </div>
        { clearAndSendButtons }
      </div>
    );
  }, [isLoading, postFns, clearAndSendButtons]);

  const postEditor = useMemo(() => (
    <div className='mt-3'>
      <h2 className='font-bebas text-xl my-1'>Post Content</h2>
      <LexicalComposer
        editorName='new-topic-post'
        outerContainerClassName='bg-s01dp'
        editorStyles={{
          minHeight: '200px',
          height: breakpoint === Breakpoints.Mobile ? '' : 'calc(100vh - 450px)',
          maxHeight: breakpoint === Breakpoints.Mobile ? '300px' : 'calc(100vh - 450px)',
        }}
      />
      { toolbar}
    </div>
  ), [postFns, clearAndSendButtons, isLoading, toolbar]);

  if (!open) return null;

  return (
    <Modal
      className='tku-no-outline'
      open={open}
      onClose={handleClose}
      aria-labelledby='topic-create-modal-container'
      aria-describedby='create-new-topic'
    >
      <Box className='tku-no-outline topic-create' sx={modalStyle}>
        <Wrapper className='bg-s01dp desktop:py-3 text-primary-text desktop:w-[992px] desktop:ml-auto desktop:mr-auto'>
          { header }
          <Content>
            <div className='flex flex-col justify-between text-primary-text w-full font-roboto py-2 px-3' style={{ maxWidth: '100vw' }}>
              { categorySelect }
              { topicEditor }
              { postEditor }
            </div>
          </Content>
        </Wrapper>
      </Box>

    </Modal>
  );
};

export default TopicCreateModal;
