import { HashtagNode } from '@lexical/hashtag/LexicalHashtag';
import { EmojiNode } from '../LexicalComposer/Nodes/EmojiNode';
import { CustomEmojiNode } from '../LexicalComposer/Nodes/CustomEmojiNode';
import LexicalComposer from '../LexicalComposer';
import './style.css';

const nodes = [
  HashtagNode,
  EmojiNode,
  CustomEmojiNode,
];

const TopicContent = ({
  content,
  innerContainerStyles,
  outerContainerStyles,
  editorStyles,
}) => (
  <LexicalComposer
    nodes={nodes}
    initialEditorState={content}
    includePlugins={false}
    editorStyles={editorStyles}
    outerContainerStyles={outerContainerStyles}
    innerContainerStyles={innerContainerStyles}
    outerContainerClassName='editor-display-topic-outer display'
    innerContainerClassName='editor-display-topic-inner display'
    editorClassName='editor-display-topic-root display'
    initialConfig={{ readOnly: true }}
    watchInitialState
  />
);

export default TopicContent;
