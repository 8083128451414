import {
  capitalize,
  Divider,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { forwardRef } from 'react';
import { Breakpoints } from '../../lib/constants';
import { breakpointNameSelector } from '../../models/settings/selectors';
import ContentComponent from './Content';
import WrapperComponent from './Wrapper';

export const StyledDivider = ({ className }) => (<Divider className={className || ''} sx={{ margin: '20px 0px' }} />);

export const StyledSectionTitle = ({ title }) => (<h3 style={{ fontSize: '18px' }} className='font-roboto text-primary-text'>{title}</h3>);

export const StyledSection = ({
  action,
  className,
  username,
  children,
  title,
}) => (
  <div className={`user-${action.toLowerCase().split(' ').join('-')} ${className ? ` ${className}` : ''}`}>
    <StyledSectionTitle title={title || `${capitalize(action)} ${username}`} />
    {children}
  </div>
);

export const ContentForList = forwardRef(({
  onLoad,
  children,
  mobileHeightOffset = 0,
  desktopHeightOffset = 0,
  className,
  style = {},
}, ref) => {
  const breakpointName = useRecoilValue(breakpointNameSelector);

  return (
    <ContentComponent
      onLoad={onLoad}
      ref={ref}
      className={`text-primary-text ${className ? `${className}` : ''}`}
      style={{
        minHeight: '400px',
        height: `calc(100% - ${[Breakpoints.Mobile, Breakpoints.Tablet].includes(breakpointName) ? mobileHeightOffset : desktopHeightOffset}px)`,
        flex: 'none',
        ...style,
      }}
    >
      { children }
    </ContentComponent>
  );
});

export const Content = ContentComponent;
export const Wrapper = WrapperComponent;
