import { IconButton, Chip } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Visibility from '@mui/icons-material/Visibility';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import { useLexicalEditor } from '../../../models/editor/useLexicalEditor';

const TopToolbar = ({ editorName, sourceType }) => {
  const {
    isLoading,
    isExpanded,
    currentReply,
    isEmpty,
    currentPostEdit,
    setCurrentReply,
    handlePreviewClick,
    handleClearEditor,
    handleExpanded,
    handleToggleEditorState,
  } = useLexicalEditor({ editorName, sourceType });

  return (
    <div className='flex items-center justify-between mb-1'>
      <div className='flex items-center'>
        <IconButton disabled={isLoading} onClick={handleExpanded} size='small' edge='start'>
          { isExpanded ? <CloseFullscreenIcon sx={{ color: 'var(--color-s24dp)' }} /> : <OpenInFullIcon sx={{ color: 'var(--color-s24dp)' }} /> }
        </IconButton>
        <IconButton disabled={isLoading || isEmpty} onClick={handlePreviewClick} size='small' edge='start'><Visibility sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
      </div>
      <div className='flex items-center space-x-1'>
        { currentPostEdit && <Chip size='small' label='editing' onDelete={handleClearEditor} /> }
        { currentReply && <Chip deleteIcon={<CancelIcon sx={{ color: 'white' }} />} sx={{ backgroundColor: 'var(--color-primary-light)', color: 'white' }} size='small' label={`replying to ${currentReply?.author?.username}`} onDelete={() => setCurrentReply(null)} /> }
        <IconButton disabled={isLoading} onClick={handleToggleEditorState} size='small' edge='start'><CancelIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
      </div>
    </div>
  );
};

export default TopToolbar;
