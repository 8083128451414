import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitOpenPostVodeModal = ({
  postId,
  postType = 'public',
}) => {
  if (!postId) return null;
  emitCustomEvent(CustomEvents.PostVoteModalOpen, { postId, postType });
};
