import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './lib/reportWebVitals';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if ('serviceWorker' in navigator) {
  try {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('./sw.js')
        .then((registration) => {
          console.log('Service Worker registered:', registration);
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    });
  } catch (error) {
    console.log('Service Worker registration failed:', error);
  }
}

reportWebVitals();

/* EXAMPLE SUBSCRIPTION
          <Button
            variant='contained'
            onClick={async () => {
              const sw = await navigator.serviceWorker.ready;
              const push = await sw.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BF8CHv1TYmYQIAWwXjJLajWeR05Xf4w73hF5KxwKyXuJ_yoBbWRRUzoQEKtUdcowZPQkN2ipTjfQnxmhPaduT3o',
              });
              console.log(JSON.stringify(push));
            }}
          >
            Click
          </Button>
*/
