import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import protectedRequirements from '../lib/protectedRequirements';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

const useProtectedRequirements = (requirements, redirectUrl = '/') => {
  const { data: user, unauthenticated, loading } = useAuthenticatedUser();
  const navigate = useNavigate();
  let isAllowed;
  useEffect(() => {
    isAllowed = protectedRequirements({ requirements, user, unauthenticated });
    if (!isAllowed && !loading) navigate(redirectUrl);
  }, [loading, user, unauthenticated]);
  return { loading, isAllowed };
};

export default useProtectedRequirements;
