import Badge from '@mui/material/Badge';
import CommentIcon from '@mui/icons-material/Comment';
import {
  useCallback,
} from 'react';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import unreadMessagesCountState from '../../models/unreadMessagesCount/atom';
import messageRequestCountState from '../../models/messageRequestCount/atom';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const MessagesButton = () => {
  const { authenticated, verified } = useAuthenticatedUser();
  const navigate = useNavigate();
  const handleClick = useCallback(() => navigate('/messages'), []);
  const unreadMessagesCount = useRecoilValue(unreadMessagesCountState);
  const messageRequestCount = useRecoilValue(messageRequestCountState);

  const totalCount = unreadMessagesCount + messageRequestCount;

  const disabled = !authenticated || !verified;

  return (
    <IconButton disabled={disabled} onClick={handleClick}>
      <Badge badgeContent={totalCount} max={50} color='secondary'>
        <CommentIcon color={!disabled ? 'primaryLight' : 'disabled'} />
      </Badge>
    </IconButton>
  );
};

export default MessagesButton;
