import { memo, useMemo } from 'react';
import {
  QueryKeys,
  SocketRooms,
} from '../../lib/constants';
import SocketRoom from '../SocketRoom';

import {
  Wrapper,
  Content,
} from '../Containers';
import MessagesListItem from '../MessagesListItem';
import QueryList from '../QueryList';
import ErrorBoundary from '../ErrorBoundary';
import ErrorCard from '../ErrorCard';

import { MessagePathLabels, useMessagesPath } from '../../hooks/useMessagesPath';
import useRequireAuth from '../../hooks/useRequireAuth';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { PrivateTopicModel } from '../../models/privateTopic/model';

const MemoMessagesListItem = memo(MessagesListItem);
const ItemComponent = ({ item }) => (
  <ErrorBoundary fallback={<ErrorCard />}>
    <MemoMessagesListItem _id={item._id} />
  </ErrorBoundary>
);

const EmptyComponentContainer = ({ text }) => (
  <div className='w-[100%] h-[200px] text-lg flex items-center justify-center'>
    { text }
  </div>
);

const onRequestSuccess = (items => {
  for (const item of items) PrivateTopicModel.setTopic(item._id, item);
});

const MessagesList = () => {
  useRequireAuth('/');
  const { data: authenticatedUser } = useAuthenticatedUser();

  const {
    label,
    filterType,
    queryParams,
  } = useMessagesPath();

  const queryKey = useMemo(() => {
    if (!authenticatedUser || !filterType) return null;
    return [QueryKeys.Messages, authenticatedUser.username, filterType];
  }, [authenticatedUser?.username, filterType]);

  const EmptyComponent = useMemo(() => {
    let text = 'No messages found 😢';
    if (label === MessagePathLabels.Archived) text = 'No archived messages found 👍🏾';
    if (label === MessagePathLabels.MessageRequests) text = 'No message requests found 🤔';
    return <EmptyComponentContainer text={text} />;
  }, [label]);

  const queryList = useMemo(() => {
    if (!queryKey || !queryParams) return null;
    return (
      <QueryList
        onRequestSuccess={onRequestSuccess}
        ItemComponent={ItemComponent}
        customScrollParentSelector='.messages-list-scroller'
        requestPath='/private-topic'
        requestParams={queryParams}
        queryKey={queryKey}
        queryEnabled={queryKey}
        shouldShowLoaderOnQueryLoading={false}
        loaderProps={{ boxType: 'fullContainer', minHeight: '60vh' }}
        EmptyComponent={EmptyComponent}
      />
    );
  }, [queryParams, queryKey]);

  return (
    <Wrapper applyBreakpoints>
      <Content className='pb-3 tku-custom-scroller text-primary-text messages-list-scroller'>
        { queryList }
      </Content>
      <SocketRoom roomPath={SocketRooms.MessageList} roomName={authenticatedUser?._id} />
    </Wrapper>
  );
};

export default MessagesList;
