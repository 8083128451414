import { TextNode } from 'lexical';

const NAME = 'post-quote';
const MODE = 'token';

// DO NOT TOUCH
const MAIN_ATTRIBUTE = `data-lexical-${NAME}`;
const DATA_ATTRIBUTE = `data-lexical-${NAME}-data`;
const CLASS_NAME = `editor-${NAME}`;

export class PostQuoteNode extends TextNode {
  __data;

  static getType() {
    return NAME;
  }

  static clone(node) {
    return new PostQuoteNode(node.__data);
  }

  static create = (data) => {
    const node = new PostQuoteNode(data);
    node.setMode(MODE);
    return node;
  };

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    const node = new PostQuoteNode(serializedNode.data);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(data, key) {
    const text = `💬 quoting ${data.author.username} in ${data.topic.textContent}`;
    super(text, key);
    this.__data = {
      author: {
        username: data.author.username,
        avatar: data.author.avatar,
        color: data.author.color,
        _id: data.author._id,
      },
      topic: {
        _id: data.topic._id,
        textContent: data.topic.textContent,
        category: data.topic.category?._id || data.topic.category,
      },
      post: {
        _id: data.post._id,
        content: data.post.content,
      },
    };
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      data: this.__data,
      type: NAME,
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.className = CLASS_NAME;
    dom.setAttribute('spellcheck', 'false');

    // PostQuoteNode SPECIFIC HANDLE PREVIEW
    // if (this.__data.type === 'image' || this.__data.type === 'photo') {
    //   dom.onclick = () => emitCustomEvent(CustomEvents.OpenImageModal, { url: this.__data.url, altText: this.__data.text });
    // }

    return dom;
  }

  // TODO: verify this pattern of export and import
  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute(MAIN_ATTRIBUTE, 'true');
    element.setAttribute(DATA_ATTRIBUTE, JSON.stringify(this.__data));
    element.textContent = this.__text;
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute(MAIN_ATTRIBUTE)) return null;
        return {
          conversion: (_domNode) => {
            let data = _domNode.getAttribute(DATA_ATTRIBUTE);
            if (data && typeof data === 'string') data = JSON.parse(data);
            if (data !== null) {
              const node = new PostQuoteNode(data);
              node.setMode(MODE);
              return {
                node,
              };
            }
            return null;
          },
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }
}

export const $createPostQuoteNode = (data) => {
  const embedNode = new PostQuoteNode(data);
  embedNode.setMode(MODE);
  return embedNode;
};

export const $isPostQuoteNode = (node) => node instanceof PostQuoteNode;
