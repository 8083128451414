import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { addDays, format } from 'date-fns';

import { useSetRecoilState } from 'recoil';
import UserSelect from '../UserSelect';
import getUtcDate from '../../lib/getUtcDate';
import { CustomEvents, UserRoles, UserStatus } from '../../lib/constants';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';

const customFilter = users => {
  let _users = users;
  if (!_users.length) return [];
  _users = _users.filter(user => {
    const roleDisqualify = [UserRoles.Admin, UserRoles.SuperAdmin].includes(user.role);
    const statusDisqualify = user.status !== UserStatus.Good;
    const bannedDisqualify = user.banned;
    return (!roleDisqualify && !statusDisqualify && !bannedDisqualify);
  });
  return _users;
};

const SuspendUser = ({
  mainContainerClassname,
  initialUser,
  handleConfirm,
  handleCancel,
}) => {
  const [user, setUser] = useState(initialUser);
  const [suspensionDate, setSuspensionDate] = useState(addDays(new Date(), 5));
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const expiresInvalid = useMemo(() => {
    if (!user) return false;
    if (!suspensionDate) return true;
    return !!suspensionDate && (suspensionDate.valueOf() <= Date.now().valueOf());
  }, [suspensionDate, user]);

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          _user: user,
          suspensionDate,
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: `Suspend ${user.username} until ${format(suspensionDate, 'M/dd/yy')}`,
        data: {
          description: { text: `${user.username} will not be able to create topics, reply to topics, or send messages until their suspension is over.` },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, user, suspensionDate]);

  return (
    <div className={`${mainContainerClassname} flex flex-col justify-between text-primary-text h-[350px]`}>
      <div>
        <h1 className='mb-4'>Suspend User</h1>
        <UserSelect
          customFilter={customFilter}
          label='user to suspend'
          user={user}
          setUser={setUser}
          selectType='single'
        />
        <div className='mt-5'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={!user}
              className='w-[100%]'
              disableCloseOnSelect={false}
              label='Expiration Date'
              value={suspensionDate}
              minDate={addDays(getUtcDate(), 1)}
              onChange={(date) => setSuspensionDate(getUtcDate(date))}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
            { expiresInvalid && (
              <div className='text-xs text-error mt-1'>
                expiration date must be at least 1 day from now
              </div>
            ) }
          </LocalizationProvider>
        </div>
      </div>
      <div className='flex justify-between'>
        <Button
          variant='outlined'
          color='secondaryLight'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={!user || !suspensionDate}
          onClick={_handleSubmit}
          sx={{ maxWidth: '60%' }}
        >
          <span className='line-clamp-1'>{ (!!user && !!suspensionDate) ? `Suspend ${user.username}` : 'Select User'}</span>
        </Button>
      </div>
    </div>
  );
};

export default SuspendUser;
