import {
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { Button, TextField } from '@mui/material';
import {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { getRecoil, setRecoil } from 'recoil-nexus';
import {
  Wrapper,
  Content,
  StyledDivider,
  StyledSection,
} from '../Containers';

import ApiClientInstance from '../../clients/api';
import { CustomEvents } from '../../lib/constants';

import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useRequireVerification } from '../../hooks/useRequireVerification';
import userProductsList from '../../models/userProductsList/atom';
import { UserProductModel } from '../../models/userProduct/model';
import { userProductSelector } from '../../models/userProduct/selectors';

const SettingsProfile = () => {
  useRequireVerification('/settings');

  const {
    data: user,
    setData: setUser,
    fetch: fetchUser,
  } = useAuthenticatedUser();
  const data = user;
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const badgeProducts = useRecoilValue(userProductsList('badge'));

  useEffect(() => {
    if (!data) return;
    setUser(data);
  }, [data]);

  useEffect(async () => {
    const res = await ApiClientInstance.sendRequest({
      path: '/store/user-products',
      method: 'GET',
      catchError: true,
      queryParams: {
        type: 'badge',
      },
      snackbarError: 'message',
    });
    if (res.success) {
      for (const userProduct of res.data) UserProductModel.setUserProduct(userProduct.product._id, userProduct);
      setRecoil(userProductsList('badge'), res.data.map(userProduct => userProduct.product._id));
    }
  }, []);

  // Nickname
  const [nickname, setNickname] = useState(user?.nickname);

  const handleNicknameChange = useCallback((event) => setNickname(event.target.value), []);

  // TODO: confirmation dialog
  const handleNicknameSubmit = useCallback(() => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: '/user/profile/',
        data: {
          nickname,
        },
        catchError: true,
        snackbarError: 'errorMessage',
      });
      if (res.success) {
        await fetchUser();
        addSnackbar({ message: 'Nickname successfully updated!', color: 'primary' });
      }
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, {
      handler,
      text: `Change your nickname to ${nickname}`,
    });
  }, [nickname, fetchUser]);

  useEffect(() => {
    setNickname(user?.nickname);
  }, [user?.nickname]);

  const nicknameUpdate = useMemo(() => (
    <StyledSection action='change-email' title='Nickname' className='tablet:w-[772px] text-primary-text font-roboto'>
      <div className='flex flex-col space-y-2 mb-2 mt-1'>
        <span className='text-sm opacity-75'>{`# ${user?.nickname}`}</span>
      </div>
      <div className='flex justify-between space-x-6 pb-4 pt-4'>
        <TextField
          className='w-[100%] desktop:w-[372px]'
          label='New Nickname'
          variant='outlined'
          size='medium'
          value={nickname}
          onChange={handleNicknameChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />
      </div>
      <Button
        className='desktop:w-[372px]'
        variant='contained'
        color='primary'
        size='medium'
        disabled={!nickname || nickname === user?.nickname}
        onClick={handleNicknameSubmit}
        label='update-nickname'
      >
        Update Nickname
      </Button>

    </StyledSection>
  ), [user?.nickname, nickname]);

  const handleToggleBadgeClick = useCallback(async (productId) => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: `/user/badge/${productId}`,
        catchError: true,
        snackbarError: 'errorMessage',
      });
      if (res.success) {
        setUser(res.data);
        addSnackbar({ message: 'Badge successfully updated!', color: 'primary' });
      }
    };
    await handler();
  }, []);

  const availableBadges = useMemo(() => {
    if (!badgeProducts || !badgeProducts?.length) return null;
    return (
      <div className='flex space-y-1 flex-wrap'>
        { badgeProducts?.map((badge) => {
          const _badge = getRecoil(userProductSelector(badge));
          const { item } = _badge.product;
          return (
            <div
              key={_badge._id}
              role='button'
              tabIndex='0'
              onKeyDown={() => handleToggleBadgeClick(_badge.product._id)}
              onClick={() => handleToggleBadgeClick(_badge.product._id)}
              className='flex flex-col space-y-2 relative'
            >
              <img src={item.image} alt={item.name} className='w-[50px] h-[50px]' />
            </div>
          );
        })}
      </div>

    );
  }, [badgeProducts]);

  const badgeUpdate = useMemo(() => {
    let _badges = user?.badges?.filter((badge) => badge.type === 'product');
    const usedBadges = _badges.length;
    const remainingBadgeSlots = user.badgeSlots - usedBadges;
    if (remainingBadgeSlots > 0) {
      _badges = _badges.concat(Array(remainingBadgeSlots).fill({ type: 'empty' }));
    }
    const badgeList = _badges.map((badge, i) => {
      if (badge.type !== 'product' && badge.type !== 'empty') return null;
      if (badge.type === 'empty') {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className='w-[50px] h-[50px] bg-s06dp rounded-full' />
        );
      }
      return (
        <div
          role='button'
          tabIndex='0'
          onKeyDown={() => handleToggleBadgeClick(badge._id)}
          key={badge._id}
          onClick={() => handleToggleBadgeClick(badge._id)}
          className='flex flex-col space-y-2 relative'
        >
          <img src={badge.image} alt={badge.name} className='w-[50px] h-[50px]' />
        </div>
      );
    });
    return (
      <StyledSection action='change-email' title='Badges' className='tablet:w-[772px] text-primary-text font-roboto'>
        <div className='flex flex-col space-y-2 mb-2 mt-1'>
          <span className='text-sm opacity-75'>{`Badge Slots: ${user?.badgeSlots}`}</span>
          <span className='text-md opacity-75'>Applied Badges</span>
          <div className='flex space-x-2 items-center'>
            { badgeList }
          </div>
          <span className='text-md opacity-75'>Available Badges</span>
          { availableBadges }
        </div>
      </StyledSection>
    );
  }, [user.badges, user.badgeSlots, badgeProducts, availableBadges]);

  return (
    <Wrapper applyBreakpoints className='text-primary-text'>
      <div className='flex space-x-2 justify-between items-center'>
        <h1 className='text-2xl font-bebas my-3 text-primary-text'>Profile Settings</h1>
      </div>
      <Content>
        <div className='px-2 py-2 relative desktop:w-[992px] desktop:ml-auto desktop:mr-auto'>
          { nicknameUpdate }
          <StyledDivider className='tablet:w-[772px] py-2' />
          { badgeUpdate }
          <StyledDivider className='tablet:w-[772px] py-2' />
        </div>
      </Content>
    </Wrapper>
  );
};

export default SettingsProfile;
