import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  IconButton,
  List,
  ListItemButton,
  Modal,
} from '@mui/material';

import Cancel from '@mui/icons-material/Cancel';

import UniverseLoader from '../Loaders/UniverseLoader';

import ApiClientInstance from '../../clients/api';
import { CustomEvents } from '../../lib/constants';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { formatDate } from '../../lib/formatDate';
import PostContent from '../PostContent';

const getNamestring = (post) => {
  const { author, editor } = post;
  return editor.username === author.username ? editor.username : `${author.username} (edited by ${editor.username})`;
};

const getPostDatestring = (post) => formatDate(new Date(post.lastModified), 'M/d/yy h:mm:ss aa');

const PostEditsModal = () => {
  const [open, setOpen] = useState(false);
  const [postId, setPostId] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleClose = useCallback(() => {
    setPostId(null);
    setPosts([]);
    setOpen(false);
    setSelectedPost(null);
  }, []);

  const handleOpen = useCallback(({ postId: _postId }) => {
    if (!_postId) return;
    setOpen(true);
    setPostId(_postId);
  }, []);

  useEffect(() => {
    if (!postId) return;

    let isMounted = true;

    const fetchPosts = async () => {
      setLoadingPosts(true);
      const res = await ApiClientInstance.sendRequest({
        method: 'GET',
        path: `/post/edits/${postId}`,
        catchError: true,
      });
      if (!isMounted) return;
      if (res.success) setPosts(res.data);
      setLoadingPosts(false);
    };

    fetchPosts();
    return () => { isMounted = false; };
  }, [postId]);

  useCustomEvent(CustomEvents.OpenPostEditsModal, handleOpen);

  const selectedPostData = useMemo(() => {
    if (!selectedPost) return {};
    const editor = selectedPost.editor.username === selectedPost.author.username ? null : selectedPost.editor.username;
    const datestring = getPostDatestring(selectedPost);
    return {
      author: selectedPost.author.username,
      editor,
      datestring,
      content: selectedPost.content,
    };
  }, [selectedPost]);

  if (!postId) return null;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ maxWidth: '992px', maxHeight: '75vh' }} className='bg-surface px-4 py-2 rounded font-roboto text-primary-text w-[95vw]'>
          <div className='flex justify-between items-center'>
            <div style={{ fontSize: '24px' }} className='font-bebas'>Previous Versions</div>
            <IconButton onClick={handleClose}><Cancel /></IconButton>
          </div>
          <div style={{ maxHeight: '65vh', minHeight: loadingPosts ? '120px' : null }} className={`tku-no-select relative ${loadingPosts ? '' : ' overflow-auto'}`}>
            { loadingPosts && <UniverseLoader boxType='fullContainer' /> }
            { !loadingPosts && (
            <List>
              { posts.map((p, i) => {
                const {
                  _id,
                } = p;
                return (
                  <ListItemButton sx={{ pl: 1 }} dense key={_id} onClick={() => setSelectedPost(p)}>
                    <span className='text-sm'>{`${getNamestring(p)} on ${getPostDatestring(p)}`}</span>
                  </ListItemButton>
                );
              }) }
            </List>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={!!selectedPost}
        onClose={() => setSelectedPost(null)}
        sx={{
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ maxWidth: '992px', maxHeight: '75vh' }} className='bg-surface px-4 py-2 rounded font-roboto text-primary-text w-[95vw]'>
          <div className='flex justify-between items-center'>
            <div>
              <div style={{ fontSize: '24px' }} className='font-bebas'>{`${selectedPostData.author} on ${selectedPostData.datestring}`}</div>
              { selectedPostData.editor && <div className='text-sm italic'>{`edited by ${selectedPostData.editor}`}</div> }
            </div>
            <IconButton onClick={() => setSelectedPost(null)}><Cancel /></IconButton>
          </div>
          <div className='mt-2 overflow-auto' style={{ maxHeight: '65vh' }}>
            <PostContent content={selectedPostData.content} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PostEditsModal;
