import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createRangeSelection, $getNearestNodeFromDOMNode } from 'lexical';

import { useCallback } from 'react';
import { setRecoil } from 'recoil-nexus';
import { emitCustomEvent } from '../../../hooks/useCustomEventListener';
import { ComposerNodeTypes, CustomEvents } from '../../../lib/constants';
import { editorIsActiveSelector } from '../../../models/editor/selectors';

export const buildRootFromNodes = (nodes) => {
  const paragraph = {
    children: [], direction: null, format: '', indent: 0, type: 'paragraph', version: 1,
  };

  const cleanRoot = {
    children: [],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  };
  const root = { ...cleanRoot };
  let currentNode = null;
  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    console.log(nodes);
    const { __type, children } = node;
    // FIRST ITERATION
    if (currentNode === null) {
      if (__type !== 'paragraph') {
        currentNode = { ...paragraph };
        if (__type === 'post-quote') continue;
        currentNode.children.push(node.exportJSON());
        if (nodes.length === 1) root.children.push(currentNode);
        continue;
      } else {
        currentNode = node.exportJSON();
        continue;
      }
    }
    // N+1 ITERATION
    if (__type !== 'paragraph') {
      currentNode.children.push(node.exportJSON());
      if (i === nodes.length - 1) {
        root.children.push(currentNode);
      }
      continue;
    }
    if (__type === 'paragraph') {
      root.children.push(currentNode);
      currentNode = node.exportJSON();
    }
  }
  return JSON.stringify({ root });
};

export const getNodesFromSelection = (windowSelection) => {
  // native
  const _windowSelection = windowSelection || window.getSelection();
  const {
    anchorNode,
    focusNode,
    anchorOffset,
    focusOffset,
  } = _windowSelection;

  if (!anchorNode || !focusNode) return null;

  const lexicalAnchorNode = $getNearestNodeFromDOMNode(anchorNode);
  const lexicalFocusNode = $getNearestNodeFromDOMNode(focusNode);

  if (!lexicalAnchorNode || !lexicalFocusNode) return null;

  const selection = $createRangeSelection();

  selection.anchor.node = lexicalAnchorNode;
  selection.anchor.offset = anchorOffset;
  selection.anchor.key = lexicalAnchorNode?.getKey();
  selection.focus.node = lexicalFocusNode;
  selection.focus.offset = focusOffset;
  selection.focus.key = lexicalFocusNode?.getKey();

  return selection.getNodes();
};

export const SelectionQuotePlugin = ({ editorName }) => {
  const [editor] = useLexicalComposerContext();

  const handleSelection = useCallback(({
    editorName: _editorName,
    data,
  }) => {
    if (editorName !== _editorName) return;
    const {
      editorName: postEditorName,
      author,
      post,
      topic,
      selection,
    } = data;
    if (!post || !topic || !author || !postEditorName) return;
    editor.update(() => {
      const nodes = getNodesFromSelection(selection);
      if (!nodes) return;
      const root = buildRootFromNodes(nodes);
      if (!root) return;
      setRecoil(editorIsActiveSelector(postEditorName), true);
      emitCustomEvent(CustomEvents.InsertInEditor, {
        editorName: postEditorName,
        nodeType: ComposerNodeTypes.PostQuote,
        data: {
          editorContents: root,
          author,
          post,
          topic,
        },
      });
    });
  }, [editor]);

  // useCustomEvent(CustomEvents.PostQuoteSelection, handleSelection, [editor, editorName]);

  return null;
};
