import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, SourceTypes } from '../../lib/constants';
import { useLexicalEditor } from '../../models/editor/useLexicalEditor';
import LexicalComposer from '../LexicalComposer';
import { editorStateJsonSelector } from '../../models/editor/selectors';
import { CustomEmojiNode } from '../LexicalComposer/Nodes/CustomEmojiNode';
import { useTopic } from '../../models/topic/useTopic';

const ChangeMessageTitle = ({
  mainContainerClassname,
  title,
  handleConfirm,
  handleCancel,
  topicId,
}) => {
  const { data, fetch } = useTopic(topicId, { sourceType: SourceTypes.Message });

  const __title = title || data?.title;

  const addSnackbar = useSetRecoilState(addSnackbarSelector);
  const editorName = `title-${topicId}`;
  const editorJson = useRecoilValue(editorStateJsonSelector(editorName));

  const { getEditorContents, getEditorTextContent } = useLexicalEditor({ editorName, sourceType: 'message' });

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          _title: getEditorContents(),
          _textContent: getEditorTextContent(),
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: 'Change topic category',
        data: {
          description: { text: `Update message title to ${getEditorTextContent()}` },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, getEditorContents]);

  const _handleCancel = useCallback(async () => {
    await handleCancel();
  }, [handleCancel]);

  if (!__title) return null;

  return (
    <div className={`${mainContainerClassname} flex flex-col justify-between`}>
      <div>
        <h1 className='mb-2'>Update Message Title</h1>
        <LexicalComposer
          excludedNodes={[CustomEmojiNode]}
          editorClassName='h-[40px] bg-surface'
          initialEditorState={__title}
          editorName={editorName}
          getInitialEditorFromEditorName={false}
          excludePlugins={{
            mentions: true,
            embeds: true,
            markdown: true,
          }}
        />
      </div>
      <div className='flex justify-between mt-4 space-x-5'>
        <Button
          color='secondaryLight'
          variant='outlined'
          onClick={_handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!editorJson || editorJson === __title}
          variant='contained'
          color='primary'
          onClick={_handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default ChangeMessageTitle;
