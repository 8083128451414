export const traverseNodeTree = (children, callback = (n) => console.log(n)) => {
  for (const child of children) {
    const isParent = child?.children?.length > -1;
    const hasChildren = child?.children?.length > 0;
    if (!isParent && !hasChildren) {
      callback(child);
      continue;
    }
    if (isParent && hasChildren) {
      callback(child);
      traverseNodeTree(child.children || [], callback);
    }
  }
};
