import { useEffect, useState } from 'react';

export const useFetchOnMount = ({
  fetch,
  loading,
  fetching,
  fetchOnMount,
}) => {
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!fetchOnMount || loading || fetching || hasFetched) return;
    fetch();
    setHasFetched(true);
  }, [fetch, loading, fetching, hasFetched, fetchOnMount]);
};
