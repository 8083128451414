import { selectorFamily } from 'recoil';
import state from './atom';

export const usersOnlineSelector = selectorFamily({
  key: 'usersOnlineSelector',
  // eslint-disable-next-line react/function-component-definition
  get: (userId) => ({ get }) => {
    const usersOnlineState = get(state);
    return usersOnlineState?.users?.[userId] || null;
  },
  set: () => ({ set, get }, usersArray) => {
    const usersOnlineState = get(state);
    const newUsersObject = {};
    for (const user of usersArray) {
      newUsersObject[user._id] = user;
    }
    set(state, { ...usersOnlineState, users: newUsersObject });
  },
});
