import { extend } from '../utils/extend';

import base, { lightBase } from './base';
import { darkBase } from './dark';

const surface = '#121212';
const overlay = '255,255,255';

const mainColors = {
  primary: '#ff3c3c', // RED
  primaryDark: '#D32F2F',
  primaryLight: '#fb695d',
  secondary: '#ffdb22', // YELLOW
  secondaryDark: '#c6a500',
  secondaryLight: '#fff38b',
};

const textAndShading = {
  textPrimaryBrandLarge: '#fb695d', // WHITE
  textSecondaryBrandLarge: '#f9e74b',
  textPrimaryBrandSmall: '#fb695d',
  textSecondaryBrandSmall: '#fff38b',
  textPrimary: '#FFF',
  cardShadingPrimary: '#424242',
  cardShadingSecondary: '#303030',
  mode: 'dark',
  mentionBackground: '#c81414',
};

const modeBase = textAndShading.mode === 'dark' ? darkBase : lightBase;

export default extend(base, {
  ...mainColors,
  ...textAndShading,
  ...modeBase,
  surface,
  overlay,
});
