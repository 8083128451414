import { useMemo } from 'react';
import { usePost } from '../../../models/post/usePost';
import PostContent from '../../PostContent';

export const CardPostContent = ({
  _id,
  sourceType,
  displaySource,
}) => {
  const { data } = usePost(_id, { sourceType });
  const postContent = useMemo(() => (
    <PostContent
      displaySource={displaySource}
      sourceType={sourceType}
      content={data?.content}
    />
  ), [data?.content, sourceType]);
  if (!data) return null;
  return (
    <section style={{ fontSize: '13px', lineHeight: '16px' }} className='post-card-content'>
      { postContent }
    </section>
  );
};
