/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import {
  useCallback,
  useState,
  useMemo,
  useEffect,
  memo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { getRecoil } from 'recoil-nexus';
import ApiClientInstance from '../../clients/api';
import { getInitialPostId } from './utils';
import {
  CustomEvents, SourceTypes,
} from '../../lib/constants';

import PostCard from '../PostCard';
import PaginatedScroller from '../PaginatedScroller';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { useLastSeenPost } from '../../hooks/useLastSeenPost';
import { PrivatePostModel } from '../../models/privatePost/model';
import { PostModel } from '../../models/post/model';
import lastSeenPostState from '../../models/lastSeenPostsInTopic/atom';
import currentPostsPages from '../../models/currentPostsPages/atom';
import postSourceType from '../../models/postSourceType/atom';
import { TopicModel } from '../../models/topic/model';

const MemoPostCard = memo(PostCard);

const onRequest = (res, { sourceType }) => {
  if (!sourceType) return;
  const Model = sourceType === SourceTypes.Message ? PrivatePostModel : PostModel;
  const { success, data } = res;
  if (!success) return;
  const { docs } = data;
  if (!docs) return;
  for (const doc of docs) Model.setPost(doc._id, doc);
};

const fetchPage = async ({
  sourceType = SourceTypes.Post,
  topicId,
  postId,
  cursorType = 'around',
}) => {
  const path = sourceType === SourceTypes.Message ? `/private-topic/${topicId}/message` : `/topic/posts/${topicId}`;
  const res = await ApiClientInstance.sendRequest({
    method: 'get',
    path,
    queryParams: {
      [cursorType]: postId,
      limit: 20,
    },
    catchError: true,
  });
  onRequest(res, { sourceType });
  return res;
};

const PostList = ({
  topic,
  setParentLoading,
}) => {
  const topicId = topic?._id;
  const [initialItemId, setInitialItemId] = useState({ [topicId]: null });
  const [searchParams] = useSearchParams();
  const initialPostFromUrl = searchParams.get('pid');
  const sourceType = useRecoilValue(postSourceType(topicId));

  useLastSeenPost({
    topicId,
    exclude: sourceType !== SourceTypes.Post,
  });

  const [pages, setPages] = useRecoilState(currentPostsPages(topicId));
  const setLastSeenPost = useSetRecoilState(lastSeenPostState(topicId));

  const getPrev = useCallback((nextBefore) => fetchPage({
    topicId, postId: nextBefore, cursorType: 'before', sourceType,
  }), [sourceType, topicId]);

  const getNext = useCallback((nextAfter) => fetchPage({
    topicId, postId: nextAfter, cursorType: 'after', sourceType,
  }), [sourceType, topicId]);

  const renderItem = useMemo(() => {
    const renderPost = ({
      item,
      index,
      isLastPageAndLastPost,
      isFirstPageAndFirstPost,
    }) => {
      const h = 'h';
      return (
        <MemoPostCard
          isLastPageAndLastPost={isLastPageAndLastPost}
          isFirstPageAndFirstPost={isFirstPageAndFirstPost}
          sourceType={sourceType}
          _id={item._id}
        />
      );
    };
    return renderPost;
  }, [
    topic,
    sourceType,
    pages,
  ]);

  const init = useCallback(async (postId) => {
    const res = await fetchPage({ topicId: topic._id, postId, sourceType });
    if (res?.success) return res;
    let path = '';
    if (sourceType === SourceTypes.Message) path = `/private-topic/${topic._id}/latest`;
    else path = `/topic/post/${topic._id}?type=latest`;
    const _res = await ApiClientInstance.sendRequest({
      method: 'get',
      path,
      catchError: true,
    });
    if (_res.success) {
      setLastSeenPost(_res.data.post._id);
      if (sourceType === SourceTypes.Post) TopicModel.updateLastPostSeenInTopic(topicId, _res.data.post._id);
      return emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: _res.data.post._id });
    }
    if (sourceType === SourceTypes.Post) TopicModel.updateLastPostSeenInTopic(topicId, topic?.mainPost?._id);
    setLastSeenPost(topic?.mainPost?._id);
    return emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: topic?.mainPost?._id });
  }, [topicId, topic?.mainPost?._id, sourceType, setLastSeenPost]);

  // Cleanup
  useEffect(() => {
    return () => setPages([]);
  }, [topicId, setPages]);

  useEffect(() => {
    emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: initialPostFromUrl });
  }, [initialPostFromUrl]);

  // Initial component for mount
  useEffect(() => {
    let _initialItemId = '';
    if (sourceType === SourceTypes.Post) {
      const initialPostInViewFromState = getRecoil(lastSeenPostState(topicId));
      _initialItemId = getInitialPostId({
        sourceType,
        initialPostInViewFromState,
        mainPostId: topic?.mainPost?._id,
        initialPostFromUrl,
      });
    } else {
      _initialItemId = getInitialPostId({
        sourceType,
        latestReply: topic?.latestReply?._id,
        initialPostFromUrl,
      });
    }
    setInitialItemId({ [topicId]: _initialItemId });
  }, [topic?.mainPost?._id, topicId, sourceType]);

  if (!sourceType || !initialItemId[topicId]) return null;

  return (
    <PaginatedScroller
      setParentLoading={setParentLoading}
      initialItemId={initialItemId}
      useInitialItemId
      setInitialItemId={setInitialItemId}
      init={init}
      renderItem={renderItem}
      getPrev={getPrev}
      getNext={getNext}
      setPages={setPages}
      pages={pages}
      firstItemId={topic?.mainPost?._id}
      topicId={topicId}
      topic={topic}
    />
  );
};

export default PostList;
