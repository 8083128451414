/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag/LexicalHashtag';
import { MentionNode } from '../LexicalComposer/Nodes/MentionNode';
import { EmojiNode } from '../LexicalComposer/Nodes/EmojiNode';
import { EmbedNode } from '../LexicalComposer/Nodes/DisplayEmbedNode';
import { PostQuoteNode } from '../LexicalComposer/Nodes/DisplayPostQuoteNode';
import { CustomEmojiNode } from '../LexicalComposer/Nodes/CustomEmojiNode';
import LexicalComposer from '../LexicalComposer';
import './style.css';
import { LexicalComposerNamespaces } from '../../lib/constants';

const nodes = [
  HashtagNode,
  HeadingNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  MentionNode,
  EmojiNode,
  EmbedNode,
  CustomEmojiNode,
  PostQuoteNode,
];

const PostContent = ({
  content, editorName, sourceType, displaySource,
}) => (

  <LexicalComposer
    nodes={nodes}
    namespace={LexicalComposerNamespaces.Display}
    initialEditorState={content}
    includePlugins={false}
    outerContainerClassName='editor-display-post-outer display'
    innerContainerClassName='editor-display-post-inner display'
    editorClassName='editor-display-post-root display'
    initialConfig={{ readOnly: true }}
    getInitialEditorFromEditorName={false}
    watchInitialState
    editorName={editorName}
    selectionQuotePlugin
    sourceType={sourceType}
    displaySource={displaySource}
  />

);

export default PostContent;
