import JsCookies from 'js-cookie';

class Cookies {
  static get = (name) => JsCookies.get(name) || '';

  static set = (name, value, config) => {
    JsCookies.set(name, value, config);
  };

  static remove = (name, config = { path: '/' }) => {
    JsCookies.remove(name, config);
  };
}

export default Cookies;
