import {
  useCallback,
} from 'react';
import { useRecoilValue } from 'recoil';

import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from '@mui/material';

import { CustomEvents, NotificationsButtonId } from '../../lib/constants';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import unseenNotificationsCountState from '../../models/unseenNotificationsCount/atom';

const NotificationsButton = () => {
  const handleNotificationsClick = useCallback(() => emitCustomEvent(CustomEvents.ToggleNotifications, {
    elementSelector: 'section.tku-wrapper',
    placement: 'bottom-end',
    offset: [0, -550],
  }), []);
  const unseenNotificationsCount = useRecoilValue(unseenNotificationsCountState);

  return (
    <IconButton id={NotificationsButtonId} className='prevent-clickaway-notifications' onClick={handleNotificationsClick}>
      <Badge badgeContent={unseenNotificationsCount} max={50} color='secondary'>
        <NotificationsIcon color='primaryLight' />
      </Badge>
    </IconButton>
  );
};

export default NotificationsButton;
