/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { setRecoil } from 'recoil-nexus';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArchiveIcon from '@mui/icons-material/Archive';

import ApiClientInstance from '../../clients/api';
import {
  ConfirmationDialogueClasses,
  CustomEvents,
  TakeoverTypes,
  SourceTypes,
  UserPrivateTopicStatus,
} from '../../lib/constants';

import ChangeMessageTitle from '../ChangeMessageTitle';
import ManageTopicUsers from '../ManageTopicUsers';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { TopicModel } from '../../models/topic/model';
import { PrivateTopicModel } from '../../models/privateTopic/model';
import { AuthenticatedUserModel } from '../../models/authenticatedUser/model';
import { addSnackbarSelector } from '../../models/snackbar/selector';

const addSnackbar = (data) => setRecoil(addSnackbarSelector, data);

const buildListData = ({
  _id,
  userId,
  handleClose,
  sourceType,
}) => {
  const Model = sourceType === SourceTypes.Message ? PrivateTopicModel : TopicModel;
  const topicInstance = new Model(_id);
  const topic = topicInstance.getData();
  if (!topic) return [];

  const loadTopic = () => topicInstance.fetch();

  if (sourceType === SourceTypes.Topic) {
    const subscribeToTopic = {
      exclude: topic.isSubscribed,
      handler: async () => {
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/topic/${topic._id}/subscribe`,
          catchError: true,
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully subscribed to the topic',
            color: 'primary',
          });
          loadTopic();
          handleClose();
        }
      },
      Icon: NotificationAddIcon,
      text: 'Subscribe',
    };

    const unsubscribeFromTopic = {
      exclude: !topic.isSubscribed,
      handler: async () => {
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/topic/${topic._id}/unsubscribe`,
          catchError: true,
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully unsubscribed from the topic',
            color: 'primary',
          });
          loadTopic();
          handleClose();
        }
      },
      Icon: NotificationsOffIcon,
      text: 'Unsubscribe',
    };

    const hideTopic = {
      exclude: true,
      handler: () => console.log('click'),
      Icon: VisibilityOffIcon,
      text: `Hide ${sourceType}`,
    };

    return [
      subscribeToTopic,
      unsubscribeFromTopic,
      hideTopic,
    ];
  }

  if (sourceType === SourceTypes.Message) {
    const UserModel = new AuthenticatedUserModel(userId);
    const user = UserModel.getData();
    const isOwner = topic.usersPermitted.find(u => u.user._id === user?._id).role === 'owner';

    const changeTitle = {
      id: 'editTitle',
      exclude: !isOwner,
      handler: async ({ _title, _textContent }) => {
        const data = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic?._id}`,
          data: {
            title: _title,
            textContent: _textContent,
          },
          catchError: true,
        });
        if (data.success) {
          addSnackbar({
            message: 'Title updated successfully!',
            color: 'primary',
          });
          handleClose();
        } else {
          addSnackbar({
            message: `Error updating title: ${data.message}`,
            severity: 'error',
          });
        }
      },
      confirmationData: {
        fullWidth: true,
        maxWidth: 'sm',
      },
      confirmationType: TakeoverTypes.Component,
      confirmationComponent: (props) => (
        <ChangeMessageTitle
          {...props}
          mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
          title={topic.title}
          topicId={topic._id}
        />
      ),
      Icon: EditIcon,
      text: 'Edit Title',
    };

    const updateUsersPermitted = {
      id: 'updateUsersPermitted',
      exclude: !isOwner,
      handler: async ({ usersPermitted }) => {
        const data = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic?._id}`,
          data: {
            usersPermitted,
          },
          catchError: true,
        });
        if (data.success) {
          addSnackbar({
            message: 'Users permitted updated successfully!',
            color: 'primary',
          });
          handleClose();
        } else {
          addSnackbar({
            message: `Error updating users permitted: ${data.message}`,
            severity: 'error',
          });
        }
      },
      confirmationData: {
        fullWidth: true,
        maxWidth: 'sm',
      },
      confirmationType: TakeoverTypes.Component,
      confirmationComponent: (props) => <ManageTopicUsers _id={topic._id} {...props} />,
      Icon: PeopleAltIcon,
      text: 'Manage Users',
    };

    const archiveMessageUser = {
      exclude: isOwner,
      handler: async () => {
        emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic._id}/status`,
          catchError: true,
          data: { status: UserPrivateTopicStatus.Archived },
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully archived the message',
            color: 'primary',
          });
          emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
          return;
        }
        handleClose();
      },
      confirmationType: TakeoverTypes.Confirmation,
      confirmationData: {
        description: {
          text: 'Are you sure you want to archive this message?',
        },
      },
      Icon: ArchiveIcon,
      text: `Archive ${sourceType}`,
    };

    const deleteMessageUser = {
      exclude: isOwner,
      handler: async () => {
        emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic._id}/status`,
          catchError: true,
          data: { status: UserPrivateTopicStatus.Deleted },
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully deleted the message',
            color: 'primary',
          });
          emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
          return;
        }
        handleClose();
      },
      confirmationType: TakeoverTypes.Confirmation,
      confirmationData: {
        description: {
          text: 'Are you sure you want to deleted this message?',
        },
      },
      Icon: DeleteForever,
      text: `Delete ${sourceType}`,
    };

    const leaveMessageUser = {
      exclude: isOwner,
      handler: async () => {
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic._id}/leave`,
          catchError: true,
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully left the message',
            color: 'primary',
          });
          emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
          return;
        }
        handleClose();
      },
      confirmationType: TakeoverTypes.Confirmation,
      confirmationData: {
        description: {
          text: 'Are you sure you want to leave this message?',
        },
      },
      Icon: ExitToAppIcon,
      text: `Leave ${sourceType}`,
    };

    const archiveMessageOwner = {
      exclude: !isOwner,
      handler: async () => {
        emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic._id}/archive`,
          catchError: true,
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully archived the message',
            color: 'primary',
          });
          emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
          return;
        }
        handleClose();
      },
      confirmationType: TakeoverTypes.Confirmation,
      confirmationData: {
        description: {
          text: 'Are you sure you want to archive this message for everyone?',
        },
      },
      Icon: ArchiveIcon,
      text: `Archive ${sourceType}`,
    };

    const deleteMessageOwner = {
      exclude: !isOwner,
      handler: async () => {
        emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
        const res = await ApiClientInstance.sendRequest({
          method: 'put',
          path: `/private-topic/${topic._id}/delete`,
          catchError: true,
        });
        if (res.success) {
          addSnackbar({
            message: 'You\'ve successfully deleted the message',
            color: 'primary',
          });
          emitCustomEvent(CustomEvents.Redirect, { to: '/messages' });
          return;
        }
        handleClose();
      },
      confirmationType: TakeoverTypes.Confirmation,
      confirmationData: {
        description: {
          text: 'Are you sure you want to delete this message for everyone?',
        },
      },
      Icon: DeleteForever,
      text: `Delete ${sourceType}`,
    };

    return [
      changeTitle,
      updateUsersPermitted,
      leaveMessageUser,
      archiveMessageUser,
      deleteMessageUser,
      archiveMessageOwner,
      deleteMessageOwner,
    ];
  }
};

export default buildListData;
