import ApiClientInstance from '../../clients/api';

export const requestPost = (id) => ApiClientInstance.sendRequest({
  method: 'get',
  path: `/private-post/id/${id}`,
  catchError: true,
});

export const vote = (id, topicId, delta) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/private-post/vote/id/${id}/${topicId}`,
  data: { delta },
  catchError: true,
});

export const getReplies = (id) => ApiClientInstance.sendRequest({
  path: `/private-post/${id}/replies`,
  method: 'get',
  catchError: true,
});

export const getVotes = (id, voteType) => ApiClientInstance.sendRequest({
  path: `/post/votes/${id}`,
  method: 'GET',
  catchError: true,
  queryParams: {
    voteType,
    postType: 'private',
  },
});
