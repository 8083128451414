/* eslint-disable react/function-component-definition */
/* eslint-disable camelcase */
import {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useRecoilValue } from 'recoil';
import GiphyClient from '../../clients/giphy';
import { useCustomEvent, emitCustomEvent } from '../../hooks/useCustomEventListener';
import {
  Breakpoints,
  CustomEvents,
  VirtuosoTypes,
} from '../../lib/constants';
import SimpleItemList from '../SimpleItemList';
import debounce from '../../lib/debounce';
import { breakpointNameSelector } from '../../models/settings/selectors';
import UniverseLoader from '../Loaders/UniverseLoader';

const getImageUrl = (images) => {
  const { downsized_large, original, downsized } = images;
  if (downsized_large) return downsized_large.url;
  if (downsized) return downsized.url;
  return original.url;
};

const getPreviewUrl = (images) => {
  const {
    downsized_large, downsized_medium, original, downsized,
  } = images;
  if (downsized_medium) return downsized_medium.url;
  if (downsized_large) return downsized_large.url;
  if (downsized) return downsized.url;
  return original.url;
};

// TODO: memoize SimpleItemList

const ItemComponent = ({ handleSelect }) => ({ item, index }) => {
  const _handleSelect = useCallback(() => {
    if (handleSelect) {
      handleSelect({ url: getImageUrl(item.images), title: item.title });
      return;
    }
    emitCustomEvent(CustomEvents.GiphySelect, { ...item, images: { original: item.images.original } });
    emitCustomEvent(CustomEvents.GiphyModalToggle);
  }, [item]);
  return (
    <div
      role='button'
      tabIndex={0}
      onClick={_handleSelect}
      onKeyPress={_handleSelect}
      className='flex items-center justify-center'
      style={{
        position: 'relative',
        paddingTop: '100%',
        width: '100%',
      }}
    >
      <img
        src={getPreviewUrl(item.images)}
        alt={item.title}
        style={{
          height: '100%',
          width: '100%',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          objectFit: 'cover',
          position: 'absolute',
          background: 'var(--color-s06dp)',
        }}
      />
    </div>
  );
};

const EmptyMessage = () => (
  <div className='flex flex-col px-4 space-y-2'>
    <span>No GIFs found. Search better next time 😒</span>
  </div>
);

const GiphyModal = () => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [handleSelect, setHandleSelect] = useState(null);
  const breakpointName = useRecoilValue(breakpointNameSelector);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleToggle = useCallback((res = {}) => {
    const handleConfirm = res?.handleConfirm;
    if (open) return setOpen(!open);
    setHandleSelect(() => handleConfirm);
    setOpen(true);
  }, [open]);

  const getTrendingGifs = useCallback(async () => {
    if (!open) return;
    setLoading(true);
    const res = await GiphyClient.getTrending({});
    if (res?.success) setItems(res.data?.data);
    setLoading(false);
  }, [open]);

  const handleSearchChange = useCallback((e) => setSearchQuery(e.target.value), []);

  const handleSearch = useCallback(debounce(async (_searchQuery) => {
    if (!_searchQuery) {
      setPage(0);
      setItems([]);
      return getTrendingGifs();
    }
    setLoading(true);
    const res = await GiphyClient.getSearch({ search: _searchQuery, page: 0 });
    if (res?.success) {
      setItems([]);
      setItems(res.data?.data);
    }
    setLoading(false);
  }, 250), []);

  useCustomEvent(CustomEvents.GiphyModalToggle, handleToggle, [handleToggle]);

  useEffect(async () => {
    if (!open) {
      setItems([]);
      setPage(0);
      setSearchQuery('');
      return;
    }
    await getTrendingGifs();
  }, [open]);

  useEffect(() => {
    setPage(0);
    handleSearch(searchQuery);
  }, [searchQuery]);

  const _getNext = useCallback(async () => {
    if (!open) return;
    let hasNextPage = false;
    const _page = page + 1;
    let data = [];
    let res;
    if (searchQuery) res = await GiphyClient.getSearch({ search: searchQuery, page: _page });
    if (!searchQuery) res = await GiphyClient.getTrending({ page: _page });
    if (res?.success) {
      data = (res?.data?.data);
      if (data?.length) hasNextPage = true;
    }
    setPage(_page);
    return {
      hasNextPage,
      data,
    };
  }, [page, searchQuery, open]);

  const containerStyle = useMemo(() => {
    let _containerStyle = {
      top: '100px',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center',
    };
    if ([Breakpoints.Desktop, Breakpoints.WideDesktop].includes(breakpointName)) {
      _containerStyle = {
        ..._containerStyle,
        width: '772px',
      };
    } else {
      _containerStyle = {
        ..._containerStyle,
        width: '95vw',
      };
    }
    return _containerStyle;
  }, [breakpointName]);

  const itemList = useMemo(() => {
    if (loading) return <UniverseLoader boxType='fullContainer' />;
    return (
      <SimpleItemList
        virtuosoType={VirtuosoTypes.Grid}
        _items={items}
        _getNext={_getNext}
        emptyMessage={<EmptyMessage />}
        ItemComponent={ItemComponent({ handleSelect })}
        showErrorMessage
        loaderProps={{
          boxType: 'fullContainer',
          zIndex: 1000,
        }}
      />
    );
  }, [items, _getNext, loading, handleSelect]);

  if (!open) return null;

  return (
    <div className='giphy-modal'>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-giphy-selector'
        aria-describedby='modal-giphy-select-gifs'
      >
        <div
          style={containerStyle}
          className='bg-surface text-primary-text overflow-auto rounded'
        >
          <div
            style={{ borderTopRightRadius: '4px', borderTopLeftRadius: '4px' }}
            className='bg-s06dp px-2 py-3 flex flex-col justify-start'
          >
            <TextField
              sx={{
                marginBottom: '10px',
                width: [Breakpoints.Desktop, Breakpoints.WideDesktop].includes(breakpointName) ? 'calc(772px - 32px)' : '100%',
              }}
              onChange={handleSearchChange}
              label='Giphy Search'
            />

            <div
              style={{
                height: breakpointName === Breakpoints.Mobile ? '400px' : '600px',
              }}
              className='overflow-auto max-h-50 rounded'
            >
              {itemList}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GiphyModal;
