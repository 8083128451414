export const Pulse = ({ color = 'primary ' }) => (
  <div className='sk-flow'>
    <div style={{ backgroundColor: `var(--color-${color})` }} className='sk-flow-dot' />
    <div style={{ backgroundColor: `var(--color-${color})` }} className='sk-flow-dot' />
    <div style={{ backgroundColor: `var(--color-${color})` }} className='sk-flow-dot' />
  </div>
);

export const Swing = ({ color = 'primary' }) => (
  <div className='sk-swing'>
    <div style={{ backgroundColor: `var(--color-${color})` }} className='sk-swing-dot' />
    <div style={{ backgroundColor: `var(--color-${color})` }} className='sk-swing-dot' />
  </div>
);

export const Circle = ({ color = 'primary' }) => (
  <>
    <style>{`.sk-circle-dot:before { background-color: var(--color-${color}) }`}</style>
    <div className='sk-circle'>
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
      <div className='sk-circle-dot' />
    </div>
  </>
);

export const Chase = ({
  color = 'primary',
  full,
  fixed = true,
  style,
  className,
  chaseStyle,
}) => {
  const element = (
    <>
      <style>{`.sk-chase-dot:before { background-color: var(--color-${color}) }`}</style>
      <div style={style} className={`chase-wrapper${className ? ` ${className}` : ''}`}>
        <div className='sk-chase' style={chaseStyle}>
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
          <div className='sk-chase-dot' />
        </div>
      </div>
    </>
  );
  if (full && !fixed) {
    return (
      <>
        <div style={{ height: '150px' }} />
        <div style={{ top: '50%', left: '49%' }} className='absolute h-10 z-2'>
          {element}
        </div>
      </>
    );
  }
  if (fixed) {
    return (
      <div style={{ top: '50%', left: '49%' }} className='fixed z-10'>
        {element}
      </div>
    );
  }
  return element;
};
