import { useCallback, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { getRecoil } from 'recoil-nexus';
import { Badge } from '@mui/material';
import { AdminPaths, Breakpoints } from '../../lib/constants';
import { ExcludedPaths, IncludedPaths } from '../PathMatch';
import UsersOnlineButton from '../UsersOnlineButton';
import ULogo from '../Svg/uLogo';
import UserAvatar from '../UserAvatar';
import TopicSettingsButton from '../TopicSettingsButton';
import NavbarSearch from '../NavbarSearch';
import UniverseLoader from '../Loaders/UniverseLoader';

import { emitQueryListScroll } from '../../lib/emitters/emitQueryListScroll';
import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';

import { siteNav, userNav } from '../../models/navigation/selectors';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { navSearchActiveSelector } from '../../models/search/selectors';
import { breakpointNameSelector, navLoaderSelector } from '../../models/settings/selectors';
import topicListQueryKeyState from '../../models/topicListQueryKey/atom';
import { notificationsAndMessagesCountSelector } from '../../models/documentTitle/selectors';
import { ThemeData } from '../../styles/themes';

const navbarAccountContainerClass = 'mr-2 my-3';
const navbarAvatarSize = '30px';

const backArrowIncludedPaths = [
  '/topic/:topicId/:slug',
  'users/:username',
  'category/:category',
  '/message/:id',
  '/admin/users/:username',
  '/messages/*',
];

const usersInRoomPaths = ['/topic/:topicId/:slug', 'message/:id'];

const Navbar = () => {
  const toggleSiteNav = useSetRecoilState(siteNav);
  const navigate = useNavigate();
  const {
    data: user,
    unauthenticated,
    loading,
    authenticated,
  } = useAuthenticatedUser();
  const toggleUserNav = useSetRecoilState(userNav);
  const navSearchActive = useRecoilValue(navSearchActiveSelector);
  const breakpointName = useRecoilValue(breakpointNameSelector);
  const notificationsAndMessagesCount = useRecoilValue(notificationsAndMessagesCountSelector);
  const navLoader = useRecoilValue(navLoaderSelector);

  const handleBack = useCallback(() => navigate(-1, { state: { isBack: 1 } }), [navigate]);

  const handleNavClick = useCallback(async () => {
    const topicListQueryKey = getRecoil(topicListQueryKeyState);
    emitInvalidateQueries({ queryKey: topicListQueryKey });
    emitQueryListScroll({ queryKey: topicListQueryKey, options: { behavior: 'instant' } });
  }, []);

  const brandLogo = useMemo(() => {
    const contents = (
      <div className='flex px-3 main-nav  text-primary-text items-center absolute justify-center h-full w-full'>
        <Link onClick={handleNavClick} className='tku-no-select' to='/'>
          <ULogo baseColor={ThemeData.Base.theme.primary} shadowColor={ThemeData.Base.theme.primaryDark} ringColor={ThemeData.Base.theme.primaryLight} />
          { navLoader && <UniverseLoader containerStyles={{ marginLeft: '50px' }} showLogo={false} size={50} boxType='fullContainer' /> }
        </Link>
      </div>
    );
    if (breakpointName !== Breakpoints.Mobile) return contents;
    if (!navSearchActive) return contents;
    return null;
  }, [navSearchActive, breakpointName, handleNavClick, navLoader]);

  const navHelpers = useMemo(() => {
    const shouldHideContents = (navSearchActive && breakpointName === Breakpoints.Mobile);
    return (
      <IncludedPaths includedPaths={[...backArrowIncludedPaths, ...usersInRoomPaths]}>
        <div className='flex items-center'>
          <IncludedPaths includedPaths={backArrowIncludedPaths}>
            <IconButton
              size='small'
              aria-label='back-button'
              aria-controls='menu-back-button'
              aria-haspopup='false'
              color='primaryLight'
              onClick={handleBack}
            >
              <ArrowBackIcon />
            </IconButton>
          </IncludedPaths>
          {
            !shouldHideContents && (
              <>
                <IncludedPaths includedPaths={usersInRoomPaths}>
                  <UsersOnlineButton />
                  { authenticated && <TopicSettingsButton />}
                </IncludedPaths>
                <IncludedPaths includedPaths={Object.values(AdminPaths)}>
                  <AdminPanelSettingsIcon color='primary' />
                </IncludedPaths>
              </>
            )
          }
        </div>
      </IncludedPaths>
    );
  }, [breakpointName, authenticated, navSearchActive]);

  const siteMenu = useMemo(() => (
    <ExcludedPaths excludedPaths={backArrowIncludedPaths}>
      <div className='flex items-center'>
        <IconButton
          size='small'
          aria-label='site-navigation'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={toggleSiteNav}
          color='primaryLight'
        >
          <MenuIcon />
        </IconButton>
        <IncludedPaths includedPaths={Object.values(AdminPaths)}>
          <AdminPanelSettingsIcon color='primary' />
        </IncludedPaths>
      </div>
    </ExcludedPaths>
  ), []);

  const navbarAccount = useMemo(() => {
    if (loading) {
      return (
        <div style={{
          height: '40px', width: '40px', borderRadius: '100%', padding: '5px  5px',
        }}
        />
      );
    }
    if (unauthenticated) {
      return (
        <IconButton
          size='small'
          aria-label='account-nav'
          aria-controls='account-nav-button'
          color='primary'
          onClick={loading ? null : toggleUserNav}
        >
          <AccountCircleIcon
            color='primary'
            sx={{ cursor: 'pointer', height: navbarAvatarSize, width: navbarAvatarSize }}
          />
        </IconButton>
      );
    }

    if (authenticated) {
      return (
        <Badge
          badgeContent={notificationsAndMessagesCount}
          max={50}
          sx={{ height: '20px' }}
          overlap='circular'
          color='secondary'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <IconButton
            size='small'
            aria-label='account-nav'
            aria-controls='account-nav-button'
            color='primary'
            onClick={toggleUserNav}
          >
            <UserAvatar _id={user?._id} avatarSize={navbarAvatarSize} />
          </IconButton>
        </Badge>

      );
    }
  }, [loading, authenticated, unauthenticated, user, notificationsAndMessagesCount]);

  const search = useMemo(() => {
    const withContainer = (
      <div className={`${navbarAccountContainerClass} navbar-account flex items-center`}>
        <NavbarSearch />
        { navbarAccount }
      </div>
    );
    if (breakpointName !== Breakpoints.Mobile || !navSearchActive) return withContainer;
    return (
      <>
        <NavbarSearch />
        <div className={`${navbarAccountContainerClass} navbar-account flex items-center`}>
          { navbarAccount }
        </div>
      </>
    );
  }, [navbarAccount, navSearchActive, breakpointName]);

  return (
    <nav className='w-full primary bg-surface h-12 tku-box-shadow z-10'>
      <div className='flex items-center justify-between desktop:w-[992px] desktop:ml-auto desktop:mr-auto relative h-[48px]'>
        { brandLogo }
        { navHelpers }
        { siteMenu }
        { search }
      </div>
    </nav>
  );
};

export default Navbar;
