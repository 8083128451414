import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getRoot,
  $getNodeByKey,
  $getSelection,
  $createTextNode,
} from 'lexical';

import { useCallback, useEffect, useState } from 'react';
import { useCustomEvent } from '../../../hooks/useCustomEventListener';
import { ComposerNodeTypes, CustomEvents } from '../../../lib/constants';
import { $createEmbedNode } from '../Nodes/EmbedNode';
import { $createMentionNode } from '../Nodes/MentionNode';
import { $createPostQuoteNode } from '../Nodes/PostQuoteNode';

const $createImageNodeFromUrlAndName = (url, title) => $createEmbedNode({
  type: 'image',
  url,
  emoji: '🖼️',
  text: `🖼️ ${title || url}`,
});

const getShouldAddSpace = (currentlySelectedNode) => {
  const currentTextContent = currentlySelectedNode.getTextContent();
  const currentTextContentSize = currentlySelectedNode.getTextContentSize();
  if (!currentTextContent || !currentTextContentSize) return true;
  const lastText = currentTextContent[currentTextContentSize - 1];
  if (lastText === ' ') return false;
  return true;
};

const handleInsert = (nodeCreationFn, editor) => {
  // No selection
  if (!nodeCreationFn) return;
  const nodeToInsert = nodeCreationFn();
  if (!nodeToInsert) return;
  const root = $getRoot();
  const selection = $getSelection();
  let currentlySelectedNode;
  if (!selection) currentlySelectedNode = root.getLastDescendant();
  if (!currentlySelectedNode) currentlySelectedNode = $getNodeByKey(selection.anchor.key, editor._editorState);
  if (currentlySelectedNode.getParent().getType() === 'root') currentlySelectedNode.append(nodeToInsert);
  else {
    const shouldAddSpace = getShouldAddSpace(currentlySelectedNode);
    if (!shouldAddSpace) currentlySelectedNode.insertAfter(nodeToInsert);
    else {
      const spaceTextNode = $createTextNode(' ');
      currentlySelectedNode.insertAfter(spaceTextNode);
      spaceTextNode.insertAfter(nodeToInsert);
    }
  }
  if (nodeToInsert.isTextEntity()) {
    const spaceAfter = $createTextNode(' ');
    nodeToInsert.insertAfter(spaceAfter);
    spaceAfter.select();
  }
};

const handleNodeCreation = async (nodeType, sourceType, data) => {
  switch (nodeType) {
    case ComposerNodeTypes.Image: {
      const { url, title } = data;
      if (!url) return null;
      return () => $createImageNodeFromUrlAndName(url, title);
    }
    case ComposerNodeTypes.Mention: {
      const {
        username,
        _id,
        avatar,
        color,
      } = data;
      if (!username || !_id || (!avatar && !color)) return null;
      return () => $createMentionNode({
        username,
        _id,
        avatar,
        color,
      });
    }
    case ComposerNodeTypes.PostQuote: {
      const {
        author,
        post,
        topic,
      } = data;
      if (!author || !post || !topic) return null;
      return () => {
        const node = $createPostQuoteNode(data);
        return node;
      };
    }
    default:
      return null;
  }
};

export const InsertPlugin = ({ editorName, sourceType }) => {
  const [editor] = useLexicalComposerContext();
  const [nodeCreationFunction, setNodeCreationFunction] = useState(null);

  const handleInsertInEditor = useCallback(async ({ editorName: _editorName, nodeType, data }) => {
    if (editorName !== _editorName) return;
    const _nodeCreationFunction = await handleNodeCreation(nodeType, sourceType, data);
    editor.update(() => {
      if (!data || !nodeType) return;
      if (!_nodeCreationFunction) return;
      setNodeCreationFunction(() => _nodeCreationFunction);
    });
  }, [sourceType, editorName]);

  const handleReplaceEditorState = useCallback(({ editorName: _editorName, data }) => {
    if (editorName !== _editorName) return;
    const editorContents = data?.editorContents;
    if (!editorContents) return;
    const newState = editor.parseEditorState(editorContents);
    editor.setEditorState(newState);
  }, [editorName, editor]);

  const handleToggleReadOnly = useCallback(({ editorName: _editorName }) => {
    if (editorName !== _editorName) return;
    editor.setReadOnly(!editor.isReadOnly());
  }, [editor, editorName]);

  useCustomEvent(CustomEvents.InsertInEditor, handleInsertInEditor, []);
  useCustomEvent(CustomEvents.ReplaceEditorState, handleReplaceEditorState, []);
  useCustomEvent(CustomEvents.ToggleEditorReadOnly, handleToggleReadOnly, []);

  useEffect(() => {
    if (!nodeCreationFunction) return;
    // this fn must be synchronous
    editor.update(() => {
      handleInsert(nodeCreationFunction, editor);
      setNodeCreationFunction(null);
    });
  }, [nodeCreationFunction, editor]);

  return null;
};
