import { useEffect, useState } from 'react';
import protectedRequirements from '../../lib/protectedRequirements';
import useAuthStatus from '../../hooks/useAuthStatus';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const Protected = ({ children, requirements }) => {
  const { data: user } = useAuthenticatedUser();
  const [isAllowed, setIsAllowed] = useState(false);
  const { unauthenticated } = useAuthStatus();

  useEffect(() => {
    setIsAllowed(protectedRequirements({ user, requirements, unauthenticated }));
  }, [user, requirements]);

  if (unauthenticated) {
    return null;
  }

  if (!requirements) {
    return children;
  }

  if (!isAllowed) {
    return null;
  }
  return children;
};

export default Protected;
