import { useRecoilState } from 'recoil';
import { themeSelector } from '../models/settings/selectors';
import { themes } from '../styles/themes';

const useTheme = () => {
  const [themeState, setThemeState] = useRecoilState(themeSelector);

  const toggleTheme = () => setThemeState(themeState === 'dark' ? 'base' : 'dark');
  const setTheme = (newTheme) => setThemeState(newTheme);

  const themeData = themes[themeState];

  return {
    toggleTheme,
    setTheme,
    theme: themeState,
    themeData,
  };
};

export default useTheme;
