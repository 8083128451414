import { useCallback } from 'react';
import { nanoid } from 'nanoid';
import { useSetRecoilState } from 'recoil';
import { addSnackbarSelector } from '../models/snackbar/selector';
import resizeFile from '../lib/resizeFile';

import ApiClientInstance from '../clients/api';

const useHandleImageUpload = ({
  uploadFolder,
  uploadTitle = nanoid(8),
  callback,
  shouldResize = true,
}) => {
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const uploadImage = useCallback(async (image) => {
    const data = await ApiClientInstance.uploadAttachment({
      attachment: {
        image,
      },
      foldername: uploadFolder,
      title: uploadTitle,
    });
    if (data?.error) {
      addSnackbar({
        message: 'Upload failed. Please try again.',
        severity: 'error',
      });
      return;
    }
    return {
      type: 'photo',
      customType: 'upload',
      url: data.data.url,
      id: nanoid(8),
    };
  }, [uploadFolder, uploadTitle]);

  const handleFileChange = useCallback(async (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    if (file.type.includes('image')) {
      let _image = file;
      if (shouldResize) {
        _image = await resizeFile(file);
      }
      const imageUploadData = await uploadImage(_image);
      if (callback) callback(imageUploadData);
      return imageUploadData;
    }
  }, [callback, uploadImage, uploadTitle, uploadFolder]);

  return handleFileChange;
};

export default useHandleImageUpload;
