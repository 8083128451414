/* eslint-disable react/jsx-props-no-spreading */
import { usePagination } from '@mui/material';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import Pagination from '../Pagination';

const ImageGallery = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChange = (event, value) => {
    setCurrentImage(value - 1);
  };

  const handlePrev = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
      return;
    }
    setCurrentImage(prev => prev - 1);
  };

  const handleNext = () => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
      return;
    }
    setCurrentImage(prev => prev + 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handlePrev,
    onSwipedRight: handleNext,
  });

  const { items } = usePagination({
    count: images.length,
    page: currentImage + 1,
  });

  const { text, url } = images[currentImage];

  return (
    <div style={{ marginLeft: '-15px', marginRight: '-15px', minHeight: '300px' }} {...handlers} className='my-2 flex justify-center flex-col items-center bg-s01dp rounded py-4'>
      <button className='tku-no-select' type='button' onClick={() => emitCustomEvent(CustomEvents.OpenImageModal, { url, text })}>
        <img
          style={{ minHeight: '300px', maxHeight: '400px' }}
          className='rounded'
          alt={text}
          src={url}
        />
      </button>
      { images.length > 1 && (
        <div className='flex justify-center mt-2'>
          <Pagination handlePrev={handlePrev} handleNext={handleNext} handleChange={handleChange} items={items} />
        </div>
      ) }
    </div>
  );
};

export default ImageGallery;

// TODO:

/* <div
className='flex items-center justify-center'
style={{
  position: 'relative',
  paddingTop: 'calc(591.44 / 1127.34 * 100%)',
  width: '100%',
}}
>
<img
  style={{
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    objectFit: 'cover',
    position: 'absolute',
  }}
  src={image.url}
  alt={title || image.url}
  className='rounded w-full h-full px-2 py-2'
/>
</div>
*/
// ASPECT RATIO: 1127.34 / 591.44 = 2.8
// https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
// https://stackoverflow.com/questions/60938707/how-to-fit-an-image-inside-a-fix-sized-div-without-distorting-the-image
