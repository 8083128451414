import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomEvents } from '../lib/constants';
import { useCustomEvent } from './useCustomEventListener';

// TODO: migrate to handleHooks

const useOnRedirect = () => {
  const navigate = useNavigate();

  const handleCustomEventRedirect = useCallback((data) => {
    const { to } = data;
    if (!to) return;
    navigate(to, { replace: true });
  }, []);

  useCustomEvent(CustomEvents.Redirect, handleCustomEventRedirect);
};

export default useOnRedirect;
