import { atom } from 'recoil';

const snackbarState = atom({
  key: 'snackbarState',
  default: {
    snackbars: [],
  },
});

export default snackbarState;
