/* eslint-disable react/jsx-closing-tag-location */
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $createTextNode } from 'lexical';
import { LexicalTypeaheadMenuPlugin } from '../utils/LexicalTypeaheadMenuPlugin';
import { $createEmojiNode } from '../Nodes/EmojiNode';
import { $createCustomEmojiNode } from '../Nodes/CustomEmojiNode';
import {
  SUGGESTION_LIST_LENGTH_LIMIT,
  ColonMentionsMentionsRegexAliasRegex,
  ColonMentionsRegex,
} from './utils/regex';
import { EmojiTypeaheadOption } from './Components/MenuItem';
import { renderMenu } from './Components/Menu';
import { useEmojiSearch } from '../../../hooks/useEmojiSearch';

const checkForColonEmojis = (text, minMatchLength) => {
  let match = ColonMentionsRegex.exec(text);
  if (match === null) match = ColonMentionsMentionsRegexAliasRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      const data = {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
      // import for replacement
      return data;
    }
  }
  return null;
};

const getPossibleQueryMatch = (text) => {
  const match = checkForColonEmojis(text, 1);
  return match || null;
};

export const EmojiPlugin = ({ excludeCustomEmojis = false }) => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState('');
  const { emojis: results, skinTone, setSkinTone } = useEmojiSearch({ queryString, excludeCustomEmojis });

  const onSelectOption = useCallback((selectedOption, nodeToReplace, closeMenu) => {
    editor.update(() => {
      const { type } = selectedOption.emoji;
      const emojiNode = type === 'native' ? $createEmojiNode(selectedOption.emoji) : $createCustomEmojiNode(selectedOption.emoji);
      if (nodeToReplace) nodeToReplace.replace(emojiNode);
      const space = $createTextNode(' ');
      emojiNode.insertAfter(space);
      space.selectNext();
      closeMenu();
    });
  }, [editor]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const checkForMentionMatch = useCallback((text) => {
    const emojiMatch = getPossibleQueryMatch(text);
    const slashMatch = checkForSlashTriggerMatch(text);
    return !slashMatch && emojiMatch ? emojiMatch : null;
  }, [checkForSlashTriggerMatch]);

  const options = useMemo(() => {
    const _results = results.map((result) => new EmojiTypeaheadOption(result));
    return _results.slice(0, SUGGESTION_LIST_LENGTH_LIMIT);
  }, [results]);

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={renderMenu(options, results, skinTone, setSkinTone)}
    />
  );
};
