/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Chip,
  debounce,
  ListItemButton,
  TextField,
} from '@mui/material';
import {
  useCallback,
  useState,
} from 'react';
import ApiClientInstance from '../../clients/api';

// eslint-disable-next-line func-names
const defaultTopicOption = () => function ({
  content,
  mainPost,
  author,
  title,
  slug,
  muiProps,
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItemButton {...muiProps} className='px-3 py-1 flex items-center'>
      <span className='ml-2 text-sm opacity-70'>{slug}</span>
    </ListItemButton>
  );
};

const TopicSelect = ({
  ItemComponent = defaultTopicOption(),
  selectType = 'single',
  setTopic,
  setTopics,
  placeholder = 'Select a topic',
  label = 'topic',
  topic,
  topics,
  topicsToExclude = [],
  className = '',
}) => {
  const [searchedTopics, setSearchedTopics] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSingleTopicChange = useCallback((event, newValue) => {
    setTopic(newValue);
  }, []);

  const handleMultipleTopicsChange = useCallback((event, newValue) => {
    setTopics(newValue);
  }, []);

  const isOptionEqualToValue = useCallback((option, val) => {
    if (!val?._id) return val === '';
    return option?._id === val?._id;
  }, []);

  const getOptionLabel = useCallback((option) => option?.slug || '', []);

  const renderOption = useCallback((props, option) => <ItemComponent key={option._id} slug={option.slug} muiProps={props} />, [ItemComponent]);

  const renderInput = useCallback((params) => <TextField {...params} label={label} />, [label]);

  const filterOptions = useCallback(() => {
    if (topicsToExclude?.length > 0) {
      return searchedTopics.filter((t) => !topicsToExclude.find(_t => _t?._id === t?._id));
    }
    return searchedTopics;
  }, [searchedTopics, topicsToExclude]);

  const renderTags = useCallback((_value, getTagProps) => {
    if (!_value) return null;
    return _value.map((option, index) => (
      <Chip
        color='primary'
        label={option.slug}
        size='small'
        {...getTagProps({ index })}
      />
    ));
  }, []);

  const fetchTopics = useCallback(debounce(async (query) => {
    setSearchLoading(true);
    if (!query) {
      setSearchedTopics([]);
      setSearchLoading(false);
      return;
    }
    const res = await ApiClientInstance.sendRequest({
      path: '/topic/search',
      method: 'GET',
      queryParams: { query },
      catchError: true,
    });
    if (res.success) setSearchedTopics(res.data);
    setSearchLoading(false);
  }, 250), []);

  const handleInputChange = useCallback((event, _value) => fetchTopics(_value), []);

  if (selectType === 'single') {
    return (
      <Autocomplete
        fullWidth
        ListboxProps={{ className: 'bg-s02dp' }}
        className={`${className} bg-s01dp`}
        disablePortal
        placeholder={placeholder}
        options={searchedTopics}
        value={topic}
        loading={searchLoading}
        onChange={handleSingleTopicChange}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
      />
    );
  }
  if (selectType === 'multiple') {
    return (
      <Autocomplete
        className={`${className} bg-s01dp`}
        multiple
        noOptionsText='no topics found'
        options={[...topics, ...searchedTopics]}
        loading={searchLoading}
        loadingText='searching for topics...'
        onChange={handleMultipleTopicsChange}
        value={topics}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        defaultValue={topics}
        filterOptions={filterOptions}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        renderTags={renderTags}
        renderInput={renderInput}
        ListboxProps={{ sx: { backgroundColor: 'var(--color-s04dp)' } }}
      />
    );
  }
};

export default TopicSelect;
