import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitOpenDrawerMenu = ({
  sourceType,
  topicId,
  authorId,
  postId,
}) => {
  if (!sourceType || (!topicId && !postId && !authorId)) return null;
  const data = {
    sourceType,
    topicId,
    authorId,
    postId,
  };
  emitCustomEvent(CustomEvents.OpenDrawerMenu, { type: sourceType, data });
};
