import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import ApiClient from '../../clients/api';
import { formatDate } from '../../lib/formatDate';
import resizeFile from '../../lib/resizeFile';

import UniverseLoader from '../Loaders/UniverseLoader';
import Protected from '../Protected';
import UserAvatar from '../UserAvatar';
import UserBadges from '../UserBadges';
import UserStats from '../UserStats';
import UserStatusDisplay from '../UserStatusDisplay';

import { usersOnlineSelector } from '../../models/usersOnline/selectors';
import { useUser } from '../../models/user/useUser';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { addSnackbarSelector } from '../../models/snackbar/selector';

const backgroundImageStyles = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const UserProfile = ({
  _id,
  isDrawer,
  boxClassName,
  coverImageHeight,
}) => {
  const userOnline = useRecoilValue(usersOnlineSelector(_id));
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const {
    data: user,
    hasLoaded,
    fetching, fetch,
  } = useUser(_id, {
    fetchOnMount: !isDrawer,
  });

  const { data: authenticatedUser, setData } = useAuthenticatedUser();

  const isAuthenticatedUser = useMemo(() => user?._id === authenticatedUser?._id, [user?._id, authenticatedUser?._id]);

  useEffect(() => {
    if (hasLoaded || fetching) return null;
    fetch();
  }, [hasLoaded, fetch, fetching]);

  const uploadImage = useCallback(async (image, type) => {
    if (!isAuthenticatedUser) return;
    const fn = type === 'cover' ? ApiClient.updateCoverImage : ApiClient.updateAvatar;
    const data = await fn(image);
    if (data?.error) return addSnackbar({ message: 'Upload failed. Please try again.', severity: 'error' });
    setData(data.data);
    return {
      type: 'photo',
      customType: 'upload',
      url: data.data.url,
      id: nanoid(16),
    };
  }, [isAuthenticatedUser]);

  const handleFileChange = useCallback(async (e, type) => {
    if (!isAuthenticatedUser) return;
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    if (file.type.includes('image')) {
      const image = await resizeFile(file);
      await uploadImage(image, type);
    }
  }, [uploadImage, isAuthenticatedUser]);

  const requirements = useMemo(() => {
    if (!user) return {};
    const {
      username,
      role,
      groups,
    } = user;
    return {
      user: {
        username,
        role,
        groups,
      },
      self: username,
    };
  }, [user]);

  const coverImageStyles = useMemo(() => {
    if (!user) return {};
    let styles = { width: '100%', backgroundColor: user.color };
    if (isDrawer) {
      styles = {
        ...styles,
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      };
    }
    if (user.coverImage) {
      styles = {
        ...styles,
        backgroundImage: `url(${user.coverImage})`,
        ...backgroundImageStyles,
      };
    }
    return styles;
  }, [user, isDrawer]);

  // Layout
  const coverImageContainer = useMemo(() => {
    if (!user) return null;
    return (
      <div style={coverImageStyles} className={`absolute top-0 left-0 ${coverImageHeight ? `${coverImageHeight}` : 'h-32'}`}>
        <Protected requirements={requirements}>
          <label
            className='file-input-label'
            htmlFor='cover-image-input'
            style={{
              position: 'absolute', bottom: '5px', right: '10px',
            }}
          >
            <input accept='image/*' onChange={(e) => handleFileChange(e, 'cover')} id='cover-image-input' style={{ display: 'none' }} type='file' hidden />
            <AddPhotoAlternateIcon sx={{ borderRadius: '100%', backgroundColor: 'var(--color-disabled)', padding: '4px' }} />
          </label>
        </Protected>
      </div>
    );
  }, [user, isAuthenticatedUser, coverImageStyles, requirements]);

  const userAvatarContainer = useMemo(() => {
    if (!user) return null;
    return (
      <div className='user-avatar relative tku-user-avatar-background'>
        <UserAvatar
          _id={_id}
          avatarSize='125px'
          showOnline
          styles={{
            border: '6px solid var(--color-surface)',
            fontSize: '50px',
          }}
        />
        <div style={{
          height: '18px',
          width: '18px',
          borderRadius: '100%',
          backgroundColor: (user.online || userOnline) ? 'var(--color-success)' : 'var(--color-disabled)',
          position: 'absolute',
          top: '7.5px',
          right: '15px',
        }}
        />
        <Protected requirements={requirements}>
          <label
            className='file-input-label'
            htmlFor='avatar-image-input'
            style={{
              position: 'absolute',
              bottom: '5px',
              right: '10px',
            }}
          >
            <input accept='image/*' onChange={(e) => handleFileChange(e, 'avatar')} id='avatar-image-input' style={{ display: 'none' }} type='file' hidden />
            <AddPhotoAlternateIcon sx={{ borderRadius: '100%', backgroundColor: 'var(--color-disabled)', padding: '4px' }} />
          </label>
        </Protected>
      </div>
    );
  }, [user, _id, requirements, handleFileChange, userOnline]);

  const profileLinkContainer = useMemo(() => {
    if (!user?.username) return null;
    return (
      <Link className='text-primary-light' to={`/users/${user.username}`}>
        <h3 className='text-lg font-bold font-roboto'>{user.username}</h3>
      </Link>
    );
  }, [user?.username]);

  const nicknameContainer = useMemo(() => {
    if (!user?.nickname) return null;
    return (
      <h3 className='text-base font-roboto italic'>
        {`# ${user.nickname}`}
      </h3>
    );
  }, [user?.nickname]);

  const dateJoinedContainer = useMemo(() => {
    if (!user?.dateJoined) return null;
    return (
      <span className='text-sm font-bold mt-2'>
        <span className='opacity-90'>joined</span>
        <span className='ml-1'>{formatDate(new Date(user.dateJoined), 'M/d/yy')}</span>
      </span>
    );
  }, [user?.dateJoined]);

  const lastLoginContainer = useMemo(() => {
    if (!user?.lastLogin || user?.online || userOnline) return null;
    return (
      <span className='text-sm font-bold mt-2'>
        <span className='opacity-90'>last seen</span>
        <span className='ml-1'>{formatDate(new Date(user.lastLogin), 'M/d/yyy h:mm aa')}</span>
      </span>
    );
  }, [user?.lastLogin, userOnline]);

  if (!user || !hasLoaded) {
    return (
      <Box>
        <div
          className={`relative tku-no-select pt-5 mt-10 flex-col flex content-center justify-center items-center text-primary-text font-roboto rounded h-[300px] ${boxClassName && boxClassName}`}
        >
          <UniverseLoader boxType='fullContainer' />
        </div>
      </Box>
    );
  }

  return (
    <Box>
      <div
        className={`tku-no-select pt-5 mt-10 flex-col flex content-center justify-center items-center text-primary-text font-roboto rounded ${boxClassName && boxClassName}`}
      >
        { coverImageContainer }
        { userAvatarContainer }
        { profileLinkContainer }
        { nicknameContainer}
        <UserBadges containerClassName='mt-2' badgeSize='25px' _id={_id} />
        <UserStats _id={_id} />
        { dateJoinedContainer }
        { lastLoginContainer }
        <UserStatusDisplay _id={_id} />
      </div>
    </Box>
  );
};

export default UserProfile;
