/* eslint-disable react/jsx-props-no-spreading */
import {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Button, IconButton, Slider } from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import {
  Breakpoints,
  CustomEvents,
  Platforms,
  SourceTypes,
} from '../../lib/constants';
import debounce from '../../lib/debounce';

import UniverseLoader from '../Loaders/UniverseLoader';
import TopicContent from '../TopicContent';
import UserAvatar from '../UserAvatar';
import PostCardPreview from './PostCardPreview';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import useAuthStatus from '../../hooks/useAuthStatus';
import { breakpointNameSelector, isPwaSelector, platformSelector } from '../../models/settings/selectors';
import { useTopic } from '../../models/topic/useTopic';
import { loadingPaginatedScroller } from '../../models/paginatedScrollerState/selectors';
import postInViewState from '../../models/postInView/atom';
import topicSourceTypeState from '../../models/topicSourceType/atom';
import { usePost } from '../../models/post/usePost';
import { PostModel } from '../../models/post/model';
import { PrivatePostModel } from '../../models/privatePost/model';
import DisplayAvatar from '../DisplayAvatar';

const getRealIndex = (value, max) => Math.abs(value - max) + 1;

const getIndexFromPostId = (postId, navigateData, adjustForSlider = true) => {
  const { posts } = navigateData;
  const keys = Object.keys(posts);
  let index = -1;
  for (let i = 0; i < keys.length; i += 1) {
    if (index !== -1) break;
    if (keys[i] === postId) index = Math.abs(i - keys.length);
  }
  return index;
};

const getPostIdFromIndex = (index, navigateData) => {
  const { posts } = navigateData;
  const keys = Object.keys(posts);
  let postId = '';
  for (let i = 0; i < keys.length; i += 1) {
    const _index = posts[keys[i]];
    if (index - 1 === _index) {
      postId = keys[i];
      break;
    }
  }
  return postId;
};

const PostNavigatorContent = ({
  topicId,
  setPostSelectorVisibility,
  navigateData,
  sourceType,
  contentOptions,
}) => {
  const [selectedPostIndex, setSelectedPostIndex] = useState(null);
  const [selectedPostSliderIndex, setSelectedPostSliderIndex] = useState(null);

  const loading = useRecoilValue(loadingPaginatedScroller(topicId));
  const currentPost = useRecoilValue(postInViewState(topicId));
  const topicSourceType = useRecoilValue(topicSourceTypeState(topicId));
  const breakpointName = useRecoilValue(breakpointNameSelector);
  const isPwa = useRecoilValue(isPwaSelector);
  const platform = useRecoilValue(platformSelector);
  const isIos = platform === Platforms.iOS;

  const { data: topic } = useTopic(topicId, { sourceType: topicSourceType });

  const selectedPostId = getPostIdFromIndex(selectedPostIndex, navigateData);
  const { data: postCardPreview, fetching, loading: postCardPreviewLoading } = usePost(selectedPostId || currentPost, { sourceType });

  const { authenticated, unauthenticated, loading: authLoading } = useAuthStatus();

  const containerStyle = useMemo(() => {
    let bottom;
    if (breakpointName === Breakpoints.Mobile && authenticated) bottom = contentOptions?.bottomValues?.mobileAuthenticated || '0';
    if (breakpointName === Breakpoints.Mobile && !authenticated) bottom = contentOptions?.bottomValues?.mobileUnauthenticated || '0';
    if (breakpointName !== Breakpoints.Mobile && authenticated) bottom = contentOptions?.bottomValues?.desktopAuthenticated || '0';
    if (breakpointName !== Breakpoints.Mobile && !authenticated) bottom = contentOptions?.bottomValues?.desktopUnauthenticated || '0';
    return {
      zIndex: 100000,
      ...contentOptions?.style,
      maxWidth: '992px',
      position: 'fixed',
      bottom,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    };
  }, [authLoading, authenticated, unauthenticated, breakpointName, contentOptions]);

  const closeButton = useMemo(() => (
    <IconButton
      onClick={() => setPostSelectorVisibility(false)}
      color='textPrimary'
      size='small'
      sx={{
        zIndex: 10,
      }}
    >
      <Cancel />
    </IconButton>
  ), [setPostSelectorVisibility]);

  const max = useMemo(() => {
    if (!navigateData?.posts) return 0;
    return Object.keys(navigateData.posts).length;
  }, [navigateData]);

  const updateSelectedPost = useCallback(debounce(async (index) => {
    if (!navigateData?.posts) return;
    const postId = getPostIdFromIndex(index, navigateData);
    if (!postId) return;
    const Model = sourceType === SourceTypes.Post ? PostModel : PrivatePostModel;
    const post = new Model(postId);
    const state = post.getState();
    const { loading: _loading, fetching: _fetching } = state;
    if (_fetching || _loading) return;
    await post.fetchIfStale(1000 * 15);
  }, 100), [navigateData]);

  useEffect(() => {
    updateSelectedPost(selectedPostIndex);
  }, [selectedPostIndex]);

  useEffect(() => {
    setSelectedPostSliderIndex(getIndexFromPostId(currentPost, navigateData, true));
  }, []);

  const handleChange = useCallback((event, newValue) => {
    setSelectedPostSliderIndex(newValue);
    setSelectedPostIndex(getRealIndex(newValue, max));
  }, [max, navigateData]);

  // Layout
  const slider = useMemo(() => (
    <div style={{
      paddingTop: '10px',
      paddingBottom: isPwa && isIos ? '26px' : '10px',
      width: '45px',
      height: '325px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
    }}
    >
      <Slider
        track={false}
        color='primaryLight'
        aria-label='Post Index Selector'
        orientation='vertical'
        onChange={handleChange}
        getAriaValueText={(value) => value}
        valueLabelDisplay='auto'
        defaultValue={selectedPostSliderIndex}
        value={selectedPostSliderIndex}
        valueLabelFormat={(value) => getRealIndex(value, max)}
        min={1}
        max={max}
      />
    </div>
  ), [max, selectedPostIndex, selectedPostSliderIndex, isPwa, isIos]);

  const postCardContainer = useMemo(() => {
    const handleGoToPost = () => {
      emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: postCardPreview._id });
      setPostSelectorVisibility(false);
    };

    const pb = isPwa && isIos ? 'pb-6' : 'pb-0';

    if (!!postCardPreview) {
      return (
        <div
          style={{
            height: '325px',
            width: '100%',
            overflow: 'auto',
            position: 'relative',
          }}
          className={`pr-2 rounded ${pb}`}
        >
          <Button
            onClick={handleGoToPost}
            color='primaryLight'
            size='small'
            sx={{
              position: 'absolute',
              top: '5px',
              right: '12px',
              zIndex: 10,
            }}
          >
            Go
          </Button>
          <PostCardPreview
            _id={postCardPreview._id}
            sourceType={sourceType}
          />
        </div>
      );
    }

    return (
      <div style={{ minWidth: '80%' }} className={`h-[325px] mr-2 px-3 ${pb} overflow-auto relative`}>
        <UniverseLoader boxType='fullContainer' size={100} showLogo={false} />
      </div>
    );
  }, [postCardPreviewLoading, postCardPreview, sourceType, fetching, isPwa, isIos]);

  const title = useMemo(() => {
    if (!topic) return '';
    if (sourceType === SourceTypes.Post) {
      return (
        <div className='space-y-1 text-primary-text'>
          <TopicContent editorStyles={{ overflow: 'hidden' }} content={topic.title} />
          <div className='flex items-center text-sm space-x-1'>
            <DisplayAvatar avatarSize='16px' avatarFontSize='12px' avatar={topic.category.image} color={topic.category.color} username={topic.category.name} />
            <span className='text-[12px] uppercase font-bold text-primary-light'>
              <Link to={`/category/${topic.category.slug}`}>{ topic.category.name }</Link>
            </span>
            <span className='mr-0'>by</span>
            <UserAvatar shouldUseProfileDrawer={sourceType === SourceTypes.Post} _id={topic.author._id} avatarSize='16px' avatarFontSize='16px' />
            <span className='ml-0'>{topic.author.username}</span>
          </div>
        </div>
      );
    }
    return (
      <div className='space-y-1 text-primary-text'>
        <TopicContent editorStyles={{ overflow: 'hidden' }} content={topic.title} />
      </div>
    );
  }, [topic, sourceType]);

  if (!navigateData || !currentPost || loading) return null;

  return (
    <div style={containerStyle} className='post-navigator-wrapper w-[100%] bg-surface relative rounded'>
      <div className='post-navigator-inner-wrapper bg-s04dp w-[100%] h-[420px] px-2 py-2 rounded'>
        <div className='post-navigator-header flex justify-between px-1 my-2 items-center h-[54px]'>
          { title }
          { closeButton }
        </div>
        <div className='post-navigator-content flex items-center pt-1 h-[345px] justify-between rounded'>
          { postCardContainer }
          { slider }
        </div>
      </div>
    </div>
  );
};

export default PostNavigatorContent;
