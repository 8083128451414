import { Button, IconButton, Modal } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import Cancel from '@mui/icons-material/Cancel';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import PostContent from '../PostContent';
import { useLexicalEditor } from '../../models/editor/useLexicalEditor';

const PostPreviewModal = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [content, setContent] = useState(null);

  const {
    isLoading,
    getEditorContents,
    handleSend: handleSendPost,
  } = useLexicalEditor({ editorName: data?.editorName, sourceType: data?.sourceType });

  useEffect(() => {
    if (!data) return;
    setContent(getEditorContents());
  }, [data]);

  const handleClose = useCallback(() => {
    setData(null);
    setOpen(false);
  }, []);

  const handleOpen = useCallback(({ editorName: _editorName, sourceType: _sourceType }) => {
    setOpen(true);
    setData({ sourceType: _sourceType, editorName: _editorName });
  }, []);

  const handleSend = useCallback(async () => {
    await handleSendPost();
    handleClose();
  }, [handleSendPost]);

  useCustomEvent(CustomEvents.OpenPostPreviewModal, handleOpen);

  if (!content) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: '992px', maxHeight: '75vh' }} className='bg-surface px-4 py-2 rounded font-roboto text-primary-text w-[95vw]'>
        <div className='flex justify-between items-center'>
          <div style={{ fontSize: '24px' }} className='font-bebas'>Post Preview</div>
          <IconButton onClick={handleClose}><Cancel /></IconButton>
        </div>
        <div style={{ maxHeight: '65vh' }} className='tku-no-select overflow-auto'>
          <PostContent content={content} />
        </div>
        <div className='flex justify-between mt-3'>
          <Button
            disabled={isLoading}
            variant='outlined'
            onClick={handleClose}
          >
            Edit
          </Button>
          <Button
            disabled={isLoading}
            endIcon={<SendIcon />}
            variant='contained'
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PostPreviewModal;
