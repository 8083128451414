import { atom } from 'recoil';

const appState = atom({
  key: 'appState',
  default: {
    isOnline: true,
  },
});

export default appState;
