import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { reputationLevelsSelector } from '../models/settings/selectors';
import ApiClientInstance from '../clients/api';

export const useReputationLevels = () => {
  const [reputationLevels, setReputationLevels] = useRecoilState(reputationLevelsSelector);

  useEffect(async () => {
    const res = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: '/misc/reputation-levels',
      retryAttempts: 3,
      catchError: true,
    });
    if (res.success) {
      const { data } = res;
      // sort data object by min
      const sortedData = Object.keys(data).sort((a, b) => data[a].min - data[b].min);
      const sortedReputationLevels = [];
      sortedData.forEach((key) => {
        sortedReputationLevels.push(data[key]);
      });
      setReputationLevels(sortedReputationLevels);
    }
  }, []);

  return reputationLevels;
};
