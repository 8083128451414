import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import ApiClientInstance from '../clients/api';
import { usersOnlineSelector } from '../models/usersOnline/selectors';

const INTERVAL_TIME = 1000 * 60 * 3;

const useUsersOnline = () => {
  // USERS ONLINE
  const setUsersOnline = useSetRecoilState(usersOnlineSelector());

  const fetchUsersOnline = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      path: '/user/online',
      method: 'get',
      catchError: true,
    });
    if (res?.success) setUsersOnline(res.data);
  }, []);

  useEffect(async () => {
    await fetchUsersOnline();
    const interval = setInterval(fetchUsersOnline, INTERVAL_TIME);
    return () => clearInterval(interval);
  }, []);
};

export default useUsersOnline;
