import { getRecoil, setRecoil } from 'recoil-nexus';
import { BaseModel } from '../BaseModel';
import * as UserProductApi from './api';
import state from './atom';

const getTime = () => new Date().getTime();

export class UserProductModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => UserProductModel.requestUserProduct(id);
    super(id, state, request);
    this.id = id;
  }

  static requestUserProduct(id) {
    if (!id) return;
    return UserProductApi.requestUserProduct(id);
  }

  static setUserProduct(id, data) {
    if (!id || !data) return;
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  static getUserProduct(id) {
    if (!id) return;
    return getRecoil(state(id)).data;
  }

  onSetState = (userProductState, prevUserProductState) => {
    console.log(userProductState);
  };
}
