/* eslint-disable react/function-component-definition */
import {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  UserRoles,
  SourceTypes,
  SocketRooms,
} from '../../lib/constants';

import PostList from '../../components/PostList';
import DrawerMenu from '../../components/DrawerMenu';
import { Wrapper } from '../../components/Containers';
import UsersOnline from '../../components/UsersOnline';
import TopicSettings from '../../components/TopicSettings';
import SocketRoom from '../../components/SocketRoom';
import TopicBottomToolbar from './TopicBottomToolbar';
import TopicComposer from './TopicComposer';
import UniverseLoader from '../../components/Loaders/UniverseLoader';
import UsersWriting from '../../components/UsersWriting';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useUserIsWriting } from '../../hooks/useUserIsWriting';
import { useTopic } from '../../models/topic/useTopic';
import topicShortIdMappingState from '../../models/topicShortIdMapping/atom';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import postSourceTypeState from '../../models/postSourceType/atom';
import postInViewState from '../../models/postInView/atom';
import topicSourceTypeState from '../../models/topicSourceType/atom';
import useEventListener from '../../hooks/useEventListener';
import { isPwaSelector } from '../../models/settings/selectors';

const Topic = () => {
  // Ref
  const wrapperRef = useRef();

  // Hooks
  const navigate = useNavigate();
  const { shortId } = useParams();
  const { data: user } = useAuthenticatedUser();
  const [parentLoading, setParentLoading] = useState(true);
  const topicIdFromShortIdMapping = useRecoilValue(topicShortIdMappingState(shortId));
  // allows fetching on mount after shortId mapping has occured at least once
  const getTopicIdFromShortIdMappingSnapshot = useRecoilCallback(({ snapshot }) => () => snapshot.getLoadable(topicShortIdMappingState(shortId))?.contents || null, []);
  const topicIdFromShortIdMappingSnapshot = useMemo(() => getTopicIdFromShortIdMappingSnapshot(), []);

  const {
    data: topicData,
    error,
    reload: reloadTopic,
  } = useTopic(topicIdFromShortIdMapping || 'false', {
    sourceType: SourceTypes.Topic,
    loadFromShortId: !topicIdFromShortIdMappingSnapshot,
    shortId,
    fetchOnMount: !!topicIdFromShortIdMappingSnapshot,
  });

  useUserIsWriting(topicData?._id || 'false');

  const setPostSourceType = useSetRecoilState(postSourceTypeState(topicData?._id || 'false'));
  const setCurrentPost = useSetRecoilState(postInViewState(topicData?._id || 'false'));
  const setTopicSourceType = useSetRecoilState(topicSourceTypeState(topicData?._id || 'false'));
  const isPwa = useRecoilValue(isPwaSelector);

  // Guard Clause Topic
  useEffect(() => {
    if (!topicData?._id) return;
    setPostSourceType(SourceTypes.Post);
    setTopicSourceType(SourceTypes.Topic);
    return () => {
      setCurrentPost(null);
    };
  }, [topicData?._id]);

  // Error Handler
  useEffect(() => {
    if (!error) return;
    navigate('/404');
  }, [error]);

  // Deleted/Purged Handler
  useEffect(() => {
    if (!topicData?.isDeleted) return null;
    if ([UserRoles.Admin, UserRoles.Moderator, UserRoles.SuperAdmin].includes(user?.role)) return null;
    return navigate('/');
  }, [topicData?.isDeleted, user]);

  // Title Sync
  useDocumentTitle(topicData?.textContent);

  // PWA Refresh
  const handleVisibilityChange = useCallback(() => {
    if (!isPwa) return;
    setParentLoading(true);
    reloadTopic();
  }, [isPwa, reloadTopic]);

  useEventListener('visibilitychange', handleVisibilityChange);

  const loader = useMemo(() => {
    if (!parentLoading) return null;
    return <UniverseLoader boxType='fullContainer' />;
  }, [parentLoading]);

  const mainPostContent = useMemo(() => {
    if (!topicData || shortId !== topicData?.shortId) return null;
    return (
      <>
        <Wrapper className='relative' applyBreakpoints ref={wrapperRef}>
          <UsersWriting sourceType={SourceTypes.Topic} topicId={topicData?._id} />
          <PostList
            topic={topicData}
            setParentLoading={setParentLoading}
          />
          <DrawerMenu sourceType={SourceTypes.Post} />
          <TopicBottomToolbar _id={topicData?._id} />
          <TopicComposer _id={topicData?._id} sourceType={SourceTypes.Post} />
        </Wrapper>
        <UsersOnline sourceType={SourceTypes.Topic} topicId={topicData?._id} />
        <TopicSettings sourceType={SourceTypes.Topic} topicId={topicData?._id} />
        <SocketRoom roomPath={SocketRooms.Topic} roomName={topicData?._id} />
      </>
    );
  }, [topicData, shortId]);

  return (
    <>
      { loader }
      { mainPostContent }
    </>
  );
};

export default Topic;
