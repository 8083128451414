import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  TextField,
} from '@mui/material';

import { useSetRecoilState } from 'recoil';
import UserSelect from '../UserSelect';
import { CustomEvents, UserRoles, UserStatus } from '../../lib/constants';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';

const customFilter = users => {
  let _users = users;
  if (!_users.length) return [];
  _users = _users.filter(user => {
    const roleDisqualify = [UserRoles.Admin, UserRoles.SuperAdmin].includes(user.role);
    const statusDisqualify = user.status !== UserStatus.Good;
    const bannedDisqualify = user.banned;
    return (!roleDisqualify && !statusDisqualify && !bannedDisqualify);
  });
  return _users;
};

const SilenceUser = ({
  mainContainerClassname,
  initialUser,
  handleConfirm,
  handleCancel,
}) => {
  const [user, setUser] = useState(initialUser);
  const [minutes, setMinutes] = useState(5);
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const minutesValid = useMemo(() => minutes > 0 && minutes < 61, [minutes]);

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          _user: user,
          minutes,
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: `Silence ${user.username} for ${minutes} minutes`,
        data: {
          description: { text: `${user.username} will not be able to create topics, reply to topics, or send messages until their silence is over.` },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, user, minutes]);

  return (
    <div className={`${mainContainerClassname} flex flex-col justify-between text-primary-text h-[350px]`}>
      <div>
        <h1 className='mb-4'>Silence User</h1>
        <UserSelect
          customFilter={customFilter}
          label='user to silence'
          user={user}
          setUser={setUser}
          selectType='single'
        />
        <div className='mt-5'>
          <TextField
            error={minutes && !minutesValid}
            label='Minutes'
            value={minutes}
            onChange={e => setMinutes(e.target.value)}
            type='number'
            variant='outlined'
            fullWidth
            helperText={minutes && !minutesValid ? 'Minutes must be greater than 0 and less than or equal to 60' : ''}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 1,
                max: 60,
              },
            }}
          />
        </div>
      </div>
      <div className='flex justify-between space-x-2'>
        <Button
          variant='outlined'
          color='secondaryLight'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={!user || !minutes || !minutesValid}
          onClick={_handleSubmit}
        >
          { (!!user && !!minutes) ? `Silence ${user.username}` : 'Select User'}
        </Button>
      </div>
    </div>
  );
};

export default SilenceUser;
