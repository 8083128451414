import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ExcludedPaths, IncludedPaths } from '../PathMatch';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, Platforms } from '../../lib/constants';
import NotificationsButton from '../NotificationsButton';
import MessagesButton from '../MessagesButton';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { isPwaSelector, platformSelector } from '../../models/settings/selectors';
import useTheme from '../../hooks/useTheme';
import getContrastColor from '../../lib/getContrastColor';

const hideFooterPaths = ['/topic/:shortId/:slug', '/message/:id', '/messages/*'];

const Footer = () => {
  const {
    loading,
    authenticated,
    unauthenticated,
    verified,
  } = useAuthenticatedUser();

  const isPwa = useRecoilValue(isPwaSelector);
  const platform = useRecoilValue(platformSelector);
  const isIos = platform === Platforms.iOS;
  const { themeData } = useTheme();

  const handleTopicCreateClick = useCallback(() => emitCustomEvent(CustomEvents.OpenTopicCreateModal), []);

  const height = isPwa ? 'h-16' : 'h-12';
  const pb = isPwa && isIos ? ' pb-3' : '';

  if (loading) return null;

  if (unauthenticated) {
    const textcolor = `#${getContrastColor(themeData.secondary.replace('#', ''))}`;
    return (
      <ExcludedPaths excludedPaths={['/login', '/register', '/password-reset', '/create-password', ...hideFooterPaths]}>
        <div style={{ color: textcolor }} className={`bg-secondary font-roboto w-full ${height} flex items-center justify-center space-x-3${pb}`}>
          <ExcludedPaths excludedPaths={['/sports']}>
            <h1>Share your take. Join the best.</h1>
          </ExcludedPaths>
          <IncludedPaths includedPaths={['/sports']}>
            <h1> Betting FOMO? Get in now.</h1>
          </IncludedPaths>
          <Button sx={{ borderColor: textcolor, color: textcolor }} variant='outlined' size='small'>
            <Link to='/register'>
              Sign Up
            </Link>
          </Button>
        </div>
      </ExcludedPaths>
    );
  }

  if (authenticated) {
    return (
      <ExcludedPaths excludedPaths={hideFooterPaths}>
        <footer className={`tku-main-footer tku-box-shadow w-full ${height} bg-s03dp px-6 z-10`}>
          <div className={`flex items-center justify-between ${height} desktop:w-[992px] desktop:ml-auto desktop:mr-auto${pb}`}>
            <MessagesButton />
            <IconButton disabled={!verified} type='button' onClick={handleTopicCreateClick}>
              <AddBoxIcon color={!verified ? 'disabled' : 'primaryLight'} />
            </IconButton>
            <NotificationsButton />
          </div>
        </footer>
      </ExcludedPaths>
    );
  }
};

export default Footer;
