/* eslint-disable react/jsx-closing-tag-location */
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $createTextNode } from 'lexical';
import { LexicalTypeaheadMenuPlugin } from '../utils/LexicalTypeaheadMenuPlugin';

import { $createEmbedNode } from '../Nodes/EmbedNode';
import { renderMenu } from './Components/Menu';
import ApiClientInstance from '../../../clients/api';
import debounce from '../../../lib/debounce';

export const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
const checkUrl = (text) => {
  const match = URL_MATCHER.exec(text);
  return (
    match && {
      index: match.index,
      length: match[0].length,
      text: match[0],
      url: match[0],
    }
  );
};

const checkForUrls = (text, minMatchLength) => {
  const match = checkUrl(text);
  if (!match) return null;
  if (match !== null) {
    const {
      index,
      length,
      text: _text,
    } = match;
    if (length >= minMatchLength) {
      const data = {
        leadOffset: index,
        matchingString: _text,
        replaceableString: _text,
      };
      // import for replacement
      return data;
    }
  }
  return null;
};

const getPossibleQueryMatch = (text) => {
  const match = checkForUrls(text, 1);
  return match || null;
};

const useEmbedData = (query) => {
  const [data, setData] = useState(null);
  const [_query, setQuery] = useState(query);
  const [loading, setLoading] = useState(false);
  const stateRef = useRef({ query: null, data: null });

  const fetchData = useCallback(async (__query) => {
    if (!__query) return;
    setLoading(true);
    const res = await ApiClientInstance.getEmbed(__query);
    setData(res);
    stateRef.current = {
      query: __query,
      data: res,
    };
    setLoading(false);
  }, []);

  const _setQuery = useCallback(debounce((q) => {
    if (!q) return;
    setQuery(q);
  }, 500), []);

  useEffect(async () => {
    await fetchData(_query);
  }, [_query]);

  useEffect(() => {
    if (!query) return;
    if (query === stateRef.current.query) {
      setData(stateRef.current.data);
      return;
    }
    _setQuery(query);
  }, [query]);

  return { data, loading, setData };
};

export const EmbedPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState('');

  const { data, loading, setData } = useEmbedData(queryString);

  const onSelectOption = useCallback((selectedOption, nodeToReplace, closeMenu) => {
    if (!selectedOption) return;
    editor.update(() => {
      const embedNode = $createEmbedNode(selectedOption);
      if (nodeToReplace) nodeToReplace.replace(embedNode);
      const space = $createTextNode(' ');
      embedNode.insertAfter(space);
      space.select();
      closeMenu();
    });
  }, [editor]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const checkForMentionMatch = useCallback((text) => {
    const urlMatch = getPossibleQueryMatch(text);
    const slashMatch = checkForSlashTriggerMatch(text);
    return !slashMatch && urlMatch ? urlMatch : null;
  }, [checkForSlashTriggerMatch]);

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={data}
      menuRenderFn={renderMenu(data, loading, queryString, setData)}
    />
  );
};
