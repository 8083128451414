import { getRecoil, setRecoil } from 'recoil-nexus';
import { SocketUpdateActions } from '../../lib/constants';

import { UserModel } from '../../models/user/model';
import authenticatedUserState from '../../models/authenticatedUser/atom';
import usersWritingState from '../../models/usersWriting/atom';

const handleApplicationStateEffects = (res) => {
};

const handleUserWriting = (res) => {
  const { data } = res;
  const { isWriting, topic, user } = data;
  const { data: authenticatedUser } = getRecoil(authenticatedUserState);
  if (authenticatedUser && (user._id === authenticatedUser._id)) return;
  if (isWriting) {
    UserModel.setUser(user._id, user);
    const currentUsersWriting = getRecoil(usersWritingState(topic));
    if (currentUsersWriting.includes(user._id)) return;
    const newUsersWriting = [...currentUsersWriting, user._id];
    setRecoil(usersWritingState(topic), newUsersWriting);
  }
  if (!isWriting) {
    const currentUsersWriting = getRecoil(usersWritingState(topic));
    const newUsersWriting = currentUsersWriting.filter((id) => id !== user._id);
    setRecoil(usersWritingState(topic), newUsersWriting);
  }
};

export const handleApplicationState = (res) => {
  const { action, data } = res;
  switch (action) {
    case SocketUpdateActions.UserWriting: {
      handleUserWriting(res);
      break;
    }
    default: {
      console.log('unknown application state action', action);
    }
  }
  handleApplicationStateEffects(res);
};
