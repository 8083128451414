import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getReputationLevel } from '../../lib/getReputationLevel';
import { reputationLevelsSelector } from '../../models/settings/selectors';
import { useUser } from '../../models/user/useUser';
import BadgeGroup from '../BadgeGroup';

const defaultSrc = '/assets/reputation/crown.svg';

const defaultBadges = [
  {
    _id: 'default',
    name: 'Default',
    image: defaultSrc,
    order: 1,
    enabled: true,
  },
];

const buildReputationBadgeObject = ({
  reputation,
  reputationLevels,
}) => {
  const reputationLevelName = getReputationLevel(reputation, reputationLevels);
  return {
    name: reputationLevelName,
    image: `https://cdn.2kuniverse.com/badge/${reputationLevelName}.svg`,
    order: 1,
    enabled: true,
  };
};

const UserBadges = ({
  _id,
  badgeSize = '22px',
  containerClassName,
}) => {
  const { data } = useUser(_id);

  const reputationLevels = useRecoilValue(reputationLevelsSelector);

  const badges = useMemo(() => {
    if (!reputationLevels) return [];
    if (!data?.badges) return [];
    let _rep = data?.reputation;
    if (Number.isNaN(_rep)) _rep = 0;
    const reputationBadge = buildReputationBadgeObject({
      reputation: _rep || 0,
      reputationLevels,
    });
    return [reputationBadge, ...data.badges];
  }, [data?.badges, data?.reputation, reputationLevels]);
  const reputation = data?.reputation;

  if ((!badges || badges?.length === 0) && Number.isNaN(reputation)) {
    return (
      <BadgeGroup badges={defaultBadges} containerClassName={containerClassName} size={badgeSize} />
    );
  }

  return (
    <BadgeGroup badges={badges} containerClassName={containerClassName} size={badgeSize} />
  );
};

export default UserBadges;
