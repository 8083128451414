export const SUGGESTION_LIST_LENGTH_LIMIT = 5;
export const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
export const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

export const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

export const PUNC = DocumentMentionsRegex.PUNCTUATION;

export const TRIGGERS = ['@'].join('');
export const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;
export const LENGTH_LIMIT = 75;
export const ALIAS_LENGTH_LIMIT = 50;

export const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()('
    + `[${
      TRIGGERS
    }]`
    + `((?:${
      VALID_CHARS
    }){0,${
      LENGTH_LIMIT
    }})`
    + ')$',
);

export const AtSignMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()('
    + `[${
      TRIGGERS
    }]`
    + `((?:${
      VALID_CHARS
    }){0,${
      ALIAS_LENGTH_LIMIT
    }})`
    + ')$',
);
