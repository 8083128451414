import { setRecoil, getRecoil } from 'recoil-nexus';

const getTime = () => new Date().getTime();

export class BaseModel {
  key = '';
  atom;
  request;
  constructor(key, atom, request) {
    this.key = key;
    this.atom = typeof atom === 'function' ? atom(key) : atom;
    this.request = request;
  }

  getState = () => getRecoil(this.atom);

  setState = (data) => {
    let _prevState;
    let _newState;
    setRecoil(this.atom, (prevState) => {
      _prevState = { ...prevState };
      const newState = {
        ...prevState,
        ...data,
        lastModified: getTime(),
      };
      _newState = { ...newState };
      return newState;
    });
    if (this.onSetState) this.onSetState(_newState, _prevState);
  };

  getData = () => {
    const _state = this.getState();
    if (!_state.data) {
      this.reload();
      return;
    }
    return _state?.data;
  };

  setData = (data) => this.setState({ data });

  getLastModified = () => {
    const _state = this.getState();
    return _state?.lastModified;
  };

  loadIfNeeded = () => {
    const _state = this.getState();
    if (!_state.hasLoaded && !_state.data && !_state.loading && !_state.fetching) this.reload();
  };

  reload = async () => {
    this.setState({
      loading: true,
      data: null,
      error: null,
    });
    const res = await this.request();
    if (res?.success) {
      this.setState({
        loading: false,
        error: null,
        data: res.data,
        hasLoaded: true,
      });
      if (this.onReload) this.onReload(res.data);
      return res;
    }
    this.setState({
      loading: false,
      error: res.error,
      data: null,
      hasLoaded: true,
    });
  };

  fetch = async () => {
    this.setState({
      fetching: true,
    });
    const res = await this.request();
    if (res.success) {
      this.setState({
        fetching: false,
        error: null,
        data: res.data,
        hasLoaded: true,
      });
      return res;
    }
    this.setState({
      fetching: false,
      error: res.error,
      data: null,
      hasLoaded: true,
    });
  };

  fetchIfStale = async (staleTime = 1000) => {
    const _state = this.getState();
    if (!_state.hasLoaded && !_state.data && !_state.loading && !_state.fetching) this.fetch();
    else if (_state.lastModified && getTime() - _state.lastModified > staleTime) this.fetch();
  };
}
