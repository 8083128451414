import { getRecoil, setRecoil } from 'recoil-nexus';
import { BaseModel } from '../BaseModel';
import * as PrivatePostApi from './api';
import { UserModel } from '../user/model';
import state from '../post/atom';

const getTime = () => new Date().getTime();

export class PrivatePostModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => PrivatePostModel.requestPost(id);
    super(id, state, request);
    this.id = id;
  }

  static requestPost(id) {
    if (!id) return;
    return PrivatePostApi.requestPost(id);
  }

  static setPost(id, data) {
    if (!id || !data) return;
    if (data.author?.username && data.author?._id) UserModel.setUserPartial(data.author._id, data.author);
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  static getPost(id) {
    if (!id) return;
    return getRecoil(state(id)).data;
  }

  vote(delta) {
    if (!this.id) return;
    const topicId = this.getData()?.topic?._id;
    if (!topicId) return;
    return PrivatePostApi.vote(this.id, topicId, delta);
  }

  getReplies() {
    if (!this.id) return;
    return PrivatePostApi.getReplies(this.id);
  }

  getVotes = (voteType) => {
    if (!this.id) return;
    return PrivatePostApi.getVotes(this.id, voteType);
  };

  onSetState = (postState, prevPostState) => {
    if (!postState?.data?.username || !postState.data?._id) return;
    if (postState.data?.username) UserModel.setUsernameMapping(postState.data.username, postState.data._id);
  };
}
