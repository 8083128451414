import { TextNode } from 'lexical';
import { emitCustomEvent } from '../../../hooks/useCustomEventListener';
import { CustomEvents } from '../../../lib/constants';

const NAME = 'mention';
const MODE = 'token';

// DO NOT TOUCH
const MAIN_ATTRIBUTE = `data-lexical-${NAME}`;
const DATA_ATTRIBUTE = `data-lexical-${NAME}-data`;
const CLASS_NAME = `editor-${NAME}`;

export class MentionNode extends TextNode {
  __data;

  static getType() {
    return NAME;
  }

  static clone(node) {
    return new MentionNode(node.__data);
  }

  static create = (data) => {
    const node = new MentionNode(data);
    node.setMode(MODE);
    return node;
  };

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    const node = new MentionNode(serializedNode.data);
    node.setMode(MODE);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(data, key) {
    const text = `@${data.username}`;
    super(text, key);
    this.__data = data;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      data: this.__data,
      type: NAME,
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.className = CLASS_NAME;
    dom.setAttribute('spellcheck', 'false');
    // MENTION SPECIFIC
    dom.ariaRoleDescription = NAME;
    dom.setAttribute('role', 'button');
    dom.tabIndex = 0;
    dom.onkeydown = () => emitCustomEvent(CustomEvents.OpenUserProfileDrawer, { sourceType: 'Post', username: this.__data.username });
    dom.onclick = () => emitCustomEvent(CustomEvents.OpenUserProfileDrawer, { sourceType: 'Post', username: this.__data.username });
    return dom;
    // possible Avatar usage
    // const wrapper = document.createElement('span');
    // wrapper.className = CLASS_NAME;
    // const outer = document.createElement('span');
    // outer.className = `${CLASS_NAME}-outer ${CLASS_NAME}-${this.__data.name}`;
    // outer.style.backgroundImage = `url(${this.__data.avatar})`;
    // // EMOJI SPECIFIC
    // const inner = document.createElement('span');
    // inner.className = `${CLASS_NAME}-inner`;
    // inner.textContent = '🙂';
    // outer.appendChild(inner);
    // const text = super.createDOM(config);
    // wrapper.append(outer);
    // wrapper.append(text);
    // return wrapper;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute(MAIN_ATTRIBUTE, 'true');
    element.setAttribute(DATA_ATTRIBUTE, JSON.stringify(this.__data));
    element.textContent = this.__text;
    console.log(element);
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute(MAIN_ATTRIBUTE)) {
          return null;
        }
        return {
          conversion: (_domNode) => {
            let data = _domNode.getAttribute(DATA_ATTRIBUTE);
            if (data && typeof data === 'string') data = JSON.parse(data);
            if (data !== null) {
              const node = new MentionNode(data);
              node.setMode(MODE);
              return {
                node,
              };
            }
            return null;
          },
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }
}

export const $createMentionNode = (mention) => {
  const mentionNode = new MentionNode(mention);
  mentionNode.setMode(MODE);
  return mentionNode;
};

export const $isMentionNode = (node) => node instanceof MentionNode;
