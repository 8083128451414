import { atom } from 'recoil';
import { GlobalNav, EditMenu } from '../../lib/constants';

const navigationState = atom({
  key: 'navigationState',
  default: {
    globalNav: GlobalNav.None,
    editMenu: EditMenu.None,
    editMenuData: {},
    location: {},
  },
});

export default navigationState;
