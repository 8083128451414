import { BetStatus } from './constants';

export default ({
  isWinner, winner, claimed, hasStarted,
}) => {
  if (!hasStarted) return BetStatus.Open;
  if (hasStarted && !winner) return BetStatus.Locked;
  if (isWinner && !claimed) return BetStatus.Unclaimed;
  if (isWinner && claimed) return BetStatus.Won;
  if (!!winner && !isWinner) return BetStatus.Lost;
};
