import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Wrapper, Content } from '../../components/Containers';
import UserProfile from '../../components/UserProfile';
import UserProfileContent from '../../components/UserProfileContent';
import SocketRoom from '../../components/SocketRoom';
import { SocketRooms } from '../../lib/constants';
import UniverseLoader from '../../components/Loaders/UniverseLoader';
import { useUser } from '../../models/user/useUser';
import usernameMappingState from '../../models/usernameMapping/atom';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Profile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const userId = useRecoilValue(usernameMappingState(username));

  const {
    data: user,
    loading,
    error,
  } = useUser(userId, {
    loadFromUsername: true,
    username,
  });

  useDocumentTitle(user ? `${user.username}'s Profile` : 'Profile');

  useEffect(() => {
    if (!error) return;
    navigate('/404');
  }, [error]);

  return (
    <Wrapper applyBreakpoints className='user-profile'>
      <Content className='user-profile-content relative z-10'>
        {(loading && !user) && <UniverseLoader zIndex={20} />}
        { !!user && <SocketRoom roomPath={SocketRooms.UserProfile} roomName={user.username} />}
        { !!user && <UserProfile _id={userId} /> }
        { !!user && <UserProfileContent _id={userId} /> }
      </Content>
    </Wrapper>
  );
};

export default Profile;
