import { formatDistanceStrict, format, formatDistanceToNowStrict } from 'date-fns';

const valueReducer = (value) => {
  switch (value) {
    case 'seconds':
    case 'second':
      return 's';
    case 'minute':
    case 'minutes':
      return 'm';
    case 'hour':
    case 'hours':
      return 'h';
    case 'day':
    case 'days':
      return 'd';
    case 'week':
    case 'weeks':
      return 'w';
    case 'year':
    case 'years':
      return 'y';
    default:
      return ` ${value}`;
  }
};

export const getDistance = (str) => {
  const t = formatDistanceStrict(new Date(str), new Date());
  const arr = t.split(' ');
  return `${arr[0]}${valueReducer(arr[1])}`;
};

export const formatDate = format;

export const distance = formatDistanceToNowStrict;
