import { atom } from 'recoil';

const searchState = atom({
  key: 'searchState',
  default: {
    topics: [],
    categories: [],
    posts: [],
    users: [],
    meta: {
      executionTime: null,
    },
    status: {
      loading: false,
      error: false,
    },
    query: '',
    lastQuery: '',
    latestQueries: [],
    navSearchActive: false,
  },
});

export default searchState;
