import { useState, useCallback, useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { emitCustomEvent } from '../../../../hooks/useCustomEventListener';
import { CustomEvents } from '../../../../lib/constants';
import AspectRatioImage from '../../../AspectRatioImage';

const Image = ({ url, text }) => {
  const [expanded, setExpanded] = useState(true);
  const handleOpen = useCallback(() => emitCustomEvent(CustomEvents.OpenImageModal, { url, text }), []);

  const handleToggleExpanded = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const title = useMemo(() => {
    let _title = text.replace('🖼️', '');
    _title = _title.length > 40 ? `${_title.substring(0, 33)}...` : _title;
    return (
      <div className='flex items-center'>
        <span className='mr-1'>🖼️</span>
        <h5 className='text-xs font-bold no-underline flex items-center'>
          {_title}
        </h5>
        <button type='button' className='tku-no-select' onClick={handleToggleExpanded}>
          { expanded ? <ArrowDropDownIcon sx={{ height: '20px' }} /> : <ArrowRightIcon sx={{ height: '20px' }} /> }
        </button>
      </div>
    );
  }, [text, url, expanded]);

  return (
    <div className='bg-s01dp py-1 px-2 rounded post-embed'>
      { title }
      { !!expanded && (
      <div style={{ width: '100%' }} className='tku-no-select my-1 flex justify-center'>
        <AspectRatioImage imageClassName='rounded' ratio='4:3' onClick={handleOpen} src={url} alt={text} />
      </div>
      )}
    </div>
  );
};

export default Image;
