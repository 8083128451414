import { useEffect } from 'react';

// https://www.ascii-art-generator.org/
const text = `
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMWXOkkkkkkkkkkkOKWMMMMMWXOkkkkkkkkkkkOKWMMM
MMMMMMMMNd,''''''''''';kWMMMMMNd,''''''''''';kWMMM
MMMMMMMW0:''''''''''''lKMMMMMM0c''''''''''''cKMMMM
MMMMMMMNx,''''''''''',xWMMMMMWx,''''''''''',xWMMMM
MMMMMMMKc''''''''''''c0WMMMMMKl'''''''''''':0WXKKX
MMMMMMWk;''''''''''',dNMMMMMWk;''''''''''',oKKxc:o
MMMMMMXo'''''''''''':OWMMMMMXo'''''''''''',:ccldkK
MMMMMWO:''''''''''''oXWMWWWNO:''',,,,,,,'':xOKNWWM
WNK0XXd,''''''''''',lkkxdool:,,,,''''....'xWMMMMMM
xl:lkx:''''',,,,,,,,;:cclool,............cKMMMMMMM
kolll;,'''''''''..'o0KXNWWNx'.....'''''',dNMMMMMMM
MWNN0:............;OWMMMMMXl'''''''''''':0MMMMMMMM
MMMWk;......'''''';kNWWWX0d,''''''''''',oNMMMMMMMM
MMMNd,''''''''''''';lddo:;,''''''''''''c0WMMMMMMMM
MMMNx,''''''''''''''''''''''''''''''';oKWMMMMMMMMM
MMMMXOdlccccccccccccccccccccccccclodkKWMMMMMMMMMMM
MMMMMMWWWNNNNNNNNNNNNNNNNNNNNNNNWWWMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM`;

const welcomeCss = `
font-size: 1.5em; 
font-weight: bold, 
font-family: Arial, 
background: #000; 
color: #8E05C2`;

const artCss = `
font-size: 1em; 
background: #000; 
color: #8E05C2
`;

const contactCss = `
font-size: 1em; 
font-weight: bold, 
font-family: Arial, 
background: #000; 
color: #8E05C2`;

export const useAsciiArt = () => {
  useEffect(() => {
    console.log('%cWelcome to 2KUniverse\n\n%s', welcomeCss, `%c${text}\n%s`, artCss, '%c\n\njoin the team: https://2kuniverse.com/engineering', contactCss);
  }, []);
};
