/* eslint-disable react/function-component-definition */
import { useState, useMemo, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { QueryKeys, SourceTypes, UserProfileContentTypes } from '../../lib/constants';

import ErrorBoundary from '../ErrorBoundary';
import ErrorCard from '../ErrorCard';
import QueryList from '../QueryList';
import UserProfilePostCard from './UserProfilePostCard';
import UserProfileTopicCard from './UserProfileTopicCard';
import UniverseLoader from '../Loaders/UniverseLoader';

import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { PostModel } from '../../models/post/model';
import { TopicModel } from '../../models/topic/model';

const getItemComponent = (contentType) => ({ item }) => {
  if (contentType === UserProfileContentTypes.Posts) {
    return (
      <ErrorBoundary fallback={<ErrorCard />}>
        <UserProfilePostCard _id={item._id} />
      </ErrorBoundary>
    );
  }
  if (contentType === UserProfileContentTypes.Topics) {
    return (
      <ErrorBoundary fallback={<ErrorCard />}>
        <UserProfileTopicCard _id={item._id} />
      </ErrorBoundary>
    );
  }
  if (contentType === UserProfileContentTypes.Saved) {
    return (
      <ErrorBoundary fallback={<ErrorCard />}>
        { item.item.type === SourceTypes.Post && <UserProfilePostCard saved _id={item.item.post._id} /> }
        { item.item.type === SourceTypes.Topic && <UserProfileTopicCard saved _id={item.item.topic._id} /> }
      </ErrorBoundary>
    );
  }
  if (contentType === UserProfileContentTypes.Hidden) {
    return (
      <ErrorBoundary fallback={<ErrorCard />}>
        <UserProfileTopicCard hidden _id={item._id} />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary fallback={<ErrorCard />}>
      <div>{item._id}</div>
    </ErrorBoundary>
  );
};

const getRequestPath = (contentType, _id) => {
  if (!contentType || !_id) return '';
  if (contentType === UserProfileContentTypes.Posts) return `/post/user/${_id}`;
  if (contentType === UserProfileContentTypes.Topics) return `/topic/user/${_id}`;
  if (contentType === UserProfileContentTypes.Saved) return '/saved-item';
  if (contentType === UserProfileContentTypes.Hidden) return '/topic/hidden';
  return '';
};

const getQueryKey = (contentType, _id) => {
  if (!_id || !contentType) return [];
  return [QueryKeys.Profile, _id, contentType];
};

const getOnRequestSuccess = (type) => (items) => {
  if (type === UserProfileContentTypes.Posts) {
    for (const item of items) PostModel.setPost(item._id, item);
  }
  if (type === UserProfileContentTypes.Topics) {
    for (const item of items) TopicModel.setTopic(item._id, item);
  }
  // leaving this separate as it may include other types of content in the future
  if (type === UserProfileContentTypes.Hidden) {
    for (const item of items) TopicModel.setTopic(item._id, item);
  }
  if (type === UserProfileContentTypes.Saved) {
    for (const item of items) {
      if (item.item.type === SourceTypes.Post) PostModel.setPost(item.item.post._id, item.item.post);
      if (item.item.type === SourceTypes.Topic) TopicModel.setTopic(item.item.topic._id, item.item.topic);
    }
  }
};

const EmptyComponent = () => (
  <div className='w-[100%] h-[200px] text-lg flex items-center justify-center text-primary-text font-roboto'>
    Nothing to see here 😎
  </div>
);

const BackButtonComponent = ({ scrollToIndex }) => (
  <div className='inline-block fixed bottom-[52px] right-2 tablet:right-4 z-10'>
    <Button
      variant='contained'
      onClick={scrollToIndex}
    >
      Back to Profile
    </Button>
  </div>
);

const boxStyles = {
  position: 'sticky',
  width: '100%',
  marginBottom: '2px',
  top: '0',
  backgroundColor: 'var(--color-surface)',
  zIndex: '2',
};

const UserProfileContent = ({
  _id,
  scrollParentSelector = '.user-profile-content',
}) => {
  const [contentType, setContentType] = useState(UserProfileContentTypes.Posts);
  const handleChange = useCallback((e, value) => setContentType(value), []);
  const { data: authenticatedUser } = useAuthenticatedUser();
  const [loading, setLoading] = useState(false);

  const isAuthenticatedUser = useMemo(() => authenticatedUser?._id === _id, [authenticatedUser?._id, _id]);

  const [onRequestSuccess, requestPath, queryKey, itemComponent] = useMemo(() => {
    if (!contentType || !_id || !scrollParentSelector) return null;
    const _onRequestSuccess = getOnRequestSuccess(contentType);
    const _requestPath = getRequestPath(contentType, _id);
    const _queryKey = getQueryKey(contentType, _id);
    const _itemComponent = getItemComponent(contentType);
    return [_onRequestSuccess, _requestPath, _queryKey, _itemComponent];
  }, [contentType, _id, scrollParentSelector]);

  const contentList = useMemo(() => (
    <QueryList
      onRequestSuccess={onRequestSuccess}
      ItemComponent={itemComponent}
      customScrollParentSelector={scrollParentSelector}
      requestPath={requestPath}
      requestParams={{ limit: 25 }}
      queryKey={queryKey}
      queryEnabled
      shouldShowLoaderOnQueryLoading={false}
      shouldShowPlaceholder={false}
      loaderProps={{ boxType: 'fullContainer', zIndex: 20 }}
      EmptyComponent={<EmptyComponent />}
      shouldShowBackButton={[UserProfileContentTypes.Posts, UserProfileContentTypes.Topics].includes(contentType)}
      BackButtonComponent={BackButtonComponent}
      setExternalLoadingState={setLoading}
    />
  ), [onRequestSuccess, requestPath, queryKey, itemComponent, contentType]);

  return (
    <>
      <Box sx={boxStyles}>
        <Tabs
          variant='fullWidth'
          value={contentType}
          onChange={handleChange}
          textColor='primary'
          indicatorColor='primary'
          aria-label='user-profile-content-tabs'
        >
          <Tab value={UserProfileContentTypes.Posts} label={UserProfileContentTypes.Posts} />
          <Tab value={UserProfileContentTypes.Topics} label={UserProfileContentTypes.Topics} />
          { isAuthenticatedUser && (<Tab value={UserProfileContentTypes.Saved} label={UserProfileContentTypes.Saved} />) }
          { isAuthenticatedUser && (<Tab value={UserProfileContentTypes.Hidden} label={UserProfileContentTypes.Hidden} />) }
        </Tabs>
      </Box>
      { loading && (
      <div className='relative h-[300px]'>
        <UniverseLoader size={100} boxType='fullContainer' />
      </div>
      )}
      { contentList }
    </>
  );
};

export default UserProfileContent;
