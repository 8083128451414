import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Content, Wrapper } from '../Containers';

const StoreHome = () => {
  const navigate = useNavigate();
  return (
    <Wrapper className='text-primary-text font-roboto'>
      <Content>
        <div className='relative'>

          <div
            style={{
              position: 'absolute',
              top: 0,
            }}
            className='w-[100%] h-[400px] rounded'
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              background: 'url(https://cdn.2kuniverse.com/public/uploads/jdealla-nba-logo-collage.jpeg)',
              backgroundSize: 'cover',
            }}
            className='w-[100%] h-[400px] rounded opacity-25'
          />

          <div className='w-[100%] h-[400px] rounded bg-primary text-white bg-black'>
            <div className='w-[100%] h-[400px] rounded mt-2 flex-col flex justify-between px-4 py-3 pb-5'>
              <div className='space-holder' />
              <div className='z-10'>
                <span style={{ lineHeight: '50px' }} className='font-bebas text-[68px]'>Rep Your Squad</span>
                <p className='font-roboto text-[18px] mb-3'>Whether you&apos;re a fan of the Lakers, the Warriors, or any other team, we&apos;ve got you covered with NBA logo badges.</p>
                <Button
                  variant='contained'
                  style={{ width: '100px' }}
                  onClick={() => navigate('/store/badges')}
                >
                  Shop
                </Button>
              </div>
            </div>
          </div>

        </div>
      </Content>
    </Wrapper>
  );
};

export default StoreHome;
