/* eslint-disable react/jsx-closing-tag-location */

import {
  TypeaheadOption,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { Grid } from '@mui/material';

export class EmojiTypeaheadOption extends TypeaheadOption {
  emoji;
  constructor(emoji) {
    if (emoji?.codes) emoji.type = 'native';
    else emoji.type = 'custom';
    super(emoji.type === 'native' ? emoji.codes : emoji.name);
    this.emoji = emoji;
  }
}

export const EmojiTypeaheadMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}) => {
  const {
    name,
    native,
    url,
    type,
  } = option.emoji;
  const className = `${isSelected ? 'bg-primary-light text-surface ' : ''}item px-2 py-1 flex items-center`;
  return (
    <li
      style={{ cursor: 'pointer' }}
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role='option'
      aria-selected={isSelected}
      id={`typeahead-item-${index}`}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <Grid container className='flex items-center'>
        <Grid item xs={2} className='flex'>
          { type === 'native' ? native : <img style={{ maxHeight: '16px', maxWidth: '100%' }} src={url} alt={name} /> }
        </Grid>
        <Grid item xs={10}>
          <span style={{ maxWidth: '210px' }} className='ml-2 line-clamp-1'>{ name }</span>
        </Grid>
      </Grid>
    </li>
  );
};
