import { selector } from 'recoil';
import documentTitleState from './atom';
import unseenNotificationsCountState from '../unseenNotificationsCount/atom';
import messageRequestCountState from '../messageRequestCount/atom';
import unreadMessagesCountState from '../unreadMessagesCount/atom';
import { SiteTitle } from '../../lib/constants';

export const notificationsAndMessagesCountSelector = selector({
  key: 'notificationsAndMessagesCountSelector',
  get: ({ get }) => {
    const notificationsCount = get(unseenNotificationsCountState);
    const messagesCount = get(unreadMessagesCountState);
    const messageRequestsCount = get(messageRequestCountState);
    const total = notificationsCount + messagesCount + messageRequestsCount;
    return total;
  },
});

export const fullTitleStateSelector = selector({
  key: 'fullTitleStateSelector',
  get: ({ get }) => {
    const documentTitle = get(documentTitleState);
    const notificationsAndMessagesCount = get(notificationsAndMessagesCountSelector);
    const _count = notificationsAndMessagesCount > 0 ? `(${notificationsAndMessagesCount}) ` : '';
    const _documentTitle = documentTitle ? `${documentTitle} | ` : '';
    return `${_count}${_documentTitle}${SiteTitle}`;
  },
});
