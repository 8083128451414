import { selectorFamily } from 'recoil';
import { getDateFromObjectId } from '../../lib/getDateFromObjectId';
import state from './atom';
import lastSeenPostsInTopicState from '../lastSeenPostsInTopic/atom';

export const topicSelector = selectorFamily({
  key: 'topicSelector_v2',
  get: (id) => ({ get }) => {
    const topicState = get(state(id));
    return topicState?.data;
  },
  set: (id) => ({ set, get }, data) => {
    const _state = state(id);
    const topicState = get(_state);
    set(_state, {
      ...topicState,
      data,
    });
  },
});

export const topicHasNewPostsSelector = selectorFamily({
  key: 'topicHasNewPostsSelector',
  get: (id) => ({ get }) => {
    const topicState = get(state(id));
    const lastSeenPostInTopic = get(lastSeenPostsInTopicState(id));
    if (!lastSeenPostInTopic) return true;
    const lastSeenPostInTopicDate = getDateFromObjectId(lastSeenPostInTopic);
    const latestReplyDate = topicState.data?.latestReply?._id || topicState.data?.latestReply || topicState.data?.mainPost?._id || topicState.data?.mainPost;
    if (!latestReplyDate) return true;
    const latestReplyDateObject = getDateFromObjectId(latestReplyDate);
    return lastSeenPostInTopicDate.valueOf() < latestReplyDateObject.valueOf();
  },
});
