/* eslint-disable react/jsx-closing-tag-location */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { debounce } from '@mui/material';
import { $createTextNode } from 'lexical';
import { LexicalTypeaheadMenuPlugin } from '../utils/LexicalTypeaheadMenuPlugin';
import { $createMentionNode } from '../Nodes/MentionNode';
import ApiClientInstance from '../../../clients/api';
import {
  SUGGESTION_LIST_LENGTH_LIMIT,
  AtSignMentionsRegex,
  AtSignMentionsRegexAliasRegex,
} from './utils/regex';
import { MentionTypeaheadOption } from './Components/MenuItem';
import { renderMenu } from './Components/Menu';
import { UserModel } from '../../../models/user/model';

const checkForAtSignMentions = (text, minMatchLength) => {
  let match = AtSignMentionsRegex.exec(text);
  if (match === null) match = AtSignMentionsRegexAliasRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      // import for replacement
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
};

const getPossibleQueryMatch = (text) => {
  const match = checkForAtSignMentions(text, 1);
  return match || null;
};

const useMentionLookupService = (_query) => {
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const queryRef = useRef();

  const fetchUsers = useCallback(debounce(async (query) => {
    setSearchLoading(true);
    const res = await ApiClientInstance.sendRequest({
      path: '/user/search',
      method: 'GET',
      queryParams: { username: query },
      catchError: true,
    });
    if (res.success) {
      for (const user of res.data) UserModel.setUserPartial(user._id, user);
      setSearchedUsers(res.data);
    }
    setSearchLoading(false);
  }, 250), []);

  useEffect(() => {
    if (!_query) return;
    if (queryRef.current === _query) return;
    if (queryRef.current !== _query) {
      setSearchedUsers([]);
      setSearchLoading(false);
    }
    fetchUsers(_query);
    if (_query) queryRef.current = _query;
  }, [_query]);

  return [searchedUsers, searchLoading];
};

export const MentionsPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState('');

  const [results, resultsLoading] = useMentionLookupService(queryString);

  const onSelectOption = useCallback((selectedOption, nodeToReplace, closeMenu) => {
    editor.update(() => {
      const {
        username,
        avatar,
        _id,
        color,
      } = selectedOption.mention;
      const mentionNode = $createMentionNode({
        username, avatar, _id, color,
      });
      if (nodeToReplace) nodeToReplace.replace(mentionNode);
      const space = $createTextNode(' ');
      mentionNode.insertAfter(space);
      space.selectNext();
      closeMenu();
    });
  }, [editor]);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const checkForMentionMatch = useCallback((text) => {
    const mentionMatch = getPossibleQueryMatch(text);
    const slashMatch = checkForSlashTriggerMatch(text);
    return !slashMatch && mentionMatch ? mentionMatch : null;
  }, [checkForSlashTriggerMatch]);

  const options = useMemo(() => {
    const _results = results.map((result) => new MentionTypeaheadOption(result));
    return _results.slice(0, SUGGESTION_LIST_LENGTH_LIMIT);
  }, [results]);

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={renderMenu(options, results, resultsLoading)}
    />
  );
};
