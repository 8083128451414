import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import getBetStatus from '../../lib/getBetStatus';
import { BetStatus } from '../../lib/constants';

const BetStatusIcon = ({
  isWinner, winner, claimed, hasStarted, columns = 2, iconSize = '20px',
}) => {
  const status = getBetStatus({
    isWinner, winner, claimed, hasStarted,
  });
  return (
    <Grid item xs={columns}>
      {(status === BetStatus.Won) && <CheckCircleIcon style={{ height: iconSize }} color='success' /> }
      {(status === BetStatus.Lost) && <CancelIcon style={{ height: iconSize }} color='error' /> }
      {(status === BetStatus.Open) && <LockOpenIcon style={{ height: iconSize }} color='primaryText' /> }
      {(status === BetStatus.Locked) && <LockIcon style={{ height: iconSize }} color='primaryText' /> }
      {(status === BetStatus.Unclaimed) && <AccessTimeFilledIcon style={{ height: iconSize }} color='primaryText' /> }
    </Grid>
  );
};

export default BetStatusIcon;
