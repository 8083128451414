import {
  useCallback,
  useState,
  memo,
  useEffect,
  useRef,
} from 'react';
import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';

import { Wrapper, Content } from '../../components/Containers';
import SimpleItemList from '../../components/SimpleItemList';
import BetCard from '../../components/BetCard';

import ApiClientInstance from '../../clients/api';
import { CustomEvents, SocketRooms } from '../../lib/constants';
import currencyFormat from '../../lib/currencyFormat';

import useSocketJoin from '../../hooks/useSocketJoin';
import useRequireAuth from '../../hooks/useRequireAuth';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useRequireVerification } from '../../hooks/useRequireVerification';
import { authenticatedUserSelector, userVerifiedSelector } from '../../models/authenticatedUser/selectors';
import { addSnackbarSelector } from '../../models/snackbar/selector';

const MemoSimpleItemList = memo(SimpleItemList);

const EmptyMessage = ({ date }) => (
  <div className='flex flex-col px-4 space-y-2'>
    <span>You don&apos;t seem to have any bets yet 🤔</span>
    <span>
      You can fix that by going to
      <Link className='text-primary-light underline font-bold mx-1' to='/games'>games</Link>
      and placing a few bets.
    </span>
  </div>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const ItemComponent = ({ item, index }) => <BetCard index={index} {...item} />;

const Bets = () => {
  // Hooks
  const addSnackbar = useSetRecoilState(addSnackbarSelector);
  useRequireAuth('/');
  useRequireVerification('/');
  useDocumentTitle('Bets');

  // State
  const [sort, setSort] = useState('new');
  const [claimed, setClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const claimedRef = useRef(0);

  // Sockets
  useSocketJoin(SocketRooms.General, 'bets');

  // CallBacks
  const handleSortChange = useCallback((e) => {
    setSort(e.target.value);
  }, []);

  // TODO: refactor this to use new state management
  const updater = useCallback((res, items, setItems) => {
    const {
      betId, betIndex, type, newBet,
    } = res;
    const bet = items[betIndex];
    if (bet._id !== betId) return null;
    if (type === 'cancel') {
      const newItems = [...items];
      newItems.splice(betIndex, 1);
      setItems(newItems);
    }
    if (type === 'claim') {
      items[betIndex] = newBet;
      const newItems = [...items];
      setItems(newItems);
    }
  }, []);

  useEffect(async () => {
    const _authenticatedUser = getRecoil(authenticatedUserSelector);
    const _verified = getRecoil(userVerifiedSelector);
    if (!_authenticatedUser || !_verified) return;
    setLoading(true);
    const res = await ApiClientInstance.sendRequest({ method: 'post', path: '/bet/claim', catchError: true });
    if (res.success) {
      const { claimedBets, currencyBalance, totalProfit } = res.data;
      setLoading(false);
      setClaimed(true);
      if (claimedBets.length === 0) return;
      addSnackbar({ color: 'primary', message: `You've successfully claimed ${currencyFormat(claimedBets.length)} bets for a total profit of ${currencyFormat(totalProfit)}. Your current balance is ${currencyFormat(currencyBalance)} MT.` });
    } else {
      setLoading(false);
      setError(true);
    }
  }, []);

  useEffect(() => {
    claimedRef.current += 1;
  }, [claimed]);

  useEffect(() => {
    if (loading) return;
    claimedRef.current += 1;
  }, [loading]);

  const navigate = useNavigate();

  useEffect(() => {
    if (error) navigate('/404');
  }, [error]);

  return (
    <Wrapper applyBreakpoints className='users'>
      <div className='flex pt-2 space-x-2 justify-between items-center pl-2 pr-3'>
        <h1 className='text-3xl font-bebas my-3 text-primary-text'>Bets</h1>
        <FormControl sx={{ width: '200px' }} size='small'>
          <InputLabel id='sort-select'>Sort</InputLabel>
          <Select
            labelId='sort-select-label'
            id='sort-select'
            label='Sort'
            value={sort}
            onChange={handleSortChange}
          >
            <MenuItem value='new'>New</MenuItem>
            <MenuItem value='old'>Old</MenuItem>
            <MenuItem value='amountDesc'>Amount +</MenuItem>
            <MenuItem value='amount'>Amount -</MenuItem>
            <MenuItem value='payoutDesc'>Profit +</MenuItem>
            <MenuItem value='payout'>Profit -</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Grid className='py-0 pl-2 pr-4 items-center !mt-0 !mb-0 text-primary-text text-xs font-bold' columns={24} container>
        <Grid item xs={2}>W/L</Grid>
        <Grid item xs={4}>DATE</Grid>
        <Grid item xs={4}>LEAGUE</Grid>
        <Grid item xs={3}>HOME</Grid>
        <Grid item xs={4}>AWAY</Grid>
        <Grid item xs={3}>BET</Grid>
        <Grid item xs={2}>PROFIT</Grid>
      </Grid>
      <Content className='pt-1 relative'>
        <MemoSimpleItemList
          eventName={CustomEvents.BetUpdate}
          updater={updater}
          emptyMessage={<EmptyMessage />}
          sort={sort}
          ItemComponent={ItemComponent}
          isHidden={false}
          path='/bet'
          loaderProps={{
            boxType: 'fullContainer',
            zIndex: 100,
            showLogo: claimedRef.current < 5,
            size: claimedRef.current < 5 ? '' : 100,
          }}
          showErrorMessage
          setParentLoading={setLoading}
          isEnabled={claimed}
        />
      </Content>
    </Wrapper>
  );
};

export default Bets;
