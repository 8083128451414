/* eslint-disable react/function-component-definition */
import { selector } from 'recoil';
import state from './atom';

export const newTopicsCountSelector = selector({
  key: 'newTopicsCountSelector',
  get: ({ get }) => get(state).length,
});

export const hasNewTopicsSelector = selector({
  key: 'hasNewTopicsSelector',
  get: ({ get }) => get(state).length > 0,
});
