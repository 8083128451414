export default (amt, moneyline) => {
  let raw;
  let profit;
  if (moneyline > 0) {
    raw = (amt * ((moneyline) * (0.01)));
    profit = raw.toFixed(0);
  } else if (moneyline < 0) {
    raw = (amt / (moneyline * (-0.01)));
    profit = raw.toFixed(0);
  }
  profit = parseInt(profit, 10);
  return profit;
};
