import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitOpenUserProfileDrawer = ({
  sourceType,
  username,
}) => {
  if (!sourceType || !username) return null;
  emitCustomEvent(CustomEvents.OpenUserProfileDrawer, { sourceType, username });
};
