import { useSetRecoilState } from 'recoil';
import { useCallback, useEffect } from 'react';

import {
  platformSelector,
  isPwaSelector,
  visibilitySelector,
} from '../models/settings/selectors';

import { Platforms } from '../lib/constants';

const determinePlatform = () => {
  const { userAgent } = navigator;
  const isWindows = /windows/i.test(userAgent);
  const isAndroid = /android/i.test(userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isMac = /Macintosh/.test(userAgent);
  const isLinux = /Linux/.test(userAgent);
  let platform = Platforms.Other;
  if (isWindows) platform = Platforms.Windows;
  if (isAndroid) platform = Platforms.Android;
  if (isIOS) platform = Platforms.iOS;
  if (isMac) platform = Platforms.Mac;
  if (isLinux) platform = Platforms.Linux;
  return platform;
};

const determinePwa = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  const isPwa = window.navigator.standalone || isStandalone;
  return isPwa;
};

export const useDeterminePlatformAndSettings = () => {
  const setPlatform = useSetRecoilState(platformSelector);
  const setIsPwa = useSetRecoilState(isPwaSelector);
  const setVisibility = useSetRecoilState(visibilitySelector);

  useEffect(() => {
    const platform = determinePlatform();
    setPlatform(platform);
  }, [navigator.userAgent, window.opera]);

  useEffect(() => {
    const isPwa = determinePwa();
    setIsPwa(isPwa);
  }, [window.matchMedia('(display-mode: standalone)').matches, window.navigator.standalone]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') setVisibility(true);
    else setVisibility(false);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};
