import { selector } from 'recoil';
import state from './atom';

export const getCurrentSnackbarSelector = selector({
  key: 'getCurrentSnackbar',
  get: ({ get }) => {
    const { snackbars } = get(state);
    if (snackbars?.length) {
      return snackbars[0];
    }
    return null;
  },
});

export const addSnackbarSelector = selector({
  key: 'addSnackbar',
  get: ({ get }) => get(state).snackbars,
  /**
   *
   * @param {Object} RecoilGetSet
   * @param {{ message: string, icon: string, variant: string, severity: string, color: 'string', type: string }} newSnackbar
   */
  set: ({ set, get }, newSnackbar) => {
    const prevState = get(state);
    const snackbars = [...prevState.snackbars, newSnackbar];
    set(state, { ...prevState, snackbars });
  },
});

export const removeSnackbarSelector = selector({
  key: 'removeSnackbar',
  get: ({ get }) => get(state).snackbars,
  set: ({ set, get }) => {
    const prevState = get(state);
    const snackbars = prevState.snackbars.length === 1 ? [] : prevState.snackbars.slice(1);
    set(state, { ...prevState, snackbars });
  },
});
