import { selector, selectorFamily } from 'recoil';
import state from './atom';
import { GlobalNav, EditMenu } from '../../lib/constants';

export const userNav = selector({
  key: 'userNav',
  get: ({ get }) => {
    const navigationState = get(state);
    return navigationState.globalNav === GlobalNav.User;
  },
  set: ({ set, get }) => {
    const navigationState = get(state);
    set(state, { ...navigationState, globalNav: navigationState.globalNav === GlobalNav.User ? GlobalNav.None : GlobalNav.User });
  },
});

export const siteNav = selector({
  key: 'siteNav',
  get: ({ get }) => {
    const navigationState = get(state);
    return navigationState.globalNav === GlobalNav.Site;
  },
  set: ({ set, get }) => {
    const navigationState = get(state);
    set(state, { ...navigationState, globalNav: navigationState.globalNav === GlobalNav.Site ? GlobalNav.None : GlobalNav.Site });
  },
});

export const topicEditMenu = selector({
  key: 'topicEditMenu',
  get: ({ get }) => {
    const navigationState = get(state);
    return { open: navigationState.editMenu === EditMenu.Topic, data: navigationState.editMenuData };
  },
  set: ({ set, get }, editMenuData) => {
    const navigationState = get(state);
    if (navigationState.editMenu !== EditMenu.Topic) {
      set(state, { ...navigationState, editMenu: EditMenu.Topic, editMenuData });
    } else {
      set(state, { ...navigationState, editMenu: EditMenu.None });
    }
  },
});

export const locationSelector = selectorFamily({
  key: 'locationSelector',
  get: (path) => ({ get }) => {
    const locationState = get(state).location;
    return { [path]: locationState?.[path] || {}, shouldRestoreScroll: locationState.shouldRestoreScroll };
  },
  set: (path) => ({ set, get }, data) => {
    const prevState = get(state);
    const newLocation = { ...prevState.location, [path]: { ...data } };
    set(state, { ...prevState, location: { ...newLocation } });
  },
});
