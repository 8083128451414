import { TextNode } from 'lexical';

const NAME = 'custom-emoji';
const MODE = 'token';

// DO NOT TOUCH
const MAIN_ATTRIBUTE = `data-lexical-${NAME}`;
const DATA_ATTRIBUTE = `data-lexical-${NAME}-data`;
const CLASS_NAME = `editor-${NAME}`;

export class CustomEmojiNode extends TextNode {
  __data;

  static getType() {
    return NAME;
  }

  static clone(node) {
    return new CustomEmojiNode(node.__data);
  }

  static create = (data, text, key) => new CustomEmojiNode(data, text, key);

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    const node = new CustomEmojiNode(serializedNode.data);
    node.setMode(MODE);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(data, key) {
    super('🙂', key);
    this.__data = data;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      data: this.__data,
      type: NAME,
      version: 1,
    };
  }

  createDOM(config) {
    const outer = document.createElement('span');
    outer.className = `${CLASS_NAME} ${CLASS_NAME}-outer ${CLASS_NAME}-${this.__data.name}`;
    outer.style.backgroundImage = `url(${this.__data.url})`;
    // EMOJI SPECIFIC
    const inner = super.createDOM(config);
    inner.className = `${CLASS_NAME}-inner`;
    outer.appendChild(inner);
    return outer;
  }

  // TODO: verify this pattern of export and import
  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute(MAIN_ATTRIBUTE, 'true');
    element.setAttribute(DATA_ATTRIBUTE, JSON.stringify(this.__data));
    element.textContent = this.__data.shortname.toUpperCase();
    element.innerHTML = this.__data.shortname.toUpperCase();
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute(MAIN_ATTRIBUTE)) return null;
        return {
          conversion: (_domNode) => {
            let data = _domNode.getAttribute(DATA_ATTRIBUTE);
            if (data && typeof data === 'string') data = JSON.parse(data);
            if (data !== null) {
              const node = new CustomEmojiNode(data);
              node.setMode(MODE);
              return {
                node,
              };
            }
            return null;
          },
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }
}

export const $createCustomEmojiNode = (data) => {
  const customEmojiNode = new CustomEmojiNode(data);
  customEmojiNode.setMode(MODE);
  return customEmojiNode;
};

export const $isCustomEmojiNode = (node) => node instanceof CustomEmojiNode;
