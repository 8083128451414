import { useState, useMemo } from 'react';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

export const useBlockedContent = ({ author }) => {
  const { data: user } = useAuthenticatedUser();

  const [blockOverride, setBlockOverride] = useState(false);

  const authorBlocked = useMemo(() => {
    if (!user || !user?.blocked || user?.blocked?.length === 0 || blockOverride) return false;
    return !!user?.blocked.find(bu => bu?._id === author?._id);
  }, [user, author, blockOverride]);

  return {
    authorBlocked,
    setBlockOverride,
  };
};
