import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { QueryKeys, SourceTypes } from '../../lib/constants';

import TopicListHeader from './TopicListHeader';
import DrawerMenu from '../DrawerMenu';
import QueryList from '../QueryList';
import NewTopicButton from '../NewTopicButton';

import { useCustomScrollParent } from '../../hooks/useCustomScrollParent';
import useSocketJoin from '../../hooks/useSocketJoin';
import topicListSortKeyState from '../../models/topicListSortKey/atom';
import topicListQueryKeyState from '../../models/topicListQueryKey/atom';
import { TopicModel } from '../../models/topic/model';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import useEventListener from '../../hooks/useEventListener';
import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';
import { isPwaSelector } from '../../models/settings/selectors';

const TopicList = ({
  locationKey,
  rooms,
  customScrollParentSelector,
  requestPath,
  requestParams = {},
  loading,
  setLoading,
  ItemComponent,
  EmptyComponent,
}) => {
  const {
    data: authenticatedUser,
    loading: authenticatedUserLoading,
    authenticated,
    unauthenticated,
  } = useAuthenticatedUser();
  const topicListSortKey = useRecoilValue(topicListSortKeyState);
  const [topicListQueryKey, setTopicListQueryKey] = useRecoilState(topicListQueryKeyState);
  const isPwa = useRecoilValue(isPwaSelector);
  const [refetching, setRefetching] = useState(false);

  const customScrollParent = useCustomScrollParent({ selector: customScrollParentSelector });

  rooms.forEach((room) => {
    useSocketJoin(room.path, room.name);
  });

  // TopicListQueryKey: | authenticated(unathenticated|username) | location(name|categorySlug) | sortType |
  useEffect(() => {
    const value = [QueryKeys.Topics, authenticated ? authenticatedUser.username : unauthenticated, ...locationKey, topicListSortKey];
    setTopicListQueryKey(value);
  }, [topicListSortKey, locationKey, authenticated, authenticatedUser, unauthenticated]);

  // Layout Components
  const header = useMemo(() => <TopicListHeader />, []);
  const drawerMenu = useMemo(() => <DrawerMenu sourceType={SourceTypes.Topic} />, []);

  const onRequestSuccess = useCallback((topics) => {
    for (const topic of topics) TopicModel.setTopic(topic._id, topic);
  }, []);

  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') emitInvalidateQueries({ queryKey: QueryKeys.Topics });
  }, [isPwa]);

  useEventListener('visibilitychange', onVisibilityChange);

  return (
    <>
      { header }
      <NewTopicButton />
      <QueryList
        ItemComponent={ItemComponent}
        customScrollParent={customScrollParent}
        requestPath={requestPath}
        requestSort={topicListSortKey}
        requestParams={{ limit: 25, ...requestParams }}
        queryKey={topicListQueryKey}
        queryEnabled={(Boolean(!authenticatedUserLoading && topicListQueryKey))}
        onRequestSuccess={onRequestSuccess}
        shouldRender={!authenticatedUserLoading && topicListQueryKey && customScrollParent}
        parentLoading={loading}
        setParentLoading={setLoading}
        shouldShowEmptyComponent
        EmptyComponent={EmptyComponent}
        shouldShowErrorComponent
        initialTopMostItemIndex={0}
        shouldShowLoaderOnQueryFetching
        useNavLoaderOnFetching
      />
      { drawerMenu }
    </>
  );
};

export default TopicList;
