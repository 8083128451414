/* eslint-disable react/function-component-definition */
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useLoadIfNeeded } from '../utils/useLoadIfNeeded';
import { useFetchOnMount } from '../utils/useFetchOnMount';
import state from './atom';
import { NotificationModel } from './model';

export const useNotification = (id, {
  loadIfNeeded = false,
  fetchOnMount = false,
} = { }) => {
  const {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
  } = useRecoilValue(state(id));

  const ModelInstance = useMemo(() => new NotificationModel(id), [id]);

  useLoadIfNeeded({
    loadIfNeeded,
    data,
    hasLoaded,
    loading,
    ModelInstance,
    id,
  });

  useFetchOnMount({
    fetch: ModelInstance?.fetch,
    fetching,
    fetchOnMount,
    loading,
  });

  return {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
    model: ModelInstance,
    fetch: ModelInstance?.fetch,
    reload: ModelInstance?.reload,
    setData: ModelInstance?.setData,
    loadIfNeeded: ModelInstance?.loadIfNeeded,
  };
};
