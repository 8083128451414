import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

const useRequireAuth = (redirectUrl = '/') => {
  const { unauthenticated } = useAuthenticatedUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (unauthenticated) {
      navigate(redirectUrl);
    }
  }, [unauthenticated]);
};

export default useRequireAuth;
