import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import debounce from '../../lib/debounce';
import ErrorBoundary from '../ErrorBoundary';
import UniverseLoader from '../Loaders/UniverseLoader';
import ErrorCard from '../ErrorCard';
import PostCardLoader from '../Loaders/PostCardLoader';

import { loadingNextPaginatedScroller, loadingPrevPaginatedScroller } from '../../models/paginatedScrollerState/selectors';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

const PaginatedItemPage = ({
  items,
  isFirstPage,
  isLastPage,
  setPageStatus,
  renderItem,
  hideHeader,
  itemsStringified,
  topicId,
  setLoading,
}) => {
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef();
  const entry = useIntersectionObserver(containerRef, { });
  const [loadingNext, setLoadingNext] = useRecoilState(loadingNextPaginatedScroller(topicId));
  const [loadingPrev, setLoadingPrev] = useRecoilState(loadingPrevPaginatedScroller(topicId));

  const firstPostId = useMemo(() => items[0]._id, [items]);

  const _setLoaded = useCallback(debounce(() => {
    setLoaded(true);
    setLoading(false);
    setLoadingPrev(false);
    setLoadingNext(false);
  }, 250), [setLoaded]);

  useEffect(() => {
    if (!entry) return;
    setPageStatus(firstPostId, { active: entry.isIntersecting, height: containerRef.current.getBoundingClientRect().height });
  }, [entry]);

  const itemList = useMemo(() => {
    if (!items.length) return null;
    return (
      <>
        { items.map((item, _index) => (
          <ErrorBoundary key={item._id} fallback={<ErrorCard />}>
            <div className={`item-${item._id}`}>
              {renderItem({
                item,
                index: _index,
              })}
            </div>
          </ErrorBoundary>
        ))}
      </>
    );
  }, [itemsStringified]);

  const _className = loaded ? 'items-content-page' : 'invisible fixed z-[-100]';

  const showTopLoader = useMemo(() => isFirstPage && loadingPrev, [loadingPrev, isFirstPage]);

  const topLoader = useMemo(() => {
    if (!showTopLoader) return null;
    return (
      <>
        <UniverseLoader size={100} boxType='fullScreen' zIndex={1000} showLogo={false} />
        <PostCardLoader height={60} />
      </>
    );
  }, [showTopLoader]);

  const showBottomLoader = useMemo(() => isLastPage && loadingNext, [loadingNext, isLastPage]);

  const bottomLoader = useMemo(() => {
    if (!showBottomLoader) return null;
    return (
      <>
        <PostCardLoader height={60} />
        <UniverseLoader size={100} boxType='fullScreen' showLogo={false} />
      </>
    );
  }, [showBottomLoader]);

  return (
    <div ref={containerRef} className={`items-content-page-container items-container-before-${firstPostId} items-container-after-${items[items.length - 1]._id}`}>
      { topLoader }
      { (loaded && !loadingPrev && isFirstPage && !hideHeader) && <PostCardLoader height={60} />}
      <div onLoad={_setLoaded} className={`${_className}`}>
        { itemList }
      </div>
      { bottomLoader }
    </div>
  );
};

export default PaginatedItemPage;
