import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useLoadIfNeeded } from '../utils/useLoadIfNeeded';
import state from './atom';
import { AuthenticatedUserModel } from './model';
import { userVerifiedSelector } from './selectors';

export const useAuthenticatedUser = ({
  loadIfNeeded = false,
} = {}) => {
  const {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
  } = useRecoilValue(state);

  const ModelInstance = useMemo(() => new AuthenticatedUserModel(), []);

  useLoadIfNeeded({
    loadIfNeeded,
    data,
    hasLoaded,
    loading,
    ModelInstance,
    id: 'authenticatedUser',
  });

  const verified = useRecoilValue(userVerifiedSelector);

  return {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
    verified,
    unauthenticated: !data && !loading,
    authenticated: !!data && !loading && hasLoaded,
    fetch: ModelInstance.fetch,
    reload: ModelInstance.reload,
    setData: ModelInstance.setData,
    loadIfNeeded: ModelInstance.loadIfNeeded,
  };
};
