import { atomFamily } from 'recoil';

const paginatedScroller = atomFamily({
  key: 'paginatedScrollerState',
  default: {
    loading: false,
    loadingPrev: false,
    loadingNext: false,
    cursorState: {},
    pageStatus: {},
    scrollLock: false,
  },
});

export default paginatedScroller;
