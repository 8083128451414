import { setRecoil } from 'recoil-nexus';
import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';
import { BaseModel } from '../BaseModel';
import * as NotificationApi from './api';

import unseenNotificationsCountState from '../unseenNotificationsCount/atom';
import unreadNotificationsCountState from '../unreadNotificationsCount/atom';
import { UserModel } from '../user/model';
import state from './atom';
import { NotificationStatus, QueryKeys } from '../../lib/constants';

const getTime = () => new Date().getTime();

export class NotificationModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => NotificationModel.requestNotification(id);
    super(id, state, request);
    this.id = id;
  }

  static requestNotification(id) {
    if (!id) return;
    return NotificationApi.requestNotification(id);
  }

  static loadNotificationCounts = async () => {
    const res = await NotificationApi.getNotificationCounts();
    if (res.success) {
      setRecoil(unseenNotificationsCountState, res.data.unseen);
      setRecoil(unreadNotificationsCountState, res.data.unread);
    }
  };

  static setNotification(id, data) {
    if (!id || !data) return;
    if (data?.entity?.user?.username) {
      UserModel.setUsernameMapping(data?.entity?.user?.username, data?.entity?.user?._id);
      UserModel.setUserPartial(data?.entity?.user?._id, data?.entity?.user);
    }
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  static loadUnseenNotificationsCount = async () => {
    const res = await NotificationApi.getUnseenNotificationsCount();
    if (res.success) setRecoil(unseenNotificationsCountState, res.data.count);
  };

  static loadUnreadNotificationsCount = async () => {
    const res = await NotificationApi.getUnreadNotificationsCount();
    if (res.success) setRecoil(unreadNotificationsCountState, res.data.count);
  };

  static updateUnseenNotificationsStatusToUnread = async () => {
    const res = await NotificationApi.updateUnseenNotificationsStatusToUnread();
    if (res.success) setRecoil(unseenNotificationsCountState, 0);
  };

  static markAllNotificationsAsRead = async () => {
    const res = await NotificationApi.updateAllNotificationsToRead();
    if (res.success) {
      setRecoil(unreadNotificationsCountState, 0);
      setRecoil(unseenNotificationsCountState, 0);
      emitInvalidateQueries({ queryKey: QueryKeys.Notifications });
    }
  };

  markAsRead = async () => {
    const res = await NotificationApi.updateNotificationStatus(this.id, NotificationStatus.Read);
    if (res.success) {
      const prevData = this.getData();
      this.setData({ ...prevData, status: NotificationStatus.Read });
      if (prevData?.status === NotificationStatus.Unseen) {
        setRecoil(unseenNotificationsCountState, prev => (prev > 0 ? prev - 1 : 0));
      }
      if (prevData?.status === NotificationStatus.Unread) {
        setRecoil(unreadNotificationsCountState, prev => (prev > 0 ? prev - 1 : 0));
      }
    }
  };

  deleteNotification = async () => {
    const res = await NotificationApi.deleteNotification(this.id);
    if (res.success) emitInvalidateQueries({ queryKey: QueryKeys.Notifications });
  };

  updateNotificationStatus(status) {
    if (!this.id) return;
    return NotificationApi.updateNotificationStatus(this.id, status);
  }

  onSetState = (notiState, prevNotiState) => {
    if (!notiState?.data?.entity?.user?.username || !notiState.data?._id) return;
    if (notiState.data?.entity?.user?.username) {
      UserModel.setUsernameMapping(notiState.data?.entity?.user?.username, notiState.data?.entity?.user?._id);
      UserModel.setUserPartial(notiState.data?.entity?.user?._id, notiState.data?.entity?.user);
    }
  };
}
