/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getCurrentSnackbarSelector, removeSnackbarSelector } from '../../models/snackbar/selector';
import Notification from '../Notification';

const iconMap = {
  user: <InsertEmoticonIcon />,
};

const iconMapper = (iconName) => {
  if (!iconName) return null;
  const icon = Object.keys(iconMap).includes(iconName.toLowerCase()) ? iconMap[iconName] : null;
  return icon;
};

const startDelay = 200;

const CustomSnackbar = () => {
  // Global State
  const currentSnackbar = useRecoilValue(getCurrentSnackbarSelector);
  const removeSnackbar = useSetRecoilState(removeSnackbarSelector);

  // Local State
  const [open, setOpen] = useState(!!currentSnackbar);
  // Effects
  useEffect(() => {
    if (!!currentSnackbar) {
      setTimeout(() => {
        setOpen(true);
      }, startDelay);
    }
  }, [currentSnackbar]);

  // Handlers
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOpen(false);
    removeSnackbar();
  };

  // Guard Clause
  if (!currentSnackbar) return null;

  const {
    className,
    message,
    severity = 'info',
    variant = 'filled',
    duration = 4000,
    icon,
    sx = {},
    color,
    horizontal = 'left',
    vertical = 'bottom',
    content,
    type = 'alert',
    notification,
  } = currentSnackbar;
  const IconComponent = iconMapper(icon);

  if (type === 'alert') {
    return (
      <Snackbar className={className} anchorOrigin={{ horizontal, vertical }} open={open} autoHideDuration={duration} onClose={handleClose}>

        <Alert color={color || ''} variant={variant} onClose={handleClose} icon={IconComponent} severity={severity || 'info'} sx={{ width: '100%', ...sx }}>
          {!!message && message}
          {!!content && content}
        </Alert>

      </Snackbar>
    );
  }

  if (type === 'notification') {
    return (
      <Snackbar
        sx={{ backgroundColor: 'var(--color-surface) !important', zIndex: 10000, padding: '0px' }}
        className={className || 'rounded px-2 py-1'}
        anchorOrigin={{ horizontal, vertical }}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <div className='bg-s06dp rounded'>
          <Notification gridClassName='px-3' source='snackbar' {...notification} handleClose={handleClose} />
        </div>
      </Snackbar>
    );
  }

  return null;
};

export default CustomSnackbar;
