import { useEffect } from 'react';
import { TopicModel } from './model';

export const useLoadFromShortId = ({
  shortId,
  loadFromShortId,
  data,
  loading,
  setError,
}) => {
  useEffect(async () => {
    if (!shortId || !loadFromShortId || data || loading) return;
    const res = await TopicModel.requestTopicFromShortId(shortId);
    if (!res.success) return setError(res.error);
    if (res.data) {
      const CurrentTopicModel = new TopicModel(res.data._id);
      CurrentTopicModel.setState({ data: res.data, loading: false, hasLoaded: true });
    }
  }, [shortId, loadFromShortId, data, loading]);
};
