import Badge from '../Badge';

const BadgeGroup = ({ badges, containerClassName, size = '18px' }) => {
  if ((!badges || badges?.length === 0)) return null;
  return (
    <div className={`badges-container flex space-x-[2px] ${containerClassName ? `${containerClassName}` : 'mt-2'}`}>
      { badges?.map((badge) => (<Badge badge={badge} size={size} key={`${badge?._id}${badge?.name}`} />))}
    </div>
  );
};

export default BadgeGroup;
