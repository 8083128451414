import { useEffect } from 'react';
import {
  BLUR_COMMAND, COMMAND_PRIORITY_NORMAL, FOCUS_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useSetRecoilState } from 'recoil';
import { editorFocusSelector } from '../../../models/editor/selectors';

const emptyEditorString = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

const FocusPlugin = ({ editorName }) => {
  const [editor] = useLexicalComposerContext();
  const setEditorFocus = useSetRecoilState(editorFocusSelector(editorName));

  useEffect(() => editor.registerCommand(
    BLUR_COMMAND,
    () => {
      setEditorFocus(false);
      return true;
    },
    COMMAND_PRIORITY_NORMAL,
  ), [editor, editorName]);

  useEffect(() => editor.registerCommand(
    FOCUS_COMMAND,
    () => {
      setEditorFocus(true);
      return true;
    },
    COMMAND_PRIORITY_NORMAL,
  ), [editor, editorName]);

  return null;
};

export default FocusPlugin;
