import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LexicalComposer from '../LexicalComposer';
import { editorFocusSelector, editorIsExpandedSelector } from '../../models/editor/selectors';
import { breakpointNameSelector, isPwaSelector, platformSelector } from '../../models/settings/selectors';
import { Breakpoints, Platforms, SourceTypes } from '../../lib/constants';
import TopToolbar from './TopToolbar';
import BottomToolbar from './BottomToolbar';

const PostEditor = ({ topicId, sourceType = SourceTypes.Post }) => {
  const [isExpanded, setIsExpanded] = useRecoilState(editorIsExpandedSelector(topicId));
  const [isFocused, setIsFocused] = useRecoilState(editorFocusSelector(topicId));
  const breakpointName = useRecoilValue(breakpointNameSelector);

  const isMobile = useMemo(() => breakpointName === Breakpoints.Mobile, [breakpointName]);
  const isPwa = useRecoilValue(isPwaSelector);
  const platform = useRecoilValue(platformSelector);
  const isIos = platform === Platforms.iOS;

  const postEditorOuterStyles = useMemo(() => {
    const base = {
      zIndex: 10,
      maxWidth: '980px',
      width: '100%',
    };
    if (!isMobile) {
      return ({
        ...base,
        position: 'fixed',
        bottom: 0,
        maxWidth: '980px',
      });
    }
    if (isMobile && isExpanded) {
      return ({
        ...base,
        position: 'fixed',
        top: 0,
      });
    }
    if (isMobile && !isExpanded) {
      return ({
        ...base,
        bottom: 0,
        position: 'fixed',
      });
    }
  }, [isExpanded, isMobile, isFocused]);

  const editor = useMemo(() => {
    const editorStyles = {
      minHeight: '40px',
    };

    if (!isExpanded && isMobile) editorStyles.maxHeight = '150px';
    if (!isExpanded && !isMobile) editorStyles.maxHeight = '300px';
    if (isExpanded && !isMobile) editorStyles.height = '600px';
    if (isExpanded && isMobile) editorStyles.height = 'calc(100vh - 84px)';

    return (
      <>
        <TopToolbar sourceType={sourceType} editorName={topicId} />
        { (isExpanded) && <BottomToolbar sourceType={sourceType} editorName={topicId} /> }
        <LexicalComposer
          outerContainerClassName='w-[100%]'
          editorStyles={editorStyles}
          editorName={topicId}
        />
        { (!isExpanded) && <BottomToolbar sourceType={sourceType} editorName={topicId} /> }

      </>
    );
  }, [topicId, isMobile, isExpanded]);

  if (!topicId) return null;

  const innerPadding = isIos && isPwa ? 'pl-2 pr-3 pt-1 pb-6' : 'px-1 py-1';

  return (
    <div
      style={postEditorOuterStyles}
      className='post-editor-outer bg-surface text-primary-text tablet:rounded'
    >
      <div
        className={`post-editor-inner bg-s03dp ${innerPadding} tablet:rounded`}
      >
        { editor }
      </div>
    </div>
  );
};
export default PostEditor;
