import { Popper } from '@mui/material';

export const PopperMenu = ({
  children,
  anchorElement,
}) => (
  <Popper
    className='rounded text-primary-text'
    style={{
      zIndex: 1301,
      overflow: 'auto',
    }}
    modifiers={[
      { name: 'offset', options: { offset: [0, 30] } },
    ]}
    placement='top-start'
    open={!!anchorElement}
    anchorEl={() => anchorElement}
    keepMounted={false}
  >
    { children }
  </Popper>
);
