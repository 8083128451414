/* eslint-disable react/function-component-definition */
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import state from './atom';
import { UserModel } from './model';
import { useLoadFromUsername } from './useLoadFromUsername';
import { useLoadIfNeeded } from '../utils/useLoadIfNeeded';
import { useFetchOnMount } from '../utils/useFetchOnMount';

export const useUser = (id, {
  loadIfNeeded = false,
  fetchOnMount = false,
  loadFromUsername = false,
  username = null,
} = { }) => {
  const {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
  } = useRecoilValue(state(id || 'false'));
  const [_error, setError] = useState(null);

  const ModelInstance = useMemo(() => new UserModel(id), [id]);

  useLoadIfNeeded({
    loadIfNeeded,
    data,
    hasLoaded,
    loading,
    ModelInstance,
    id,
  });

  useLoadFromUsername({
    username,
    loadFromUsername,
    data,
    loading,
    setError,
  });

  useFetchOnMount({
    fetch: ModelInstance?.fetch,
    fetching,
    fetchOnMount,
    loading,
  });

  return {
    data,
    loading,
    error: _error || error,
    fetching,
    hasLoaded,
    model: ModelInstance,
    fetch: ModelInstance.fetch,
    reload: ModelInstance.reload,
    setData: ModelInstance.setData,
    loadIfNeeded: ModelInstance.loadIfNeeded,
  };
};
