import {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useSetRecoilState } from 'recoil';
import {
  TextField,
  InputAdornment,
  debounce,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Wrapper, Content } from '../../components/Containers';
import useSocketJoin from '../../hooks/useSocketJoin';
import UserCard from '../../components/UserCard';
import { usersOnlineSelector } from '../../models/usersOnline/selectors';
import ApiClientInstance from '../../clients/api';
import DrawerMenu from '../../components/DrawerMenu';
import { QueryKeys, SocketRooms, SourceTypes } from '../../lib/constants';
import QueryList from '../../components/QueryList';
import { UserModel } from '../../models/user/model';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const onRequestSuccess = (items) => {
  for (const item of items) UserModel.setUser(item._id, item);
};

const EmptyMessage = 'No users found with that query 😢';

const itemComponent = ({ isAdmin }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const ItemComponent = ({ item }) => (<UserCard isAdmin={isAdmin} _id={item._id} {...item} />);
  return ItemComponent;
};

const Users = ({ isAdmin }) => {
  // Hooks
  useDocumentTitle('Users');

  // State
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(isAdmin ? '' : 'old');
  const [filter, setFilter] = useState(isAdmin ? 'all' : '');
  const [path, setPath] = useState(isAdmin ? '/admin/user' : '/user');
  const [value, setValue] = useState(isAdmin ? 'all' : 'old');
  const searchRef = useRef('');

  const setUsersOnline = useSetRecoilState(usersOnlineSelector());

  // Sockets
  useSocketJoin(SocketRooms.General, 'users');
  const toggleSearchFocus = useCallback(() => setSearchFocused(prev => !prev), []);

  const debouncedSetPath = useRef(debounce((username) => {
    if (searchRef.current === username) return;
    if (username) {
      setPath('/user/search');
      setQuery({ username });
    } else {
      setPath('/user');
      setQuery({});
    }
    searchRef.current = username;
  }, 500));

  const handleSearchValueChange = useCallback((e) => {
    setSearchValue(e.target.value);
    debouncedSetPath.current(e.target.value);
  }, []);

  const handleSortChange = useCallback((e) => {
    setValue(e.target.value);
    setSort(e.target.value);
  }, []);

  const fetchUsersOnline = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      path: '/user/online',
      method: 'GET',
    });
    if (res?.success) setUsersOnline(res.data);
  }, []);

  const handleAdminChange = useCallback((e) => {
    const { value: _value } = e.target;
    switch (_value) {
      case 'all':
        setFilter('');
        setPath('/admin/user');
        setSort('');
        setValue(_value);
        break;
      case 'unapproved':
      case 'banned':
      case 'unverified':
      case 'suspended':
      case 'silenced':
      case 'user':
      case 'admin':
      case 'superadmin':
      case 'moderator':
        setPath('/admin/user');
        setFilter(_value);
        setSort('');
        setValue(_value);
        break;
      case 'online':
      case 'old':
      case 'new':
      case 'currencyDesc':
      case 'reputationDesc':
      case 'currency':
      case 'reputation':
        setQuery({});
        setFilter('');
        setSort(_value);
        setValue(_value);
        break;
      default:
        setPath('/admin/user');
        setFilter('unapproved');
        setSort('');
        setValue('unapproved');
        break;
    }
  }, []);

  useEffect(async () => {
    await fetchUsersOnline();
  }, []);

  const sortMenu = useMemo(() => {
    if (!isAdmin) {
      return (
        <FormControl sx={{ width: '100px' }} size='small'>
          <InputLabel id='sort-select'>Sort/Filter</InputLabel>
          <Select
            labelId='sort-select-label'
            id='sort-select'
            label='Sort/Filter'
            value={value}
            onChange={handleSortChange}
          >
            <MenuItem value='online'>Online</MenuItem>
            <MenuItem value='old'>Old</MenuItem>
            <MenuItem value='new'>New</MenuItem>
            <MenuItem value='currencyDesc'>MT +</MenuItem>
            <MenuItem value='reputationDesc'>XP +</MenuItem>
            <MenuItem value='currency'>MT -</MenuItem>
            <MenuItem value='reputation'>XP -</MenuItem>
          </Select>
        </FormControl>
      );
    }
    return (
      <FormControl sx={{ width: '100px' }} size='small'>
        <InputLabel id='sort-select'>Sort/Filter</InputLabel>
        <Select
          labelId='sort-select-label'
          id='sort-select'
          label='Sort/Filter'
          value={value}
          onChange={handleAdminChange}
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='unapproved'>Not Approved</MenuItem>
          <MenuItem value='moderator'>Moderator</MenuItem>
          <MenuItem value='admin'>Admin</MenuItem>
          <MenuItem value='user'>User</MenuItem>
          <MenuItem value='superadmin'>Superadmin</MenuItem>
          <MenuItem value='unverified'>Unverified</MenuItem>
          <MenuItem value='banned'>Banned</MenuItem>
          <MenuItem value='suspended'>Suspended</MenuItem>
          <MenuItem value='silenced'>Silenced</MenuItem>
          <MenuItem value='online'>Online</MenuItem>
          <MenuItem value='old'>Old</MenuItem>
          <MenuItem value='new'>New</MenuItem>
          <MenuItem value='currencyDesc'>MT +</MenuItem>
          <MenuItem value='reputationDesc'>XP +</MenuItem>
          <MenuItem value='currency'>MT -</MenuItem>
          <MenuItem value='reputation'>XP -</MenuItem>
        </Select>
      </FormControl>
    );
  }, [isAdmin, value, handleSortChange]);

  const queryParams = useMemo(() => ({ ...query, filter }), [query, filter]);

  const listContents = useMemo(() => {
    const queryKey = [QueryKeys.Users, 'public', sort, query?.username || '', filter];

    return (
      <Content className='pt-1 users-list text-primary-text'>
        <QueryList
          requestPath={path}
          requestParams={{ ...queryParams, sort }}
          ItemComponent={itemComponent({ isAdmin })}
          queryKey={queryKey}
          queryEnabled
          EmptyComponent={<div className='w-[100%] flex justify-center h-[200px] items-center'>{ EmptyMessage }</div>}
          customScrollParentSelector='.users-list'
          onRequestSuccess={onRequestSuccess}
        />
      </Content>
    );
  }, [queryParams, path, sort, isAdmin, query, filter]);

  return (
    <Wrapper applyBreakpoints className='users'>
      <div className='flex pt-2 space-x-2 justify-between items-center pl-2 pr-3 desktop:pr-5'>
        <h1 className='text-3xl font-bebas my-3 text-primary-text'>Users</h1>
        {sortMenu}
        <TextField
          value={searchValue}
          onChange={handleSearchValueChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ height: '22px', opacity: '.8' }} color={searchFocused ? 'primary' : 'primaryText'} />
              </InputAdornment>
            ),
          }}
          onFocus={toggleSearchFocus}
          onBlur={toggleSearchFocus}
          color=''
          sx={{ maxWidth: '175px' }}
          label='search users'
          size='small'
        />
      </div>
      { listContents }
      <DrawerMenu sourceType={SourceTypes.User} />
    </Wrapper>
  );
};

export default Users;
