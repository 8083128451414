import { useRecoilValue } from 'recoil';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePost } from '../../../models/post/usePost';
import postRepliesExpandedState from '../../../models/postRepliesExpanded/atom';
import { CardHeaderContentDetailTypes } from '../../../lib/constants';
import UniverseLoader from '../../Loaders/UniverseLoader';
import {
  CardContainer,
} from '../../TopicCard/CardContainer';
import { CardHeaderContainer } from '../CardHeaderContainer';
import { CardHeaderContent } from '../CardHeaderContent';
import { CardPostContent } from '../CardPostContent';
import { CardActions } from '../CardActions';
import { PostModel } from '../../../models/post/model';

export const CardReplyItem = ({ sourceType, _id }) => {
  const { data } = usePost(_id, { sourceType });

  if (!data) return null;
  return (
    <div className='rounded bg-surface'>
      <div className='bg-s01dp rounded'>
        <CardContainer
          isModeratorPost={data?.isModeratorPost}
          sourceType={sourceType}
          className='my-2'
          author={data?.author}
          isDeleted={data?.isDeleted}
        >
          <CardHeaderContainer
            isDeleted={data.isDeleted}
            pin={false}
            sourceType={sourceType}
            includeEditMenu={false}
            includeScrollToPostButton
            _id={_id}
          >
            <CardHeaderContent
              sourceType={sourceType}
              avatarSize='24px'
              author={data.author}
              detailsType={CardHeaderContentDetailTypes.User}
              createdOn={data.createdOn}
            />
          </CardHeaderContainer>
          <CardPostContent sourceType={sourceType} _id={_id} />
          <CardActions showRepliesCount={false} topicId={data.topic._id} postId={_id} />
        </CardContainer>
      </div>
    </div>
  );
};

export const CardRepliesContainer = ({ sourceType, _id }) => {
  const { data, model } = usePost(_id, { sourceType });
  const postRepliesExpanded = useRecoilValue(postRepliesExpandedState(_id));
  const replies = useMemo(() => data?.replies || [], [data]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!postRepliesExpanded || !model) return;
    let isCancelled = false;
    const handler = async () => {
      let allLoaded = true;
      for (const reply of replies) {
        const replyId = typeof reply === 'string' ? reply : reply?._id;
        if (!replyId) {
          allLoaded = false;
          console.log('no reply id', reply);
          continue;
        }
        const ReplyPost = new PostModel(replyId);
        if (!ReplyPost.getData()) allLoaded = false;
      }
      if (!allLoaded && !isCancelled) setLoading(true);
      const res = await model?.getReplies();
      if (res.success) {
        if (isCancelled) return;
        for (const reply of res.data) PostModel.setPost(reply._id, reply);
      }
      if (!isCancelled) setLoading(false);
    };
    handler();
    return () => {
      isCancelled = true;
    };
  }, [postRepliesExpanded, replies, model]);

  if (!data || !data.replies?.length || !postRepliesExpanded) return null;

  return (
    <div className='mt-2 text-xs border-s06dp relative'>
      { loading && (
        <div className='bg-s01dp py-7 rounded' style={{ height: `${data.replies.length * 40}px` }}>
          <UniverseLoader showLogo={false} size={60} boxType='fullContainer' />
        </div>
      )}
      { !loading ? replies.map(reply => {
        const replyId = typeof reply === 'string' ? reply : reply?._id;
        if (!replyId) return null;
        return (
          <CardReplyItem sourceType={sourceType} key={replyId} _id={replyId} />
        );
      }) : null}
    </div>
  );
};
