import Image from '../Image';

// TODO: change src
const defaultSrc = '/assets/reputation/crown.svg';

const Badge = ({ badge, size }) => {
  if (!badge) return null;
  const {
    _id,
    order,
    enabled,
    image,
    name,
  } = badge;
  if (!enabled) return null;

  return (
    <span key={_id} style={{ order }} className='text-xs opacity-80 inline-block'>
      <Image
        style={{ height: size, width: size }}
        src={image}
        alt={`${name}-badge-icon`}
        onError={(e) => { e.target.src = defaultSrc; }}
      />
    </span>
  );
};

export default Badge;
