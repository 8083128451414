import { useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Button, Chip } from '@mui/material';

import {
  Wrapper,
  Content,
  StyledDivider,
  StyledSection,
} from '../Containers';
import UserSelect from '../UserSelect';
import UserAvatar from '../UserAvatar';

import ApiClientInstance from '../../clients/api';
import { CustomEvents, UserRoles } from '../../lib/constants';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useRequireVerification } from '../../hooks/useRequireVerification';

const SettingsPrivacy = () => {
  useRequireVerification('/settings');
  const { data: user, setData: setUser, fetch } = useAuthenticatedUser();
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const fetchUser = fetch;

  const [userToBlock, setUserToBlock] = useState(null);

  const handleBlockUser = useCallback(() => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: `/user/block/${userToBlock?._id}`,
        catchError: true,
        snackbarError: 'errorMessage',
      });
      if (res.success) {
        addSnackbar({
          message: `${userToBlock?.username} has been blocked.`,
          color: 'primary',
        });
        setUserToBlock(null);
        fetchUser();
      }
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text: `Block ${userToBlock.username}` });
  }, [userToBlock]);

  const handleUnblockUser = useCallback((userToUnblock) => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: `/user/unblock/${userToUnblock?._id}`,
        catchError: true,
        snackbarError: 'errorMessage',
      });
      if (res.success) {
        addSnackbar({
          message: `${userToUnblock?.username} has been unblocked.`,
          color: 'primary',
        });
        setUserToBlock(null);
        fetchUser();
      }
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text: `Unblock ${userToUnblock.username}` });
  }, []);

  const blockedUsers = useMemo(() => {
    const blocked = user?.blocked || [];
    const hasBlocked = blocked && blocked.length > 0;

    const submitDisabled = userToBlock === null;

    return (
      <StyledSection action='blocked-users' title='Blocked Users' className='tablet:w-[772px] text-primary-text font-roboto'>
        { !hasBlocked && <p className='mt-1 mb-2'>You&apos;re not blocking anyone! 🎉</p> }
        { hasBlocked && (
          <div style={{ columnGap: '5px', rowGap: '5px' }} className='mt-2 mb-3 flex flex-wrap items-center'>
            { blocked.map((bu) => (
              <Chip
                key={bu._id}
                label={bu.username}
                onDelete={() => handleUnblockUser(bu)}
                avatar={(
                  <UserAvatar
                    styles={{ marginLeft: '0.25rem' }}
                    avatarFontSize='12px'
                    avatar={bu.avatar}
                    color={bu.color}
                    username={bu.username}
                    avatarSize='20px'
                  />
)}
              />
            )) }
          </div>
        ) }
        <UserSelect
          usersToExclude={[user, ...blocked]}
          selectType='single'
          setUser={setUserToBlock}
          user={userToBlock}
          title='select user to block'
          placeholder='select user to block'
          label='select user to block'
          customFilter={(users) => users.filter((u) => ![UserRoles.SuperAdmin].includes(u.role))}
        />
        <div className='mt-4'>
          <Button
            disabled={submitDisabled}
            color='primary'
            variant='contained'
            onClick={handleBlockUser}
          >
            {userToBlock ? `Block ${userToBlock?.username}` : 'Select User to Block'}
          </Button>
        </div>
      </StyledSection>
    );
  }, [user, userToBlock]);

  return (
    <Wrapper applyBreakpoints className='text-primary-text'>
      <div className='flex space-x-2 justify-between items-center'>
        <h1 className='text-2xl font-bebas my-3'>Privacy Settings</h1>
      </div>
      <Content className='pb-3 tku-custom-scroller'>
        <div className='px-2 py-2 relative desktop:w-[992px] desktop:ml-auto desktop:mr-auto'>
          { blockedUsers }
          <StyledDivider className='tablet:w-[772px] py-2' />
        </div>
      </Content>
    </Wrapper>
  );
};

export default SettingsPrivacy;
