/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Chip,
  debounce,
  ListItemButton,
  TextField,
} from '@mui/material';
import {
  useCallback,
  useState,
} from 'react';
import ApiClientInstance from '../../clients/api';
import { UserModel } from '../../models/user/model';
import UserAvatar from '../UserAvatar';

// eslint-disable-next-line func-names
const defaultUserOption = () => function ({
  username,
  muiProps,
  color,
  avatar,
  online,
  _id,
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItemButton {...muiProps} className='px-3 py-1 flex items-center'>
      <UserAvatar
        _id={_id}
        showOnline
        avatarSize='24px'
        avatarFontSize='16px'
        selected={muiProps['aria-selected']}
      />
      <span className='ml-3'>{username}</span>
    </ListItemButton>
  );
};

const UserSelect = ({
  ItemComponent = defaultUserOption(),
  selectType = 'single',
  setUser,
  setUsers,
  placeholder = 'Select a user',
  label = 'user',
  user,
  users,
  style,
  usersToExclude = [],
  className = '',
  customFilter,
  externalLoading = false,
}) => {
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSingleUserChange = useCallback((event, newValue) => {
    setUser(newValue);
  }, []);

  const handleMultipleUsersChange = useCallback((event, newValue) => {
    setUsers(newValue);
  }, []);

  const isOptionEqualToValue = useCallback((option, val) => {
    if (!val?._id) return val === '';
    return option?._id === val?._id;
  }, []);

  const getOptionLabel = useCallback((option) => option?.username || '', []);

  const renderOption = useCallback((props, option) => <ItemComponent key={option._id} {...option} muiProps={props} />, [ItemComponent]);

  const renderInput = useCallback((params) => <TextField {...params} label={label} />, [label]);

  const filterOptions = useCallback(() => {
    let items = searchedUsers;
    if (customFilter) {
      items = customFilter(searchedUsers);
    }
    if (usersToExclude?.length > 0) {
      items = items.filter((t) => !usersToExclude.find(_t => _t?._id === t?._id));
    }
    return items.slice(0, 5);
  }, [searchedUsers, usersToExclude, customFilter]);

  const renderTags = useCallback((_value, getTagProps) => {
    if (!_value) return null;
    return _value.map((option, index) => (
      <Chip
        color='primary'
        label={option.username}
        size='small'
        avatar={(
          <UserAvatar
            avatarFontSize='16px'
            color={option.color}
            avatar={option.avatar}
            username={option.username}
            avatarSize='24px'
          />
)}
        {...getTagProps({ index })}
      />
    ));
  }, []);

  const fetchUsers = useCallback(debounce(async (query) => {
    setSearchLoading(true);
    if (!query) {
      setSearchedUsers([]);
      setSearchLoading(false);
      return;
    }
    const res = await ApiClientInstance.sendRequest({
      path: '/user/search',
      method: 'GET',
      queryParams: { username: query },
      catchError: true,
    });
    if (res.success) {
      setSearchedUsers(res.data);
      for (const item of res.data) UserModel.setUserPartial(item._id, item);
    }
    setSearchLoading(false);
  }, 250), []);

  const handleInputChange = useCallback((event, _value) => fetchUsers(_value), []);

  if (selectType === 'single') {
    return (
      <Autocomplete
        fullWidth
        ListboxProps={{ className: 'bg-s02dp' }}
        className={`${className} bg-s01dp`}
        disablePortal
        placeholder={placeholder}
        options={searchedUsers}
        value={user}
        sx={style}
        loading={externalLoading || searchLoading}
        onChange={handleSingleUserChange}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
      />
    );
  }
  if (selectType === 'multiple') {
    return (
      <Autocomplete
        className={`${className} bg-s01dp`}
        multiple
        sx={style}
        noOptionsText='no users found'
        options={[...users, ...searchedUsers]}
        loading={externalLoading || searchLoading}
        loadingText='searching for users...'
        onChange={handleMultipleUsersChange}
        value={users}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        defaultValue={users}
        filterOptions={filterOptions}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        renderTags={renderTags}
        renderInput={renderInput}
        ListboxProps={{ sx: { backgroundColor: 'var(--color-s04dp)' } }}
      />
    );
  }
};

export default UserSelect;
