import { getObjectValue } from '../../lib/getObjectValue';
import set from '../../lib/set';
import { SimpleListUpdateActions } from '../../lib/constants';

export const itemListUpdater = (items, setItems, eventData) => {
  const path = eventData?.path;
  const _id = eventData?._id;
  const action = eventData?.action;
  if (!action || !path || !_id) return;
  let object = null;
  let index = null;
  for (const item of items) {
    const itemIdValue = getObjectValue(path, item);
    if (itemIdValue === _id) {
      object = item;
      index = items.indexOf(item);
      break;
    }
  }
  if (!object || typeof index === 'undefined') return;
  switch (action) {
    case (SimpleListUpdateActions.Add): {
      const { newItem } = eventData;
      if (!newItem) return;
      setItems([...items, eventData.newItem]);
      break;
    }
    case (SimpleListUpdateActions.Update): {
      const newItems = [...items];
      const newItem = { ...newItems[index] };
      const { updatePath, updateValue } = eventData;
      if (!updatePath || !updateValue) return null;
      let newValue;
      if (typeof updateValue === 'string') newValue = updateValue;
      else if (Array.isArray(updateValue)) newValue = [...updateValue];
      else if (typeof updateValue === 'object') newValue = { ...updateValue };
      set(newItem, updatePath, newValue);
      newItems[index] = { ...newItem };
      setItems([...newItems]);
      break;
    }
    case (SimpleListUpdateActions.Remove): {
      const newItems = [...items.slice(0, index), ...items.slice(index + 1)];
      setItems([...newItems]);
      break;
    }
    case (SimpleListUpdateActions.Log): {
      console.log({ items });
      console.log({ object });
      console.log({ index });
      break;
    }
    default:
      break;
  }
};
