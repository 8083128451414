import { useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Menu,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { ExitToApp } from '@mui/icons-material';
import {
  Breakpoints,
  MessageStatus,
  SourceTypes,
  UserPrivateTopicStatus,
} from '../../lib/constants';

import { useManageMessage } from '../../hooks/useManageMessage';
import { breakpointNameSelector } from '../../models/settings/selectors';
import { useTopic } from '../../models/topic/useTopic';
import { useMessageMetaData } from '../../hooks/useMessageMetaData';

const MessageManageMenu = ({
  _id,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const { data, fetch, model } = useTopic(_id, { sourceType: SourceTypes.Message });

  const {
    isArchived,
  } = data;

  const {
    acceptanceStatus,
    role,
    messageReadStatus,
  } = useMessageMetaData({ _id });

  const open = !!anchorEl;

  const handleClose = useCallback((e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const acceptCallback = useCallback(() => {
    navigate(`/message/${_id}`);
  }, [_id]);

  const rejectCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const archiveCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const unarchiveCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const deleteCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const ownerArchiveCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const ownerDeleteCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const markLatestAsReadCallback = useCallback(async () => {
    handleClose();
  }, [_id, model]);

  const manageUsersCallback = useCallback(async () => {
    await fetch();
    handleClose();
  }, [_id]);

  const editTitleCallback = useCallback(async () => {
    await fetch();
    handleClose();
  }, [_id]);

  const leaveMessageCallback = useCallback(async () => {
    handleClose();
  }, [_id]);

  const errorCallback = useCallback(() => () => handleClose(), []);

  const {
    handleAccept,
    handleReject,
    handleArchive,
    handleUnarchive,
    handleDelete,
    handleOwnerArchive,
    handleOwnerDelete,
    handleMarkLatestAsRead,
    handleManageUsers,
    handleEditTitle,
    handleLeaveMessage,
  } = useManageMessage({
    _id,
    rejectCallback,
    acceptCallback,
    errorCallback,
    archiveCallback,
    unarchiveCallback,
    deleteCallback,
    ownerArchiveCallback,
    ownerDeleteCallback,
    markLatestAsReadCallback,
    manageUsersCallback,
    editTitleCallback,
    leaveMessageCallback,
  });

  const breakpoint = useRecoilValue(breakpointNameSelector);
  const isMobile = breakpoint === Breakpoints.Mobile;

  return (
    <div>
      { isMobile && (
        <IconButton
          color={open ? 'primaryLight' : 'textPrimary'}
          id='message-request-menu-button'
          aria-controls={open ? 'message-request-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </IconButton>
      )}
      { !isMobile && (
        <Button
          variant='outlined'
          color={open ? 'primaryLight' : 'textPrimary'}
          id='message-request-menu-button'
          aria-controls={open ? 'message-request-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<SettingsIcon />}
        >
          Manage
        </Button>
      )}
      <Menu
        id='message-request-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'message-request-menu-button',
        }}
      >
        { [MessageStatus.Unseen, MessageStatus.Unread].includes(messageReadStatus) && (
        <MenuItem dense onClick={handleMarkLatestAsRead}>
          <ListItemIcon>
            <CheckCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Mark as Read</ListItemText>
        </MenuItem>
        )}
        { (role !== 'owner' && acceptanceStatus === UserPrivateTopicStatus.Accepted) && (
        <MenuItem dense onClick={handleLeaveMessage}>
          <ListItemIcon>
            <ExitToApp fontSize='small' />
          </ListItemIcon>
          <ListItemText>Leave</ListItemText>
        </MenuItem>
        )}
        { (role !== 'owner' && acceptanceStatus === UserPrivateTopicStatus.Accepted) && (
        <MenuItem dense onClick={handleArchive}>
          <ListItemIcon>
            <ArchiveIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
        )}
        { (role !== 'owner' && acceptanceStatus === UserPrivateTopicStatus.Archived) && (
        <MenuItem dense onClick={handleUnarchive}>
          <ListItemIcon>
            <UnarchiveIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Unarchive</ListItemText>
        </MenuItem>
        )}
        { acceptanceStatus === UserPrivateTopicStatus.Pending && (
        <MenuItem dense onClick={handleAccept}>
          <ListItemIcon>
            <CheckCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Accept</ListItemText>
        </MenuItem>
        )}
        { acceptanceStatus === UserPrivateTopicStatus.Pending && (
        <MenuItem dense onClick={handleReject}>
          <ListItemIcon>
            <RemoveCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Reject</ListItemText>
        </MenuItem>
        ) }
        { (role !== 'owner') && (
        <MenuItem dense onClick={handleDelete}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        )}
        { (role === 'owner' && acceptanceStatus === UserPrivateTopicStatus.Accepted) && (
        <MenuItem dense onClick={handleEditTitle}>
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Edit Title</ListItemText>
        </MenuItem>
        )}
        { (role === 'owner' && acceptanceStatus === UserPrivateTopicStatus.Accepted) && (
        <MenuItem dense onClick={handleManageUsers}>
          <ListItemIcon>
            <PeopleAltIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Manage Users</ListItemText>
        </MenuItem>
        )}
        { (role === 'owner') && (
        <MenuItem dense onClick={handleOwnerArchive}>
          <ListItemIcon>
            { isArchived ? <UnarchiveIcon fontSize='small' /> : <ArchiveIcon fontSize='small' /> }
          </ListItemIcon>
          <ListItemText>{ isArchived ? 'Unarchive for Everyone' : 'Archive for Everyone' }</ListItemText>
        </MenuItem>
        )}
        { (role === 'owner') && (
        <MenuItem dense onClick={handleOwnerDelete}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete for Everyone</ListItemText>
        </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MessageManageMenu;
