import { useCallback, useMemo } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { Button, IconButton } from '@mui/material';

import { usePost } from '../../../models/post/usePost';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import { emitOpenPostVodeModal } from '../../../lib/emitters/emitOpenPostVodeModal';
import { addSnackbarSelector } from '../../../models/snackbar/selector';
import { emitInsertInEditor } from '../../../lib/emitters/emitInsertInEditor';
import {
  ComposerNodeTypes, QueryKeys, SourceTypes, UserProfileContentTypes,
} from '../../../lib/constants';
import { useTopic } from '../../../models/topic/useTopic';
import { editorCurrentReplySelector, editorIsActiveSelector } from '../../../models/editor/selectors';
import { CardRepliesCount } from '../CardRepliesCount';
import topicSourceTypeState from '../../../models/topicSourceType/atom';
import postSourceTypeState from '../../../models/postSourceType/atom';
import { emitInvalidateQueries } from '../../../lib/emitters/emitInvalidateQueries';

const btnSize = '20px';
const btnSizes = { height: btnSize, width: btnSize };

export const CardActions = ({
  topicId,
  postId,
  saved = false,
  showIsSaved = false,
  showEditorActions = true,
  showRepliesCount = true,
}) => {
  const topicSourceType = useRecoilValue(topicSourceTypeState(topicId));
  const postSourceType = useRecoilValue(postSourceTypeState(topicId));
  const { authenticated, data: authenticatedUser, verified } = useAuthenticatedUser();
  const { data } = useTopic(topicId || 'cardActions', { sourceType: topicSourceType });
  const { data: postData, model: postModel } = usePost(postId || data?.mainPost._id, { sourceType: postSourceType });
  const setEditorIsActive = useSetRecoilState(editorIsActiveSelector(topicId));
  const setEditorCurrentReply = useSetRecoilState(editorCurrentReplySelector(topicId));

  const { score, vote } = postData;

  const isAuthor = useMemo(() => authenticatedUser?._id === postData?.author?._id, [authenticated, postData]);

  const handleVote = useCallback((delta) => async () => {
    if (isAuthor) return null;
    postModel.vote(delta);
  }, [postId, isAuthor, postModel]);

  const handlePostVoteCountClick = useCallback(() => emitOpenPostVodeModal({ postId, postType: postSourceType === SourceTypes.Message ? 'private' : 'public' }), [score, postId, postSourceType]);

  const handleUnsave = useCallback(async () => {
    if (!saved) return;
    const res = await postModel.unsave();
    if (res.success) {
      setRecoil(addSnackbarSelector, { message: 'Post unsaved successfully', color: 'primary' });
      emitInvalidateQueries({ queryKey: [QueryKeys.Profile, authenticatedUser?._id, UserProfileContentTypes.Saved] });
      postModel.fetch();
    }
  }, [saved, postModel, authenticatedUser]);

  const handleReply = useCallback(() => {
    setEditorIsActive(true);
    emitInsertInEditor({
      editorName: topicId,
      nodeType: ComposerNodeTypes.Mention,
      data: { ...postData.author },
    });
    setEditorCurrentReply({ _id: postId, author: postData.author });
  }, [postId, postData.author, topicId]);

  const handleQuote = useCallback(() => {
    const _data = {
      editorName: topicId,
      author: postData.author,
      topic: postData.topic,
      post: { _id: postId, content: postData.content },
    };
    setEditorIsActive(true);
    emitInsertInEditor({
      editorName: topicId,
      nodeType: ComposerNodeTypes.PostQuote,
      data: _data,
    });
  }, [postId, postData.author]);

  if (!postData && !data) return null;

  return (
    <section className='topic-card-actions flex justify-between mt-1'>
      <div className='flex content-center items-center space-x-1'>
        <IconButton
          disabled={isAuthor || !verified}
          size='small'
          aria-label='like-button'
          color={authenticated && vote === 1 ? 'primaryLight' : 'disabledText'}
          onClick={handleVote(1)}
        >
          <FavoriteIcon sx={btnSizes} />
        </IconButton>
        <Button
          onClick={handlePostVoteCountClick}
          size='small'
          sx={{ minWidth: '20px', padding: '2px 2px' }}
          color={score === 0 ? 'textPrimary' : score > 0 ? 'primaryLight' : 'secondaryLight'}
        >
          <span className='text-xs font-bold block w-5 text-center'>{score || 0}</span>
        </Button>
        <IconButton
          disabled={isAuthor || !verified}
          size='small'
          aria-label='dislike-button'
          color={authenticated && vote === -1 ? 'secondaryLight' : 'disabledText'}
          onClick={handleVote(-1)}
        >
          <ThumbDownIcon sx={btnSizes} />
        </IconButton>
      </div>

      { showRepliesCount && <CardRepliesCount sourceType={postSourceType} _id={postId} /> }

      { (showEditorActions) && (
      <div className='space-x-2 opacity-80'>
        <IconButton disabled={!verified} className='prevent-clickaway' onClick={handleReply} size='small' aria-label='quote-button'>
          <ReplyAllIcon sx={btnSizes} />
        </IconButton>
        <IconButton disabled={!verified} className='prevent-clickaway' onClick={handleQuote} size='small' aria-label='quote-button'>
          <FormatQuoteIcon sx={btnSizes} />
        </IconButton>
      </div>
      )}

      { (showIsSaved && saved) && (
      <div className='user-post-management-actions'>
        <div>
          <Button
            size='small'
            color='primaryLight'
            variant='outlined'
            startIcon={<BookmarkRemoveIcon />}
            onClick={handleUnsave}
          >
            Unsave
          </Button>
        </div>
      </div>
      )}
    </section>
  );
};
