/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  useInfiniteQuery,
} from 'react-query';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';
import { Button } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import UniverseLoader from '../Loaders/UniverseLoader';
import ApiClientInstance from '../../clients/api';
import { CustomEvents, VirtuosoTypes } from '../../lib/constants';
import throttle from '../../lib/throttle';
import { useCustomScrollParent } from '../../hooks/useCustomScrollParent';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { navLoaderSelector } from '../../models/settings/selectors';

// UTILS
const _fetchPage = ({
  requestPath,
  requestSort,
  requestParams,
  onRequestSuccess,
  onRequestFail,
  setError,
}) => async ({ pageParam = 0 }) => {
  const res = await ApiClientInstance.sendRequest({
    method: 'get',
    path: requestPath,
    queryParams: {
      sort: requestSort,
      ...requestParams,
      page: pageParam,
    },
    catchError: true,
    snackbarError: true,
  });
  if (res.success) {
    if (res.data?.docs) {
      if (onRequestSuccess) onRequestSuccess(res.data.docs, res.data);
      return res.data;
    }
    if (res.data?.length > -1) {
      if (onRequestSuccess) onRequestSuccess(res.data, res.data);
      return { docs: res.data, nextPage: null };
    }
  }
  if (onRequestFail) onRequestFail(res.data);
  setError(res);
  return res;
};

const virtuosoComponent = (ItemComponent, items) => {
  const component = (index, item) => {
    if (!item) item = items[index];
    return <ItemComponent index={index} item={item} />;
  };
  return component;
};

const Placeholder = ({ shouldShowPlaceholder, props = { boxType: 'fullScreen', zIndex: 20 } }) => () => {
  if (!shouldShowPlaceholder) return <div className='h-[1px] w-[1px]' />;
  return (
    <div style={{ minHeight: '300px' }}>
      <UniverseLoader {...props} />
    </div>
  );
};

const QueryList = ({
  virtuosoType = VirtuosoTypes.List,
  listClassName,
  itemClassName,
  ItemComponent,
  shouldUseCustomScrollParent = true,
  customScrollParent,
  customScrollParentSelector,
  requestPath,
  refetchInterval = false,
  requestParams = {},
  requestSort,
  onRequestSuccess,
  onRequestFail,
  queryEnabled = true,
  queryKey,
  initialTopMostItemIndex = 0,
  queryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  },
  shouldRender = true,
  loaderProps = {
    boxType: 'fullScreen',
  },
  parentLoading = false,
  setParentLoading = null,
  ErrorComponent,
  shouldShowErrorComponent,
  EmptyComponent,
  BackButtonComponent,
  shouldShowLoaderOnQueryLoading = true,
  shouldShowEmptyComponent = true,
  shouldShowBackButton = true,
  shouldShowPlaceholder = true,
  setExternalLoadingState,
  HeaderComponent,
  FooterComponent,
  useNavLoaderOnFetching,
}) => {
  const virtuoso = useRef();
  const stateRef = useRef({});
  const [isScrollAtTop, setIsScrollAtTop] = useState(false);
  const [error, setError] = useState(null);
  const setNavLoader = useSetRecoilState(navLoaderSelector);

  const customScrollParentFromSelector = useCustomScrollParent({ selector: customScrollParentSelector, maxAttempts: customScrollParentSelector ? 50 : 0 });

  // React Query
  const fetchPage = useMemo(() => {
    if (!requestPath) return null;
    return _fetchPage({
      requestPath,
      requestSort,
      requestParams,
      onRequestSuccess,
      onRequestFail,
      setError,
    });
  }, [requestPath, requestSort, setError, requestParams]);

  const getNextPageParam = useCallback((lastPage) => lastPage?.nextPage, []);

  // React Query
  const {
    data,
    isLoading: queryLoading,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery(queryKey, fetchPage, {
    enabled: (queryEnabled && Boolean((typeof queryKey === 'string' ? !!queryKey : queryKey?.length > 0) && fetchPage)),
    getNextPageParam,
    select: (_data) => ({ ..._data, docs: _data?.pages?.[0]?.docs ? _data?.pages?.reduce((acc, page) => [...acc, ...page.docs], []) : [] }),
    ...queryOptions,
    refetchInterval,
  });

  // Virtuoso Helpers
  const throttledScroll = useCallback(throttle((scroll) => {
    if (!stateRef.current.hasScrolled && scroll) stateRef.current.hasScrolled = true;
  }, 400), []);

  const bottomHandler = useCallback((isBottom) => {
    if (isBottom && !isFetchingNextPage && hasNextPage) fetchNextPage();
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  const topHandler = (isTop) => {
    if (isTop !== isScrollAtTop) setIsScrollAtTop(isTop);
  };

  const scrollToIndex = useCallback((index = {}, { align = 'start', behavior = 'smooth' } = {}) => {
    virtuoso.current.scrollToIndex({
      index,
      align,
      behavior,
    });
  }, []);

  // Scroll Event Handling
  const handleQueryListScrollEvent = useCallback(({ index, queryKey: _queryKey, options }) => {
    if (_queryKey && _queryKey !== queryKey) return;
    scrollToIndex(index, options);
  }, [queryKey, scrollToIndex]);

  useCustomEvent(CustomEvents.QueryListScroll, handleQueryListScrollEvent);

  // Flags
  const isEmpty = useMemo(() => {
    if (!data || parentLoading || queryLoading) return false;
    return data.docs.length === 0;
  }, [data, queryLoading, parentLoading]);

  // Layout Components
  const loader = useMemo(() => {
    if (!queryLoading || !shouldShowLoaderOnQueryLoading) return null;
    return <UniverseLoader boxType={loaderProps?.boxType || 'fullScreen'} />;
  }, [queryLoading, shouldShowLoaderOnQueryLoading, loaderProps]);

  const emptyComponent = useMemo(() => {
    if (shouldShowErrorComponent && error) return false;
    if (!shouldShowEmptyComponent || !isEmpty) return null;
    if (EmptyComponent) return EmptyComponent;
    return <div className='flex pt-10 justify-center items-center font-roboto text-lg'>No items found 😢</div>;
  }, [isEmpty, EmptyComponent, shouldShowEmptyComponent, error, shouldShowErrorComponent]);

  const errorComponent = useMemo(() => {
    if (!shouldShowErrorComponent || !error) return null;
    if (ErrorComponent) return ErrorComponent;
    return (
      <div className='flex flex-col pt-10 justify-center items-center font-roboto text-lg'>
        <div>Something&apos;s gone wrong 😢</div>
        { error?.error && <div className='text-sm italic opacity-90 pt-2'>{`details: ${error.error}`}</div> }
      </div>
    );
  }, [error, shouldShowErrorComponent, ErrorComponent]);

  const scrollToTopButton = useMemo(() => {
    if (isScrollAtTop || !data?.docs?.length > 0 || queryLoading || !stateRef.current.hasScrolled || !shouldShowBackButton) return null;
    if (BackButtonComponent) return <BackButtonComponent scrollToIndex={scrollToIndex} />;
    return (
      <div className='inline-block absolute bottom-2 right-2 tablet:right-4 z-10'>
        <Button
          variant='contained'
          onClick={scrollToIndex}
        >
          Back to Top
        </Button>
      </div>
    );
  }, [scrollToIndex, isScrollAtTop, data, queryLoading, BackButtonComponent, shouldShowBackButton]);

  // allows a custom scroll parent to be passed in or a selector to be passed in
  const [scrollParent, isScrollParentStoppingRender, shouldUseCustomVirtuosoScroller] = useMemo(() => {
    if (!shouldUseCustomScrollParent) return [null, false, false];
    if (!customScrollParentSelector) {
      const isCustomScrollParentAvailable = !!customScrollParent;
      return [customScrollParent, !isCustomScrollParentAvailable, true];
    }
    if (customScrollParentSelector) {
      const isCustomScrollParentAvailable = !!customScrollParentFromSelector;
      return [customScrollParentFromSelector, !isCustomScrollParentAvailable, true];
    }
    return [null, true, false];
  }, [customScrollParent, shouldUseCustomScrollParent, customScrollParentFromSelector, customScrollParentSelector]);

  // Sync Parent Loading
  useEffect(() => {
    if (!setParentLoading || !parentLoading) return;
    if (shouldRender && !isScrollParentStoppingRender) setParentLoading(false);
  }, [shouldRender, parentLoading, isScrollParentStoppingRender, setParentLoading, isEmpty]);

  useEffect(() => {
    if (setExternalLoadingState) setExternalLoadingState(queryLoading);
  }, [queryLoading]);

  useEffect(() => {
    if (!useNavLoaderOnFetching) return;
    if (isFetching) setNavLoader(true);
    else setNavLoader(false);
    return () => setNavLoader(false);
  }, [isFetching, useNavLoaderOnFetching]);

  // Render Guard Clause
  if (!shouldRender || parentLoading || isScrollParentStoppingRender) return null;

  return (
    <div>
      { loader }
      { emptyComponent }
      { errorComponent}
      { (!isEmpty && !error && virtuosoType === VirtuosoTypes.List) && (
        <Virtuoso
          components={{ Header: HeaderComponent || null, Footer: FooterComponent || null }}
          customScrollParent={shouldUseCustomVirtuosoScroller ? scrollParent : null}
          style={{ overflowY: scrollParent ? 'hidden' : 'auto', height: '100%' }}
          ref={virtuoso}
          initialTopMostItemIndex={initialTopMostItemIndex}
          atBottomStateChange={shouldUseCustomVirtuosoScroller ? null : bottomHandler}
          atTopStateChange={topHandler}
          isScrolling={throttledScroll}
          endReached={shouldUseCustomVirtuosoScroller ? bottomHandler : null}
          data={data?.docs?.length > 0 ? data.docs : ['loader']}
          itemContent={data?.docs?.length > 0 ? virtuosoComponent(ItemComponent, data.docs) : Placeholder({ props: loaderProps, shouldShowPlaceholder })}
        />
      ) }
      { (!isEmpty && !error && virtuosoType === VirtuosoTypes.Grid) && (
        <VirtuosoGrid
          components={{ Header: HeaderComponent || null, Footer: FooterComponent || null }}
          totalCount={data?.docs?.length > 0 ? data.docs.length : 0}
          customScrollParent={shouldUseCustomVirtuosoScroller ? scrollParent : null}
          style={{ overflowY: scrollParent ? 'hidden' : 'auto', height: '100%' }}
          atBottomStateChange={shouldUseCustomVirtuosoScroller ? null : bottomHandler}
          atTopStateChange={topHandler}
          isScrolling={throttledScroll}
          endReached={shouldUseCustomVirtuosoScroller ? bottomHandler : null}
          listClassName={listClassName || 'virtuoso-grid-list-container'}
          itemClassName={itemClassName || 'virtuoso-grid-item-container'}
          data={data?.docs?.length > 0 ? data.docs : ['loader']}
          itemContent={data?.docs?.length > 0 ? virtuosoComponent(ItemComponent, data.docs) : Placeholder({ props: loaderProps, shouldShowPlaceholder })}
        />
      ) }
      { scrollToTopButton }
    </div>
  );
};

export default QueryList;
