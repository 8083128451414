import axios from 'axios';
import { setRecoil } from 'recoil-nexus';
import { addSnackbarSelector } from '../models/snackbar/selector';

class GiphyClient {
  constructor() {
    this.client = null;
    this.apiBaseUrl = 'https://api.giphy.com/v1/gifs';
    this.api_key = process.env.REACT_APP_GIPHY_KEY;
  }

  initClient = () => {
    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    });
  };

  buildQuery = (params) => {
    const query = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        query.push(`${key}=${value}`);
      }
    });
    return query.length > 0
      ? `?${query.join('&')}`
      : '';
  };

  sendRequest = async ({
    data, method, queryParams, path, headers, catchError = false, snackbarError = false,
  }) => {
    if (!this.client) {
      return {
        success: false,
        error: 'api client not found',
      };
    }
    const defaults = { api_key: this.api_key, rating: 'pg-13' };
    const query = queryParams ? this.buildQuery({ ...queryParams, ...defaults }) : this.buildQuery(defaults);

    try {
      const res = await this.client[method.toLowerCase()](`${this.apiBaseUrl}${path}${query}`, data, headers || {});
      console.log(`API Request- path:${path}`);
      return {
        success: res.status >= 200 && res?.status < 300,
        data: res?.data,
      };
    } catch (e) {
      if (snackbarError) {
        const message = snackbarError !== 'errorMessage' ? 'Something went wrong :(' : e.response?.data?.message || 'Something went wrong :(';
        setRecoil(addSnackbarSelector, { message, severity: 'error' });
      }
      if (!catchError) {
        throw new Error(e);
      }
      return {
        success: false,
        error: e.response?.data?.message || 'request failed',
      };
    }
  };

  getOffset = (page, limit) => ({ offset: (page * limit) });

  getTrending = async ({ limit = 50, page = 0 }) => {
    const offset = this.getOffset(page, limit);
    const res = await this.sendRequest({ path: '/trending', method: 'get', queryParams: { limit, ...offset } });
    if (res.success) return res;
    console.log('error', res);
  };

  getSearch = async ({ search, limit = 50, page = 0 }) => {
    if (search) {
      const offset = this.getOffset(page, limit);
      const res = await this.sendRequest({ path: '/search', method: 'get', queryParams: { limit, q: search, ...offset } });
      if (res.success) return res;
      console.log('error', res);
    }
  };
}

const GiphyClientInstance = new GiphyClient();

GiphyClientInstance.initClient();

export default GiphyClientInstance;
