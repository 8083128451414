import { Link } from 'react-router-dom';
import {
  SourceTypes,
} from '../../../lib/constants';
import { usePost } from '../../../models/post/usePost';

import { getPathToPost } from '../../../lib/getPathToPost';

export const CardLinkToPost = ({ _id }) => {
  const { data } = usePost(_id, { sourceType: SourceTypes.Post });

  if (!data) return null;
  return (
    <div className='text-sm text-primary-brand-large-text font-bold'>
      <Link to={getPathToPost(data, data.topic)}>
        {data.topic.textContent}
      </Link>
    </div>
  );
};
