import { SourceTypes } from '../../lib/constants';

export const getInitialPostId = ({
  sourceType,
  navigationType,
  initialPostInViewFromState,
  initialPostFromUrl,
  mainPostId,
  resetPid,
  latestReply,
}) => {
  // TODO: refine this for first load detection
  if (sourceType === SourceTypes.Post) {
    if (resetPid) return resetPid;
    if (navigationType === 'POP' && initialPostInViewFromState) return initialPostInViewFromState || mainPostId;
    if (initialPostFromUrl) return initialPostFromUrl || mainPostId;
    if (initialPostInViewFromState) return initialPostInViewFromState || mainPostId;
    return mainPostId;
  }
  if (sourceType === SourceTypes.Message) {
    // TODO: refine this for first load detection
    if (resetPid) return resetPid;
    if (initialPostFromUrl) return initialPostFromUrl || latestReply;
    return latestReply;
  }
};
