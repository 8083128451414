import { atom } from 'recoil';

const usersOnlineState = atom({
  key: 'usersOnlineState',
  default: {
    defaultValue: { users: {} },
  },
});

export default usersOnlineState;
