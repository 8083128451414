import {
  useCallback,
} from 'react';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  IconButton,
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CategoryIcon from '@mui/icons-material/Category';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import {
  AdminPaths,
} from '../../lib/constants';

const AdminNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToPath = useCallback((e, path) => {
    if (path === location.pathname) return;
    navigate(path);
  }, [location]);

  const isUsers = ([AdminPaths.Users].some(p => location.pathname.includes(p)) || location.pathname === AdminPaths.Admin);

  return (
    <nav className='admin-navbar w-full bg-s01dp h-12 tku-box-shadow z-10'>
      <div className='desktop:w-[550px] desktop:ml-auto desktop:mr-auto flex items-center h-12 justify-around space-x-2 px-1'>
        <IconButton
          size='medium'
          aria-label='admin-manage-users'
          aria-controls='menu-admin-appbar'
          color={isUsers ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Users)}
        >
          <PeopleAltIcon />
        </IconButton>
        <IconButton
          sx={{ display: 'none' }}
          size='medium'
          aria-label='admin-manage-settings'
          aria-controls='menu-admin-appbar'
          color={AdminPaths.Settings === location.pathname ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Settings)}
        >
          <SettingsIcon />
        </IconButton>

        <IconButton
          size='medium'
          aria-label='admin-manage-badges'
          aria-controls='menu-admin-appbar'
          color={AdminPaths.Badge === location.pathname ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Badge)}
        >
          <LoyaltyIcon />
        </IconButton>
        <IconButton
          size='medium'
          aria-label='admin-manage-categories'
          aria-controls='menu-admin-appbar'
          color={AdminPaths.Category === location.pathname ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Category)}
        >
          <CategoryIcon />
        </IconButton>

        <IconButton
          size='medium'
          aria-label='admin-manage-jobs'
          aria-controls='menu-admin-appbar'
          color={AdminPaths.Jobs === location.pathname ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Jobs)}
        >
          <WorkIcon />
        </IconButton>
        <IconButton
          size='medium'
          aria-label='admin-manage-analytics'
          aria-controls='menu-admin-appbar'
          color={AdminPaths.Analytics === location.pathname ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Analytics)}
        >
          <AnalyticsIcon />
        </IconButton>
        <IconButton
          size='medium'
          aria-label='admin-manage-developer'
          aria-controls='menu-admin-appbar'
          color={AdminPaths.Developer === location.pathname ? 'primary' : 'textPrimary'}
          onClick={(e) => navigateToPath(e, AdminPaths.Developer)}
        >
          <IntegrationInstructionsIcon />
        </IconButton>
      </div>
    </nav>
  );
};

export default AdminNavbar;
