import { useSetRecoilState } from 'recoil';
import { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import TopicSelect from '../TopicSelect';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import CategorySelect from '../CategorySelect';

const MergeTopic = ({
  mainContainerClassname,
  contentContainerClassname,
  handleConfirm,
  handleCancel,
  topicToMerge,
}) => {
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const [_mainTopic, setMainTopic] = useState(null);

  const [topicsToMerge, setTopicsToMerge] = useState(topicToMerge ? [topicToMerge] : []);

  const [_category, setCategory] = useState(null);

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          mainTopic: _mainTopic,
          topicsToMerge,
          newCategory: _category,
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: 'Merge topics',
        data: {
          description: { text: `${topicsToMerge.length} topic${topicsToMerge.length > 1 ? 's' : ''} will be merged into ${_mainTopic.slug}` },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, topicsToMerge, _mainTopic, _category]);

  const _handleCancel = useCallback(async () => {
    await handleCancel();
  }, [handleCancel]);

  return (
    <div className={`${mainContainerClassname} h-[430px] tablet:h-[500px] flex flex-col justify-between`}>
      <div>
        <h1 className='mb-2'>Merge Topics</h1>
        <TopicSelect
          className='mt-4'
          label='main topic'
          placeholder='main topic'
          setTopic={setMainTopic}
          topicsToExclude={topicsToMerge}
        />
        <TopicSelect
          className='mt-4'
          topicsToExclude={[_mainTopic]}
          label='topics to merge'
          placeholder='topics to merge'
          setTopics={setTopicsToMerge}
          selectType='multiple'
          topics={topicsToMerge}
        />
        <CategorySelect
          category={_category}
          sx={{ marginTop: '1rem' }}
          setCategory={setCategory}
          placeholder='new category (optional)'
          label='new category (optional)'
        />
      </div>
      <div className='flex justify-between mt-4 space-x-5'>
        <Button
          color='secondaryLight'
          variant='outlined'
          onClick={_handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!_mainTopic || !topicsToMerge.length}
          variant='contained'
          color='primary'
          onClick={_handleSubmit}
        >
          Merge Topics
        </Button>
      </div>
    </div>
  );
};

export default MergeTopic;
