import {
  Fab,
  Grid,
  Card,
  Checkbox,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { nanoid } from 'nanoid';
import AddIcon from '@mui/icons-material/Add';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useSetRecoilState } from 'recoil';
import { Wrapper, Content } from '../Containers';
import SimpleItemList from '../SimpleItemList';
import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import useHandleImageUpload from '../../hooks/useHandleImageUpload';
import ApiClientInstance from '../../clients/api';
import { addSnackbarSelector } from '../../models/snackbar/selector';

const badgeSize = '18px';

const badgeOrders = [1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

export const BadgeTypes = {
  Reputation: 'reputation',
  Badge: 'badge',
  Role: 'role',
};

const BadgeHeader = () => ((
  <div>
    <Grid className='items-center !mt-0 !mb-0' spacing={0} container>
      <Grid item xs={5}>
        <span className='text-sm'>display</span>
      </Grid>
      <Grid item xs={3}>
        <span className='text-sm'>enabled</span>
      </Grid>
      <Grid item xs={4}>
        <span className='text-sm'>actions</span>
      </Grid>
    </Grid>
  </div>
));

const BadgeDisplay = () => {
  const BadgeDisplayComponent = ({ item, index }) => {
    const {
      _id,
      image,
      name,
    } = item;

    const addSnackbar = useSetRecoilState(addSnackbarSelector);

    const handleEdit = useCallback(() => emitCustomEvent(CustomEvents.AdminItemEdit, { badge: item }), [_id]);

    const handleEnabled = useCallback(async (e) => {
      const { checked } = e.target;
      const res = await ApiClientInstance.sendRequest({
        method: 'PUT',
        path: `/admin/badge/${_id}`,
        data: { enabled: checked },
        catchError: true,
      });
      if (res.success) emitCustomEvent(CustomEvents.AdminListUpdate, { badge: res.data });
    }, [item._id]);

    const handleDelete = useCallback(() => {
      const handler = async () => {
        // TODO: make a real api call to delete the badge
        const res = await ApiClientInstance.sendRequest({
          method: 'delete',
          path: `/admin/badge/${item._id}`,
          catchError: true,
          snackbarError: 'errorMessage',
        });
        if (res.success) {
          addSnackbar({ message: `Badge ${item.name} successfully deleted.`, color: 'primary' });
          emitCustomEvent(CustomEvents.Rerender, { badge: res.data });
        }
      };

      emitCustomEvent(CustomEvents.ConfirmationDialog, {
        handler,
        text: `Are you sure you want delete the ${item.name} badge`,
        data: {
          description: {
            text: 'This action will remove the badge from the database and from all users who currently have it. This cannot be undone.',
          },
        },
      });
    }, [item._id]);

    return (
      <div className='pl-1 pr-2 py-1'>
        <div className='bg-s01dp'>
          <Grid className='py-2 items-center !mt-0 !mb-0' columnSpacing={3} container>
            <Grid item xs={5}>
              <div className='text-primary-text font-roboto flex items-center'>
                <div className='flex space-x-2 items-center'>
                  <span key={_id} className='ml-1 text-xs opacity-80'><img style={{ height: badgeSize, width: badgeSize }} src={image} alt={`${name}-badge-icon`} /></span>
                  <span className='text-sm'>{item?.name}</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Checkbox color='primaryLight' size='small' sx={{ padding: '2px 2px' }} onClick={handleEnabled} checked={item.enabled} />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleEdit} color='primaryLight' size='small'>Edit</Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={[BadgeTypes.Reputation, BadgeTypes.Role].includes(item.type)}
                onClick={handleDelete}
                color='secondaryLight'
                size='small'
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
  return BadgeDisplayComponent;
};

const AdminBadge = () => {
  const [badge, setBadge] = useState(null);
  const [editing, setEditing] = useState(false);
  const [originalEditingBadge, setOriginalEditingBadge] = useState(null);
  const [creating, setCreating] = useState(false);

  const handleCreateBadgeClick = useCallback(() => {
    setCreating(prev => !prev);
    setBadge({
      name: '',
      image: '',
      order: 50,
      type: BadgeTypes.Badge,
      enabled: true,
    });
  }, []);

  const handleOrderChange = useCallback((event) => {
    const { value } = event.target;
    setBadge(prev => ({ ...prev, order: value }));
  }, []);

  const handleTypeChange = useCallback((event) => {
    const { value } = event.target;
    setBadge(prev => ({ ...prev, type: value }));
  }, []);

  const handleNameChange = useCallback((event) => {
    const { value } = event.target;
    setBadge(prev => ({ ...prev, name: value }));
  }, []);

  const handleEnabledChange = useCallback((event) => {
    const { checked } = event.target;
    setBadge(prev => ({ ...prev, enabled: checked }));
  }, []);

  const handleEditing = useCallback(({ badge: _badge }) => {
    setEditing(true);
    setBadge(_badge);
    setOriginalEditingBadge(_badge);
  }, []);

  const handleCancel = useCallback(() => {
    setEditing(false);
    setCreating(false);
    setBadge(null);
    setOriginalEditingBadge(null);
  }, []);

  const handleImageChange = useCallback(({ url }) => {
    setBadge(prev => ({ ...prev, image: url }));
  }, []);

  const handleImageUpload = useHandleImageUpload({
    uploadFolder: 'badge',
    uploadTitle: `${badge?.name ? `${badge.name}_` : ''}${nanoid(6)}`,
    callback: handleImageChange,
    shouldResize: false,
  });

  const handleReset = useCallback(() => {
    setBadge(originalEditingBadge);
  }, [originalEditingBadge]);

  const handleSubmit = useCallback(() => {
    let req = { data: { ...badge } };
    if (creating) {
      req = {
        method: 'post',
        path: '/admin/badge',
        ...req,
      };
    } else {
      req = {
        method: 'put',
        path: `/admin/badge/${badge._id}`,
        ...req,
      };
    }
    const handler = async () => {
      await ApiClientInstance.sendRequest(req);
      handleCancel();
    };
    const text = editing ? `Save modifications for ${badge.name}` : `Create ${badge.name}`;
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text });
  }, [creating, editing, JSON.stringify(badge), handleCancel]);

  const handleBadgeUpdate = useCallback(async ({ badge: _badge }, items, setItems) => {
    const _items = items.map(item => {
      if (item._id === _badge._id) item = { ..._badge };
      return item;
    });
    setItems(_items);
  }, []);

  useCustomEvent(CustomEvents.AdminItemEdit, handleEditing);

  const badgeList = useMemo(() => (
    <div style={{ height: 'calc(100% - 80px)' }} className='badge-list'>
      <SimpleItemList
        path='/badge'
        ItemComponent={BadgeDisplay()}
        sort={null}
        showErrorMessage
        eventName={CustomEvents.AdminListUpdate}
        updater={handleBadgeUpdate}
      />
    </div>
  ), []);

  const createBadge = useMemo(() => (
    <div className='badge-edit tablet:w-[550px] px-3'>
      <h2 className='text-3xl mt-2 mb-2 font-bebas'>{editing ? `Edit ${badge.name} Badge` : 'Create Badge'}</h2>
      <Card className='px-2 py-3'>
        <div className='flex justify-between'>
          <TextField InputLabelProps={{ shrink: true }} value={badge?.name} onChange={handleNameChange} size='small' label='Name' />
          <FormControl>
            <InputLabel id='badge-order-select-label'>Order</InputLabel>
            <Select
              size='small'
              labelId='badge-order-select-label'
              id='badge-order-select'
              value={badge?.order}
              label='order'
              onChange={(handleOrderChange)}
            >
              {badgeOrders.map((bo) => (
                <MenuItem key={bo} value={bo}>{bo}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className='flex justify-between space-x-10 mt-5'>
          <FormControl sx={{ width: '200px' }}>
            <InputLabel id='badge-type-select-label'>Type</InputLabel>
            <Select
              size='small'
              labelId='badge-type-select-label'
              id='badge-type-select'
              value={badge?.type}
              label='type'
              onChange={(handleTypeChange)}
            >
              {Object.values(BadgeTypes).map((bt) => (
                <MenuItem key={bt} value={bt}>{bt}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className='flex items-center'>
            <span className='mr-2'>enabled</span>
            <Checkbox color='primaryLight' size='small' sx={{ padding: '2px 2px' }} onClick={handleEnabledChange} checked={badge?.enabled} />
          </div>
        </div>
        <div className='mt-5'>

          { badge?.image && (
            <div className='flex justify-start items-center mt-5'>
              <span className='text-sm opacity-80'><img style={{ height: '30px', maxWidth: '30px' }} src={badge?.image} alt={`${badge?.name}-badge-icon`} /></span>
              <span className='text-md ml-3'>{badge?.name}</span>
            </div>
          )}
          <label className='file-input-label' htmlFor='file-input'>
            <input accept='image/*' onChange={handleImageUpload} id='file-input' style={{ display: 'none' }} type='file' />
            <Button
              sx={{ marginTop: '10px' }}
              startIcon={<AddPhotoAlternateIcon />}
              size='small'
              color='primaryLight'
              component='span'
            >
              {`${badge?.image ? 'change' : 'add'} image`}
            </Button>
          </label>

        </div>
        <div className='flex justify-end mt-5 space-x-3'>
          <Button color='secondaryLight' variant='outlined' size='medium' onClick={handleCancel}>Cancel</Button>
          { editing && <Button disabled={JSON.stringify(badge) === JSON.stringify(originalEditingBadge)} color='primaryLight' variant='outlined' size='medium' onClick={handleReset}>Reset</Button> }
          <Button
            disabled={!badge?.name || !badge?.image || !badge.order || !badge.type || (JSON.stringify(badge) === JSON.stringify(originalEditingBadge))}
            color='primary'
            variant='contained'
            size='medium'
            onClick={handleSubmit}
          >
            {editing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Card>
    </div>
  ), [badge, originalEditingBadge]);

  const createButton = useMemo(() => (
    <div className='absolute bottom-3 right-3'>
      <Fab
        onClick={handleCreateBadgeClick}
        variant='extended'
        color='primary'
      >
        Create Badge
        <AddIcon />
      </Fab>
    </div>
  ), [handleCreateBadgeClick]);

  return (
    <Wrapper applyBreakpoints className='badges relative'>
      { (!creating && !editing) && (
      <div className='pt-2 space-x-2 justify-between items-center text-primary-text'>
        <h1 className='pl-2 text-3xl font-bebas my-3 text-primary-text'>Badges</h1>
        <BadgeHeader />
      </div>
      ) }
      <Content className='text-primary-text font-roboto'>
        {(!creating && !editing) && createButton}
        {(!editing && !creating) && badgeList}
        {(creating || editing) && createBadge}
      </Content>
    </Wrapper>
  );
};

export default AdminBadge;
