import {
  memo, useCallback, useState,
} from 'react';
import { ClickAwayListener, Popper } from '@mui/material';

import { useRecoilValue } from 'recoil';
import { CustomEvents, PopoverHeight, QueryKeys } from '../../lib/constants';

import QueryList from '../QueryList';
import Notification from '../Notification';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { NotificationModel } from '../../models/notification/model';
import notificationListStatusKeyState from '../../models/notificationListStatusKey/atom';
import notificationListSortKeyState from '../../models/notificationListSortKey/atom';
import NotificationsListHeader from '../NotificationsListHeader';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const MemoNotificationsListHeader = memo(NotificationsListHeader);

// eslint-disable-next-line react/jsx-props-no-spreading
const ItemComponent = ({ item, index }) => <Notification index={index} {...item} />;

const EmptyComponent = () => (<div className='flex pt-10 justify-center items-center font-roboto text-lg'>Nothing to see here 😎</div>);

const onRequestSuccess = (items) => {
  for (const item of items) NotificationModel.setNotification(item._id, item);
};

const NotificationsContainer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [_offset, setOffset] = useState([0, 4]);
  const [_placement, setPlacement] = useState('top-start');
  const notificationListStatusKey = useRecoilValue(notificationListStatusKeyState);
  const notificationListSortKey = useRecoilValue(notificationListSortKeyState);
  const { data: authenticatedUser } = useAuthenticatedUser();

  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorEl(null);
    setOffset([0, 4]);
    setPlacement('top-start');
    NotificationModel.updateUnseenNotificationsStatusToUnread();
  }, []);

  const handleOpen = useCallback(async ({
    elementId,
    elementSelector,
    placement = 'top-start',
    offset = [0, 4],
  }) => {
    if (!elementId && !elementSelector) return null;
    const notificationsElement = elementId ? document.querySelector(`#${elementId}`) : document.querySelector(elementSelector);
    if (!notificationsElement) return null;
    setAnchorEl(notificationsElement);
    setPlacement(placement);
    if (offset) setOffset(offset);
    await NotificationModel.loadNotificationCounts();
    setOpen(true);
  }, []);

  const handleEvent = useCallback((data) => {
    if (data?.close) return handleClose();
    if (!open) return handleOpen(data);
    if (open) return handleClose();
  }, [handleOpen, handleClose, open]);

  const handleClickAway = useCallback((e) => {
    if (e.target.closest('.prevent-clickaway-notifications') !== null) return null;
    handleClose();
  }, []);

  useCustomEvent(CustomEvents.ToggleNotifications, handleEvent, []);

  if (!open || !anchorEl) return null;

  return (
    <Popper
      className='bg-surface rounded w-full desktop:w-[660px]'
      style={{ zIndex: 1000, maxHeight: '80%', overflow: 'auto' }}
      modifiers={[{ name: 'offset', options: { offset: _offset } }]}
      placement={_placement}
      id='notifications-container'
      open={open}
      anchorEl={anchorEl}
      keepMounted={false}
    >
      <MemoNotificationsListHeader />
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          style={{
            height: PopoverHeight.Mobile,
            overflow: 'auto',
          }}
          className='notifications bg-s02dp  text-primary-text font-roboto text-sm relative overflow-auto'
        >
          <QueryList
            requestPath='/notification'
            requestSort={notificationListSortKey}
            requestParams={{ status: notificationListStatusKey }}
            ItemComponent={ItemComponent}
            queryKey={[QueryKeys.Notifications, authenticatedUser?.username, notificationListSortKey, notificationListStatusKey]}
            queryEnabled
            loaderProps={{ boxType: 'fullContainer' }}
            shouldShowPlaceholder={false}
            customScrollParentSelector='.notifications'
            shouldShowBackButton={false}
            onRequestSuccess={onRequestSuccess}
            EmptyComponent={<EmptyComponent />}
            shouldShowEmptyComponent
            shouldShowErrorComponent
          />
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

export default NotificationsContainer;
