import { useMemo, useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { useFirstAndLastPost } from '../../models/paginatedScrollerState/useFirstAndLastPost';
import postInViewState from '../../models/postInView/atom';

export const usePostInView = ({
  topicId,
  cardRef,
  postId,
}) => {
  const setCurrentPost = useSetRecoilState(postInViewState(topicId));
  const { firstPost, lastPost } = useFirstAndLastPost({ topicId });
  const isFirstPostinTopic = useMemo(() => firstPost?._id === postId, [firstPost?._id, postId, topicId]);
  const isLastPostinTopic = useMemo(() => lastPost?._id === postId, [lastPost?._id, postId, topicId]);
  const entry = useIntersectionObserver(cardRef, { threshold: 1, root: document.querySelector('.item-list-scroller') });

  useLayoutEffect(() => {
    if (!entry) return;
    const {
      isIntersecting,
    } = entry;
    if (isFirstPostinTopic || isLastPostinTopic) return setCurrentPost(postId);
    if (isIntersecting) setCurrentPost(postId);
  }, [entry, setCurrentPost, topicId]);
};
