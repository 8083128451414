/* eslint-disable react/jsx-props-no-spreading */
import { nanoid } from 'nanoid';
import {
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { TakeoverTypes } from './constants';

import Protected from '../components/Protected';
import { emitConfirmationDialog } from './emitters/emitConfirmationDialog';
import { emitTakeoverDialog } from './emitters/emitTakeoverDialog';
import { emitTakeoverModal } from './emitters/emitTakeoverModal';

const buildList = ({
  listData,
  listSx,
  listDense,
  listItemProps = {},
  listProps = {},
}) => {
  const items = [];
  listData.forEach((item, i) => {
    if (item?.exclude) return null;
    const key = nanoid(15);
    const {
      handler,
      Icon,
      text,
      confirmationComponent,
      confirmationData,
      divider,
      isProtected,
      requirements,
      condition,
      Component,
      confirmationType,
    } = item;
    if (divider) {
      items.push(<Divider variant='middle' sx={{ ':last-child': { display: 'none' }, ':first-child': { display: 'none' } }} key={key} />);
      return;
    }
    if (!!Component) {
      items.push(<Component key={key} />);
      return;
    }
    let onClick;
    const eventData = {
      handler,
      text,
      data: confirmationData,
      component: confirmationComponent,
    };
    switch (confirmationType) {
      case TakeoverTypes.Modal:
        onClick = () => emitTakeoverModal(eventData);
        break;
      case TakeoverTypes.Component:
        onClick = () => emitTakeoverDialog(eventData);
        break;
      case TakeoverTypes.Confirmation:
        onClick = () => emitConfirmationDialog(eventData);
        break;
      default:
        onClick = handler;
        break;
    }
    let listItem = (
      <ListItem
        className='prevent-clickaway prevent-clickaway-messages'
        key={key}
        button
        onClick={onClick}
        {...listItemProps}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
    if (!!isProtected && !!requirements) {
      listItem = (
        <Protected key={key} requirements={requirements}>
          {listItem}
        </Protected>
      );
    }
    if (!condition || (condition && condition())) {
      items.push(listItem);
    }
  });
  return (
    <List {...listProps} dense={listDense} sx={listSx}>
      {items}
    </List>
  );
};

export default buildList;
