import { getRecoil, setRecoil } from 'recoil-nexus';
import { BaseModel } from '../BaseModel';
import * as PostApi from './api';
import { UserModel } from '../user/model';
import state from './atom';

const getTime = () => new Date().getTime();

export class PostModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => PostModel.requestPost(id);
    super(id, state, request);
    this.id = id;
  }

  static requestPost(id) {
    if (!id) return;
    return PostApi.requestPost(id);
  }

  static setPost(id, data) {
    if (!id || !data) return;
    if (data.author?.username && data.author?._id) {
      UserModel.setUsernameMapping(!data.author.username, data.author._id);
      UserModel.setUserPartial(data.author._id, data.author);
    }
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  static getPost(id) {
    if (!id) return;
    return getRecoil(state(id)).data;
  }

  vote(delta) {
    if (!this.id) return;
    return PostApi.vote(this.id, delta);
  }

  save() {
    if (!this.id) return;
    return PostApi.save(this.id);
  }

  unsave() {
    if (!this.id) return;
    return PostApi.unsave(this.id);
  }

  getReplies() {
    if (!this.id) return;
    return PostApi.getReplies(this.id);
  }

  getVotes = (voteType) => {
    if (!this.id) return;
    return PostApi.getVotes(this.id, voteType);
  };

  onSetState = (postState, prevPostState) => {
    if (!postState?.data?.author?.username || !postState.data?._id) return;
    if (postState.data?.author?.username) {
      UserModel.setUsernameMapping(postState.data.author.username, postState.data.author._id);
      UserModel.setUserPartial(postState.data.author._id, postState.data.author);
    }
  };
}
