import {
  useCallback,
  useMemo,
} from 'react';
import {
  TextField,
  InputAdornment,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState, useRecoilValue } from 'recoil';
import { breakpointNameSelector } from '../../models/settings/selectors';
import { navSearchActiveSelector, searchQuerySelector } from '../../models/search/selectors';
import { Breakpoints } from '../../lib/constants';
import { useSearchOnQueryChange } from '../../models/search/useSearchOnQueryChange';

const NavbarSearch = () => {
  const breakpointName = useRecoilValue(breakpointNameSelector);
  const [navSearchActive, setNavSearchActive] = useRecoilState(navSearchActiveSelector);
  const [query, setQuery] = useRecoilState(searchQuerySelector);

  useSearchOnQueryChange(400);

  const handleChange = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  const handleClickAaway = useCallback((e) => {
    if (e.target.id === 'nav-search-input') return;
    if (query) return;
    if (e.target.closest('.prevent-clickaway-search-results') !== null) return null;
    setNavSearchActive(false);
  }, [query]);

  const handleBlur = useCallback((_query) => (e) => {
    if (e.target.id === 'nav-search-input') return;
    if (_query) return;
    setNavSearchActive(false);
  }, []);

  const handleCloseClick = useCallback((_query) => () => {
    if (_query) setQuery('');
    if (!_query)setNavSearchActive(false);
  }, []);

  const nonMobileWidth = useMemo(() => {
    switch (breakpointName) {
      case Breakpoints.Tablet:
        return navSearchActive ? '250px' : '175px';
      case Breakpoints.Desktop:
      case Breakpoints.WideDesktop:
        return navSearchActive ? '300px' : '175px';
      default:
        return '175px';
    }
  }, [breakpointName, navSearchActive]);

  const inputSx = useMemo(() => {
    let sx = {
      '& .MuiOutlinedInput-root': {
        height: '35px',
      },
    };
    if (breakpointName === Breakpoints.Mobile) {
      sx = {
        ...sx,
        marginLeft: '6px',
        marginRight: '6px',
      };
    } else {
      sx = {
        ...sx,
        marginRight: '15px',
        width: nonMobileWidth,
        transition: 'width .25s ease-in-out',
      };
    }
    return sx;
  }, [breakpointName, nonMobileWidth]);

  const input = useMemo(() => (
    <TextField
      id='nav-search-input'
      value={query}
      onChange={handleChange}
      size='small'
      fullWidth={breakpointName === Breakpoints.Mobile}
      focused={navSearchActive}
      autoFocus={navSearchActive}
      color='primaryLight'
      onFocus={() => setNavSearchActive(true)}
      onBlur={breakpointName === Breakpoints.Mobile ? handleBlur(query) : handleClickAaway}
      className='hidden tablet:inline-block'
      placeholder='Search 2KU'
      sx={inputSx}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon className='opacity-80' color={navSearchActive ? 'primaryLight' : 'text-primary-text'} />
          </InputAdornment>
        ),
        endAdornment: navSearchActive
          ? (
            <InputAdornment
              position='end'
            >
              <CloseIcon
                color='primaryText'
                sx={{
                  cursor: 'pointer',
                  height: '20px',
                  opacity: '.8',
                }}
                onClick={handleCloseClick(query)}
              />
            </InputAdornment>
          )
          : null,
      }}
    />
  ), [breakpointName, navSearchActive, handleChange, handleBlur, handleClickAaway, query, inputSx]);

  if (!breakpointName) return null;

  if (breakpointName !== Breakpoints.Mobile) {
    return (
      <ClickAwayListener onClickAway={handleClickAaway}>
        { input }
      </ClickAwayListener>
    );
  }

  if (breakpointName === Breakpoints.Mobile && !navSearchActive) {
    return (
      <div className='tablet:hidden flex'>
        <IconButton
          onClick={() => {
            setNavSearchActive(true);
          }}
          size='small'
        >
          <SearchIcon color='primaryLight' />
        </IconButton>
      </div>
    );
  }

  if (breakpointName === Breakpoints.Mobile && navSearchActive) return input;

  return null;
};

export default NavbarSearch;
