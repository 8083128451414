/* eslint-disable react/jsx-props-no-spreading */
import { getRecoil, setRecoil } from 'recoil-nexus';
import { format } from 'date-fns';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MergeIcon from '@mui/icons-material/Merge';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import ApiClient from '../../clients/api';

import {
  CustomEvents,
  UserEmailStatus,
  UserRoles,
  UserStatus,
  ConfirmationDialogueClasses,
  TakeoverTypes,
  ComposerNodeTypes,
  SourceTypes,
  QueryKeys,
} from '../../lib/constants';

import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';

import MergeTopic from '../MergeTopic';
import ChangeTopicCategory from '../ChangeTopicCategory';
import ChangeAuthor from '../ChangeAuthor';
import SuspendUser from '../SuspendUser';
import SilenceUser from '../SilenceUser';
import TopicPin from '../TopicPin';

import { TopicModel } from '../../models/topic/model';
import { UserModel } from '../../models/user/model';
import { PostModel } from '../../models/post/model';
import { PrivatePostModel } from '../../models/privatePost/model';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import {
  editorCurrentPostEditSelector,
  editorCurrentReplySelector,
  editorIsActiveSelector,
  editorIsModeratorPostSelector,
} from '../../models/editor/selectors';
import authenticatedUserState from '../../models/authenticatedUser/atom';
import { userVerifiedSelector } from '../../models/authenticatedUser/selectors';

const eventHandler = (eventName, data, toggle) => {
  emitCustomEvent(eventName, data);
  toggle();
};

const buildListData = (
  editMenuData,
  goToPath,
  sourceType,
  handlers,
  toggle,
) => {
  const { data: authenticatedUser } = getRecoil(authenticatedUserState);
  const verified = getRecoil(userVerifiedSelector);

  const {
    post,
    topic,
    author,
  } = editMenuData;

  let __sourceItem = {};

  if (sourceType === SourceTypes.Post) __sourceItem = post;
  if (sourceType === SourceTypes.Topic) __sourceItem = topic;
  if (sourceType === SourceTypes.Message) __sourceItem = post;
  if (sourceType === SourceTypes.User) __sourceItem = author;

  let topicModel;
  let postModel;
  let userModel;
  let privatePostModel;

  if (topic) topicModel = new TopicModel(topic._id);
  if (post && sourceType === SourceTypes.Post) postModel = new PostModel(post._id);
  if (post && sourceType === SourceTypes.Message) privatePostModel = new PrivatePostModel(post._id);
  if (author) userModel = new UserModel(author._id);

  const isMain = post?.isMain;

  const {
    username,
    _id: authorId,
    status: userStatus,
    banned,
    emailStatus,
    approved,
  } = author;

  const removeUserFromMessage = {
    id: 'removeUserFromMessage',
    exclude: sourceType !== 'message',
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: async () => {
      try {
        toggle();
        // await ApiClient.sendRequest({ method: 'put', path: `/admin/user/ban/${authorId}` });
        // if (sourceType === SourceTypes.User) emitCustomEvent(CustomEvents.Rerender);
        setRecoil(addSnackbarSelector, { message: `${username} has been removed.`, color: 'primary' });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: RemoveCircleIcon,
    text: `Ban ${username}`,
  };

  // GENERAL
  const copyLink = {
    id: 'copyLink',
    exclude: sourceType === SourceTypes.Message,
    handler: async () => {
      let text = '';
      if (sourceType === SourceTypes.Topic) {
        text = `${window.location.origin}/topic/${topic?.shortId}/${topic?.slug}`;
      }
      if (sourceType === SourceTypes.Post) {
        text = `${window.location.origin}/topic/${topic?.shortId}/${topic?.slug}?pid=${post?._id}`;
      }
      if (sourceType === SourceTypes.User) {
        text = `${window.location.origin}/users/${username}`;
      }
      try {
        await navigator.clipboard.writeText(text);
        setRecoil(addSnackbarSelector, {
          message: 'Permalink copied successfully!',
          color: 'primary',
        });
        toggle();
      } catch (e) {
        setRecoil(addSnackbarSelector, {
          message: `Error copying link: ${e.message}`,
          severity: 'error',
        });
        toggle();
      }
    },
    Icon: ContentCopyIcon,
    text: 'Copy permalink',
  };

  const saveItem = {
    id: 'saveItem',
    exclude: __sourceItem?.isSaved || sourceType === SourceTypes.Message,
    isProtected: true,
    requirements: { authenticated: true },
    handler: async () => {
      const isTopic = sourceType === SourceTypes.Topic || isMain;
      const model = isTopic ? topicModel : postModel;
      const res = await model.save();
      if (res.success) {
        setRecoil(addSnackbarSelector, { message: `${sourceType} saved successfully!`, color: 'primary' });
        model.fetch();
      } else setRecoil(addSnackbarSelector, { message: `${sourceType} failed to save`, color: 'error' });
      toggle();
    },
    Icon: BookmarkAddIcon,
    text: `Save ${sourceType}`,
  };

  const changeAuthor = {
    id: 'changeAuthor',
    isProtected: true,
    exclude: ![SourceTypes.Topic, SourceTypes.Post].includes(sourceType),
    requirements: { roles: [UserRoles.SuperAdmin] },
    handler: async ({ _user }) => {
      try {
        const res = await ApiClient.sendRequest({
          method: 'put',
          path: `/admin/${sourceType.toLowerCase()}/author/${__sourceItem._id}`,
          data: { authorId: _user?._id },
          catchError: true,
          snackbarError: 'errorMessage',
        });
        if (res.success) {
          setRecoil(addSnackbarSelector, {
            message: `${sourceType} author has been successfully changed from ${author?.username} to ${_user.username}.`,
            color: 'primary',
          });
          toggle();
        }
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
    },
    confirmationType: TakeoverTypes.Component,
    Icon: SupervisedUserCircleIcon,
    confirmationComponent: (props) => (
      <ChangeAuthor
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        author={author}
      />
    ),
    text: `Change ${sourceType.toLowerCase()} author`,
  };

  const unsaveItem = {
    id: 'unsave',
    exclude: !__sourceItem?.isSaved || sourceType === SourceTypes.Message,
    isProtected: true,
    requirements: { authenticated: true },
    handler: async () => {
      const isTopic = sourceType === SourceTypes.Topic || isMain;
      const model = isTopic ? topicModel : postModel;
      const res = await model.unsave();
      if (res.success) {
        setRecoil(addSnackbarSelector, { message: `${sourceType} unsaved`, color: 'primary' });
        model.fetch();
      } else setRecoil(addSnackbarSelector, { message: `${sourceType} failed to unsave`, color: 'error' });
      toggle();
    },
    Icon: BookmarkRemoveIcon,
    text: `Unsave ${sourceType}`,
  };

  // TOPIC
  const hideTopic = {
    id: 'hideTopic',
    exclude: sourceType !== SourceTypes.Topic,
    isProtected: true,
    requirements: { authenticated: true },
    Icon: VisibilityOff,
    confirmationType: TakeoverTypes.Confirmation,
    text: 'Hide Topic',
    handler: async () => {
      const res = await topicModel.hide();
      if (res.success) {
        emitInvalidateQueries({ queryKey: [QueryKeys.Topics] });
        setRecoil(addSnackbarSelector, { message: 'Topic hidden', color: 'primary' });
      } else setRecoil(addSnackbarSelector, { message: 'Error hiding topic', color: 'error' });
      toggle();
    },
  };

  const mergeTopic = {
    id: 'mergeTopic',
    isProtected: true,
    exclude: sourceType !== SourceTypes.Topic,
    requirements: { roles: [UserRoles.Moderator, UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: async ({ mainTopic, topicsToMerge, newCategory }) => {
      if (!mainTopic || !topicsToMerge) return toggle();
      const data = await ApiClient.sendRequest({
        method: 'put',
        path: '/admin/topic/merge',
        data: {
          mainTopic: mainTopic?._id,
          topicsToMerge: topicsToMerge.map(_topic => _topic._id),
          newCategory: newCategory?._id,
        },
        catchError: true,
        snackbarError: 'errorMessage',
      });
      if (data.success) {
        const message = `${topicsToMerge.length} topic${topicsToMerge.length > 1 ? 's' : ''} ${topicsToMerge.length > 1 ? 'have' : 'has'} been merged into ${mainTopic.slug}${newCategory ? ` and the category has been changed to ${newCategory.name}` : '.'}.`;
        setRecoil(addSnackbarSelector, { message, color: 'primary' });
      }
      toggle();
    },
    confirmationData: {
      fullWidth: true,
      maxWidth: 'sm',
    },
    Icon: MergeIcon,
    confirmationType: TakeoverTypes.Component,
    confirmationComponent: (props) => (
      <MergeTopic
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        topicToMerge={{ _id: topic?._id, slug: topic?.slug }}
      />
    ),
    text: `Merge ${sourceType}`,
  };

  const changeTopicCategory = {
    id: 'changeTopicCategory',
    isProtected: true,
    exclude: sourceType !== SourceTypes.Topic,
    requirements: { roles: [UserRoles.Moderator, UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: async ({ _category }) => {
      try {
        const res = await ApiClient.sendRequest({
          method: 'put',
          path: `/admin/topic/category/${topic?._id}`,
          data: { categoryId: _category?._id },
          catchError: true,
          snackbarError: 'errorMessage',
        });
        if (res.success) {
          setRecoil(addSnackbarSelector, {
            message: `${topic?.slug} has been moved to ${_category?.name}.`,
            color: 'primary',
          });
        }
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
    },
    confirmationType: TakeoverTypes.Component,
    Icon: ChangeCircleIcon,
    confirmationComponent: (props) => (
      <ChangeTopicCategory
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        category={topic?.category}
      />
    ),
    text: 'Change topic category',
  };

  const lock = {
    id: 'lock',
    exclude: sourceType !== SourceTypes.Topic,
    condition: () => !topic?.isLocked,
    isProtected: true,
    requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      try {
        await topicModel.lock();
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} locked successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, {
          message: `Error locking: ${e.message}`,
          severity: 'error',
        });
      }

      toggle();
    },
    Icon: LockIcon,
    confirmationType: TakeoverTypes.Confirmation,
    text: `Lock ${sourceType}`,
  };

  const unlock = {
    id: 'unlock',
    exclude: sourceType !== SourceTypes.Topic,
    condition: () => topic?.isLocked,
    isProtected: true,
    requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      try {
        await topicModel.unlock();
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} unlocked successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, {
          message: `Error unlocking: ${e.message}`,
          severity: 'error',
        });
      }
      toggle();
    },
    Icon: LockOpenIcon,
    text: `Unlock ${sourceType}`,
  };

  const pinTopic = {
    id: 'pin',
    exclude: sourceType !== SourceTypes.Topic,
    isProtected: true,
    requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async ({
      active,
      type,
      expires,
    }) => {
      try {
        const res = await topicModel.pin({ active, type, expires });
        if (res.success) {
          const message = active ? `Successfully pinned ${topic?.slug}!` : `Successfully unpinned ${topic?.slug}!`;
          setRecoil(addSnackbarSelector, { message, color: 'primary' });
        }
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: 'Something went wrong when trying to pin this topic. Please try again.', severity: 'error' });
      }
      toggle();
    },
    confirmationType: TakeoverTypes.Component,
    confirmationComponent: (props) => (
      <TopicPin
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        sourceType={sourceType}
        topicId={topic?._id}
      />
    ),
    Icon: PushPinIcon,
    text: `Update ${sourceType} pin`,
  };

  // USER
  const banUser = {
    id: 'banUser',
    exclude: banned,
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: async () => {
      try {
        toggle();
        userModel.banUser();
        setRecoil(addSnackbarSelector, { message: `${username} has been banned.`, color: 'primary' });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: RemoveCircleIcon,
    text: `Ban ${username}`,
  };

  const unbanUser = {
    id: 'unbanUser',
    exclude: !banned,
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: async () => {
      try {
        toggle();
        userModel.unbanUser();
        setRecoil(addSnackbarSelector, { message: `${username} has been unbanned.`, color: 'primary' });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: RemoveCircleIcon,
    text: `Unban ${username}`,
  };

  const approveUser = {
    id: 'approveUser',
    requirements: {
      roles: [UserRoles.SuperAdmin, UserRoles.Admin],
    },
    exclude: approved,
    handler: async () => {
      const res = await userModel.approveUser();
      if (res.success) {
        toggle();
        emitInvalidateQueries({ queryKey: QueryKeys.Users });
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} approved successfully!`,
          color: 'primary',
        });
      } else setRecoil(addSnackbarSelector, { message: 'An error occurred. Please try again.', severity: 'error' });
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: DeleteForeverIcon,
    text: `Approve ${username}`,
  };

  const reinstateUser = {
    id: 'reinstateUser',
    exclude: userStatus === UserStatus.Good || (userStatus !== UserStatus.Silenced && authenticatedUser.role === UserRoles.Moderator),
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      try {
        userModel.reinstateUser();
        toggle();
        setRecoil(addSnackbarSelector, {
          message: `${username} reinstated successfully.`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: NoAccountsIcon,
    text: `Remove ${username}'s ${userStatus} status`,
  };

  const verifyEmail = {
    id: 'verifyEmail',
    requirements: {
      roles: [UserRoles.SuperAdmin, UserRoles.Admin],
    },
    exclude: emailStatus === UserEmailStatus.Verified,
    handler: async () => {
      const res = await userModel.verifyOtherUserEmail();
      if (res.success) {
        toggle();
        setRecoil(addSnackbarSelector, {
          message: `${username}'s email verified successfully`,
          color: 'primary',
        });
        emitInvalidateQueries({ queryKey: QueryKeys.Users });
      } else setRecoil(addSnackbarSelector, { message: 'An error occurred. Please try again.', severity: 'error' });
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: DeleteForeverIcon,
    text: `Verify ${username}'s email`,
  };

  const viewProfile = {
    id: 'viewProfile',
    handler: () => goToPath(`/users/${username}`),
    Icon: AccountCircleIcon,
    text: `View ${username}'s profile`,
  };

  const authorIsBlocked = () => {
    if (!author || !authenticatedUser || !authenticatedUser?.blocked || !authenticatedUser?.blocked?.length) return false;
    return !!authenticatedUser.blocked.find((bu => bu._id === author._id));
  };

  const messageUser = {
    id: 'messageUser',
    exclude: sourceType === SourceTypes.Message || authorIsBlocked() || !verified,
    isProtected: true,
    requirements: { notSelf: username, authenticated: true },
    handler: () => {
      goToPath(`/messages/create?uids=${author._id}`);
    },
    Icon: ChatIcon,
    text: `Message ${username}`,
  };

  const silenceUser = {
    id: 'silenceUser',
    exclude: userStatus !== UserStatus.Good,
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async ({
      _user,
      minutes,
    }) => {
      const res = await userModel.silenceUser(minutes);
      if (res.success) {
        const message = `${_user.username} has been silenced for ${minutes} minutes`;
        setRecoil(addSnackbarSelector, { message, color: 'primary' });
        toggle();
      }
    },
    confirmationType: TakeoverTypes.Component,
    confirmationComponent: (props) => (
      <SilenceUser
        initialUser={author}
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        sourceType={sourceType}
      />
    ),
    Icon: HourglassDisabledIcon,
    text: `Silence ${username}`,
  };

  const suspendUser = {
    id: 'suspendUser',
    exclude: userStatus === UserStatus.Suspended,
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: async ({
      _user,
      suspensionDate,
    }) => {
      const res = await userModel.suspendUser(suspensionDate);
      if (res.success) {
        const message = `${_user.username} has been suspended until ${format(suspensionDate, 'MM/dd/yy')}`;
        setRecoil(addSnackbarSelector, { message, color: 'primary' });
      }
    },
    confirmationType: TakeoverTypes.Component,
    confirmationComponent: (props) => (
      <SuspendUser
        initialUser={author}
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        sourceType={sourceType}
      />
    ),
    Icon: NoAccountsIcon,
    text: `Suspend ${username}`,
  };

  const resetUserLoginAttempts = {
    id: 'resetUserLoginAttempts',
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin] },
    handler: async () => {
      const res = await userModel.resetUserLoginAttempts();
      if (res.success) setRecoil(addSnackbarSelector, { message: res.data.message, color: 'primary' });
      toggle();
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: LockOpenIcon,
    text: `Reset ${username}'s login attempts`,
  };

  const manageUser = {
    id: 'manageUser',
    isProtected: true,
    requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin] },
    handler: () => goToPath(`/admin/users/${username}`),
    text: `Manage ${username}'s account`,
    Icon: ManageAccountsIcon,
  };

  // POST/OTHER

  const pinPost = {
    id: 'pin',
    exclude: sourceType === SourceTypes.Topic || (sourceType === SourceTypes.Post && isMain) || sourceType === SourceTypes.Message,
    condition: () => !post?.pinned,
    isProtected: true,
    requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      try {
        if (sourceType === SourceTypes.Post) {
          await ApiClient.sendRequest({ method: 'put', path: `/admin/post/pin/id/${post?._id}` });
        }
        if (sourceType === SourceTypes.Message) {
          await ApiClient.sendRequest({ method: 'put', path: `/admin/post/pin/id/${post?._id}` });
        }
        setRecoil(addSnackbarSelector, { message: `${sourceType} pinned successfully.`, color: 'primary' });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
      toggle();
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: PushPinIcon,
    text: `Pin ${sourceType}`,
  };

  const flagPost = {
    id: 'flag',
    isProtected: true,
    requirements: { notSelf: username },
    handler: () => console.log('flagging post', post?._id),
    Icon: FlagIcon,
    confirmationType: TakeoverTypes.Confirmation,
    text: `Flag ${sourceType}`,
  };

  const unpinPost = {
    id: 'unpin',
    exclude: sourceType === SourceTypes.Topic || (sourceType === SourceTypes.Post && isMain),
    condition: () => post?.pinned,
    isProtected: true,
    requirements: { roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      try {
        if (sourceType === SourceTypes.Post) {
          await ApiClient.sendRequest({ method: 'put', path: `/admin/post/unpin/id/${post?._id}` });
        }
        if (sourceType === SourceTypes.Message) {
          await ApiClient.sendRequest({ method: 'put', path: `/admin/post/pin/id/${post?._id}` });
        }
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} unpinned successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, {
          message: `Error unpinning: ${e.message}`,
          severity: 'error',
        });
      }
      toggle();
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: PushPinIcon,
    text: `Unpin ${sourceType}`,
  };

  const editHandler = async () => {
    switch (sourceType) {
      case SourceTypes.Topic:
        eventHandler(CustomEvents.EditTopic, { sourceType, _id: topic?._id }, toggle);
        break;
      case SourceTypes.Post:
      case SourceTypes.Message: {
        setRecoil(editorIsActiveSelector(topic._id), true);
        setRecoil(editorCurrentPostEditSelector(topic?._id), post?._id);
        const res = await ApiClient.sendRequest({
          method: 'get',
          path: sourceType === SourceTypes.Message ? `/private-post/id/${post?._id}` : `/post/id/${post?._id}`,
          catchError: true,
          snackbarError: 'Failed to retreive post',
        });
        if (!res.success) return;
        if (sourceType === SourceTypes.Post) setRecoil(editorIsModeratorPostSelector(topic?._id), res.data.isModeratorPost);
        emitCustomEvent(CustomEvents.ReplaceEditorState, { editorName: topic._id, data: { editorContents: res.data.content } });
        toggle();
        break;
      }
      default:
        break;
    }
  };

  const edit = {
    id: 'edit',
    isProtected: true,
    requirements: { self: username },
    handler: editHandler,
    Icon: EditIcon,
    text: `Edit ${sourceType}`,
  };

  const editAdmin = {
    id: 'editAdmin',
    isProtected: true,
    exclude: ![SourceTypes.Post, SourceTypes.Topic].includes(sourceType),
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: editHandler,
    Icon: EditIcon,
    text: `Edit ${sourceType}`,
  };

  const deleteItem = {
    id: 'delete',
    exclude: (sourceType === SourceTypes.Post && isMain),
    // TODO: fix this
    condition: () => !__sourceItem?.isDeleted,
    isProtected: true,
    requirements: { self: username },
    handler: async () => {
      try {
        switch (sourceType) {
          case SourceTypes.Topic:
            await ApiClient.sendRequest({ method: 'put', path: `/topic/delete/${topic?._id}` });
            break;
          case SourceTypes.Post:
            await ApiClient.sendRequest({ method: 'put', path: `/post/delete/id/${post?._id}` });
            break;
          case SourceTypes.Message:
            await ApiClient.sendRequest({ method: 'put', path: `/private-post/delete/id/${post?._id}` });
            break;
          default:
            break;
        }
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} deleted successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
      toggle();
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: DeleteIcon,
    text: `Delete ${sourceType}`,
  };

  const deleteItemAdmin = {
    id: 'deleteAdmin',
    exclude: (sourceType === SourceTypes.Post && isMain) || sourceType === SourceTypes.Message,
    condition: () => !__sourceItem.isDeleted,
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      try {
        switch (sourceType) {
          case SourceTypes.Topic:
            await ApiClient.sendRequest({ method: 'put', path: `/admin/topic/delete/${__sourceItem._id}` });
            break;
          case SourceTypes.Post:
            await ApiClient.sendRequest({ method: 'put', path: `/post/delete/id/${__sourceItem._id}` });
            break;
          case SourceTypes.Message:
            await ApiClient.sendRequest({ method: 'put', path: `/post/delete/id/${__sourceItem._id}` });
            break;
          default:
            break;
        }
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} deleted successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, {
          message: `Error deleting: ${e.message}`,
          severity: 'error',
        });
      }
      toggle();
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: DeleteIcon,
    text: `Delete ${sourceType}`,
  };

  const restore = {
    id: 'restore',
    condition: () => __sourceItem.isDeleted,
    isProtected: true,
    requirements: { self: username },
    handler: async () => {
      try {
        switch (sourceType) {
          case SourceTypes.Topic:
            await ApiClient.sendRequest({ method: 'put', path: `/topic/restore/${__sourceItem._id}` });
            break;
          case SourceTypes.Post:
            await ApiClient.sendRequest({ method: 'put', path: `/post/restore/id/${__sourceItem._id}` });
            break;
          case SourceTypes.Message:
            await ApiClient.sendRequest({ method: 'put', path: `/private-post/restore/id/${__sourceItem._id}` });
            break;
          default:
            break;
        }
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} restored successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }
      toggle();
    },
    Icon: RestoreIcon,
    text: `Restore ${sourceType}`,
  };

  const restoreAdmin = {
    id: 'restoreAdmin',
    exclude: sourceType === SourceTypes.Message,
    condition: () => __sourceItem.isDeleted,
    isProtected: true,
    requirements: { notSelf: username, roles: [UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator] },
    handler: async () => {
      switch (sourceType) {
        case SourceTypes.Topic:
          await ApiClient.sendRequest({ method: 'put', path: `/admin/topic/restore/${__sourceItem._id}` });
          break;
        case SourceTypes.Post:
          await ApiClient.sendRequest({ method: 'put', path: `/post/restore/id/${__sourceItem._id}` });
          break;
        default:
          break;
      }
      toggle();
    },
    Icon: RestoreIcon,
    text: `Restore ${sourceType}`,
  };

  const purge = {
    id: 'purge',
    exclude: (sourceType === SourceTypes.Post && isMain),
    isProtected: true,
    requirements: {
      roles: [UserRoles.SuperAdmin],
    },
    handler: async () => {
      try {
        switch (sourceType) {
          case SourceTypes.Topic:
            await ApiClient.sendRequest({ method: 'delete', path: `/admin/topic/purge/${__sourceItem._id}` });
            break;
          case SourceTypes.Post:
            await ApiClient.sendRequest({ method: 'delete', path: `/admin/post/purge/${__sourceItem._id}` });
            break;
          case SourceTypes.Message:
            await ApiClient.sendRequest({ method: 'delete', path: `/private-post/purge/id/${__sourceItem._id}` });
            break;
          default:
            break;
        }
        setRecoil(addSnackbarSelector, {
          message: `${sourceType} purged successfully!`,
          color: 'primary',
        });
      } catch (e) {
        setRecoil(addSnackbarSelector, { message: e.message, severity: 'error' });
      }

      toggle();
    },
    confirmationType: TakeoverTypes.Confirmation,
    Icon: DeleteForeverIcon,
    text: `Purge ${sourceType}`,
  };

  const reply = {
    id: 'reply',
    isProtected: true,
    exclude: !verified,
    requirements: { notSelf: username },
    handler: async () => {
      setRecoil(editorIsActiveSelector(topic._id), true);
      setRecoil(editorCurrentReplySelector(topic._id), { _id: __sourceItem._id, author });
      emitCustomEvent(CustomEvents.InsertInEditor, { nodeType: ComposerNodeTypes.Mention, editorName: topic._id, data: { ...author } });
      toggle();
    },
    Icon: ReplyAllIcon,
    text: `Reply to ${username}`,
  };

  const divider = { divider: true };

  let mainList = [
    manageUser,
    viewProfile,
    messageUser,
    banUser,
    unbanUser,
    suspendUser,
    silenceUser,
  ];

  if (sourceType !== SourceTypes.User) {
    mainList = [
      messageUser,
      copyLink,
      saveItem,
      unsaveItem,
      hideTopic,
      flagPost,
      divider,
      mergeTopic,
      changeTopicCategory,
      changeAuthor,
      pinTopic,
      pinPost,
      unpinPost,
      lock,
      unlock,
      edit,
      editAdmin,
      deleteItem,
      deleteItemAdmin,
      restore,
      restoreAdmin,
      purge,
      silenceUser,
      suspendUser,
      banUser,
      unbanUser,
      reinstateUser,
    ];
  }

  if (sourceType === SourceTypes.User) {
    mainList = [
      ...mainList,
      approveUser,
      verifyEmail,
      reinstateUser,
      resetUserLoginAttempts,
    ];
  }

  // POST/MESSAGE SPECIFIC
  if ([SourceTypes.Post, SourceTypes.Message].includes(sourceType)) mainList.unshift(reply);

  return mainList;
};

export default buildListData;
