import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag/LexicalHashtag';
import { MentionNode } from './Nodes/MentionNode';
import { EmojiNode } from './Nodes/EmojiNode';
import { EmbedNode } from './Nodes/EmbedNode';
import { CustomEmojiNode } from './Nodes/CustomEmojiNode';
import { PostQuoteNode } from './Nodes/PostQuoteNode';

const nodes = [
  HashtagNode,
  HeadingNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  MentionNode,
  EmojiNode,
  EmbedNode,
  CustomEmojiNode,
  PostQuoteNode,
];

export default nodes;
