import { useRecoilState } from 'recoil';
import { useCallback, useEffect } from 'react';
import { isOnlineSelector } from '../models/app/selectors';

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useRecoilState(isOnlineSelector);

  const handleOnline = useCallback(() => setIsOnline(true), [setIsOnline]);
  const handleOffline = useCallback(() => setIsOnline(false), [setIsOnline]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'development') return null;
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
};
