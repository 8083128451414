import { SocketEffects, SocketUpdateActions } from '../../lib/constants';
import { PostModel } from '../../models/post/model';
import { invalidateCurrentTopicQueries } from '../../lib/queryUtils';
import { handleGlobalEffects } from './handleGlobalEffects';

const handlePostEffects = (res) => {
  const { effects } = res;
  if (effects?.length < 1) return;
  for (const effect of effects) {
    switch (effect) {
      case SocketEffects.InvalidateQueries:
        invalidateCurrentTopicQueries();
        break;
      default:
        break;
    }
  }
};

const handlePostUpdate = (res) => {
  const { data } = res;
  const Post = new PostModel(data.post._id);
  const prevData = Post.getData() || {};
  Post.setData({ ...prevData, ...data.post });
  if (data.post.replyToPid?._id) {
    const ReplyToPost = new PostModel(data.post.replyToPid._id);
    const prevReplyToData = ReplyToPost.getData() || {};
    const replies = prevReplyToData.replies || [];
    if (!replies?.length) return;
    const newReplies = replies.map((reply) => {
      if (reply._id === data.post._id) return data.post;
      return reply;
    });
    ReplyToPost.setData({ ...prevReplyToData, replies: newReplies });
  }
};

export const handlePost = (res) => {
  const { action, data } = res;
  switch (action) {
    case SocketUpdateActions.New: {
      console.log('new post', data);
      break;
    }
    case SocketUpdateActions.Update: {
      handlePostUpdate(res);
      break;
    }
    case SocketUpdateActions.Delete: {
      const Post = new PostModel(data.post._id);
      Post.setData({ ...data.post, isDeleted: true });
      break;
    }
    default: {
      console.log('unknown post action', action);
    }
  }
  handlePostEffects(res);
  handleGlobalEffects(res);
};
