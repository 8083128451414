/* eslint-disable react/jsx-props-no-spreading */
import {
  Dialog,
  Button,
} from '@mui/material';
import {
  useCallback,
  useState,
} from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Breakpoints,
  CustomEvents,
} from '../../lib/constants';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { breakpointNameSelector } from '../../models/settings/selectors';
import { addSnackbarSelector } from '../../models/snackbar/selector';

const ConfirmationDialog = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [text, setText] = useState('');
  const [handler, setHandler] = useState(() => {});
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const breakpointName = useRecoilValue(breakpointNameSelector);

  useCustomEvent(CustomEvents.ConfirmationDialog, ({
    text: _text,
    handler: _handler,
    data: _data,
  }) => {
    setOpen(true);
    if (_text) setText(_text);
    if (_data) setData(_data);
    setHandler(() => _handler);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setText('');
    setHandler(() => {});
    setData(null);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      const snackbarData = await handler();
      handleClose(snackbarData);
    } catch (e) {
      handleClose(false);
      addSnackbar({ message: e.message, severity: 'error' });
    }
  }, [handler]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      { text && (
        <div className='bg-surface px-4 py-4 rounded font-roboto'>
          <div className='pb-5'>
            <div>{`${text}?`}</div>
            { data?.description?.html && (
              <div className='mt-2' dangerouslySetInnerHTML={{ __html: data?.description.html }} />
            )}
            { data?.description?.text && (
            <div className='mt-2'>
              <p className='text-sm opacity-75'>{data.description.text}</p>
            </div>
            )}
          </div>
          <div className='tablet:space-x-3 tablet:flex tablet:justify-between space-y-3 tablet:space-y-0'>
            <Button
              fullWidth={breakpointName === Breakpoints.Mobile}
              onClick={handleClose}
              variant='outlined'
              color={data?.cancel?.color || 'secondaryLight'}
            >
              Cancel
            </Button>
            <Button
              fullWidth={breakpointName === Breakpoints.Mobile}
              variant='contained'
              color={data?.confirm?.color || 'primary'}
              onClick={handleConfirm}
            >
              {data?.confirm?.text || 'Confirm'}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
