/* eslint-disable react/function-component-definition */
import { selectorFamily } from 'recoil';
import state from './atom';
import currentPostsPages from '../currentPostsPages/atom';

// loading: false,
// loadingPrev: false,
// loadingNext: false,
// cursorState: {},
// pageStatus: {},
// scrollLock: false,

export const loadingPaginatedScroller = selectorFamily({
  key: 'loadingPaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).loading,
  set: topicId => ({ set, get }, loading) => {
    const prevState = get(state(topicId));
    set(state(topicId), { ...prevState, loading });
  },
});

export const loadingPrevPaginatedScroller = selectorFamily({
  key: 'loadingPrevPaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).loadingPrev,
  set: topicId => ({ set, get }, loadingPrev) => {
    const prevState = get(state(topicId));
    set(state(topicId), { ...prevState, loadingPrev });
  },
});

export const loadingNextPaginatedScroller = selectorFamily({
  key: 'loadingNextPaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).loadingNext,
  set: topicId => ({ set, get }, loadingNext) => {
    const prevState = get(state(topicId));
    set(state(topicId), { ...prevState, loadingNext });
  },
});

export const loadingAnyPaginatedScroller = selectorFamily({
  key: 'loadingAnyPaginatedScroller',
  get: topicId => ({ get }) => {
    const paginatedScrollerState = get(state(topicId));
    return paginatedScrollerState.loading || paginatedScrollerState.loadingPrev || paginatedScrollerState.loadingNext;
  },
});

export const cursorStatePaginatedScroller = selectorFamily({
  key: 'cursorStatePaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).cursorState,
  set: topicId => ({ set, get }, cursorState) => {
    const prevState = get(state(topicId));
    set(state(topicId), { ...prevState, cursorState });
  },
});

export const pageStatusPaginatedScroller = selectorFamily({
  key: 'pageStatusPaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).pageStatus,
  set: topicId => ({ set, get }, pageStatus) => {
    const prevState = get(state(topicId));
    set(state(topicId), { ...prevState, pageStatus });
  },
});

export const scrollLockPaginatedScroller = selectorFamily({
  key: 'scrollLockPaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).scrollLock,
  set: topicId => ({ set, get }, scrollLock) => {
    const prevState = get(state(topicId));
    set(state(topicId), { ...prevState, scrollLock });
  },
});

export const firstPostInTopic = selectorFamily({
  key: 'firstPostInTopicSelector',
  get: topicId => ({ get }) => {
    if (!topicId) return null;
    const paginatedScrollerState = get(state(topicId));
    const { cursorState } = paginatedScrollerState;
    if (cursorState.nextBefore) return null;
    const currentPagesState = get(currentPostsPages(topicId));
    if (currentPagesState.length === 0) return null;
    return currentPagesState[0][0];
  },
});

export const lastPostInTopic = selectorFamily({
  key: 'lastPostInTopicSelector',
  get: topicId => ({ get }) => {
    if (!topicId) return null;
    const paginatedScrollerState = get(state(topicId));
    const { cursorState } = paginatedScrollerState;
    if (cursorState.nextAfter) return null;
    const currentPagesState = get(currentPostsPages(topicId));
    const pagesLength = currentPagesState.length;
    if (pagesLength === 0) return null;
    const lastPage = currentPagesState[pagesLength - 1];
    if (!lastPage) return null;
    const lastPost = lastPage[lastPage.length - 1];
    return lastPost;
  },
});
