import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

export const useRequireVerification = (redirectUrl = '/') => {
  const { unauthenticated, verified } = useAuthenticatedUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (unauthenticated || !verified) navigate(redirectUrl);
  }, [unauthenticated, verified]);
};
