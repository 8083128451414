import { useRecoilValue } from 'recoil';
import { QueryKeys, VirtuosoTypes } from '../../lib/constants';
import { ContentForList } from '../Containers';
import QueryList from '../QueryList';
import StoreBadge from '../StoreBadge';
import storeProductSortKeyState from '../../models/storeProductsSortKey/atom';
import StoreBadgesToolbar from '../StoreBadgesToolbar';
import storeProductSelectedCategoriesState from '../../models/storeProductSelectedCategories/atom';
import storeProductSearchState from '../../models/storeProductSearch/atom';

const EmptyComponent = () => (<div className='flex pt-20 justify-center items-center font-roboto text-lg'>No badges found with those parameters 🤔</div>);

const StoreBadges = () => {
  const sortKey = useRecoilValue(storeProductSortKeyState);
  const selectedCategories = useRecoilValue(storeProductSelectedCategoriesState);
  const categories = selectedCategories.join(',');
  const search = useRecoilValue(storeProductSearchState);

  return (
    <>
      <StoreBadgesToolbar />
      <ContentForList
        desktopHeightOffset={58}
        mobileHeightOffset={58}
        className='text-primary-text all-topics-content store-badges'
      >
        <QueryList
          virtuosoType={VirtuosoTypes.Grid}
          listClassName='virtuoso-grid-list-container-store-badges'
          itemClassName='virtuoso-grid-item-container-store-badges'
          requestPath='/store/products'
          requestSort={sortKey}
          requestParams={{ type: 'badge', categories, search }}
          ItemComponent={StoreBadge}
          queryKey={[QueryKeys.StoreBadges, sortKey, categories, search]}
          queryEnabled
          loaderProps={{ boxType: 'fullContainer' }}
          shouldShowPlaceholder={false}
          customScrollParentSelector='.store-badges'
          shouldShowBackButton={false}
          EmptyComponent={<EmptyComponent />}
          shouldShowEmptyComponent
          shouldShowErrorComponent
        />
      </ContentForList>
    </>
  );
};

export default StoreBadges;
