import { extend } from '../utils/extend';

import base, { lightBase } from './base';
import { darkBase } from './dark';

const surface = '#1a1a1a';
const overlay = '255,255,255';

const mainColors = {
  primary: '#0dba66', // GREEN
  primaryDark: '#00d659',
  primaryLight: '#69F0AE',
  secondary: '#FF4081', // PINK
  secondaryDark: '#D500F9',
  secondaryLight: '#FF80AB',
};

const textAndShading = {
  textPrimaryBrandLarge: '#8fecbf', // WHITE
  textSecondaryBrandLarge: '#ff80ab',
  textPrimaryBrandSmall: '#b5dfcb',
  textSecondaryBrandSmall: '#ff80ab',
  textPrimary: '#FFF',
  cardShadingPrimary: '#424242',
  cardShadingSecondary: '#303030',
  mode: 'dark',
  mentionBackground: '#0a693b',
};

const modeBase = textAndShading.mode === 'dark' ? darkBase : lightBase;

export default extend(base, {
  ...mainColors,
  ...textAndShading,
  ...modeBase,
  surface,
  overlay,
});
