import { useMemo, useState } from 'react';
import Image from '../Image';

const imageStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  objectFit: 'cover',
  position: 'absolute',
};

const AspectRatioImage = ({
  src,
  alt,
  containerClassName,
  imageClassName,
  style,
  onClick,
  ratio = '1:1',
}) => {
  const [hiddenByError, setHiddenByError] = useState(false);

  const ratioStyles = useMemo(() => {
    switch (ratio) {
      case '1:1':
        return {
          width: '100%',
          paddingTop: '100%',
        };
      case '4:3':
        return {
          paddingTop: '75%',
        };
      case '3:4':
        return {
          paddingTop: '133.33%',
        };
      case '16:9':
        return {
          paddingTop: '56.25%',
        };
      case '21:9':
        return {
          paddingTop: '42.857142857142854%',
        };
      case '3:2':
        return {
          paddingTop: '66.66666667%',
        };
      case '5:4':
        return {
          paddingTop: '133.33333333%',
        };
      default:
        return {
          width: '100%',
          paddingTop: '100%',
        };
    }
  }, [ratio]);

  return (
    <div
      role='button'
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      className={`flex items-center justify-center${containerClassName ? ` ${containerClassName}` : ''}`}
      style={{
        ...style,
        width: '100%',
        position: 'relative',
        ...ratioStyles,
      }}
    >
      { hiddenByError && (
      <div
        style={{
          padding: '4px 6px',
          position: 'absolute',
          color: 'white',
          bottom: '0px',
          width: '100%',
          borderBottomLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          backgroundColor: 'var(--color-primary-light)',
          zIndex: 1,
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        Image Not Found
      </div>
      ) }
      <Image onError={() => setHiddenByError(true)} src={src} alt={alt} className={imageClassName} style={imageStyle} />
    </div>
  );
};

export default AspectRatioImage;
