import {
  useCallback, useState, useEffect,
} from 'react';
import {
  TextField, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { addDays } from 'date-fns';
import { Wrapper, Content } from '../../components/Containers';
import useSocketJoin from '../../hooks/useSocketJoin';
import GameCard from '../../components/GameCard';
import { formatDate } from '../../lib/formatDate';
import ApiClientInstance from '../../clients/api';
import { QueryKeys, SocketRooms } from '../../lib/constants';
import QueryList from '../../components/QueryList';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const path = '/game/league';

const EmptyMessage = ({ date }) => (
  <div className='flex flex-col px-4 space-y-2 py-6'>
    <span>{`Either no games are scheduled for ${date ? formatDate(date, 'M/d') : 'today'} or the odds aren't available yet. `}</span>
    <span>Odds are usually available around 11AM ET of game days.</span>
  </div>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const ItemComponent = ({ item }) => <GameCard game={item} />;

const Games = () => {
  // State
  const [league, setLeague] = useState(null);
  const [date, setDate] = useState(new Date());

  useDocumentTitle('Sports Scores');

  // Sockets
  useSocketJoin(SocketRooms.General, 'sports');

  // CallBacks
  const handleSortChange = useCallback((e) => {
    setLeague(e.target.value);
  }, []);

  const handleDateChange = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  useEffect(async () => {
    const leagueRes = await ApiClientInstance.sendRequest({
      path: '/game/active-leagues',
      method: 'GET',
      catchError: true,
      queryParams: { date: formatDate(date, 'MM/dd/yyy') },
    });
    if (leagueRes.success && leagueRes?.data?.length > 0) setLeague(leagueRes?.data[0].code.toLowerCase());
    else setLeague('nba');
  }, []);

  return (
    <Wrapper applyBreakpoints className='games'>
      <div className='flex px-3 pt-2 pb-1 space-x-2 justify-between items-center mt-3'>
        { !league && <TextField sx={{ width: '100px' }} size='small' value='league' disabled>League</TextField>}
        { league && (
        <FormControl sx={{ width: '100px' }} size='small'>
          <InputLabel id='sort-select'>league</InputLabel>
          <Select
            labelId='sort-select-label'
            id='sort-select'
            label='League'
            value={league}
            onChange={handleSortChange}
          >
            <MenuItem value='nba'>NBA</MenuItem>
            <MenuItem value='nfl'>NFL</MenuItem>
            <MenuItem value='mlb'>MLB</MenuItem>
            <MenuItem value='ncaam'>NCAAM</MenuItem>
            <MenuItem value='ncaaf'>NCAAF</MenuItem>
            <MenuItem value='nhl'>NHL</MenuItem>
          </Select>
        </FormControl>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            showTodayButton
            disableCloseOnSelect={false}
            label='Date'
            value={date}
            minDate={new Date('2022-01-31')}
            maxDate={addDays(new Date(), 1)}
            onChange={handleDateChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField size='small' {...params} />}
          />
        </LocalizationProvider>
      </div>
      <Content className='pt-1 game-list text-primary-text'>
        <QueryList
          requestPath={`${path}/${league}`}
          requestParams={{ date: formatDate(date, 'M/d/yyyy') }}
          ItemComponent={ItemComponent}
          queryKey={[QueryKeys.Games, league, formatDate(date, 'M/d/yyyy')]}
          queryEnabled={league !== null}
          EmptyComponent={<EmptyMessage date={date} />}
          customScrollParentSelector='.game-list'
          refetchInterval={1000 * 60 * 1}
        />
      </Content>
    </Wrapper>
  );
};

export default Games;
