import { useRecoilValue } from 'recoil';

import usersWritingState from '../../models/usersWriting/atom';
import { useUser } from '../../models/user/useUser';

const UserWritingDisplay = ({
  userId,
}) => {
  const {
    data: user,
    fetch: fetchUser,
    error,
    model,
    loading: isLoading,
    setData: setUser,
  } = useUser(userId);

  if (!user) return null;
  return (
    <div>{ user.username }</div>
  );
};

const UsersWriting = ({
  topicId = '',
}) => {
  const usersWriting = useRecoilValue(usersWritingState(topicId));
  if (usersWriting?.length === 0) return null;
  return (
    <div style={{ zIndex: 2 }} className='users-writing absolute w-[100%] bg-surface tablet:rounded'>
      <div className='flex justify-center bg-surface pt-2 pb-1 text-sm font-semibold font-roboto text-primary-text'>
        { usersWriting.length > 3 && (<span>multiple users</span>)}
        { usersWriting.length < 4 && usersWriting.map((userId, i) => (
          <>
            <UserWritingDisplay key={userId} userId={userId} />
            { i < usersWriting.length - 1 && <span>,&nbsp;</span>}
          </>
        )) }
        <span>
          &nbsp;
          {`${usersWriting?.length > 1 ? 'are' : 'is'} typing...`}
        </span>
      </div>
    </div>
  );
};

export default UsersWriting;
