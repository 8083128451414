import { atomFamily } from 'recoil';
import { defaultLoadableAtom } from '../../lib/constants';

const notificationState = atomFamily({
  key: 'notificationState',
  default: {
    ...defaultLoadableAtom,
  },
});

export default notificationState;
