import {
  useEffect,
} from 'react';
import { useRecoilValue } from 'recoil';
import { fullTitleStateSelector } from '../models/documentTitle/selectors';

export const useFullTitleSync = () => {
  const fullTitle = useRecoilValue(fullTitleStateSelector);

  useEffect(() => {
    document.title = fullTitle;
  }, [fullTitle]);
};
