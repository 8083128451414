import {
  useCallback,
  useEffect, useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import ApiClientInstance from '../../clients/api';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, SourceTypes } from '../../lib/constants';
import PostNavigatorBar from '../PostNavigatorBar';
import PostNavigatorContent from '../PostNavigatorContent';
import postSourceType from '../../models/postSourceType/atom';
import { loadingPaginatedScroller } from '../../models/paginatedScrollerState/selectors';

const PostNavigator = ({
  topicId,
  contentOptions,
}) => {
  const [navigateData, setNavigateData] = useState(null);
  const [postSelectorVisible, setPostSelectorVisibility] = useState(false);
  const sourceType = useRecoilValue(postSourceType(topicId));
  const loading = useRecoilValue(loadingPaginatedScroller(topicId));

  const getNavigateData = useCallback(async () => {
    if (!sourceType || !topicId) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: `/${sourceType === SourceTypes.Post ? 'post' : 'private-post'}/navigate/${topicId}`,
      catchError: true,
    });
    if (res.success) setNavigateData(res.data);
  }, [topicId, sourceType]);

  useEffect(async () => {
    if (!topicId) return;
    await getNavigateData();
  }, [topicId, sourceType]);

  useEffect(() => {
    if (loading && postSelectorVisible) setPostSelectorVisibility(false);
  }, [loading, topicId]);

  useCustomEvent(CustomEvents.RefreshNavigateData, getNavigateData, [topicId, getNavigateData]);

  return (
    <>
      { postSelectorVisible && (
      <PostNavigatorContent
        topicId={topicId}
        navigateData={navigateData}
        setPostSelectorVisibility={setPostSelectorVisibility}
        setNavigateData={setNavigateData}
        getNavigateData={getNavigateData}
        sourceType={sourceType}
        contentOptions={contentOptions}
      />
      ) }
      { !postSelectorVisible && (
      <PostNavigatorBar
        topicId={topicId}
        postSelectorVisible={postSelectorVisible}
        setPostSelectorVisibility={setPostSelectorVisibility}
        navigateData={navigateData}
      />
      )}
    </>
  );
};

export default PostNavigator;
