/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItemButton } from '@mui/material';
import ApiClient from '../../clients/api';
import DisplayAvatar from '../DisplayAvatar';

const CategoryOption = ({
  name,
  color,
  slug,
  _id,
  image,
  muiProps,
}) => (
  <ListItemButton {...muiProps} className='px-3 py-1 flex items-center'>
    <DisplayAvatar username={name} color={color} avatarSize='24px' avatar={image} />
    <span className='ml-2 text-sm opacity-70'>{name}</span>
  </ListItemButton>
);

const CategorySelect = ({
  disabled,
  category,
  setCategory,
  placeholder = 'select a category',
  label = 'select a category',
  sx = {},
}) => {
  const [options, setOptions] = useState([]);

  useEffect(async () => {
    const data = await ApiClient.sendRequest({ method: 'get', path: '/category', catchError: true });
    if (data.success) {
      setOptions(data.data);
    }
  }, []);

  const value = category || '';

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      ListboxProps={{ className: 'h-[200px] tablet:h-[300px] desktop:h-[400px] bg-s02dp' }}
      className='bg-s01dp mt-2'
      disablePortal
      placeholder={placeholder}
      id='category-search'
      options={options}
      sx={sx}
      value={value}
      onChange={(event, newValue) => {
        setCategory(newValue);
      }}
      isOptionEqualToValue={(option, val) => {
        if (!val?.name) {
          return val === '';
        }
        return option?.name === val?.name;
      }}
      getOptionLabel={(option) => option?.name || ''}
      renderOption={(props, option) => <CategoryOption key={option._id} name={option?.name} color={option?.color} image={option?.image} muiProps={props} />}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default CategorySelect;
