/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  memo,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Wrapper, ContentForList } from '../../components/Containers';
import ApiClientInstance from '../../clients/api';
import { SourceTypes, CardHeaderContentDetailTypes, QueryLocationKeys } from '../../lib/constants';
import CategoryHeader from '../../components/CategoryHeader';
import UniverseLoader from '../../components/Loaders/UniverseLoader';
import TopicList from '../../components/TopicList';
import { TopicCard } from '../../components/TopicCard';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorCard from '../../components/ErrorCard';
import TopicListEmptyComponent from '../../components/TopicList/TopicListEmptyComponent';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const MemoTopicCardComponent = memo(TopicCard);
const ItemComponent = ({ item, index }) => (
  <ErrorBoundary fallback={<ErrorCard />}>
    <MemoTopicCardComponent _id={item._id} key={item._id} sourceType={SourceTypes.Topic} detailsType={CardHeaderContentDetailTypes.User} />
  </ErrorBoundary>
);

const Category = () => {
  // Hooks
  const { slug } = useParams();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(true);

  useDocumentTitle(categoryData?.name ? `${categoryData?.name} | Category` : '');

  const init = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      method: 'get',
      path: `/category/slug/${slug}`,
      catchError: true,
    });
    if (res?.success) setCategoryData(res.data[0]); else navigate('/404');
  }, [slug]);

  useEffect(async () => {
    await init();
  }, [slug]);

  const loader = useMemo(() => {
    if (!loading) return null;
    return <UniverseLoader boxType='fullScreen' />;
  }, [loading]);

  return (
    <>
      { loader }
      <Wrapper applyBreakpoints className='category'>
        <ContentForList
          desktopHeightOffset={1}
          mobileHeightOffset={1}
          className='text-primary-text category-topics-content'
        >
          { categoryData && (
          <>
            <CategoryHeader category={categoryData} />
            <TopicList
              customScrollParentSelector='.category-topics-content'
              locationKey={[QueryLocationKeys.Category, categoryData._id]}
              rooms={[{ path: 'category', name: categoryData.slug }]}
              requestPath='/topic/categories'
              requestParams={{ slugs: [categoryData.slug] }}
              loading={loading}
              setLoading={setLoading}
              ItemComponent={ItemComponent}
              EmptyComponent={<TopicListEmptyComponent category={categoryData} />}
            />
          </>
          ) }
        </ContentForList>
      </Wrapper>
    </>
  );
};
export default Category;

// Resource owner: could be on any page. send them update and write the handlers to update the pages they need to be on.

// Viewers: won't get immediate update

// subscribe by component? work goes to server side (need to keep up with what rooms)
