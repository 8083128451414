/* eslint-disable react/function-component-definition */
import { selectorFamily } from 'recoil';

import state from './atom';
import { authenticatedUserSelector, userVerifiedSelector } from '../authenticatedUser/selectors';

export const editorStateJsonSelector = selectorFamily({
  key: 'editorSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.editorStateJson || null;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], editorStateJson: data } });
  },
});

export const editorFocusSelector = selectorFamily({
  key: 'editorFocusSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.focusState || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], focusState: data } });
  },
});

export const editorIsEmptySelector = selectorFamily({
  key: 'editorEmptySelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    const hasValue = (editorState?.[editorName]?.isEmpty !== undefined);
    return hasValue ? editorState?.[editorName]?.isEmpty : true;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], isEmpty: data } });
  },
});

export const editorIsExpandedSelector = selectorFamily({
  key: 'editorExpandedSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.isExpanded || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], isExpanded: data } });
  },
});

export const editorIsLoadingSelector = selectorFamily({
  key: 'editorIsLoadingSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.isLoading || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], isLoading: data } });
  },
});

export const editorIsActiveSelector = selectorFamily({
  key: 'editorIsActiveSelector',
  get: (editorName) => ({ get, set }) => {
    const authenticatedUser = get(authenticatedUserSelector);
    const verified = get(userVerifiedSelector);
    if (!authenticatedUser || !verified) return false;
    const editorState = get(state);
    return editorState?.[editorName]?.isActive || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], isActive: data } });
  },
});

export const editorTextContentSelector = selectorFamily({
  key: 'editorTextContentSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.textContent || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], textContent: data } });
  },
});

export const editorCurrentReplySelector = selectorFamily({
  key: 'editorCurrentReplySelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.currentReply || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], currentReply: data } });
  },
});

export const editorCurrentPostEditSelector = selectorFamily({
  key: 'editorCurrentPostEditSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.currentPostEdit || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], currentPostEdit: data } });
  },
});

export const editorIsModeratorPostSelector = selectorFamily({
  key: 'editorIsModeratorPostSelector',
  get: (editorName) => ({ get, set }) => {
    const editorState = get(state);
    return editorState?.[editorName]?.isModeratorPost || false;
  },
  set: (editorName) => ({ set, get }, data) => {
    const editorState = get(state);
    set(state, { ...editorState, [editorName]: { ...editorState[editorName], isModeratorPost: data } });
  },
});
