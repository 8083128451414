import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';

import { CustomEvents } from '../../lib/constants';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import unseenNotificationsCountState from '../../models/unseenNotificationsCount/atom';
import { userNav } from '../../models/navigation/selectors';

const UserNavNotificationsID = 'user-nav-notifications';

const UserNavNotificationsItem = () => {
  const toggleOpen = useSetRecoilState(userNav);
  const unseenNotificationsCount = useRecoilValue(unseenNotificationsCountState);
  const handleNotificationsClick = useCallback(() => {
    toggleOpen();
    emitCustomEvent(CustomEvents.ToggleNotifications, {
      elementSelector: 'section.tku-wrapper',
      placement: 'bottom-end',
      offset: [0, -550],
    });
  }, []);

  return (
    <ListItem
      id={UserNavNotificationsID}
      className='prevent-clickaway prevent-clickaway-notifications'
      onClick={handleNotificationsClick}
      button
    >
      <ListItemIcon>
        <Badge
          badgeContent={unseenNotificationsCount}
          max={50}
          sx={{ height: '20px' }}
          overlap='circular'
          color='secondary'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <NotificationsIcon />
        </Badge>

      </ListItemIcon>
      <ListItemText primary='Notifications' />
    </ListItem>
  );
};

export default UserNavNotificationsItem;
