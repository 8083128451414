import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Tooltip } from '@mui/material';
import UserAvatar from '../../UserAvatar';
import DisplayAvatar from '../../DisplayAvatar';
import UserBadges from '../../UserBadges';

import {
  Breakpoints,
  CardHeaderContentDetailTypes,
} from '../../../lib/constants';
import { formatDate, getDistance } from '../../../lib/formatDate';
import { emitOpenUserProfileDrawer } from '../../../lib/emitters/emitOpenUserProfileDrawer';

import { breakpointNameSelector } from '../../../models/settings/selectors';

const userNameSize = { fontSize: '13px', lineHeight: '16px' };

export const CardHeaderContent = ({
  avatarSize = '30px ',
  category,
  author,
  createdOn,
  detailsType,
  sourceType,
  disableUserProfileDrawer,
}) => {
  const navigate = useNavigate();
  const breakpoint = useRecoilValue(breakpointNameSelector);
  const isMoble = breakpoint === Breakpoints.Mobile;

  const handleOpenUserProfileDrawer = useCallback(() => {
    if (disableUserProfileDrawer) return null;
    return emitOpenUserProfileDrawer({ sourceType, username: author?.username });
  }, [disableUserProfileDrawer]);

  const handleCategoryAvatarClick = useCallback(() => navigate(`/category/${category?.slug}`), [category]);

  const userButton = useMemo(() => (
    <span style={userNameSize} className='opacity-80'>
      <button
        type='button'
        onClick={handleOpenUserProfileDrawer}
        disabled={disableUserProfileDrawer}
      >
        <span style={{ letterSpacing: '.05ch' }} className='font-bold text-primary-text'>{`@${author.username}`}</span>
      </button>
      <Tooltip enterTouchDelay={1} title={formatDate(new Date(createdOn), 'M/d/yyyy - h:mm:ss aa')}>
        <span style={{ cursor: 'pointer' }}>
          {' '}
          { getDistance(createdOn) }
        </span>
      </Tooltip>
    </span>
  ), [userNameSize, createdOn, handleOpenUserProfileDrawer, author?.username, disableUserProfileDrawer]);

  return (
    <>
      { [CardHeaderContentDetailTypes.User, CardHeaderContentDetailTypes.Simple].includes(detailsType) && (
        <UserAvatar
          onClick={handleOpenUserProfileDrawer}
          _id={author?._id}
          avatarSize='45px'
          avatarFontSize='13px'
        />
      )}

      { detailsType === CardHeaderContentDetailTypes.Category && (
        <DisplayAvatar
          onClick={handleCategoryAvatarClick}
          name={category?.name}
          color={category?.color}
          avatar={category?.image}
          avatarSize='45px'
          avatarFontSize='13px'
        />
      ) }

      <div className='flex flex-col ml-3'>

        { detailsType === CardHeaderContentDetailTypes.Category && (
          <span className='text-[13px] uppercase font-bold text-primary-brand-small-text'>
            <Link to={`/category/${category.slug}`}>{ category.name }</Link>
          </span>
        )}
        { userButton }

        { detailsType === CardHeaderContentDetailTypes.User && (
          <UserBadges
            _id={author?._id}
            badgeSize={isMoble ? '22px' : '25px'}
            containerClassName='mt-1'
          />
        )}
      </div>
    </>
  );
};
