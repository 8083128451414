import { atom } from 'recoil';
import { TopicListDisplayKeys } from '../../lib/constants';

import { loadFromLocalStorage, setInLocalStorage } from '../utils/atomUtills';

const key = 'topicListDisplayKey';

const topicListDisplayKey = atom({
  key: 'topicListDisplayKeyState',
  default: TopicListDisplayKeys.Full,
  effects: [
    loadFromLocalStorage(key),
    setInLocalStorage(key),
  ],
});

export default topicListDisplayKey;
