import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';

import buildList from '../../lib/buildList';
import { CustomEvents, SourceTypes } from '../../lib/constants';

import buildListData from './buildListData';
import UniverseLoader from '../Loaders/UniverseLoader';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { TopicModel } from '../../models/topic/model';
import { PostModel } from '../../models/post/model';
import { UserModel } from '../../models/user/model';

const DrawerMenu = ({ sourceType = SourceTypes.Topic }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [editMenuData, setEditMenuData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEvent = useCallback(({ data }) => {
    setOpen(true);
    setEventData(data);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setEventData(null);
    setEditMenuData(null);
    setLoading(false);
  }, []);

  const goToPath = useCallback((path) => {
    // TODO: verify this is a good experience across all browswers and connections
    // setting timeout to show click ripple
    setTimeout(() => {
      handleClose();
      navigate(path);
    }, 100);
  }, [handleClose]);

  useCustomEvent(CustomEvents.OpenDrawerMenu, handleEvent);

  // Effects
  useEffect(async () => {
    if (!open) return null;
    if (!eventData) return null;
    setLoading(true);
    const _editMenuData = {};
    let hasAllRequiredData = true;
    if (eventData.topicId) {
      hasAllRequiredData = false;
      let topic = TopicModel.getTopic(eventData.topicId);
      if (!topic) {
        const topicRes = await TopicModel.requestTopic(eventData.topicId);
        if (topicRes.success) {
          topic = topicRes.data;
          _editMenuData.topic = topic;
          _editMenuData.topic = topic;
          hasAllRequiredData = true;
        }
      } else {
        _editMenuData.topic = topic;
        hasAllRequiredData = true;
      }
    }
    if (eventData.postId) {
      hasAllRequiredData = false;
      let post = PostModel.getPost(eventData.postId);
      if (!post) {
        const postRes = await PostModel.requestPost(eventData.postId);
        if (postRes.success) {
          post = postRes.data;
          _editMenuData.post = post;
          hasAllRequiredData = true;
        }
      } else {
        _editMenuData.post = post;
        hasAllRequiredData = true;
      }
    }
    if (eventData.authorId) {
      hasAllRequiredData = false;
      let author = UserModel.getUser(eventData.authorId);
      if (!author) {
        const authorRes = await UserModel.requestUser(eventData.authorId);
        if (authorRes.success) {
          author = authorRes.data;
          UserModel.setUser(eventData.authorId, author);
          _editMenuData.author = author;
          hasAllRequiredData = true;
        }
      } else {
        _editMenuData.author = author;
        hasAllRequiredData = true;
      }
    }
    if (hasAllRequiredData) {
      setEditMenuData({ ..._editMenuData });
      setLoading(false);
    } else handleClose();
  }, [open, eventData, eventData?.sourceType, sourceType, handleClose]);

  const sourceMatch = sourceType === eventData?.sourceType;

  return (
    <Drawer
      ModalProps={{ keepMounted: false, className: 'prevent-clickaway prevent-clickaway-messages tku-drawer-menu' }}
      transitionDuration={75}
      anchor='bottom'
      open={open && sourceMatch}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <div className='bg-surface desktop:w-[755px] desktop:ml-auto desktop:mr-auto rounded'>
        <div style={{ minHeight: '50px' }} className='bg-s03dp rounded'>
          { (loading) && (
          <div className='h-[100px] relative'>
            <UniverseLoader boxType='fullContainer' showLogo={false} size={100} />
          </div>
          )}
          {(open && !loading && editMenuData) && buildList({
            listData: buildListData(editMenuData, goToPath, sourceType, null, handleClose),
            listDense: true,
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
