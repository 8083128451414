import usePatchMatch from '../../hooks/usePathMatch';

const PathMatch = ({ excludedPaths, includedPaths, children }) => {
  const isExcludedPath = usePatchMatch(excludedPaths || []);
  const isIncludedPath = usePatchMatch(includedPaths || []);
  if (!!excludedPaths?.length && isExcludedPath) {
    return null;
  }

  if (!!excludedPaths?.length && !isExcludedPath) {
    return children;
  }

  if (!!includedPaths?.length && isIncludedPath) {
    return children;
  }

  if (!!includedPaths?.length && !isIncludedPath) {
    return null;
  }

  return null;
};

export const ExcludedPaths = ({ excludedPaths, children }) => PathMatch({ excludedPaths, children });
export const IncludedPaths = ({ includedPaths, children }) => PathMatch({ includedPaths, children });

export default PathMatch;
