/* eslint-disable react/jsx-props-no-spreading */
import {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';
import { debounce } from '@mui/material';
import ApiClientInstance from '../../clients/api';
import UniverseLoader from '../Loaders/UniverseLoader';
import throttle from '../../lib/throttle';
import useSocketOn from '../../hooks/useSocketOn';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import {
  CustomEvents,
  VirtuosoTypes,
} from '../../lib/constants';
import ErrorBoundary from '../ErrorBoundary';
import ErrorCard from '../ErrorCard';
import { itemListUpdater } from './itemListUpdater';

const buildQueryParams = ({ queryParams, sort }) => {
  let obj = {};
  if (!!queryParams) obj = { ...queryParams };
  if (!!sort) obj = { ...obj, sort };
  return obj;
};

const virtuosoComponent = (ItemComponent, items) => {
  const component = (index, item) => {
    if (!item) item = items[index];
    return (
      <ErrorBoundary fallback={<ErrorCard />}>
        <ItemComponent index={index} item={item} />
      </ErrorBoundary>
    );
  };
  return component;
};

const DefaultFooter = () => <div style={{ height: '20px' }} />;

const Placeholder = () => (
  <>
    <div className='h-[1px] w-[1px]' />
    <UniverseLoader boxType='fullContainer' />
  </>
);

const SimpleItemList = ({
  wrapperRef,
  ItemComponent,
  Header,
  Footer,
  emptyMessage,
  customScrollParent,
  listClassName,
  itemClassName,
  path,
  queryParams,
  refetchInterval,
  sort = 'new',
  eventName,
  socketEvent,
  updater,
  renderKey,
  isHidden,
  displayHeaderWhenEmpty = false,
  showErrorMessage = false,
  isEnabled = true,
  virtuosoType = VirtuosoTypes.List,
  setParentLoading,
  showLoader = true,
  loaderProps,
  minimumItemsAfterRemoval = -1,
  setItemInState,
  _items = null,
  _getInitData = null,
  _getNext = null,
  _allowSetItems = true,
}) => {
  const [loading, _setLoading] = useState(true);

  const [items, setItems] = useState([]);
  const [loadingNext, _setLoadingNext] = useState(false);
  const [error, setError] = useState(null);

  const setLoading = useCallback((val) => {
    if (setParentLoading) setParentLoading(val);
    _setLoading(val);
  }, []);

  const setLoadingNext = useCallback((val) => {
    if (setParentLoading) setParentLoading(val);
    _setLoadingNext(val);
  }, []);

  const stateRef = useRef({});
  const virtuoso = useRef();

  stateRef.current.hasScrolled = !!stateRef?.current?.hasScrolled;

  const getNext = useCallback(async () => {
    // sub in _getNext if available;
    if (!stateRef.current.hasNextPage) return;
    setLoadingNext(true);
    if (!_getNext) {
      const newData = await ApiClientInstance.sendRequest({ method: 'get', path, queryParams: { page: stateRef.current.nextPage, ...buildQueryParams({ queryParams, sort }) } });
      const newItems = newData?.data?.docs || [];
      stateRef.current.nextPage = newData.data?.nextPage;
      stateRef.current.hasNextPage = newData.data?.hasNextPage;
      setItems(prev => [...prev, ...newItems]);
      setLoadingNext(false);
    }
    if (_getNext) {
      const res = await _getNext();
      console.log(res);
      stateRef.current.hasNextPage = res?.hasNextPage;
      if (_allowSetItems) setItems(prev => [...prev, ...res.data]);
      setLoadingNext(false);
    }
  }, [queryParams, sort, path, _getNext]);

  const bottomHandler = useCallback(throttle(async (isBottom) => {
    if (isBottom && stateRef.current.hasScrolled) {
      await getNext();
    }
  }, 1000), [queryParams, sort, path, _getNext]);

  const throttledScroll = useCallback(throttle((scroll) => {
    if (!stateRef.current.hasScrolled && scroll) {
      stateRef.current.hasScrolled = true;
    }
  }, 400), []);

  useSocketOn(socketEvent, (res) => updater(res, items, setItems), [items, isEnabled]);
  useCustomEvent(eventName || 'simpleList', (res) => updater(res, items, setItems), [items, isEnabled]);

  const init = useRef(debounce(async (newPath, newQueryParams, sortParam) => {
    if (_items) {
      setItems(_items);
      setLoading(false);
      stateRef.current.hasNextPage = true;
      return;
    }
    setLoading(true);
    setError(false);
    setItems([]);
    // substite _getInitData if passed on as prop
    const data = await ApiClientInstance.sendRequest({
      method: 'get',
      catchError: true,
      path: newPath,
      queryParams: buildQueryParams({ queryParams: newQueryParams, sort: sortParam }),
    });
    if (data.success) {
      setLoading(false);
      const newItems = data.data?.docs ? data.data.docs : data.data;
      setItems(newItems);
      stateRef.current.nextPage = data.data?.nextPage;
      stateRef.current.hasNextPage = data.data?.hasNextPage;
    }
    if (data.error || !data.success) setError(data.error);
  }, 1));

  const handleRerender = useCallback((data) => {
    const _renderKey = data?.renderKey;
    if (renderKey && _renderKey && renderKey !== _renderKey) return;
    init.current(path, queryParams, sort);
  }, [path, queryParams, sort]);

  const handleItemListUpdate = useCallback((data) => {
    itemListUpdater(items, setItems, data);
  }, [items, setItems]);

  useCustomEvent(CustomEvents.Rerender, handleRerender, [handleRerender]);
  useCustomEvent(CustomEvents.SimpleItemListUpdate, handleItemListUpdate, [handleItemListUpdate, items]);

  // Init Handling
  useEffect(async () => {
    if (!isEnabled) return;
    init.current(path, queryParams, sort);
  }, [path, JSON.stringify(queryParams), sort, isEnabled]);

  // Refetch Interval Handling
  // TODO: likely will need work if passing in custom functions to get data instead of path
  useEffect(async () => {
    clearInterval(stateRef?.current?.interval);
    if (refetchInterval) {
      stateRef.current.interval = setInterval(async () => {
        const data = await ApiClientInstance.sendRequest({ method: 'get', path, queryParams: buildQueryParams({ queryParams, sort }) });
        if (data.success) {
          const newItems = data.data?.docs ? data.data.docs : data.data;
          setItems(newItems);
          stateRef.current.nextPage = data.data?.nextPage;
          stateRef.current.hasNextPage = data.data?.hasNextPage;
        }
      }, refetchInterval);
    }
  }, [path, queryParams, sort]);

  useEffect(() => () => clearInterval(stateRef?.current?.interval), []);

  // Scroll Lock for Parent Height
  useEffect(() => {
    if (!wrapperRef?.current) return null;
    if (!isHidden && items?.length === 0 && !loading) {
      wrapperRef.current.style.height = 0;
    }
    if (!isHidden && items?.length > 0 && !loading) {
      wrapperRef.current.style.height = '100%';
    }
  }, [items, loading, isHidden]);

  // handles custom items passed in
  useEffect(() => {
    virtuoso?.current?.scrollToIndex({
      index: 0,
      align: 'start',
    });
    setItems(_items);
  }, [_items]);

  // handles removing items and getting next page to never get stuck
  useEffect(() => {
    if (minimumItemsAfterRemoval < 0) return null;
    if (items?.length > minimumItemsAfterRemoval) return null;
    if (!stateRef.current.hasNextPage) return null;
    getNext();
  }, [minimumItemsAfterRemoval, items?.length, getNext]);

  useEffect(() => {
    if (!setItemInState) return;
    if (!items?.length) return;
    setItemInState(items);
  }, [items, setItemInState]);

  const shouldRender = items?.length > 0;
  const isEmpty = !loading && items?.length === 0;

  const style = {};

  if (isHidden) style.display = 'none';

  if (error && showErrorMessage && !isHidden) {
    return (
      <div style={{ height: '50%' }} className='px-3 roboto flex flex-col space-y-3 justify-center text-primary-text'>
        <span className='text-lg'>Looks like something went wrong 😔</span>
        <span className='italic text-xs'>{`error: ${error.toLowerCase()}`}</span>
      </div>
    );
  }

  return (
    <>
      { (showLoader && !isHidden && (loadingNext || loading)) && <UniverseLoader {...loaderProps} />}
      { (isEmpty && !isHidden) && (
        <>
          { (displayHeaderWhenEmpty && Header) && Header()}
          <div className='flex justify-center items-center text-primary-text pt-10 font-roboto opacity-80 h-100 w-100 top-0'>{emptyMessage || 'Nothing Here 😢'}</div>
        </>
      )}
      { (shouldRender && virtuosoType === VirtuosoTypes.List) && (
        <Virtuoso
          customScrollParent={customScrollParent || null}
          style={style}
          ref={virtuoso}
          firstItemIndex={0}
          initialTopMostItemIndex={0}
          atBottomStateChange={customScrollParent ? null : bottomHandler}
          isScrolling={throttledScroll}
          endReached={customScrollParent ? bottomHandler : null}
          data={items?.length > 0 ? items : ['loader']}
          components={{ Footer: Footer || DefaultFooter, Header: Header || null }}
          itemContent={items?.length > 0 ? virtuosoComponent(ItemComponent) : Placeholder}
        />
      )}
      { (shouldRender && virtuosoType === VirtuosoTypes.Grid) && (
        <VirtuosoGrid
          totalCount={items?.length}
          customScrollParent={customScrollParent || null}
          style={style}
          atBottomStateChange={customScrollParent ? null : bottomHandler}
          isScrolling={throttledScroll}
          endReached={customScrollParent ? bottomHandler : null}
          listClassName={listClassName || 'virtuoso-grid-list-container'}
          itemClassName={itemClassName || 'virtuoso-grid-item-container'}
          components={{
            Footer: Footer || DefaultFooter,
            Header: Header || null,
          }}
          itemContent={items?.length > 0 ? virtuosoComponent(ItemComponent, items) : Placeholder}
        />
      )}
    </>
  );
};

export default SimpleItemList;
