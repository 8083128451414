import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import TopicContent from '../../TopicContent';
import UniverseLoader from '../../Loaders/UniverseLoader';

import topicSourceTypeState from '../../../models/topicSourceType/atom';
import { useTopic } from '../../../models/topic/useTopic';

export const CardTitle = ({
  topicId,
}) => {
  const topicSourceType = useRecoilValue(topicSourceTypeState(topicId));
  const { data, loading } = useTopic(topicId, { loadIfNeeded: true, sourceType: topicSourceType });

  if (loading || !data) return <UniverseLoader boxType='fullContainer' />;

  const { title, shortId, slug } = data;

  return (
    <section style={{ lineHeight: '20px' }} className='topic-card-title my-1'>
      <span className='font-bold leading-snug'>
        <Link to={`/topic/${shortId}/${slug}`}>
          <TopicContent content={title} />
        </Link>
      </span>
    </section>
  );
};
