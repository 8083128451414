import { useCallback, useRef } from 'react';
import { useTopic } from '../../models/topic/useTopic';
import {
  CardHeaderContentDetailTypes,
} from '../../lib/constants';
import { emitOpenDrawerMenu } from '../../lib/emitters/emitOpenDrawerMenu';
import { CardContainer } from './CardContainer';
import { CardHeaderContainer } from './CardHeaderContainer';
import { CardHeaderContent } from './CardHeaderContent';
import { CardTitle } from './CardTitle';
import { CardPostContent } from './CardPostContent';
import { CardActions } from './CardActions';

export const TopicCard = ({
  sourceType,
  detailsType = CardHeaderContentDetailTypes.User,
  _id,
}) => {
  const containerRef = useRef(null);

  const {
    loading,
    data,
  } = useTopic(_id, { sourceType });

  const handleEditClick = useCallback(() => {
    if (!data || loading || !sourceType) return null;
    emitOpenDrawerMenu({
      sourceType,
      topicId: _id,
      authorId: data?.author._id,
      postId: data?.mainPost._id,
    });
  }, [data, loading, sourceType]);

  if (loading) return null;

  return (
    <article ref={containerRef} className='topic-card font-roboto flex flex-col space-y-2 py-1 px-1'>
      <CardContainer
        author={data?.author}
        isDeleted={data?.isDeleted}
        isModeratorPost={data?.mainPost?.isModeratorPost}
      >
        <CardHeaderContainer
          isModeratorPost={data?.mainPost?.isModeratorPost}
          pin={data?.pin}
          sourceType={sourceType}
          isDeleted={data?.isDeleted}
          isLocked={data?.isLocked}
          handleEditClick={handleEditClick}
          includeEditMenu
          showNewPostIcon
          _id={_id}
        >
          <CardHeaderContent
            category={data?.category}
            author={data?.author}
            createdOn={data?.createdOn}
            detailsType={detailsType}
            sourceType={sourceType}
          />
        </CardHeaderContainer>
        <CardTitle topicId={data?._id} />
        <CardPostContent sourceType={sourceType} _id={data?.mainPost._id} />
        <CardActions
          topicId={data?._id}
          postId={data?.mainPost?._id}
          vote={data?.mainPost?.vote}
          score={data?.mainPost?.score}
          shortId={data?.shortId}
          slug={data?.slug}
          stats={data?.stats}
          showIsSaved={false}
          showIsHidden={false}
        />
      </CardContainer>
    </article>
  );
};

export default TopicCard;
