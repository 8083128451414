/* eslint-disable react/jsx-closing-tag-location */

import {
  TypeaheadOption,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import UserAvatar from '../../../UserAvatar';

export class MentionTypeaheadOption extends TypeaheadOption {
  mention;
  constructor(mention) {
    super(mention.username);
    this.mention = mention;
  }
}

export const MentionsTypeaheadMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}) => {
  const {
    username,
    avatar,
    _id,
    color,
  } = option.mention;
  const className = `${isSelected ? 'bg-primary-light text-surface ' : ''}item px-2 py-1 flex items-center `;
  return (
    <li
      style={{ cursor: 'pointer' }}
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role='option'
      aria-selected={isSelected}
      id={`typeahead-item-${index}`}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <UserAvatar _id={_id} avatarFontSize='12px' avatarSize='24px' />
      <span className='text-md ml-2'>{username}</span>
    </li>
  );
};
