import { Card } from '@mui/material';

const ErrorCard = ({ message }) => (
  <article className='topic-card tku-no-select font-roboto flex flex-col space-y-2 py-1 px-1 text-primary-text'>
    <Card sx={{ padding: '5px 8px' }}>
      <div className='flex flex-col space-y-2 py-4 px-1'>
        <span className='leading-0 text-xs'>{message || 'it looks like we\'ve got an error 🙃'}</span>
      </div>
    </Card>
  </article>
);

export default ErrorCard;
