import { selector } from 'recoil';
import { Breakpoints } from '../../lib/constants';
import state from './atom';

export const themeSelector = selector({
  key: 'themeSelector',
  get: ({ get }) => get(state).theme,
  set: ({ set, get }, newValue) => set(state, { ...get(state), theme: newValue }),
});

export const randomSelector = selector({
  key: 'randomSelector',
  get: ({ get }) => get(state).random,
  set: ({ set, get }, newValue) => set(state, { ...get(state), random: newValue }),
});

export const breakpointNameSelector = selector({
  key: 'breakpointNameSelector',
  get: ({ get }) => get(state).breakpointName,
  set: ({ set, get }, newValue) => {
    const newBreakpoint = Object.values(Breakpoints).find(bp => bp === newValue);
    if (newBreakpoint) {
      set(state, { ...get(state), breakpointName: newBreakpoint });
    }
  },
});

// TODO: make this a sep atom with load effect
export const reputationLevelsSelector = selector({
  key: 'reputationLevelsSelector',
  get: ({ get }) => get(state).reputationLevels,
  set: ({ set, get }, newValue) => {
    set(state, { ...get(state), reputationLevels: newValue });
  },
});

export const connectionIdSelector = selector({
  key: 'connectionIdSelector',
  get: ({ get }) => get(state).connectionId,
  set: ({ set, get }, newValue) => set(state, { ...get(state), connectionId: newValue }),
});

export const isPwaSelector = selector({
  key: 'isPwaSelector',
  get: ({ get }) => get(state).isPwa,
  set: ({ set, get }, newValue) => set(state, { ...get(state), isPwa: newValue }),
});

export const hasNotificationsEnabledSelector = selector({
  key: 'hasNotificationsEnabledSelector',
  get: ({ get }) => get(state).hasNotificationsEnabled,
  set: ({ set, get }, newValue) => set(state, { ...get(state), hasNotificationsEnabled: newValue }),
});

export const platformSelector = selector({
  key: 'platformSelector',
  get: ({ get }) => get(state).platform,
  set: ({ set, get }, newValue) => set(state, { ...get(state), platform: newValue }),
});

export const visibilitySelector = selector({
  key: 'visibilitySelector',
  get: ({ get }) => get(state).visibility,
  set: ({ set, get }, newValue) => set(state, { ...get(state), visibility: newValue }),
});

export const navLoaderSelector = selector({
  key: 'navLoaderSelector',
  get: ({ get }) => get(state).navLoader,
  set: ({ set, get }, newValue) => set(state, { ...get(state), navLoader: newValue }),
});
