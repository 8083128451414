import { useUser } from '../../models/user/useUser';
import currencyFormat from '../../lib/currencyFormat';
import abbreviateNumber from '../../lib/abbreviateNumber';

const UserStats = ({
  _id,
  iconSize = '22px',
  containerClass,
  iconSide = 'right',
  shouldAbbreviateNumber = false,
}) => {
  const { data } = useUser(_id);

  if (!data) return null;

  const {
    reputation,
    currency,
  } = data;

  let _reputation = currencyFormat(reputation);
  let _currency = currencyFormat(currency);
  if (shouldAbbreviateNumber) {
    _reputation = abbreviateNumber(reputation);
    _currency = abbreviateNumber(currency);
  }

  if (iconSide === 'right') {
    return (
      <div className={`flex justify-between ${containerClass || 'px-10 space-x-5 mt-2'}`}>
        <span className='font-bold font-roboto flex items-center'>
          { _currency }
          <img className='inline ml-2' src='/assets/brand/2kumt.svg' style={{ height: iconSize }} alt='MT icon' />
        </span>
        <span className='font-bold font-roboto flex items-center'>
          { _reputation }
          <img className='inline ml-2' src='/assets/brand/xp.svg' style={{ height: iconSize }} alt='xp icon' />
        </span>
      </div>
    );
  }
  if (iconSide === 'left') {
    return (
      <div className={`flex justify-between ${containerClass || 'px-10 space-x-5 mt-2'}`}>
        <span className='font-bold font-roboto flex items-center'>
          <img className='inline mr-2' src='/assets/brand/2kumt.svg' style={{ height: iconSize }} alt='MT icon' />
          { _currency }
        </span>
        <span className='font-bold font-roboto flex items-center'>
          <img className='inline mr-2' src='/assets/brand/xp.svg' style={{ height: iconSize }} alt='xp icon' />
          { _reputation }
        </span>
      </div>
    );
  }
};

export default UserStats;
