import {
  Button,
} from '@mui/material'; import { useCallback, useMemo, useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useSetRecoilState } from 'recoil';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { CustomEvents, SourceTypes, UserPrivateTopicStatus } from '../../lib/constants';

import UserAvatar from '../UserAvatar';
import UserSelect from '../UserSelect';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import ManageUserInMessageMenu from '../ManageUserInMessageMenu';
import { useTopic } from '../../models/topic/useTopic';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const ManageTopicUsers = ({
  _id,
  addUserCallback,
}) => {
  const { data, model, loading } = useTopic(_id, { sourceType: SourceTypes.Message });

  const [view, setView] = useState('manage');
  const [userToAdd, setUserToAdd] = useState(null);
  const { data: user } = useAuthenticatedUser();
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const userIsOwner = useMemo(() => {
    if (!data || !user) return false;
    return data.usersPermitted.find(u => u.user._id === user._id && u.role === 'owner');
  }, [user, data]);

  const addUser = useCallback(() => {
    const handler = async () => {
      const res = await model.addUser(userToAdd._id);
      if (res.success) {
        addSnackbar({ message: `Message request sent to ${userToAdd.username}.`, color: 'primary' });
        setUserToAdd(null);
        if (addUserCallback) addUserCallback();
      }
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, {
      handler,
      text: `Are you sure you want to add ${userToAdd.username} to this message`,
      data: { description: { text: 'This user will be able to see all previous messages. You can always remove them from the message later.' } },
    });
  }, [data, userToAdd, addUserCallback]);

  const handleViewToggle = useCallback(() => setView(prev => (prev === 'manage' ? 'add' : 'manage')), []);

  const renderUser = useCallback((u) => {
    const { user: _user, role, status } = u;
    const {
      avatar,
      _id: userId,
      color,
      username,
    } = _user;
    if (user._id === userId) return null;

    const messageStatus = Object.values(UserPrivateTopicStatus).find(s => s.toLowerCase() === status.toLowerCase());

    const pending = status === UserPrivateTopicStatus.Pending;
    const banned = status === UserPrivateTopicStatus.Banned;

    // TODO: add dropdown for mobile options
    return (
      <div key={userId} className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <div className='relative'>
            <div style={{ top: -7.5, right: -7.5 }} className='absolute z-10'>
              { pending && <AccessTimeFilledIcon sx={{ height: '20px', width: '20px', color: 'var(--color-disabled)' }} /> }
              { banned && <RemoveCircleIcon sx={{ height: '20px', width: '20px', color: 'var(--color-error)' }} /> }
            </div>
            {(pending || banned) && (
            <div
              style={{
                borderRadius: '100%',
                padding: 0,
                position: 'absolute',
                height: '12.5px',
                width: '12.5px',
                top: -1.5,
                right: -5,
                zIndex: 9,
              }}
              className='bg-primary-text'
            />
            )}
            <UserAvatar _id={userId} avatar={avatar} color={color} username={username} avatarSize='30px' />
          </div>
          <span>{ username }</span>
        </div>
        <div className='space-x-2 flex'>
          <ManageUserInMessageMenu
            userId={userId}
            _id={_id}
            messageStatus={messageStatus}
            userRole={role}
          />
        </div>
      </div>
    );
  }, [data]);

  if (!data) return null;

  return (
    <div className='bg-surface'>
      <div className='bg-s02dp py-4 px-2 font-roboto text-primary-text'>
        <div className='pb-3 px-3 flex justify-between items-center'>
          <h3 className='font-bold text-[16px]'>{`${view === 'manage' ? 'Manage' : 'Add'} Users`}</h3>
          <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={handleViewToggle}
            endIcon={view === 'manage' ? <PersonAddIcon /> : <PersonRemoveIcon />}
          >
            { view === 'manage' ? 'Add Users' : 'Manage Users'}
          </Button>
        </div>
        <div className='flex flex-col space-y-3 max-h-[300px] overflow-auto tku-custom-scroller px-3 py-2'>
          {
            view === 'manage' && (
              <>
                { data.usersPermitted.map((u, i) => renderUser(u))}
              </>
            )
          }
          { view === 'add' && (
            <div className='pt-2 h-[300px] flex flex-col justify-between'>
              <UserSelect setUser={setUserToAdd} user={userToAdd} usersToExclude={data.usersPermitted.map((u, i) => u.user)} />
              <Button
                disabled={!userToAdd}
                color='primary'
                variant='contained'
                fullWidth
                onClick={addUser}
              >
                {userToAdd ? `Add ${userToAdd.username}` : 'Select User to Add'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageTopicUsers;
