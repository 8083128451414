import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import ApiClientInstance from '../../clients/api';
import { Wrapper, Content } from '../../components/Containers';
import Error from '../../components/Error';
import sleep from '../../lib/sleep';
import { isOnlineSelector } from '../../models/app/selectors';

const NotFound = ({ error }) => {
  const navigate = useNavigate();
  const setIsOnline = useSetRecoilState(isOnlineSelector);

  useEffect(() => {
    let isCancelled = false;
    const handler = async () => {
      try {
        const res = await ApiClientInstance.sendRequest({
          method: 'get',
          path: '/status',
          catchError: true,
          retryTimes: 3,
        });
        if (!res.success) return setIsOnline(false);
      } catch (e) {
        setIsOnline(false);
      }
      await sleep(3000);
      if (isCancelled) return;
      navigate('/');
    };
    handler();
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Wrapper className='error'>
      <Content className='flex items-center justify-center text-primary-text text-xl font-roboto flex-col space-y-2'>
        <Error />
      </Content>
    </Wrapper>
  );
};

export default NotFound;
