/* eslint-disable quotes */
/* eslint-disable no-useless-escape */
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from '@mui/material';
import { Wrapper, Content } from '../Containers';
import { TopicModel } from '../../models/topic/model';
import { topicSelector } from '../../models/topic/selectors';
import { AuthenticatedUserModel } from '../../models/authenticatedUser/model';
import { TkuQueryClient } from '../../providers/query';
import topicListQueryKeyState from '../../models/topicListQueryKey/atom';
// 62ec0b9e3bc8dea958885af4

const h = [topicSelector, useRecoilValue];

const AdminDeveloper = () => {
  // const { topic, loading: topicLoading, fetching } = useRecoilValue(topicState('62ec0b9e3bc8dea958885af4'));

  // const { topic, loading, fetching } = useTopic('62ec0b9e3bc8dea958885af4');

  const CurrentTopicModel = useMemo(() => new TopicModel('62ec0b9e3bc8dea958885af4'), []);
  const AuthenticatedUser = useMemo(() => new AuthenticatedUserModel(), []);
  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const topicListQueryKey = useRecoilValue(topicListQueryKeyState);

  console.log({
    topicListQueryKey,
  });

  return (
    <Wrapper applyBreakpoints className='text-primary-text'>
      <style>
        {
          `
          .u-logo-rotate {
            animation: rotate 3s infinite linear;
          }
          `
        }
      </style>
      <div className='flex px-3 pt-2 space-x-2 justify-between items-center'>
        <h1 className='text-3xl font-bebas my-3 '>Feature Preview</h1>
      </div>
      <Content className='font-roboto flex flex-col items-center text-primary-text'>
        <div style={{ height: '20%' }} className='text-primary-text flex flex-col items-center justify-center'>
          <h1>Coming Soon!</h1>
        </div>
        <div className='flex flex-col justify-center space-y-4'>
          {/* <button
            type='button'
            onClick={async () => {
              const res = await CurrentTopicModel.requestTopic();
              if (res.success) CurrentTopicModel.setTopic(res.data);
            }}
          >
            req topic
          </button> */}
          <Button
            type='button'
            onClick={() => {
              const queries = TkuQueryClient.getQueryData(topicListQueryKey);
              console.log(queries);
            }}
          >
            log query data
          </Button>
          <Button
            type='button'
            onClick={() => CurrentTopicModel.reload()}
          >
            reload
          </Button>
          <Button
            type='button'
            onClick={() => {
              CurrentTopicModel.fetch();
            }}
          >
            fetch
          </Button>
          {/* <div>
            {JSON.stringify(topicSelectorTopic?.textContent || 'no topic')}
          </div> */}
          <div>
            {JSON.stringify(loading)}

          </div>
          <div>
            {JSON.stringify(fetching)}

          </div>
        </div>
      </Content>
    </Wrapper>
  );
};

export default AdminDeveloper;
