import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeSelector } from '../models/settings/selectors';
import { themes, DEFAULT_THEME } from '../styles/themes';

const mapPalette = (theme) => {
  const keys = Object.keys(theme);
  const obj = {};
  for (const key of keys) {
    if (key === 'mode') continue;
    const value = theme[key];
    if (key === 'primary' || key === 'secondary') {
      obj[key] = {
        main: value,
        light: theme[`${key}Light`],
        dark: theme[`${key}Dark`],
      };
    } else if (key !== 'primary' && key !== 'secondary') {
      obj[key] = {
        main: value,
      };
    }
  }
  return obj;
};

const PaletteProvider = ({ children }) => {
  const [themeState] = useRecoilState(themeSelector);
  const [currentTheme, setCurrentTheme] = useState(createTheme({
    palette: {
      mode: themes[DEFAULT_THEME].mode || 'light',
      ...mapPalette(themes[DEFAULT_THEME]),
    },
  }));

  useEffect(() => {
    const themeObj = themes[themeState];
    const newTheme = createTheme({
      palette: {
        mode: themeObj.mode || 'light',
        ...mapPalette(themes[themeState]),
        background: {
          paper: themeObj.surface,
          default: themeObj.surface,
        },
      },
    });
    window.muiTheme = newTheme;
    setCurrentTheme(newTheme);
    localStorage.setItem('DEFAULT_THEME', themeState);
  }, [themeState]);

  return (
    <ThemeProvider theme={currentTheme}>
      {children}
    </ThemeProvider>
  );
};

export default PaletteProvider;
