/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import {
  useMemo,
} from 'react';

import {
  MessageStatus,
  SourceTypes,
  UserPrivateTopicStatus,
} from '../lib/constants';
import { getDistance } from '../lib/formatDate';

import { useTopic } from '../models/topic/useTopic';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

const MAX_PREVIEW_CHARACTERS = 75;

export const useMessageMetaData = ({ _id }) => {
  const { data: message, loading } = useTopic(_id, { sourceType: SourceTypes.Message });

  const { data: user } = useAuthenticatedUser();

  const {
    latestReply,
    latestReplyDate,
    usersPermitted,
    createdOn,
    isDeleted,
    textContent,
  } = message;

  const { status: acceptanceStatus, role } = useMemo(() => usersPermitted.find(u => u.user._id === user._id), [usersPermitted, user]);

  const viewStatus = useMemo(() => {
    if (latestReply?.author._id === user._id) return MessageStatus.Read;
    const _status = latestReply?.status;
    if (!_status || !_status?.length) return MessageStatus.Unread;
    const _statusObj = _status.find((s) => s.user === user._id);
    if (!_statusObj) return MessageStatus.Unread;
    return _statusObj.value;
  }, [latestReply]);

  const timeAgoColor = useMemo(() => {
    if (acceptanceStatus !== UserPrivateTopicStatus.Accepted) return 'var(--color-text-primary)';
    if (!latestReplyDate) return 'var(--color-text-primary)';
    if (viewStatus === MessageStatus.Read) return 'var(--color-text-primary)';
    return 'var(--color-primary-light)';
  }, [viewStatus, acceptanceStatus]);

  const users = useMemo(() => usersPermitted.filter(u => u.user._id !== user._id), [usersPermitted, user]);

  const chatTitle = useMemo(() => {
    if (textContent) return textContent;
    if (usersPermitted.length === 2) return users[0].user.username;
  }, [usersPermitted.length, textContent]);

  const latestReplyText = useMemo(() => {
    const { textContent: replyTextContent } = latestReply;
    if (!latestReply) return 'no messages';
    if (latestReply.isDeleted) return 'this message has been deleted';
    const msgText = replyTextContent.length > MAX_PREVIEW_CHARACTERS ? `${replyTextContent.substring(0, MAX_PREVIEW_CHARACTERS)}...` : replyTextContent;

    if (latestReply.author._id === user._id) return `you: ${msgText}`;
    return `${latestReply.author.username}: ${msgText}`;
  }, [latestReply, usersPermitted.length, isDeleted]);

  const [isRead, isUnread, isUnseen] = useMemo(() => {
    let _isRead = false;
    let _isUnread = false;
    let _isUnseen = false;
    if (!latestReply) return [_isRead, _isUnread, _isUnseen];
    if (viewStatus === MessageStatus.Read) {
      _isRead = true;
    } else if (viewStatus === MessageStatus.Unread) {
      _isUnread = true;
    } else if (viewStatus === MessageStatus.Unseen) {
      _isUnseen = true;
    }
    return [_isRead, _isUnread, _isUnseen];
  }, [viewStatus, latestReply]);

  const backgroundColor = useMemo(() => {
    if (isRead || acceptanceStatus !== UserPrivateTopicStatus.Accepted) return '';
    if (isUnseen) return 'var(--color-secondary-light)';
    if (isUnread) return 'var(--color-primary-light)';
    return '';
  }, [isRead, isUnread, isUnseen, acceptanceStatus]);

  const timeAgo = useMemo(() => {
    if (acceptanceStatus !== UserPrivateTopicStatus.Accepted) return getDistance(createdOn);
    if (latestReplyDate) return getDistance(latestReplyDate);
    return getDistance(createdOn);
  }, [latestReplyDate, createdOn, acceptanceStatus]);

  const messageReadStatus = useMemo(() => (acceptanceStatus === UserPrivateTopicStatus.Accepted ? viewStatus : false), [viewStatus, acceptanceStatus]);

  return {
    acceptanceStatus,
    role,
    viewStatus,
    timeAgoColor,
    users,
    chatTitle,
    latestReplyText,
    backgroundColor,
    isRead,
    isUnread,
    isUnseen,
    timeAgo,
    messageReadStatus,
  };
};
