import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

const usePathMatch = (patterns = []) => {
  const location = useLocation();
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    if (patterns.length) {
      const match = patterns.some(pattern => matchPath(pattern, location.pathname));
      setIsMatch(match);
    }
  }, [location]);

  return isMatch;
};

export default usePathMatch;
