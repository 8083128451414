import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLayoutEffect, useEffect } from 'react';

const CAN_USE_DOM = typeof window !== 'undefined' && typeof window.document !== 'undefined' && typeof window.document.createElement !== 'undefined';

const useLayoutEffectImpl = CAN_USE_DOM ? useLayoutEffect : useEffect;
const _useLayoutEffect = useLayoutEffectImpl;

export const OnChangePlugin = ({
  ignoreInitialChange = true,
  ignoreSelectionChange = false,
  onChange,
  editorName,
}) => {
  const [editor] = useLexicalComposerContext();
  _useLayoutEffect(() => {
    if (onChange) {
      return editor.registerUpdateListener(({
        editorState,
        dirtyElements,
        dirtyLeaves,
        prevEditorState,
      }) => {
        if (ignoreSelectionChange && dirtyElements.size === 0 && dirtyLeaves.size === 0) return;
        if (ignoreInitialChange && prevEditorState.isEmpty()) return;
        onChange(editorState, editor, editorName);
      });
    }
  }, [editor, ignoreInitialChange, ignoreSelectionChange, onChange, editorName]);
  return null;
};
