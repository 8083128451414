import { useSetRecoilState } from 'recoil';
import { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import UserSelect from '../UserSelect';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const ChangeAuthor = ({
  mainContainerClassname,
  author,
  handleConfirm,
  handleCancel,
}) => {
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const [_user, setUser] = useState(author || null);

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          _user,
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: 'Change author',
        data: {
          description: { text: `Author will be changed from ${author.username} to ${_user.username}` },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, _user]);

  const _handleCancel = useCallback(async () => {
    await handleCancel();
  }, [handleCancel]);

  return (
    <div className={`${mainContainerClassname} h-[350px] tablet:h-[400px] flex flex-col justify-between`}>
      <div>
        <h1 className='mb-2'>Change Author</h1>
        <UserSelect
          className='mt-4'
          label='author'
          placeholder='author'
          user={_user}
          setUser={setUser}
        />
      </div>
      <div className='flex justify-between mt-4 space-x-5'>
        <Button
          color='secondaryLight'
          variant='outlined'
          onClick={_handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!_user || _user?._id === author?._id}
          variant='contained'
          color='primary'
          onClick={_handleSubmit}
        >
          Change Author
        </Button>
      </div>
    </div>
  );
};

export default ChangeAuthor;
