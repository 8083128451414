import { atom } from 'recoil';
import { defaultLoadableAtom } from '../../lib/constants';

const authenticatedUserState = atom({
  key: 'authenticatedUserState_v2',
  default: {
    ...defaultLoadableAtom,
  },
  // effects: [
  //   ({
  //     setSelf,
  //     trigger,
  //     getLoadable,
  //     node,
  //   }) => {
  //     if (trigger === 'set') return;
  //     const currentState = getLoadable(node).contents;
  //     if (currentState.hasLoaded || currentState.loading) return;
  //     const fetchData = async () => {
  //       setSelf({
  //         loading: true,
  //         error: null,
  //         data: null,
  //         fetching: false,
  //         hasLoaded: false,
  //       });
  //       const res = await verifyUser();
  //       if (res.success) {
  //         setSelf({
  //           loading: false,
  //           error: null,
  //           data: res.data,
  //           fetching: false,
  //           hasLoaded: true,
  //         });
  //       } else {
  //         setSelf({
  //           loading: false,
  //           error: res.error,
  //           data: null,
  //           fetching: false,
  //           hasLoaded: false,
  //         });
  //       }
  //     };
  //     fetchData();
  //   },
  // ],
});

export default authenticatedUserState;
