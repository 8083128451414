import {
  Button,
  IconButton,
  Modal,
  TextField,
} from '@mui/material';
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import useHandleImageUpload from '../../hooks/useHandleImageUpload';
import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, ImageUploadModalTypes } from '../../lib/constants';

const ImageUploadModal = () => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [bodyType, setBodyType] = useState(ImageUploadModalTypes.General);
  const [_handleConfirm, setHandleConfirm] = useState(null);
  const [_handleCancel, setHandleCancel] = useState(null);
  const [uploadFolder, setUploadFolder] = useState('');
  const [modalTitle, setModalTitle] = useState('Image Upload');
  const [options, setOptions] = useState({});
  const location = useLocation();

  const handleOpen = useCallback(({
    uploadFolder: _uploadFolder,
    type,
    modalTitle: _modalTitle,
    handleConfirm,
    handleCancel,
    image: _image,
    options: _options,
  }) => {
    if (type) setBodyType(type);
    if (_modalTitle) setModalTitle(_modalTitle);
    if (handleConfirm) setHandleConfirm(() => handleConfirm);
    if (handleCancel) setHandleCancel(() => handleCancel);
    if (_uploadFolder) setUploadFolder(_uploadFolder);
    if (_image) setImage(_image);
    if (_image?.title) setTitle(_image.title);
    if (_options) setOptions(_options);
    setOpen(true);
  }, []);

  useCustomEvent(CustomEvents.OpenImageUploadModal, handleOpen);

  const handleClear = useCallback(() => {
    setImage(null);
    setTitle('');
    if (_handleCancel) _handleCancel();
    if (options?.closeOnClear) setOpen(false);
  }, [options]);

  const handleClose = useCallback(() => {
    setOpen(false);
    handleClear();
    if (_handleCancel) _handleCancel();
  }, []);

  const handleFileChange = useHandleImageUpload({
    uploadFolder: uploadFolder || 'uploads',
    uploadTitle: title,
  });

  const handleFileUpload = useCallback(async (e) => {
    const data = await handleFileChange(e);
    if (data) setImage(data);
  }, [handleFileChange, title]);

  const __handleConfirm = useCallback(async () => {
    let _title;
    if (!title) _title = image.url.split('/').pop();
    await _handleConfirm({ ...image, title: title || _title });
    handleClose();
  }, [image, title]);

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const body = useMemo(() => {
    if (bodyType === ImageUploadModalTypes.General) {
      return (
        <>
          <div className='mt-2 flex justify-center items-center bg-s01dp h-[250px] w-[100%] rounded'>
            { !image && (
            <label
              className='file-input-label'
              htmlFor='file-image-input'
            >
              <input accept='image/*' onChange={handleFileUpload} id='file-image-input' style={{ display: 'none' }} type='file' hidden />
              <div style={{ border: '1px solid var(--color-primary-light)' }} role='button' className='flex items-center space-x-2 py-2 px-3 rounded text-primary-light'>
                <FileUploadIcon />
                <span className='font-roboto uppercase'>Select Image</span>
              </div>
            </label>
            )}
            { image && (
              <img
                style={{
                  width: 'auto',
                  maxHeight: '100%',
                  display: 'block',
                }}
                src={image.url}
                alt={title || image.url}
                className='rounded w-full h-full px-2 py-2'
              />
            ) }
          </div>
          { image && (
          <TextField
            value={title}
            onChange={handleTitleChange}
            size='small'
            sx={{ marginTop: '12px' }}
            fullWidth
            label='add custom title (optional)'
          />
          ) }
        </>
      );
    }
    return null;
  }, [image, title, handleFileUpload, bodyType]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='bg-surface px-4 py-2 rounded font-roboto text-primary-text w-[95vw] tablet:w-[400px] overflow-hidden'>
        <div className='flex justify-between items-center'>
          <div style={{ fontSize: '24px' }} className='font-bebas'>{ modalTitle }</div>
          <IconButton onClick={handleClose}><CancelIcon /></IconButton>
        </div>
        { body }
        <div className='flex justify-between pt-5'>
          <Button
            variant='outlined'
            color='secondaryLight'
            onClick={image ? handleClear : handleClose}
          >
            { image ? 'Clear' : 'Cancel' }
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!image}
            onClick={__handleConfirm}
          >
            Insert
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImageUploadModal;
