import { useCallback, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplyIcon from '@mui/icons-material/Reply';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Lock from '@mui/icons-material/Lock';
import { emitResetPaginatedPostList } from '../../../lib/emitters/emitResetPaginatedPostList';
import { emitOpenDrawerMenu } from '../../../lib/emitters/emitOpenDrawerMenu';
import { usePost } from '../../../models/post/usePost';
import { useTopic } from '../../../models/topic/useTopic';

export const CardHeaderContainer = ({
  isDeleted,
  pinned,
  isModeratorPost,
  replyToPid,
  includeEditMenu = true,
  includeReply = true,
  children,
  includeScrollToPostButton = false,
  sourceType,
  _id,
}) => {
  const { data, loading } = usePost(_id, { sourceType });

  const { data: topicData } = useTopic(data?.topic?._id, { sourceType });

  const handleEditClick = useCallback(() => {
    if (!data || loading || !sourceType) return null;
    emitOpenDrawerMenu({
      sourceType,
      topicId: data?.topic?._id,
      authorId: data?.author?._id,
      postId: _id,
    });
  }, [data, loading, sourceType]);

  const replyButton = useMemo(() => {
    if (!replyToPid || !includeReply) return null;
    return (
      <button type='button' className='flex items-center mr-1 flex-shrink-0' onClick={() => emitResetPaginatedPostList({ postId: replyToPid._id })}>
        <span className='text-xs mr-1 opacity-80'>{replyToPid.author.username}</span>
        <ReplyIcon className='opacity-70' sx={{ fontSize: '16px' }} />
      </button>
    );
  }, [replyToPid, includeReply]);

  const scrollToPostButton = useMemo(() => {
    if (!includeScrollToPostButton) return null;
    const handleScrollToPostClick = () => emitResetPaginatedPostList({ postId: _id });
    return (
      <IconButton edge='end' size='small' onClick={handleScrollToPostClick}>
        <ArrowDownwardIcon />
      </IconButton>
    );
  }, [includeScrollToPostButton, _id]);

  return (
    <section className='post-card-header flex items-center relative'>
      <div className='absolute top-0 right-0 w-12 flex justify-between items-center flex-row-reverse'>
        { (includeEditMenu && handleEditClick) && (
          <IconButton edge='end' size='small' onClick={handleEditClick}>
            <MoreHorizIcon sx={{ opacity: '.8' }} color='text.main' />
          </IconButton>
        ) }
        { scrollToPostButton }
        { isModeratorPost && <LocalPoliceIcon sx={{ fontSize: '18px' }} /> }
        { pinned && <PushPinIcon sx={{ fontSize: '18px' }} /> }
        { isDeleted && <DeleteIcon sx={{ fontSize: '18px' }} /> }
        { topicData?.isLocked && data?.isMain && <Lock sx={{ fontSize: '18px' }} /> }
        { replyButton }
      </div>
      { children }
    </section>
  );
};
