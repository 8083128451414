import { nanoid } from 'nanoid';
import { useEffect, useRef } from 'react';

const getElement = () => document.querySelector('.tku-event-div');

export const useCustomEvent = (eventName, eventHandler, dependencies = []) => {
  const savedHandler = useRef();
  const eventId = useRef(nanoid(8));

  useEffect(() => {
    // console.log(`[#]::[${eventId.current}]::UPDATE::event: ${eventName}`);
    savedHandler.current = eventHandler;
  }, [eventHandler]);

  useEffect(() => {
    if (!eventName) return null;
    const element = getElement();
    const handleEvent = (event) => {
      savedHandler.current(event.detail);
    };
    // console.log(`[+]::[${eventId.current}]::ADDED::event: ${eventName}`);
    element.addEventListener(eventName, handleEvent, false);
    return () => {
      // console.log(`[-]::[${eventId.current}]::REMOVED::event: ${eventName}`);
      element.removeEventListener(eventName, handleEvent);
    };
  }, [...dependencies]);
};

export const emitCustomEvent = (eventName, data) => {
  const element = getElement();
  const event = new CustomEvent(eventName, { detail: data });
  element.dispatchEvent(event);
};

window.emitCustomEvent = emitCustomEvent;
