import { useMemo } from 'react';
import { Button, Card } from '@mui/material';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import { SourceTypes, UserRoles } from '../../../lib/constants';
import { useBlockedContent } from '../../../hooks/useBlockedContent';

const DeletedMessage = ({ sourceType = 'topic' }) => (
  <div className='flex items-center justify-center h-[100px] text-primary-text text-sm opacity-75'>
    {`this ${sourceType} has been deleted`}
  </div>
);

const BlockedMessage = ({ setBlockOverride, username }) => (
  <div className='flex flex-col items-center justify-center h-[100px] text-primary-text text-sm opacity-80'>
    {`this content is hidden because you have ${username} blocked`}
    <Button
      color='primaryLight'
      onClick={() => setBlockOverride(true)}
    >
      View Anyways
    </Button>
  </div>
);

export const CardContainer = ({
  author,
  isDeleted,
  children,
  className,
  sourceType,
  isModeratorPost,
}) => {
  const { data, unauthenticated } = useAuthenticatedUser();
  const { setBlockOverride, authorBlocked } = useBlockedContent({ author });

  const showDeleted = useMemo(() => {
    if (!isDeleted) return true;
    if (unauthenticated) return false;
    if (data._id === author._id) return true;
    if (sourceType === SourceTypes.Message) return false;
    return [UserRoles.Admin, UserRoles.SuperAdmin, UserRoles.Moderator].includes(data?.role);
  }, [unauthenticated, data, isDeleted]);

  const showContent = useMemo(() => {
    if (isModeratorPost) return true;
    if (!showDeleted) return false;
    return !authorBlocked;
  }, [showDeleted, authorBlocked, isModeratorPost]);

  const backgroundColor = useMemo(() => {
    if (isDeleted) return 'var(--color-s001dp)';
    if (isModeratorPost) return 'color-mix(in srgb, var(--color-s03dp), var(--color-card-shading-secondary))';
    return 'var(--color-s003dp)';
  }, [isDeleted, isModeratorPost]);

  return (
    <Card
      className={className}
      sx={{
        pointerEvents: !showDeleted ? 'none' : '',
        padding: '5px 8px',
        backgroundColor,
        opacity: isDeleted ? '.65' : '',
      }}
    >
      { showContent && children }
      { !showDeleted && <DeletedMessage sourceType={sourceType} /> }
      { (authorBlocked && showDeleted && !isModeratorPost) && <BlockedMessage username={author.username} setBlockOverride={setBlockOverride} />}
    </Card>
  );
};
