/* eslint-disable react/function-component-definition */
import {
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  useSetRecoilState,
} from 'recoil';
import {
  UserRoles,
  SourceTypes,
  SocketRooms,
} from '../../lib/constants';

import PostList from '../../components/PostList';
import DrawerMenu from '../../components/DrawerMenu';
import { Wrapper } from '../../components/Containers';
import UsersOnline from '../../components/UsersOnline';
import TopicSettings from '../../components/TopicSettings';
import SocketRoom from '../../components/SocketRoom';
import TopicBottomToolbar from '../Topic/TopicBottomToolbar';
import TopicComposer from '../Topic/TopicComposer';
import UniverseLoader from '../../components/Loaders/UniverseLoader';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useTopic } from '../../models/topic/useTopic';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import postSourceTypeState from '../../models/postSourceType/atom';
import topicSourceTypeState from '../../models/topicSourceType/atom';
import postInViewState from '../../models/postInView/atom';
import useRequireAuth from '../../hooks/useRequireAuth';
import { useUserIsWriting } from '../../hooks/useUserIsWriting';
import UsersWriting from '../../components/UsersWriting';

const Message = () => {
  // Ref
  const wrapperRef = useRef();

  // Hooks
  const navigate = useNavigate();
  const { id } = useParams();
  useDocumentTitle(null);
  const { data: user, authenticated } = useAuthenticatedUser();
  const [parentLoading, setParentLoading] = useState(true);

  useRequireAuth('/login');

  const {
    data: topicData,
    error,
  } = useTopic(id, {
    fetchOnMount: true,
    sourceType: SourceTypes.Message,
  });

  useUserIsWriting(topicData?._id || 'false');

  useDocumentTitle(topicData?.textContent);

  const setPostSourceType = useSetRecoilState(postSourceTypeState(topicData?._id || 'false'));
  const setCurrentPost = useSetRecoilState(postInViewState(topicData?._id || 'false'));
  const setTopicSourceType = useSetRecoilState(topicSourceTypeState(topicData?._id || 'false'));

  // Guard Clause Topic
  useEffect(() => {
    if (!topicData?._id) return;
    setPostSourceType(SourceTypes.Message);
    setTopicSourceType(SourceTypes.Message);
    return () => {
      setCurrentPost(null);
    };
  }, [topicData?._id]);

  // Error Handler
  useEffect(() => {
    if (!error) return;
    navigate('/404');
  }, [error]);

  // Deleted/Purged Handler
  useEffect(() => {
    if (!topicData?.isDeleted) return null;
    if ([UserRoles.Admin, UserRoles.Moderator, UserRoles.SuperAdmin].includes(user?.role)) return null;
    return navigate('/');
  }, [topicData?.isDeleted, user]);

  const loader = useMemo(() => {
    if (!parentLoading) return null;
    return <UniverseLoader boxType='fullContainer' />;
  }, [parentLoading]);

  const mainPostContent = useMemo(() => {
    if (!topicData) return null;
    return (
      <>
        <Wrapper className='relative' applyBreakpoints ref={wrapperRef}>
          <UsersWriting sourceType={SourceTypes.Message} topicId={topicData?._id} />
          <PostList
            topic={topicData}
            setParentLoading={setParentLoading}
          />
          <DrawerMenu sourceType={SourceTypes.Message} />
          <TopicBottomToolbar _id={topicData?._id} />
          <TopicComposer _id={topicData?._id} sourceType={SourceTypes.Message} />
        </Wrapper>
        <UsersOnline sourceType={SourceTypes.Message} topicId={topicData?._id} />
        <TopicSettings sourceType={SourceTypes.Message} topicId={topicData?._id} />
        <SocketRoom roomPath={SocketRooms.Message} roomName={topicData?._id} />
      </>
    );
  }, [topicData]);

  return (
    <>
      { loader }
      { mainPostContent }
    </>
  );
};

export default Message;
