import Drawer from '@mui/material/Drawer';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';

import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import MessageIcon from '@mui/icons-material/Message';
import { ClickAwayListener } from '@mui/material';

import getContrastColor from '../../lib/getContrastColor';
import { CustomEvents } from '../../lib/constants';

import UserProfile from '../UserProfile';
import UniverseLoader from '../Loaders/UniverseLoader';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import usePathMatch from '../../hooks/usePathMatch';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useUser } from '../../models/user/useUser';
import usernameMappingState from '../../models/usernameMapping/atom';

const UserProfileDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isUserProfile = usePathMatch(['/users/:username']);

  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [sourceType, setSourceType] = useState('');
  const [zIndex, setZIndex] = useState(null);

  const { data: authenticatedUser, verified } = useAuthenticatedUser();

  const userId = useRecoilValue(usernameMappingState(username));

  const {
    data,
    loading,
    error,
  } = useUser(userId, {
    username,
    loadFromUsername: true,
  });

  const handleClose = useCallback((e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
    setUsername('');
  }, []);

  const handleOpen = useCallback(({
    username: _username,
    sourceType: _sourceType,
    zIndex: _zIndex,
  }) => {
    setUsername(_username);
    setSourceType(_sourceType);
    setOpen(true);
    if (_zIndex) setZIndex(_zIndex);
  }, []);

  useEffect(() => {
    if (!error) return;
    handleClose();
    navigate('/404');
  }, [error]);

  useEffect(() => {
    if (isUserProfile) handleClose();
  }, [isUserProfile]);

  useEffect(() => {
    handleClose();
  }, [location]);

  useCustomEvent(CustomEvents.OpenUserProfileDrawer, handleOpen);

  const handleMessageClick = useCallback(() => {
    handleClose();
    navigate(`/messages/create?uids=${data?._id}`);
  }, [data]);

  const userIsBlocked = useMemo(() => {
    if (!data || !authenticatedUser || !authenticatedUser?.blocked || !authenticatedUser?.blocked?.length) return false;
    return !!authenticatedUser.blocked.find((bu => bu._id === data._id));
  }, [data, authenticatedUser]);

  const actions = useMemo(() => {
    if (!data?.username) return null;
    const contrastColor = getContrastColor(data.color.replace('#', ''));
    return (
      <div className='absolute top-0 left-0 w-[100%] z-10 flex justify-between'>
        <IconButton
          onClick={handleClose}
          type='button'
          size='small'
        >
          <CancelIcon sx={{ color: `#${contrastColor}` }} />
        </IconButton>

        { (!userIsBlocked && authenticatedUser?.username !== data.username && sourceType !== 'Message') && (
        <IconButton
          variant='contained'
          size='small'
          onClick={handleMessageClick}
          type='button'
          disabled={!verified}
        >
          <MessageIcon sx={{ color: verified ? `#${contrastColor}` : 'var(--color-disabled)' }} />
        </IconButton>
        )}
      </div>
    );
  }, [userIsBlocked, authenticatedUser?.username, data?.username, sourceType, data?.color, verified]);

  return (
    <Drawer
      className='user-profile-drawer rounded'
      ModalProps={{ keepMounted: false }}
      transitionDuration={75}
      anchor='bottom'
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
      }}
      sx={{
        zIndex: zIndex || '',
        '& .MuiDrawer-paper': {
          backgroundColor: 'transparent',
          maxWidth: '772px',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}
    >
      { (loading || !data) && (
        <div style={{ minHeight: '321px' }} className='desktop:w-[772px] desktop:ml-auto desktop:mr-auto relative bg-surface rounded'>
          <UniverseLoader boxType='fullContainer' size={150} showLogo={false} />
        </div>
      ) }

      { data?.username === username && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className='rounded'>
            <div
              style={{
                borderTopLeftRadius: '1.25rem',
                borderTopRightRadius: '1.25rem',
              }}
              className='desktop:w-[772px] desktop:ml-auto desktop:mr-auto relative bg-surface'
            >
              { actions}
              <UserProfile coverImageHeight='h-[90px]' boxClassName='pb-8' isDrawer _id={data?._id || userId} />
            </div>
          </div>
        </ClickAwayListener>
      )}

    </Drawer>
  );
};

export default UserProfileDrawer;
