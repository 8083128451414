import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import useEventListener from './useEventListener';
import { isPwaSelector } from '../models/settings/selectors';
import { NotificationModel } from '../models/notification/model';

export const useNotificationUpdate = () => {
  const isPwa = useRecoilState(isPwaSelector);

  const handleChange = useCallback(async () => {
    if (!isPwa) return;
    console.log('visibilitychange', document.visibilityState);
    if (document.visibilityState === 'visible') await NotificationModel.loadNotificationCounts();
  }, [isPwa]);

  useEventListener('visibilitychange', handleChange);
};
