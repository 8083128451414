/* eslint-disable react/no-danger */
/* eslint-disable no-useless-escape */
import {
  useCallback, useMemo, useState,
} from 'react';
import EmbedContainer from 'react-oembed-container';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AspectRatioImage from '../../../AspectRatioImage';
import Image from '../Image';

const Embed = ({ embedObject }) => {
  // linkData and embed are same data structure, but preserving the split to maybe fetch from API if needed
  const [linkData, setLinkData] = useState(embedObject);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const { url } = embedObject;

  const handleToggleShouldLoad = useCallback(() => {
    setShouldLoad(prev => !prev);
  }, []);

  const handleToggleExpanded = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  const data = useMemo(() => linkData || embedObject, [embedObject, linkData]);

  const title = useMemo(() => (
    <div className='flex items-center'>
      <span className='mr-1'>{embedObject.emoji}</span>
      <h5 className='text-xs font-bold text-primary-brand-small no-underline flex items-center'>
        <a target='_blank' href={data.url} rel='noreferrer' className='no-underline line-clamp-1'>
          {`${embedObject.text.substring(3)}${data?.provider_name ? ` | ${data.provider_name}` : ''}${data.author ? ` | ${data.author}` : ''}`}
        </a>
      </h5>
      <button style={{ color: 'var(--color-text-primary-brand-small)' }} type='button' className='tku-no-select' onClick={handleToggleExpanded}>
        { expanded ? <ArrowDropDownIcon sx={{ height: '20px' }} /> : <ArrowRightIcon sx={{ height: '20px' }} /> }
      </button>
    </div>
  ), [data, embedObject, expanded]);

  const articleTitle = useMemo(() => {
    if (!['link', 'article'].includes(data.type)) return null;
    return (
      <div className='text-sm font-bold mt-1'><a className='no-underline' rel='noreferrer' target='_blank' href={data.url}>{data.title}</a></div>
    );
  }, [data]);

  const embedContent = useMemo(() => {
    if (data.html) {
      // breaking twitter
      // className={data.type === 'video' ? '' : 'flex justify-center'}
      return (
        <div>
          <EmbedContainer
            markup={data.html}
          >
            <article
              style={{
                maxWidth: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: data.html }}
            />
          </EmbedContainer>
        </div>
      );
    }
    return null;
  }, [data]);

  const thumbnail = useMemo(() => {
    if (!data.thumbnail_url) return null;
    return <AspectRatioImage imageClassName='rounded' containerClassName='mt-1' ratio='4:3' src={data.thumbnail_url} alt={data.title} />;
  }, [data]);

  const description = useMemo(() => {
    if (!data || !data.description) return null;
    return (
      <>
        <p className='my-2 text-sm'>
          { data.description.trim() }
        </p>
        <br />
      </>
    );
  }, [data]);

  const isRich = useMemo(() => data?.html, [data]);

  const isTweet = useMemo(() => data?.type === 'tweet' || url.includes('twitter'), [data?.type, url]);

  const twitterStyles = useMemo(() => {
    if (!isTweet) return {};
    return {
      position: 'absolute',
      bottom: '14px',
      left: '50%',
      marginTop: 0,
      marginBottom: 0,
      transform: 'translate(-50%, 0)',
    };
  }, [isTweet]);

  if (!url) return null;

  if (embedObject?.type === 'photo') return <Image url={embedObject.url} text={embedObject?.text} />;

  return (
    <div className={`text-primary-text font-roboto bg-s01dp py-1 px-2 rounded my-2 no-underline post-embed relative${(isTweet && expanded) ? ' pb-8' : ''}`}>
      { title }
      { !!expanded && (
        <>
          { (!shouldLoad) && articleTitle}
          { (!shouldLoad) && thumbnail }
          { (!shouldLoad) && description }
          { (isRich && shouldLoad && data) && embedContent }
          { isRich && (
          <Button
            sx={{
              marginBottom: '15px',
              marginTop: '15px',
              minWidth: '150px',
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
              ...twitterStyles,
            }}
            variant='outlined'
            color='primaryLight'
            size='small'
            type='button'
            onClick={handleToggleShouldLoad}
          >
            { shouldLoad ? 'Hide Content' : 'Load Content' }
          </Button>
          )}
        </>
      )}
    </div>
  );
};

export default Embed;
