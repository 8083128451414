import Chip from '@mui/material/Chip';
import {
  UserEmailStatus,
  UserRoles,
  UserStatus,
} from '../../lib/constants';
import { distance, formatDate } from '../../lib/formatDate';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useUser } from '../../models/user/useUser';
import Protected from '../Protected';

const UserStatusDisplay = ({
  _id,
}) => {
  const { data } = useUser(_id);
  const { data: authenticatedUser } = useAuthenticatedUser();

  if (!data) return null;
  let _user = { ...data };

  if (authenticatedUser?._id === data?._id) _user = { ...data, ...authenticatedUser };

  const {
    username,
    banned,
    status,
    approved,
    statusExpiration,
    emailStatus,
    deleteAccountRequested,
  } = _user;

  if (banned) {
    return (
      <Chip color='error' sx={{ marginTop: '10px' }} label='banned' variant='filled' />
    );
  }

  let isStatusExpired = true;
  if (status !== UserStatus.Good && (new Date(statusExpiration) > new Date())) isStatusExpired = false;
  const awaitingVerification = emailStatus !== UserEmailStatus.Verified;

  return (
    <>
      { deleteAccountRequested?.status && (
      <Protected requirements={{ self: username, roles: [UserRoles.Admin, UserRoles.SuperAdmin] }}>
        <Chip color='error' sx={{ marginTop: '10px' }} label='pending deletion' variant='filled' />
      </Protected>
      )}
      { (awaitingVerification || !approved) && (
      <Protected requirements={{ self: username, roles: [UserRoles.Admin, UserRoles.SuperAdmin] }}>
        <div className='flex flex-col justify-center items-center mb-3'>
          { awaitingVerification && (
          <Chip color='secondary' sx={{ marginTop: '10px' }} label='awaiting email verification' variant='filled' />
          ) }
          { (!approved) && (
          <Chip color='secondary' sx={{ marginTop: '10px' }} label='awaiting approval' variant='filled' />
          ) }
        </div>
      </Protected>
      ) }
      { (!isStatusExpired && status === UserStatus.Silenced) && (
        <Chip color='warning' sx={{ marginTop: '10px' }} label={`silenced for ${distance(new Date(statusExpiration))}`} variant='filled' />
      ) }
      { (!isStatusExpired && status === UserStatus.Suspended) && (
        <Chip color='error' sx={{ marginTop: '10px' }} label={`suspended until ${formatDate(new Date(statusExpiration), 'M/d/yy')}`} variant='filled' />
      ) }
    </>
  );
};

export default UserStatusDisplay;
