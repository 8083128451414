const ULogo = ({
  baseColor,
  ringColor,
  shadowColor,
  containerStyles = {},
  className,
}) => (
  <svg
    className={`u-logo ${className ? ` ${className}` : ''}`}
    style={{
      height: '25px',
      ...containerStyles,
    }}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 287.3 176.2'
  >
    <path
      className='a'
      style={{ fill: baseColor || 'var(--color-primary)' }}
      d='M264.8,146.7l28.8-136H213L185.6,140.9c-2.6,12-10.2,18.6-21.9,18.6-7.3,0-10.9-1-14.6-5.5l-1.1-1.4-1.2-2.9c-2.5-6.3-1.7-9.9-.6-14.3L172.5,10.6h-81L62,151.8c-1.5,7.3-1.8,13.1-1.1,17.9,1.1,7.7,5.1,11.7,13.5,14.2,5.8,1.8,15.7,2.9,28.1,2.9H201c20.1,0,34.6-2.6,43.8-7.7h0C254.2,173.6,261.5,163.1,264.8,146.7Z'
      transform='translate(-33 -10.6)'
    />
    <path
      className='b'
      style={{ fill: shadowColor || 'var(--color-primary-dark)' }}
      d='M276.7,91.2l-3.2,14.3c-22.2,7.3-51.1,14.5-85,21.8l2.9-13.9C225.7,105.8,254.8,98.5,276.7,91.2Z'
      transform='translate(-33 -10.6)'
    />
    <path
      className='b'
      style={{ fill: shadowColor || 'var(--color-primary-dark)' }}
      d='M149.2,121.9l-2.9,13.5h0c-28.4,4.4-55.4,7.3-75.9,7.3H63.9l2.8-13.2c1.1,0,2.6.1,3.4.1C91.6,129.9,119.3,127,149.2,121.9Z'
      transform='translate(-33 -10.6)'
    />
    <path
      style={{ fill: ringColor || 'var(--color-primary-light)' }}
      className='c'
      d='M320.2,66.1c-1.1-6.9-16.4-8-21.2-8.4a1.85,1.85,0,0,0-1.8,1.5,1.77,1.77,0,0,0,.7,2.2c5.1,2.9,5.1,4.4,5.1,4.4,0,.4-.7,1.8-5.8,3.6-4,2.2-9.8,4-16.4,5.8-2.2.7-4.4,1.1-7.3,1.8-44.1,12.8-85.3,21.5-157.6,33.6-21.9,3.6-38.3,5.5-49.2,5.5h0c-12.8,0-13.5-2.6-13.9-2.6-.4-1.8,4.4-6.6,9.5-9.5a2.16,2.16,0,0,0,.7-2.6c0-.7-1.1-1.1-2.2-.7a108.75,108.75,0,0,0-20.4,9.8c-5.1,3.6-8,6.9-7.3,10.2s4,5.5,11.3,7.3a212.69,212.69,0,0,0,24.8,1.8c29.2,0,70.4-4.7,112-13.9,41.6-8.8,76.2-17.5,100.7-25.9C317.7,77.7,321,70.8,320.2,66.1Z'
      transform='translate(-33 -10.6)'
    />
  </svg>
);

export default ULogo;
