import { atomFamily } from 'recoil';
import { defaultLoadableAtom } from '../../lib/constants';

const userProductState = atomFamily({
  key: 'userProductState',
  default: {
    ...defaultLoadableAtom,
  },
});

export default userProductState;
