/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import {
  useCallback,
  useState,
  useMemo,
} from 'react';
import {
  Popper,
  ClickAwayListener,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { CustomEvents } from '../../lib/constants';
import buildList from '../../lib/buildList';
import buildListData from './buildListData';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { useTopic } from '../../models/topic/useTopic';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const TopicSettings = ({
  sourceType,
  topicId,
  offset = [50, -5],
}) => {
  const [open, setOpen] = useState(false);
  const { data: user } = useAuthenticatedUser;
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const { data, loading, error } = useTopic(topicId, { sourceType });

  const handleEvent = useCallback(() => setOpen((prev) => !prev), []);

  useCustomEvent(CustomEvents.TopicSettings, handleEvent, []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClickAway = useCallback((e) => {
    if (e.target === document.body) return null;
    if (e.target.closest('.topic-settings-button') !== null) return null;
    handleClose();
  }, []);

  const listData = useMemo(() => buildListData({
    sourceType,
    _id: topicId,
    userId: user?._id,
    addSnackbar,
    handleClose,
  }), [
    sourceType,
    topicId,
    data,
    user,
  ]);

  if (!open || error) return null;

  const anchorEl = document.querySelector('.topic-settings-button');

  if (!anchorEl) return null;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        className='rounded'
        anchorEl={anchorEl}
        open={open}
        style={{ zIndex: 1000 }}
        modifiers={[{ name: 'offset', options: { offset } }]}
      >
        <div className='topic-settings bg-surface rounded'>
          <div
            className='bg-s06dp space-y-3 py-3 text-primary-text roboto rounded'
            style={{
              overflow: 'auto',
              maxHeight: '300px',
              minWidth: '150px',
            }}
          >
            <div className='flex justify-center'>
              <span className='text-sm capitalize inline-block'>{`${sourceType} Settings`}</span>
            </div>
            { (!loading && data) && buildList({
              listData,
              listDense: true,
              listProps: {
                disablePadding: true,
                xs: { width: '100%' },
              },
              listItemProps: {
                disablePadding: true,
                sx: { padding: '0px 12px' },
              },
            }) }
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
};

export default TopicSettings;
