import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { SkinTones } from '../../../../lib/constants';
import { PopperMenu } from '../../PopperMenu';
import { EmojiTypeaheadMenuItem } from './MenuItem';

const tonesArr = ['👍🏻', '👍🏼', '👍🏽', '👍🏾', '👍🏿', '👍'];

const Menu = ({
  options,
  selectedIndex,
  setHighlightedIndex,
  selectOptionAndCleanUp,
  cleanup,
  skinTone,
  setSkinTone,
}) => {
  useEffect(() => () => cleanup(), []);

  const handleSkinToneChange = useCallback((e, tone) => {
    e.preventDefault();
    e.stopPropagation();
    if (tone === skinTone) return;
    if (tone) setSkinTone(tone);
  }, [skinTone]);
  return (
    <div className='bg-surface'>
      <ul style={{ minWidth: '300px' }} className='rounded bg-s04dp z-10 pt-2'>
        { (options.length > 0) && options.map((option, i) => (
          <EmojiTypeaheadMenuItem
            index={i}
            isSelected={selectedIndex === i}
            onClick={() => {
              setHighlightedIndex(i);
              selectOptionAndCleanUp(option);
            }}
            onMouseEnter={() => setHighlightedIndex(i)}
            key={option.key}
            option={option}
          />
        ))}
        <div className='mt-1 px-2 pb-2'>
          <ToggleButtonGroup
            size='small'
            fullWidth
            color='primary'
            value={skinTone}
            exclusive
            variant='contained'
            onChange={handleSkinToneChange}
          >
            {Object.values(SkinTones).map((tone, i) => (
              <ToggleButton key={tone} selected={tone === skinTone} value={tone}>
                <span className='text-lg'>{ tonesArr[i] }</span>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </ul>

    </div>
  );
};

export const renderMenu = (options, results, skinTone, setSkinTone) => {
  const _renderMenu = (anchorElement, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
    const cleanup = () => document.querySelectorAll('#typeahead-menu').forEach(n => n?.remove());
    if (!anchorElement || !results.length > 0) return null;
    return (
      <PopperMenu anchorElement={anchorElement}>
        <Menu skinTone={skinTone} setSkinTone={setSkinTone} cleanup={cleanup} options={options} selectedIndex={selectedIndex} setHighlightedIndex={setHighlightedIndex} selectOptionAndCleanUp={selectOptionAndCleanUp} />
      </PopperMenu>
    );
  };
  return _renderMenu;
};
