// https://stackoverflow.com/questions/635022/calculating-contrasting-colours-in-javascript
const hexToRGBArray = (color) => {
  if (color.length === 3) { color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2); } else if (color.length !== 6) { throw new Error(`Invalid hex color: ${color}`); }
  const rgb = [];
  for (let i = 0; i <= 2; i++) { rgb[i] = parseInt(color.substr(i * 2, 2), 16); }
  return rgb;
};
const luma = (color) => {
  const rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
  return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
};

const getContrastColor = (color) => ((luma(color) >= 165) ? '000' : 'fff');

export default getContrastColor;
