import {
  useMemo,
} from 'react';
import {
  useRecoilValue,
} from 'recoil';
import {
  UserRoles,
  SourceTypes,
} from '../../../lib/constants';
import Protected from '../../../components/Protected';
import PostEditor from '../../../components/PostEditor';
import { editorIsActiveSelector } from '../../../models/editor/selectors';
import { useTopic } from '../../../models/topic/useTopic';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import topicSourceTypState from '../../../models/topicSourceType/atom';

const TopicComposer = ({ _id, sourceType = SourceTypes.Post }) => {
  const editorIsActive = useRecoilValue(editorIsActiveSelector(_id));
  const topicSourceType = useRecoilValue(topicSourceTypState(_id));

  const { data: topicData } = useTopic(_id, { sourceType: topicSourceType });
  const { data: user } = useAuthenticatedUser();

  const hideComposer = useMemo(() => {
    if ([UserRoles.SuperAdmin, UserRoles.Admin, UserRoles.Moderator].includes(user?.role)) return false;
    return topicData?.isLocked;
  }, [topicData?.isLocked, user, editorIsActive]);

  if (hideComposer || !topicData) return null;

  return (
    <Protected>
      <div style={{ visibility: editorIsActive ? '' : 'hidden' }}>
        <PostEditor
          sourceType={sourceType}
          topic={topicData}
          topicId={topicData._id}
        />
      </div>
    </Protected>
  );
};

export default TopicComposer;
