import { Avatar } from '@mui/material';
import { useState } from 'react';
import getContrastColor from '../../lib/getContrastColor';

const DisplayAvatar = ({
  avatar,
  color,
  name,
  onClick,
  avatarSize = '24px',
  avatarFontSize,
  styles,
}) => {
  const [error, setError] = useState(false);
  return (
    <>
      { (!!avatar && !error) && (
        <Avatar
          onClick={onClick}
          src={avatar}
          imgProps={{ onError: () => setError(true) }}
          sx={{
            width: avatarSize,
            height: avatarSize,
            fontSize: avatarFontSize,
            cursor: 'pointer',
            ...styles,
          }}
        />
      ) }
      { (!avatar || error) && (
        <div
          className='flex items-center justify-center'
          role='button'
          onClick={onClick}
          tabIndex={0}
          onKeyDown={onClick}
          style={{
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: color,
            width: avatarSize,
            height: avatarSize,
            fontSize: avatarFontSize || '',
            color: `#${color ? getContrastColor(color?.replace('#', '')) : '#333'}`,
            ...styles,
          }}
        >
          <span
            className='inline-block'
          >
            {name?.[0].toUpperCase()}
          </span>
        </div>
      ) }
    </>
  );
};

export default DisplayAvatar;
