import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import data from '@emoji-mart/data';
import { init, SearchIndex } from 'emoji-mart';
import { SkinTones } from '../lib/constants';
import ApiClientInstance from '../clients/api';

init({ data });

export const useEmojiSearch = ({ queryString, excludeCustomEmojis, LIMIT = 5 }) => {
  const [emojis, setEmojis] = useState([]);
  const [customEmojis, setCustomEmojis] = useState([]);
  const [nativeEmojis, setNativeEmojis] = useState([]);
  const [skinTone, setSkinTone] = useState(SkinTones.Default);

  const queryRef = useRef();

  const handleCustomSearch = useCallback(async (query) => {
    const customSearchResults = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: `/emoji/search?q=${query}`,
      catchError: true,
    });
    if (!customSearchResults.success || customSearchResults?.data?.length === 0) return;
    const customResults = customSearchResults.data?.length > LIMIT ? customSearchResults.data.slice(0, LIMIT) : customSearchResults.data;
    setCustomEmojis(customResults);
  }, []);

  const handleSearch = useCallback(async (query, tone) => {
    const nativeSearchResults = await SearchIndex.search(query);
    let nativeResults = nativeSearchResults?.length > LIMIT ? nativeSearchResults.slice(0, LIMIT) : nativeSearchResults;
    nativeResults = nativeResults?.map((result) => {
      const {
        id,
        name,
        skins,
      } = result;
      const emoji = {
        shortname: id,
        name,
      };
      const _emoji = skins.length === 0 || !skins[tone] ? skins[0] : skins[tone];
      emoji.native = _emoji.native;
      emoji.codes = _emoji.unified;
      return emoji;
    });
    setNativeEmojis(nativeResults);
  }, []);

  useEffect(() => {
    if (!queryString) return;
    queryRef.current = queryString;
    handleSearch(queryString, skinTone);
  }, [queryString, skinTone]);

  useEffect(() => {
    if (!queryString || excludeCustomEmojis) return;
    handleCustomSearch(queryString);
  }, [queryString]);

  useEffect(() => {
    setEmojis([...customEmojis, ...nativeEmojis]);
  }, [customEmojis, nativeEmojis]);

  return { emojis, skinTone, setSkinTone };
};
