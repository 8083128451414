import { setRecoil } from 'recoil-nexus';
import { SocketEffects, SocketUpdateActions } from '../../lib/constants';
import { PrivateTopicModel } from '../../models/privateTopic/model';
import { PrivatePostModel } from '../../models/privatePost/model';
import { UserModel } from '../../models/user/model';
import { addPostToLastPage } from '../../models/currentPostsPages/selectors';
import { invalidatePrivateTopicQueries } from '../../lib/queryUtils';
import { getIsOwnerBlocked } from './utils';
import messageRequestCount from '../../models/messageRequestCount/atom';
import { handleGlobalEffects } from './handleGlobalEffects';

const handleTopicEffects = async (res) => {
  const { effects, data } = res;
  const isOwnerBlocked = getIsOwnerBlocked(data.topic);
  if (isOwnerBlocked) return;
  if (effects?.length < 1) return;
  for (const effect of effects) {
    switch (effect) {
      case SocketEffects.InvalidateQueries:
        await invalidatePrivateTopicQueries();
        break;
      case SocketEffects.LoadCounts:
        await PrivateTopicModel.loadUnreadCounts();
        break;
      default:
        break;
    }
  }
};

const handleReplyPrivateTopic = (res) => {
  const { data } = res;
  const Post = new PrivatePostModel(data.post._id);
  Post.setData(data.post);
  const PrivateTopic = new PrivateTopicModel(data.topic._id);
  PrivateTopic.setData(data.topic);
  const replyToPid = data.post?.replyToPid ? data.post?.replyToPid?._id : null;
  if (replyToPid) {
    const PostRepliedTo = new PrivatePostModel(replyToPid);
    const postRepliedToData = PostRepliedTo.getData(replyToPid);
    const newReplies = [...postRepliedToData.replies, data.post];
    PostRepliedTo.setData({ ...postRepliedToData, replies: newReplies });
  }
  const User = new UserModel(data.post.author._id);
  User.setData(data.post.author);
  setRecoil(addPostToLastPage(data.topic._id), data.post);
};

const handlePrivateTopicRequest = (res) => {
  const { data } = res;
  const PrivateTopic = new PrivateTopicModel(data.topic._id);
  PrivateTopic.setData(data.topic);
  UserModel.setUserPartial(data.topic.author);
  const isOwnerBlocked = getIsOwnerBlocked(data.topic);
  if (!isOwnerBlocked) setRecoil(messageRequestCount, prev => prev + 1);
};

export const handlePrivateTopic = (res) => {
  const { action, data } = res;
  switch (action) {
    case SocketUpdateActions.Reply: {
      handleReplyPrivateTopic(res);
      break;
    }
    case SocketUpdateActions.Request: {
      handlePrivateTopicRequest(res);
      break;
    }
    case SocketUpdateActions.New: {
      console.log('new private topic', data);
      break;
    }
    case SocketUpdateActions.Update: {
      const Topic = new PrivateTopicModel(data.topic._id);
      Topic.setData(data.topic);
      break;
    }
    case SocketUpdateActions.Delete: {
      const Topic = new PrivateTopicModel(data.topic._id);
      Topic.setData({ ...data.topic, isDeleted: true });
      break;
    }
    default: {
      console.log('unknown private topic action', action);
    }
  }
  handleTopicEffects(res);
  handleGlobalEffects(res);
};
