import { useEffect, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSetRecoilState } from 'recoil';
import { usePost } from '../../../models/post/usePost';
import UserAvatar from '../../UserAvatar';
import postRepliesExpandedState from '../../../models/postRepliesExpanded/atom';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import { UserRoles } from '../../../lib/constants';

const replyAvatarSize = '20px';
const replyAvatarStyle = {
  width: replyAvatarSize,
  height: replyAvatarSize,
  marginLeft: '-10px',
};
const maxReplyAvatars = 4;

export const CardRepliesCount = ({
  sourceType,
  _id,
}) => {
  const setPostRepliesExpanded = useSetRecoilState(postRepliesExpandedState(_id));

  const { data } = usePost(_id, { sourceType });
  const { data: authenticatedUser } = useAuthenticatedUser();

  useEffect(() => () => setPostRepliesExpanded(false), []);

  const _replies = useMemo(() => {
    if (!data || !data?.replies?.length) return [];
    if ([UserRoles.Admin, UserRoles.SuperAdmin].includes(authenticatedUser?.role)) return data.replies;
    return data.replies.filter(reply => !reply.isDeleted);
  }, [data?.replies, authenticatedUser]);

  const replyAvatars = useMemo(() => {
    const content = [];
    for (let i = 0; i < _replies.length; i += 1) {
      if (i === maxReplyAvatars) break;
      const reply = _replies[i];
      const replyId = typeof reply === 'string' ? reply : reply?._id;
      const avatar = (
        <div style={{ marginLeft: i > 0 ? '-10px' : '', zIndex: `${i}` }} className='h-[20px] w-[20px] inline-block' key={replyId}>
          <UserAvatar
            _id={reply?.author?._id}
            styles={replyAvatarStyle}
            avatarFontSize='12px'
            onClick={null}
            avatarSize='20px'
          />
        </div>
      );
      content.push(avatar);
    }
    return (
      <div className='mr-[-5px] flex justify-center items-center'>
        {content}
      </div>
    );
  }, [_replies]);

  if (!data?.replies?.length || !_replies?.length) return null;

  return (
    <button type='button' className='cursor-pointer flex items-center opacity-80 text-xs' onClick={() => setPostRepliesExpanded(prev => !prev)}>
      { replyAvatars }
      <span className='ml-1'>
        {`${data.replies.length} ${data.replies.length === 1 ? 'reply' : 'replies'}`}
      </span>
      <ExpandMoreIcon />
    </button>
  );
};
