import ApiClientInstance from '../../clients/api';

export const verifyUser = async () => ApiClientInstance.sendRequest({
  method: 'post',
  path: '/user/verify',
  catchError: true,
});

export const logout = async () => ApiClientInstance.sendRequest({
  method: 'post',
  path: '/user/logout',
  catchError: true,
});
