import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import CasinoIcon from '@mui/icons-material/Casino';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LoginIcon from '@mui/icons-material/Login';
import LockResetIcon from '@mui/icons-material/LockReset';
import ApiClientInstance from '../../clients/api';
import { userNav } from '../../models/navigation/selectors';
import buildList from '../../lib/buildList';
import UserAvatar from '../UserAvatar';
import UserBadges from '../UserBadges';
import UserStats from '../UserStats';
import UserNavMessagesItem from '../UserNavMessagesItem';
import UserNavNotificationsItem from '../UserNavNotificationsItem';
import UserStatusDisplay from '../UserStatusDisplay';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import ULogo from '../Svg/uLogo';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { ThemeData } from '../../styles/themes';

const UserNav = () => {
  const navigate = useNavigate();
  const [open, toggleOpen] = useRecoilState(userNav);

  const {
    data: user,
    loading,
    fetch,
    authenticated,
    unauthenticated,
    verified,
  } = useAuthenticatedUser();

  // Local State
  const toggleDrawer = useCallback((event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    toggleOpen();
  }, []);

  // Effects
  useEffect(() => {
    if (open && user?.username) fetch();
  }, [open, user?.username]);

  // Handlers
  const goToPath = useCallback((path) => {
    // TODO: verify this is a good experience across all browswers and connections
    // setting timeout to show click ripple
    setTimeout(() => {
      toggleOpen();
      navigate(path);
    }, 100);
  }, []);

  const logout = useCallback(async () => {
    const handler = async () => {
      toggleOpen();
      await ApiClientInstance.logout();
      navigate('/');
    };
    const text = 'Are you sure you want to logout';
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text });
  }, []);

  const authenticatedNav = useMemo(() => {
    if (!authenticated || !user) return null;
    const {
      nickname,
      _id,
    } = user;
    const listData = [
      {
        Component: UserNavNotificationsItem,
        condition: () => verified,
      },
      {
        Component: UserNavMessagesItem,
        condition: () => verified,
      },

      { divider: true },
      {
        handler: () => goToPath(`/users/${user.username}`),
        Icon: AccountCircleIcon,
        text: 'Profile',
      },
      {
        handler: () => goToPath('/bets'),
        Icon: CasinoIcon,
        text: 'Bets',
        condition: () => verified,
      },
      {
        handler: () => goToPath('/settings'),
        Icon: SettingsIcon,
        text: 'Settings',
      },
      { divider: true },
      {

        handler: logout,
        Icon: LogoutIcon,
        text: 'Logout',
      },
    ];
    return (
      <>
        <div className='mt-10 mb-10 flex-col flex content-center justify-center items-center tku-no-select'>
          <UserAvatar _id={_id} avatarSize='75px' />
          <h3 className='mt-3 text-lg font-bold font-roboto'>{user.username}</h3>
          { nickname && (
          <h3 className='text-base font-roboto italic'>
            {`# ${nickname}`}
          </h3>
          ) }
          <UserBadges badgeSize='25px' _id={_id} />
          <UserStats _id={_id} containerClass='px-10 space-x-5 mt-2' iconSize='22px' />
          <UserStatusDisplay _id={_id} />
        </div>
        {buildList({ listData })}
      </>
    );
  }, [authenticated, user]);

  const unauthenticatedNav = useMemo(() => {
    if (!unauthenticated || loading) return null;
    const listData = [
      { handler: () => goToPath('/login'), Icon: LoginIcon, text: 'Login' },
      { handler: () => goToPath('/register'), Icon: HowToRegIcon, text: 'Sign Up' },
      { handler: () => goToPath('/password-reset'), Icon: LockResetIcon, text: 'Reset Password' },
    ];
    return (
      <>
        <div className='mt-10 mb-5 flex-col flex content-center justify-center items-center tku-no-select'>
          <ULogo
            baseColor={ThemeData.Base.theme.primary}
            shadowColor={ThemeData.Base.theme.primaryDark}
            ringColor={ThemeData.Base.theme.primaryLight}
            containerStyles={{ height: '50px' }}
          />
        </div>
        {buildList({ listData })}
      </>
    );
  }, [unauthenticated, loading, user]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={toggleDrawer}
    >
      <Box className='w-[250px] tablet:w-[300px]'>
        { authenticatedNav }
        { unauthenticatedNav }
      </Box>
    </Drawer>
  );
};

export default UserNav;
