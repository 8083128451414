import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';

const Pagination = ({
  items, handleChange, handlePrev, handleNext, iconSize = 18,
}) => {
  const circleIconSize = `${iconSize - 6}px`;
  const arrowIconSize = `${iconSize}px`;

  return (
    <>
      <IconButton sx={{ padding: '4px' }} onClick={handlePrev}>
        <ArrowBackIosIcon sx={{ opacity: '.7', height: arrowIconSize }} />
      </IconButton>
      { items.map((item) => {
        const {
          type, selected, disabled, page,
        } = item;
        if (type !== 'page') return null;
        return (
          <IconButton sx={{ padding: '2px' }} onClick={(e) => handleChange(e, page)} type='button' disabled={disabled} aria-current={item['aria-current']} size='small' key={item.page}>
            <CircleIcon sx={{ opacity: '.7', height: circleIconSize }} color={selected ? 'primaryLight' : 'textPrimary'} />
          </IconButton>
        );
      })}
      <IconButton sx={{ padding: '4px' }} onClick={handleNext}>
        <ArrowForwardIosIcon sx={{ opacity: '.7', height: arrowIconSize }} />
      </IconButton>
    </>
  );
};

export default Pagination;
