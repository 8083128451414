export default (title) => {
  try {
    const object = JSON.parse(title);
    const str = object.blocks.map(block => (!block.text.trim() && '') || block.text.trim()).join('').trim()
      .replace('  ', ' ')
      .replace(/(\[.+\])/gi, '');
    return str;
  } catch (e) {
    return title;
  }
};
