import { useState, useCallback } from 'react';
import {
  Menu,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { useRecoilValue } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { Breakpoints, UserPrivateTopicStatus } from '../../lib/constants';
import { breakpointNameSelector } from '../../models/settings/selectors';
import ApiClientInstance from '../../clients/api';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { PrivateTopicModel } from '../../models/privateTopic/model';

const reloadTopic = null;

const addSnackbar = ({ message, color }) => setRecoil(addSnackbarSelector, { message, color });

const ManageUserInMessageMenu = ({
  messageStatus,
  userId,
  userRole,
  _id,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = !!anchorEl;

  const handleClose = useCallback(() => {
    let isCanceled = false;
    const handler = () => {
      if (isCanceled) return;
      setAnchorEl(null);
    };
    handler();
    // eslint-disable-next-line no-return-assign
    return () => isCanceled = false;
  }, []);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleRemove = useCallback(async () => {
    if (!userId) return;
    const removeRes = await ApiClientInstance.sendRequest({
      path: `/private-topic/${_id}/remove-user`,
      method: 'PUT',
      data: {
        userToRemove: userId,
      },
      catchError: true,
    });
    if (removeRes.success) {
      addSnackbar({ message: 'User successfully removed from message.', color: 'primary' });
    } else addSnackbar({ message: 'Error removing user from message', color: 'error' });
  }, [userId, reloadTopic, _id]);

  const handleResendRequest = useCallback((fetch = false) => async () => {
    if (!userId) return;
    const resendRes = await ApiClientInstance.sendRequest({
      path: `/private-topic/${_id}/add-user`,
      method: 'PUT',
      data: {
        userToAdd: userId,
      },
      catchError: true,
    });
    if (resendRes.success) {
      addSnackbar({ message: 'User successfully added to message.', color: 'primary' });
      handleClose();
      if (fetch) {
        const model = new PrivateTopicModel(_id);
        await model.fetch();
      }
      // await reloadTopic();
    } else addSnackbar({ message: 'Error adding user to message', color: 'error' });
  }, [userId, reloadTopic, _id]);

  const handleBan = useCallback(async () => {
    if (!userId) return;
    const banRes = await ApiClientInstance.sendRequest({
      path: `/private-topic/${_id}/owner/status`,
      method: 'PUT',
      data: {
        status: UserPrivateTopicStatus.Banned,
        userToUpdate: userId,
      },
      catchError: true,
    });
    if (banRes.success) {
      addSnackbar({ message: 'User successfully banned from message.', color: 'primary' });
      handleClose();
    } else addSnackbar({ message: 'Error banning user', color: 'error' });
  }, [userId, reloadTopic, _id]);

  const handleAddOwner = useCallback(async () => {
    const addOwnerRes = await ApiClientInstance.sendRequest({
      path: `/private-topic/${_id}/add-owner`,
      method: 'PUT',
      data: { userToAdd: userId },
      catchError: true,
    });
    if (addOwnerRes.success) {
      addSnackbar({ message: 'User successfully added as owner.', color: 'primary' });
      handleClose();
      // await reloadTopic();
    } else addSnackbar({ message: 'Error adding user as owner', color: 'error' });
  }, []);

  const handleRemoveOwner = useCallback(async () => {
    const removeOwnerRes = await ApiClientInstance.sendRequest({
      path: `/private-topic/${_id}/remove-owner`,
      method: 'PUT',
      data: { userToRemove: userId },
      catchError: true,
    });
    if (removeOwnerRes.success) {
      addSnackbar({ message: 'User successfully removed as owner.', color: 'primary' });
      handleClose();
      // await reloadTopic();
    } else addSnackbar({ message: 'Error removing user as owner', color: 'error' });
  }, []);

  const breakpoint = useRecoilValue(breakpointNameSelector);
  const isMobile = breakpoint === Breakpoints.Mobile;

  return (
    <div>
      <Button
        variant='outlined'
        color={open ? 'primaryLight' : 'textPrimary'}
        id='message-request-menu-button'
        aria-controls={open ? 'message-request-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={isMobile ? null : <SettingsIcon />}
      >
        { isMobile && <SettingsIcon /> }
        { !isMobile && 'Manage' }
      </Button>

      <Menu
        id='message-request-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'message-request-menu-button',
        }}
      >
        { userRole !== 'owner' && [UserPrivateTopicStatus.Accepted].includes(messageStatus) && (
        <MenuItem dense onClick={handleAddOwner}>
          <ListItemIcon>
            <GroupAddIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Add as Owner</ListItemText>
        </MenuItem>
        )}
        { userRole === 'owner' && (
        <MenuItem dense onClick={handleRemoveOwner}>
          <ListItemIcon>
            <GroupRemoveIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Remove Owner Status</ListItemText>
        </MenuItem>
        )}
        { [UserPrivateTopicStatus.Accepted, UserPrivateTopicStatus.Pending, UserPrivateTopicStatus.Archived].includes(messageStatus) && (
        <MenuItem dense onClick={handleRemove}>
          <ListItemIcon>
            <PersonRemoveIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
        )}
        { [UserPrivateTopicStatus.Rejected, UserPrivateTopicStatus.Pending].includes(messageStatus) && (
        <MenuItem dense onClick={handleResendRequest(false)}>
          <ListItemIcon>
            <ScheduleSendIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Resend Request</ListItemText>
        </MenuItem>
        )}
        { ![UserPrivateTopicStatus.Banned].includes(messageStatus) && (
        <MenuItem dense onClick={handleBan}>
          <ListItemIcon>
            <NoAccountsIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Ban from Message</ListItemText>
        </MenuItem>
        )}
        { [UserPrivateTopicStatus.Banned].includes(messageStatus) && (
        <MenuItem dense onClick={handleResendRequest(true)}>
          <ListItemIcon>
            <AccountCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Unban from Message</ListItemText>
        </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ManageUserInMessageMenu;
