import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import ForumIcon from '@mui/icons-material/Forum';

import messageRequestCountState from '../../models/messageRequestCount/atom';
import unreadMessagesCountState from '../../models/unreadMessagesCount/atom';
import { userNav } from '../../models/navigation/selectors';

const UserNavMessagesItem = () => {
  const navigate = useNavigate();

  const messageRequestCount = useRecoilValue(messageRequestCountState);
  const unreadMessagesCount = useRecoilValue(unreadMessagesCountState);
  const toggleOpen = useSetRecoilState(userNav);

  const goToPath = useCallback((path) => {
    // TODO: verify this is a good experience across all browswers and connections
    // setting timeout to show click ripple
    setTimeout(() => {
      toggleOpen();
      navigate(path);
    }, 100);
  }, []);

  return (
    <ListItem
      className='prevent-clickaway prevent-clickaway-messages'
      onClick={() => goToPath('/messages')}
      button
    >
      <ListItemIcon>
        <Badge
          badgeContent={messageRequestCount + unreadMessagesCount}
          max={50}
          sx={{ height: '20px' }}
          overlap='circular'
          color='secondary'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ForumIcon />
        </Badge>

      </ListItemIcon>
      <ListItemText primary='Messages' />
    </ListItem>
  );
};

export default UserNavMessagesItem;
