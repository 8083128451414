import { useCallback, useState } from 'react';

import { Box, Modal } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '6px',
  p: 2,
  display: 'flex',
  minWidth: '300px',
  maxHeight: 'calc(100vh - 130px)',
  justifyContent: 'center',
  alignItems: 'center',
};

const ImageModal = ({
  styles,
}) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [altText, setAltText] = useState('');
  const [title, setTitle] = useState('');

  const handleEvent = useCallback(({ url: _url, text }) => {
    if (!_url) return;
    setUrl(_url);
    if (text) setTitle(text);
    setAltText(text || _url);
    setOpen(true);
  }, []);

  useCustomEvent(CustomEvents.OpenImageModal, handleEvent);

  return (
    <Modal
      className='tku-no-select'
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='image-container'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles || modalStyle} className='tku-no-select flex flex-col'>
        <div className='flex justify-between items-center image-modal-header w-[100%]'>
          <div className='text-primary-text font-roboto line-clamp-1'>{ title }</div>
          <IconButton
            className='tku-no-select'
            size='small'
            onClick={() => setOpen(false)}
            type='button'
            edge='end'
            style={{
              opacity: '.7',
            }}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <img style={{ minHeight: '100px', maxHeight: '90vh', maxWidth: '90vw' }} className='rounded my-2' src={url} alt={altText} />
      </Box>
    </Modal>
  );
};

export default ImageModal;
