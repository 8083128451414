import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CommentIcon from '@mui/icons-material/Comment';
import Visibility from '@mui/icons-material/Visibility';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { setRecoil } from 'recoil-nexus';
import { useRecoilValue } from 'recoil';
import {
  QueryKeys,
  UserProfileContentTypes,
} from '../../../lib/constants';
import { emitOpenPostVodeModal } from '../../../lib/emitters/emitOpenPostVodeModal';
import { useTopic } from '../../../models/topic/useTopic';
import { usePost } from '../../../models/post/usePost';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import { addSnackbarSelector } from '../../../models/snackbar/selector';
import topicSourceTypeState from '../../../models/topicSourceType/atom';
import postSourceTypeState from '../../../models/postSourceType/atom';
import { emitInvalidateQueries } from '../../../lib/emitters/emitInvalidateQueries';

const btnSize = '20px';
const btnSizes = { height: btnSize, width: btnSize };

export const CardActions = ({
  topicId,
  postId,
  saved = false,
  showIsSaved = false,
  hidden = false,
  showIsHidden = false,
  children,
}) => {
  const isTopic = !!topicId;
  const topicSourceType = useRecoilValue(topicSourceTypeState(topicId));
  const postSourceType = useRecoilValue(postSourceTypeState(postId));
  const { authenticated, data: authenticatedUser, verified } = useAuthenticatedUser();
  const { data, model } = useTopic(topicId || 'cardActions', { sourceType: topicSourceType });
  const { data: postData, model: postModel } = usePost(postId || data?.mainPost._id, { sourceType: postSourceType });

  const { shortId, slug, stats } = data || {};
  const { score: _score, vote: _vote } = data?.mainPost || {};
  const score = postData?.score;
  const vote = postData?.vote;

  const __score = typeof score === 'undefined' ? _score : score;
  const __vote = typeof vote === 'undefined' ? _vote : vote;

  const isAuthor = useMemo(() => authenticatedUser?._id === postData?.author?._id, [authenticated, postData]);

  const handleVote = useCallback((delta) => async () => postModel.vote(delta), [postModel]);

  const handlePostVoteCountClick = useCallback(() => emitOpenPostVodeModal({ postId }), [__score, postId]);

  const handleUnhide = useCallback(async () => {
    if (!hidden) return;
    const res = await model.unhide();
    if (res.success) {
      setRecoil(addSnackbarSelector, { message: 'Topic unhidden successfully', color: 'primary' });
      emitInvalidateQueries({ queryKey: [QueryKeys.Profile, authenticatedUser._id, UserProfileContentTypes.Hidden] });
      model.fetch();
    }
  }, [hidden, model, authenticatedUser]);

  const handleUnsave = useCallback(async () => {
    if (!saved) return;
    const res = await model.unsave();
    if (res.success) {
      setRecoil(addSnackbarSelector, { message: 'Topic unsaved successfully', color: 'primary' });
      emitInvalidateQueries({ queryKey: [QueryKeys.Profile, authenticatedUser._id, UserProfileContentTypes.Saved] });
      model.fetch();
    }
  }, [saved, model, authenticatedUser]);

  if (!postData && !data) return null;

  return (
    <section className='topic-card-actions flex justify-between mt-1'>
      <div className='flex content-center items-center space-x-1'>

        <IconButton
          size='small'
          aria-label='like-button'
          color={authenticated && __vote === 1 ? 'primaryLight' : 'disabledText'}
          onClick={handleVote(1)}
          disabled={isAuthor || !verified}
        >
          <FavoriteIcon sx={btnSizes} />
        </IconButton>

        <Button
          onClick={handlePostVoteCountClick}
          size='small'
          sx={{ minWidth: '20px', padding: '2px 2px' }}
          color={__score === 0 ? 'textPrimary' : __score > 0 ? 'primaryLight' : 'secondaryLight'}
        >
          <span className='text-xs font-bold block w-5 text-center'>{__score || 0}</span>
        </Button>

        <IconButton
          size='small'
          aria-label='dislike-button'
          color={authenticated && __vote === -1 ? 'secondaryLight' : 'disabledText'}
          onClick={handleVote(-1)}
          disabled={isAuthor || !verified}
        >
          <ThumbDownIcon sx={btnSizes} />
        </IconButton>

        { isTopic && (
          <div style={{ marginLeft: '25px' }} className='flex content-center items-center space-x-1'>
            <Link aria-label='comments-link' to={`/topic/${shortId}/${slug}`}>
              <CommentIcon sx={btnSizes} />
            </Link>
            <span className='text-xs font-bold'>{stats?.posts?.count}</span>
          </div>
        )}
        { children }
      </div>

      { (showIsHidden || showIsSaved) && (
      <div className='user-topic-management-actions'>
        { (showIsHidden && hidden) && (
        <div>
          <Button
            size='small'
            color='primaryLight'
            variant='outlined'
            startIcon={<Visibility />}
            onClick={handleUnhide}
          >
            Unhide
          </Button>
        </div>
        )}
        { (showIsSaved && saved) && (
          <div>
            <Button
              size='small'
              color='primaryLight'
              variant='outlined'
              startIcon={<BookmarkRemoveIcon />}
              onClick={handleUnsave}
            >
              Unsave
            </Button>
          </div>
        )}
      </div>
      )}
    </section>
  );
};
