export const SiteName = process.env.REACT_APP_SITE_NAME;
export const SiteTitle = `${SiteName} - NBA2K MyTeam Guides, Discussion, Reviews - NBA Discussion - Real Time 2K and NBA Information`;

export const VirtuosoTypes = {
  List: 'list',
  Grid: 'grid',
};

export const PinTypes = {
  Global: 'global',
  Category: 'category',
};

export const UserProfileContentTypes = {
  Posts: 'posts',
  Topics: 'topics',
  Saved: 'saved',
  Hidden: 'hidden',
};

export const NewTopicComposer = 'newTopicComposer';
export const NewTopicTitleComposer = 'newTopicTitleComposer';

export const UserEmailStatus = {
  Unverified: 'unverified',
  Verified: 'verified',
  Bounced: 'bounced',
  Complained: 'complained',
};

export const AuthStatus = {
  Authenticated: 'authenticated',
  Unauthenticated: 'unauthenticated',
  Loading: 'loading',
};

export const GlobalNav = {
  User: 'user',
  Site: 'site',
  None: 'none',
};

export const EditMenu = {
  Topic: 'topic',
  Post: 'post',
  None: 'none',
};

export const SiteCookies = {
  AuthKey: 'authkey',
};

export const RoomPaths = {
  General: 'general',
  User: 'user',
};

export const RoomNames = {
  Unauthenticated: 'unauthenticated',
};

export const QueryKeys = {
  Topics: 'topics',
  Messages: 'messages',
  Profile: 'profile',
  Users: 'users',
  Games: 'games',
  Categories: 'categories',
  Notifications: 'notifications',
  StoreBadges: 'storeBadges',
};

export const QueryKeyEventTypes = {
  All: 'all',
  Active: 'active',
};

export const QueryLocationKeys = {
  Home: 'home',
  Category: 'category',
  All: 'all',
};

export const UserRoles = {
  User: 'user',
  Moderator: 'moderator',
  Admin: 'admin',
  SuperAdmin: 'superadmin',
};

export const SnackbarSeverity = {
  Error: 'error',
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
};

export const SocketRooms = {
  General: {
    Unauthenticated: 'unauthenticated',
    Category: 'category',
    Home: 'home',
    Sports: 'sports',
    Users: 'users',
    TopicList: 'topicList',
    All: 'all',
  },
  Topic: 'topic',
  Message: 'message',
  UserProfile: 'userProfile',
  MessageList: 'messageList',
};

export const SocketEvents = {
  Update: 'update', // used for general listeners with reducers for specific updates in FE. type should be resource type
  App: 'app', // used for general app level actions
};

export const GlobalLoadingOptions = {
  WhenDataNotAvailable: 'whenDataNotAvailable',
  OnMount: 'onMount',
  OnLoad: 'onLoad',
  All: 'all',
};

export const SocketUpdateTypes = {
  New: 'new',
  Deleted: 'deleted',
  Post: 'post',
  Topic: 'topic',
  PrivateTopic: 'privateTopic',
  UserWriting: 'userWriting',
  UserNotification: 'userNotification',
  InvalidateQueries: 'invalidateQueries',
  Message: 'message',
};

export const SocketEffects = {
  InvalidateQueries: 'invalidateQueries',
  LoadCounts: 'loadCounts',
};

export const SocketResourceTypes = {
  Post: 'post',
  Topic: 'topic',
  PrivateTopic: 'privateTopic',
  PrivateMessage: 'privateMessage',
  User: 'user',
  Notification: 'notification',
  Application: 'application',
  ApplicationState: 'applicationState',
};

export const SocketUpdateActions = {
  New: 'new',
  Delete: 'delete',
  Update: 'update',
  Reply: 'reply',
  Request: 'request',
  Reload: 'reload',
  Redirect: 'redirect',
  NewPostInTopic: 'newPostInTopic',
  UserWriting: 'userWriting',
};

export const UserStatus = {
  NotApproved: 'notapproved',
  Suspended: 'suspended',
  Silenced: 'silenced',
  Good: 'good',
};

// TODO: refactor to public/private

export const SourceTypes = {
  All: 'all',
  Topic: 'topic',
  Category: 'category',
  SimpleList: 'simpleList',
  Home: 'home',
  User: 'user',
  Message: 'message',
  Post: 'post',
};

export const DisplaySourceTypes = {
  Simple: 'simple',
  PostList: 'postList',
  TopicList: 'topicList',
};

export const EntityTypes = {
  Mention: 'MENTION',
  ImageLink: 'IMAGE_LINK',
  ArticleLink: 'ARTICLE_LINK',
  EmbedLink: 'EMBED_LINK',
  Quote: 'QUOTE',
  Emoji: 'EMOJI',
};

export const TakeoverTypes = {
  Confirmation: 'confirmation',
  Component: 'component',
  Modal: 'modal',
};

export const LexicalComposerNamespaces = {
  Display: 'display',
  PostQuote: 'postQuote',
  Editor: 'editor',
  PostQuoteEditor: 'postQuoteEditor',
};

export const SimpleListUpdateActions = {
  Add: 'add',
  Remove: 'remove',
  Update: 'update',
  Log: 'log',
};

export const CustomEvents = {
  ResetPid: 'resetPid',
  EditPost: 'editPost',
  EditTopic: 'editTopic',
  EditMessage: 'editMessage',
  CloseTopicCreateModal: 'closeTopicCreateModal',
  OpenTopicCreateModal: 'openTopicCreateModal',
  OpenUserProfileDrawer: 'openUserProfileDrawer',
  BetUpdate: 'betUpdate',
  QuotePost: 'quotePost',
  ReplyPost: 'replyPost',
  ToggleNotifications: 'toggleNotifications',
  UpdateNotifications: 'updateNotifications',
  MarkAllNotificationsAsRead: 'markAllNotificationsAsRead',
  ToggleMessages: 'toggleMessages',
  UpdateMessages: 'updateMessages',
  GiphyModalToggle: 'giphyModalToggle',
  GiphySelect: 'giphySelect',
  UsersOnlineInRoom: 'usersOnlineInRoom',
  // used primarily for simple list general rerender
  Rerender: 'Rerender',
  // used for specific simple item list functionality
  SimpleItemListUpdate: 'simpleItemListUpdate',
  ConfirmationDialog: 'confirmationDialog',
  TakeoverDialog: 'TakeoverDialog',
  AdminItemEdit: 'adminItemEdit',
  AdminListUpdate: 'adminListUpdate',
  OpenDirectMessage: 'openDirectMessage',
  ToggleGlobalLoader: 'toggleGlobalLoader',
  TopicSettings: 'topicSettings',
  PostVoteModalOpen: 'postVoteModalOpen',
  ResetPaginatedPostList: 'resetPaginatedPostList',
  RefreshNavigateData: 'refreshNavigateData',
  InsertInEditor: 'insertInEditor',
  OpenImageModal: 'openImageModal',
  OpenImageUploadModal: 'openImageUploadModal',
  ReplaceEditorState: 'replaceEditorState',
  ToggleEditorReadOnly: 'toggleEditorReadOnly',
  CloseEditorPopper: 'closeEditorPopper',
  OpenPostPreviewModal: 'openPostPreviewModal',
  PostQuoteSelection: 'postQuoteSelection',
  PostQuoteSelectionPopper: 'quoteSelectionPopper',
  TopicListRefresh: 'topicListRefresh',
  Redirect: 'redirect',
  OpenDrawerMenu: 'openDrawerMenu',
  Update: 'update',
  InvalidateQueries: 'invalidateQueries',
  TakeoverModal: 'takeoverModal',
  QueryListScroll: 'queryListScroll',
  OpenPostEditsModal: 'openPostEditsModal',
  OpenThemesModal: 'openThemesModal',
};

export const ImageUploadModalTypes = {
  General: 'general',
  Avatar: 'avatar',
  CoverImage: 'coverImage',
};

export const ComposerNodeTypes = {
  Embed: 'embed',
  Image: 'image',
  Mention: 'mention',
  Quote: 'quote',
  Editor: 'editor',
  PostQuote: 'postQuote',
};

export const Breakpoints = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  Desktop: 'desktop',
  WideDesktop: 'wideDesktop',
};

export const BreakpointValues = {
  Mobile: {
    Min: 0,
    Max: 767,
  },
  Tablet: {
    Min: 768,
    Max: 991,
  },
  Desktop: {
    Min: 992,
    Max: 1199,
  },
  WideDesktop: {
    Min: 1200,
    Max: 99999,
  },
};

export const BetStatus = {
  Won: 'won',
  Lost: 'lost',
  Locked: 'locked',
  Open: 'open',
  Claimed: 'claimed',
  Unclaimed: 'unclaimed',
};

export const ReputationLevels = {
  Bronze: 'bronze',
  Silver: 'silver',
  Gold: 'gold',
  Emerald: 'emerald',
  Sapphire: 'sapphire',
  Ruby: 'ruby',
  Amethyst: 'amethyst',
  Diamond: 'diamond',
  PinkDiamond: 'pinkDiamond',
  GalaxyOpal: 'galaxyOpal',
  DarkMatter: 'darkMatter',
};

export const Paths = {
  UserProfile: '/users/:username',
};

export const SkinTones = {
  Default: 1,
  Light: 2,
  MediumLight: 3,
  Medium: 4,
  MediumDark: 5,
  Dark: 6,
};

export const SuggestionHelperNames = {
  EmojiSuggestionsPost: 'emojiSuggestionsPost',
  EmojiSuggestionsTopic: 'emojiSuggestionsTopic',
  MentionSuggestionsPost: 'mentionSuggestionsPost',
};

export const NotificationStatus = {
  Unseen: 'unseen',
  Unread: 'unread',
  Read: 'read',
  All: 'all',
};

export const MessageStatus = {
  Unseen: 'unseen',
  Unread: 'unread',
  Read: 'read',
  All: 'all',
};

export const UserPrivateTopicStatus = {
  Accepted: 'accepted',
  Pending: 'pending',
  Rejected: 'rejected',
  Archived: 'archived',
  Deleted: 'deleted',
  Banned: 'banned',
};

export const PopoverHeight = {
  Mobile: '450px',
  Desktop: '600px',
};

export const MessageViewState = {
  MessageList: 'messageList',
  Create: 'create',
  Message: 'message',
};

export const AdminPaths = {
  Admin: '/admin',
  Users: '/admin/users',
  User: '/admin/users/:username',
  Settings: '/admin/settings',
  Jobs: '/admin/jobs',
  Badge: '/admin/badges',
  Category: '/admin/categories',
  Analytics: '/admin/analytics',
  Developer: '/admin/developer',
};

export const SignUpFormClasses = {
  Content: 'mx-4 my-4 rounded bg-s02dp desktop:w-[992px] tablet:mx-auto desktop:flex desktop:align-center desktop:items-center',
  MainDiv: 'py-5 px-3 form-container desktop:w-[992px] tablet:mx-auto',
  Form: 'registration-form tablet:w-[600px] font-roboto mb-8 mt-4 tablet:mx-auto',
};

export const ConfirmationDialogueClasses = {
  MainDiv: 'bg-surface px-4 py-4 rounded font-roboto',
  Content: 'pb-5',
};

export const PostVoteTypes = {
  Upvote: 'upvote',
  Downvote: 'downvote',
};

export const defaultLoadableAtom = {
  loading: false,
  error: null,
  data: null,
  hasLoaded: false,
  fetching: false,
  lastModified: null,
};

export const TopicListSortKeys = {
  New: 'new',
  Recent: 'recent',
  Hot: 'score',
  Old: 'old',
  Count: 'count',
};

export const ProductSortOptions = {
  New: 'new',
  Old: 'old',
  PriceAsc: 'priceAsc',
  PriceDesc: 'priceDesc',
  Name: 'name',
};

export const ProductSortValues = [
  {
    label: 'Name',
    value: ProductSortOptions.Name,
  },
  {
    label: 'Newest',
    value: ProductSortOptions.New,
  },
  {
    label: 'Oldest',
    value: ProductSortOptions.Old,
  },
  {
    label: 'Price: High-Low',
    value: ProductSortOptions.PriceDesc,
  },
  {
    label: 'Price: Low-High',
    value: ProductSortOptions.PriceAsc,
  },
];

export const TopicListDisplayKeys = {
  Full: 'full',
  Compact: 'compact',
  Preview: 'preview',
};

export const CardHeaderContentDetailTypes = {
  User: 'user',
  Category: 'category',
  Simple: 'simple',
};

export const NotificationsButtonId = 'notifications-button';

export const Platforms = {
  iOS: 'iOS',
  Android: 'Android',
  Windows: 'Windows',
  MacOS: 'MacOS',
  Other: 'Other',
};
