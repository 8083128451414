import {
  useCallback,
  useMemo,
} from 'react';
import {
  IconButton,
  Button,
} from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationStatus, CustomEvents } from '../../lib/constants';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import notificationListStatusKeyState from '../../models/notificationListStatusKey/atom';
import unreadNotificationsCountState from '../../models/unreadNotificationsCount/atom';
import unseenNotificationsCountState from '../../models/unseenNotificationsCount/atom';
import { NotificationModel } from '../../models/notification/model';

const NotificationsListHeader = () => {
  const [notificationListStatusKey, setNotificationListStatusKey] = useRecoilState(notificationListStatusKeyState);
  const unreadNotificationsCount = useRecoilValue(unreadNotificationsCountState);
  const unseenNotificationsCount = useRecoilValue(unseenNotificationsCountState);
  const totalUnreadNotificationsCount = useMemo(() => unreadNotificationsCount + unseenNotificationsCount, [unreadNotificationsCount, unseenNotificationsCount]);

  const handleStatusFilterChange = useCallback((data) => {
    if (!data || data === notificationListStatusKey) return null;
    setNotificationListStatusKey(data);
  }, [notificationListStatusKey]);

  const handleMarkAllAsRead = useCallback(() => {
    NotificationModel.markAllNotificationsAsRead();
  }, []);

  const handleClose = useCallback(() => emitCustomEvent(CustomEvents.ToggleNotifications), []);

  const statusButtonGroup = useMemo(() => (
    <div className='flex justify-between my-2'>
      <div className='flex space-x-2 opacity-80'>
        <Button
          disableElevation
          onClick={() => handleStatusFilterChange(NotificationStatus.All)}
          size='small'
          variant={notificationListStatusKey === NotificationStatus.All ? 'contained' : 'outlined'}
          color={notificationListStatusKey === NotificationStatus.All ? 'primary' : 'primaryLight'}
        >
          All
        </Button>
        <Button
          disabled={totalUnreadNotificationsCount === 0}
          disableElevation
          onClick={() => handleStatusFilterChange(NotificationStatus.Unread)}
          size='small'
          variant={notificationListStatusKey !== NotificationStatus.All ? 'contained' : 'outlined'}
          color={notificationListStatusKey !== NotificationStatus.All ? 'primary' : 'primaryLight'}
        >
          Unread
        </Button>
      </div>
      <Button
        disableElevation
        disabled={totalUnreadNotificationsCount === 0}
        size='small'
        variant='outlined'
        color='secondaryLight'
        onClick={handleMarkAllAsRead}
      >
        Mark All As Read
      </Button>
    </div>
  ), [totalUnreadNotificationsCount, notificationListStatusKey]);

  return (
    <div className='pl-4 pr-2 py-2  prevent-clickaway-notifications bg-s02dp'>
      <div className='flex justify-between items-center'>
        <h2 style={{ fontSize: '24px' }} className='font-bebas text-primary-text'>Notifications</h2>
        <IconButton
          color='textPrimary'
          size='small'
          onClick={handleClose}
          type='button'
        >
          <CancelIcon />
        </IconButton>
      </div>
      { statusButtonGroup }
    </div>
  );
};

export default NotificationsListHeader;
