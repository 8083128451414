import { useCallback } from 'react';
import {
  CustomEvents,
  SocketEvents,
  SocketResourceTypes,
} from '../../lib/constants';
import useSocketOn from '../useSocketOn';
import { useCustomEvent } from '../useCustomEventListener';
import { handleTopic } from './handleTopic';
import { handlePost } from './handlePost';
import { handlePrivatePost } from './handlePrivatePost';
import { handlePrivateTopic } from './handlePrivateTopic';
import { handleNotification } from './handleNotification';
import { handleApplication } from './handleApplication';
import { handleApplicationState } from './handleApplicationState';

export const useHandleSocketEvents = () => {
  const handleSocketEvent = useCallback((res) => {
    const {
      type,
      data,
      resource,
      action,
      effects,
    } = res;
    console.log('\n🟢 socket event');
    console.log({
      type,
      data,
      resource,
      action,
      effects,
    });
    switch (resource) {
      case SocketResourceTypes.Topic:
        handleTopic(res);
        break;
      case SocketResourceTypes.Post:
        handlePost(res);
        break;
      case SocketResourceTypes.PrivateMessage:
        handlePrivatePost(res);
        break;
      case SocketResourceTypes.PrivateTopic:
        handlePrivateTopic(res);
        break;
      case SocketResourceTypes.Notification:
        handleNotification(res);
        break;
      case SocketResourceTypes.Application:
        handleApplication(res);
        break;
      case SocketResourceTypes.ApplicationState:
        handleApplicationState(res);
        break;
      default:
        console.log('unknown resource', { resource });
    }
  }, []);

  useSocketOn(SocketEvents.Update, handleSocketEvent, []);
  useCustomEvent(CustomEvents.Update, handleSocketEvent, []);
};
