import { getRecoil, setRecoil } from 'recoil-nexus';
import { debounceByKey } from '../../lib/debounceByKey';
import { BaseModel } from '../BaseModel';
import * as UserApi from './api';
import state from './atom';
import usernameMappingState from '../usernameMapping/atom';

const getTime = () => new Date().getTime();
export class UserModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => UserModel.requestUser(id);
    super(id, state, request);
    this.id = id;
  }

  static setUsernameMapping = (username, userId) => setRecoil(usernameMappingState(username), userId);

  static requestUser(id) {
    if (!id) return;
    return UserApi.requestUser(id);
  }

  static getUser(id) {
    if (!id) return;
    return getRecoil(state(id)).data;
  }

  static setUser(id, data) {
    if (!id || !data) return;
    if (data?.username) UserModel.setUsernameMapping(data.username, id);
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  static setUserPartial = (id, data) => {
    if (!id || !data) return;
    debounceByKey({ key: id, duration: 50 })
      .then(() => {
        if (data?.username) UserModel.setUsernameMapping(data.username, id);
        if (data?.badges?.length && !data?.badges.every(b => !!b?._id)) return;
        return setRecoil(state(id), prev => ({ ...prev, data: { ...prev.data, ...data, lastModified: getTime() } }));
      })
      .catch(err => err);
  };

  banUser = async () => {
    if (!this.id) return;
    const res = await UserApi.ban(this.id);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  unbanUser = async () => {
    if (!this.id) return;
    const res = await UserApi.unban(this.id);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  approveUser = async () => {
    if (!this.id) return;
    const res = await UserApi.approveUser(this.id);
    if (res?.success) this.setData({ ...res.data, approved: true });
    return { ...res };
  };

  reinstateUser = async () => {
    if (!this.id) return;
    const res = await UserApi.reinstateUser(this.id);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  verifyOtherUserEmail = async () => {
    if (!this.id) return;
    const res = await UserApi.verifyOtherUserEmail(this.id);
    if (res?.success) this.setData({ ...res.data, emailStatus: 'verified' });
    return { ...res };
  };

  silenceUser = async (minutes) => {
    if (!this.id) return;
    const res = await UserApi.silenceUser(this.id, minutes);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  suspendUser = async (date) => {
    if (!this.id) return;
    const res = await UserApi.suspendUser(this.id, date);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  resetUserLoginAttempts = async () => {
    if (!this.id) return;
    const res = await UserApi.resetUserLoginAttempts(this.id);
    return { ...res };
  };

  incrementMt = async (amount) => {
    if (!this.id) return;
    const res = await UserApi.incrementMt(this.id, amount);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  updateUserRole = async (role) => {
    if (!this.id) return;
    const res = await UserApi.updateUserRole(this.id, role);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  addBadge = async (badgeId) => {
    if (!this.id) return;
    const res = await UserApi.addBadge(this.id, badgeId);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  removeBadge = async (badgeId) => {
    if (!this.id) return;
    const res = await UserApi.removeBadge(this.id, badgeId);
    if (res?.success) this.setData(res.data);
    return { ...res };
  };

  onSetState = (topicState, prevTopicState) => {
    if (!topicState?.data?.username || !topicState.data?._id) return;
    if (topicState.data?.username) UserModel.setUsernameMapping(topicState.data.username, topicState.data._id);
  };
}
