import { useSetRecoilState } from 'recoil';
import {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import ApiClientInstance from '../clients/api';
import { addSnackbarSelector } from '../models/snackbar/selector';
import { UserEmailStatus } from '../lib/constants';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

const useHandleSearchParams = () => {
  const { data: user, loading, unauthenticated } = useAuthenticatedUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const emailVerificationTokenValue = searchParams.get('emailVerification');
  const stateRef = useRef({ emailVerificationTokenValue: null });

  const handleEmailVerificationParam = useCallback(async () => {
    if (!stateRef.current.emailVerificationTokenValue) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'PUT',
      path: `/user/email/token/verify/${stateRef.current.emailVerificationTokenValue}`,
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res?.success) {
      addSnackbar({ message: 'Email verified successfully', color: 'primary' });
      console.log('success');
      searchParams.delete('emailVerification');
      setSearchParams(searchParams);
    } else {
      stateRef.current.emailVerificationTokenValue = null;
    }
  }, []);

  useEffect(async () => {
    if (emailVerificationTokenValue) stateRef.current.emailVerificationTokenValue = emailVerificationTokenValue;
    if (loading || unauthenticated || !emailVerificationTokenValue || user?.emailStatus === UserEmailStatus.Verified) return null;
    await handleEmailVerificationParam();
  }, [emailVerificationTokenValue, unauthenticated, user?.emailStatus, loading]);
};

export default useHandleSearchParams;
