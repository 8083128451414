import { useEffect } from 'react';
import SocketClientInstance from '../clients/socket';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';
import { NotificationModel } from '../models/notification/model';
import { PrivateTopicModel } from '../models/privateTopic/model';

export const useOnAuthenticationChange = () => {
  const { data: authenticatedUser, verified } = useAuthenticatedUser();

  useEffect(() => {
    if (!authenticatedUser) return;
    SocketClientInstance.joinUser(authenticatedUser.username);
    NotificationModel.loadNotificationCounts();
    if (verified) PrivateTopicModel.loadUnreadCounts();
  }, [authenticatedUser?._id, verified]);
};
