import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';

const useAuthStatus = () => {
  const {
    loading,
    authenticated,
    unauthenticated,
  } = useAuthenticatedUser();

  return {
    authenticated,
    loading,
    unauthenticated,
  };
};

export default useAuthStatus;
