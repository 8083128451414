import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitTakeoverDialog = ({
  handler,
  text,
  data,
  component,
}) => {
  console.log({
    handler, text, data, component,
  });
  if (!handler || !component) return null;
  emitCustomEvent(CustomEvents.TakeoverDialog, {
    handler,
    text,
    data,
    component,
  });
};
