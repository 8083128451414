import { useMemo } from 'react';
import ULogo from '../../Svg/uLogo';
import './style.css';

const DEFAULT_STROKE_WIDTH = 2;

const CircleLoading = ({
  innerColor,
  outerColor,
  innerStrokeWidth,
  outerStrokeWidth,
  strokeWidth,
  height = 200,
  width = 200,
  size = 200,
  boxType = 'fullScreen',
  zIndex = 1,
  showLogo = true,
  containerStyles = {},
}) => {
  const [_height, _width] = useMemo(() => {
    const h = size ? `${size}px` : `${height}px`;
    const w = size ? `${size}px` : `${width}px`;
    return [h, w];
  }, [height, width, size]);

  const style = useMemo(() => ({
    margin: 'auto',
    background: 'none',
    display: 'block',
    shapeRendering: 'auto',
    height: _height,
    width: _width,
    zIndex,
  }), [height, width, zIndex, _height, _width]);

  const _outerColor = useMemo(() => {
    if (!outerColor) return 'var(--color-primary)';
    return `var(--color-${outerColor})`;
  }, [outerColor]);

  const _innerColor = useMemo(() => {
    if (!innerColor) return 'var(--color-primary-light)';
    return `var(--color-${innerColor})`;
  }, [innerColor]);

  const _innerStrokeWidth = useMemo(() => {
    if (strokeWidth) return strokeWidth;
    if (!innerStrokeWidth) return DEFAULT_STROKE_WIDTH;
    return innerStrokeWidth;
  }, [innerStrokeWidth, strokeWidth]);

  const _outerStrokeWidth = useMemo(() => {
    if (strokeWidth) return strokeWidth;
    if (!outerStrokeWidth) return DEFAULT_STROKE_WIDTH;
    return outerStrokeWidth;
  }, [outerStrokeWidth, strokeWidth]);

  const _containerStyle = useMemo(() => {
    if (boxType === 'fullScreen') {
      return {
        top: 0,
        left: 0,
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex,
        ...containerStyles,
      };
    }
    if (boxType === 'fullContainer') {
      return {
        height: '100%',
        minHeight: _height,
        minWidth: _width,
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex,
        ...containerStyles,
      };
    }
    return {};
  }, [boxType, zIndex, _height, _width, containerStyles]);

  const imageSize = useMemo(() => {
    const _imageSize = Math.ceil((size || height) * 0.15);
    if (_imageSize < 20) return 20;
    return _imageSize;
  }, [size, height]);

  const logo = useMemo(() => {
    if (!showLogo) return null;
    return (
      <div className={`h-[${imageSize}px] w-[${imageSize}px] absolute flex items-center justify-center`}>
        <ULogo containerStyles={{ height: imageSize }} />
      </div>
    );
  }, [imageSize, showLogo]);

  return (
    <div className='loading-container' style={_containerStyle}>
      { logo }
      <svg
        style={style}
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid'
      >
        <circle
          id='loading-circle-outer'
          cx='50'
          cy='50'
          r='24'
          strokeWidth={_outerStrokeWidth}
          stroke={_outerColor}
          strokeDasharray='40.840704496667314 40.840704496667314'
          fill='none'
          strokeLinecap='round'
        />
        <circle
          id='loading-circle-inner'
          cx='50'
          cy='50'
          r='18'
          strokeWidth={_innerStrokeWidth}
          stroke={_innerColor}
          strokeDasharray='20 20 20'
          strokeDashoffset='28.274333882308138'
          fill='none'
          strokeLinecap='round'
        />
      </svg>
    </div>
  );
};

export default CircleLoading;
