import { useRecoilState, useSetRecoilState } from 'recoil';
import state from './atom';
import {
  loadingPaginatedScroller,
  loadingPrevPaginatedScroller,
  loadingNextPaginatedScroller,
  cursorStatePaginatedScroller,
  pageStatusPaginatedScroller,
  scrollLockPaginatedScroller,
} from './selectors';

export const usePaginatedScroller = ({ topicId }) => {
  const [paginatedScrollerState, setPaginatedScrollerState] = useRecoilState(state(topicId));
  const setLoading = useSetRecoilState(loadingPaginatedScroller(topicId));
  const setLoadingPrev = useSetRecoilState(loadingPrevPaginatedScroller(topicId));
  const setLoadingNext = useSetRecoilState(loadingNextPaginatedScroller(topicId));
  const setCursorState = useSetRecoilState(cursorStatePaginatedScroller(topicId));
  const setPageStatus = useSetRecoilState(pageStatusPaginatedScroller(topicId));
  const setScrollLock = useSetRecoilState(scrollLockPaginatedScroller(topicId));

  const {
    loading,
    loadingPrev,
    loadingNext,
    cursorState,
    pageStatus,
    scrollLock,
  } = paginatedScrollerState;

  return {
    loading,
    loadingPrev,
    loadingNext,
    cursorState,
    pageStatus,
    scrollLock,
    setPaginatedScrollerState,
    setLoading,
    setLoadingPrev,
    setLoadingNext,
    setCursorState,
    setPageStatus,
    setScrollLock,
  };
};
