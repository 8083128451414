/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import ImageGallery from '../ImageGallery';
import './style.css';

const PostPreviewContent = ({
  texts,
  embeds,
  mentions,
  hashtags,
}) => {
  const images = embeds.filter((embed) => embed.type === 'image');
  const _embeds = embeds.filter((embed) => embed.type !== 'image');
  const _embed = _embeds[0] || null;
  let _texts = texts.length > 1 ? texts.join(' ') : texts[0];
  if (_texts[0] === ' ' || _texts[0] === '') _texts = _texts.slice(1);
  return (
    <section className='tku-topic-preview pl-1 text-[14px] leading-5'>
      { _texts && <div className='line-clamp-4'>{ _texts }</div> }
      { images.length > 0 && <ImageGallery images={images} /> }
      { images.length === 0 && _embed && (
        <div className='tku-topic-preview-embed py-2 px-2 bg-s01dp rounded mt-2'>
          <div className='tku-topic-preview-embed-title'>
            <a className='text-primary-brand-small-text line-clamp-1' target='_blank' href={_embed.url} rel='noreferrer'>
              { `${_embed.emoji} ${_embed.title}` }
            </a>
          </div>
          <div className='tku-topic-preview-embed-description mt-2 line-clamp-3'>
            { _embed.description }
          </div>
        </div>
      ) }
    </section>

  );
};

export default PostPreviewContent;
