import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitInsertInEditor = ({
  editorName,
  nodeType,
  data,
}) => {
  if (!data || !editorName || !nodeType) return null;
  emitCustomEvent(CustomEvents.InsertInEditor, {
    editorName,
    nodeType,
    data,
  });
};
