import {
  Routes,
  Route,
} from 'react-router-dom';

import Home from '../../pages/Home';
import Users from '../../pages/Users';
import Category from '../../pages/Category';
import Topic from '../../pages/Topic';
import Navbar from '../Navbar';
import Login from '../../pages/Login';
import Register from '../../pages/Register';
import Profile from '../../pages/Profile';
import Privacy from '../../pages/Privacy';
import TermsOfUse from '../../pages/TermsOfUse';
import Games from '../../pages/Games';
import Footer from '../Footer';
import NotFound from '../../pages/NotFound';
import Categories from '../../pages/Categories';
import PasswordReset from '../../pages/PasswordReset';
import useApplyTheme from '../../hooks/useApplyTheme';
import Admin from '../../pages/Admin';
import Snackbar from '../Snackbar';
import HooksAndAnalytics from '../HooksAndAnalytics';
import UserNav from '../UserNav';
import SiteNav from '../SiteNav';
import AllTopics from '../../pages/AllTopics';
import Content from '../Containers/Content';
import TopicCreateModal from '../TopicCreateModal';
import Bets from '../../pages/Bets';
import Message from '../../pages/Message';
import UserProfileDrawer from '../UserProfileDrawer';
import ConfirmationDialog from '../ConfirmationDialog';
import UniverseLoader from '../Loaders/UniverseLoader';
import CreatePassword from '../../pages/CreatePassword';
import Settings from '../../pages/Settings';
import Messages from '../../pages/Messages';
import TakeoverDialog from '../TakeoverDialog';
import ErrorBoundary from '../ErrorBoundary';
import SearchPopper from '../SearchPopper';
import { useIsOnline } from '../../hooks/useIsOnline';
import Disconnected from '../../pages/Disconnected';
import PostVotesModal from '../PostVotesModal';
import ImageModal from '../ImageModal';
import ImageUploadModal from '../ImageUploadModal';
import GiphyModal from '../GiphyModal';
import PostPreviewModal from '../PostPreviewModal';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import NotificationsContainer from '../NotificationsContainer';
import Store from '../../pages/Store';
import PostEditsModal from '../PostEditsModal';
import ThemesModal from '../ThemesModal';

const wrapWithErrorBoundary = (Component) => <ErrorBoundary redirect='/404'><Component /></ErrorBoundary>;

const MainRouter = () => {
  const { hasLoaded, loading } = useAuthenticatedUser();
  const isOnline = useIsOnline();
  useApplyTheme();

  if (!isOnline) return <Disconnected />;

  return (
    <>
      <Navbar />
      <Content id='tku-main-content' className='tku-main-content'>
        { (loading || !hasLoaded) && <UniverseLoader boxType='fullScreen' />}
        { hasLoaded && (
        <Routes>
          <Route path='/' element={wrapWithErrorBoundary(Home)} />
          <Route path='/users' element={wrapWithErrorBoundary(Users)} />
          <Route path='/users/:username' element={wrapWithErrorBoundary(Profile)} />
          <Route path='/category/:slug' element={wrapWithErrorBoundary(Category)} />
          <Route path='/topic/:shortId/:slug' element={wrapWithErrorBoundary(Topic)} />
          <Route path='/register' element={wrapWithErrorBoundary(Register)} />
          <Route path='/login' element={wrapWithErrorBoundary(Login)} />
          <Route path='/games' element={wrapWithErrorBoundary(Games)} />
          <Route path='/terms-of-use' element={wrapWithErrorBoundary(TermsOfUse)} />
          <Route path='/privacy' element={wrapWithErrorBoundary(Privacy)} />
          <Route path='/password-reset' element={wrapWithErrorBoundary(PasswordReset)} />
          <Route path='/create-password' element={wrapWithErrorBoundary(CreatePassword)} />
          <Route path='/categories' element={wrapWithErrorBoundary(Categories)} />
          <Route path='/games' element={wrapWithErrorBoundary(Games)} />
          <Route path='/bets' element={wrapWithErrorBoundary(Bets)} />
          <Route path='/store/*' element={wrapWithErrorBoundary(Store)} />
          <Route path='/settings/*' element={wrapWithErrorBoundary(Settings)} />
          <Route path='/all' element={wrapWithErrorBoundary(AllTopics)} />
          <Route path='/404' element={<NotFound />} />
          <Route path='/admin/*' element={wrapWithErrorBoundary(Admin)} />
          <Route path='/message/:id' element={wrapWithErrorBoundary(Message)} />
          <Route path='/messages/*' element={wrapWithErrorBoundary(Messages)} />
          <Route path='*' element={wrapWithErrorBoundary(NotFound)} />
        </Routes>
        ) }
      </Content>
      { hasLoaded && (
      <>
        <Footer />
        <UserProfileDrawer />
        <Snackbar />
        <TopicCreateModal />
        <UserNav />
        <HooksAndAnalytics />
        <SiteNav />
        <ConfirmationDialog />
        <TakeoverDialog />
        <SearchPopper />
        <PostVotesModal />
        <ImageModal />
        <ImageUploadModal />
        <GiphyModal />
        <PostEditsModal />
        <PostPreviewModal />
        <NotificationsContainer />
        <ThemesModal />
      </>
      )}
    </>
  );
};

export default MainRouter;
