import { atom } from 'recoil';

const storeProductCategoriesState = atom({
  key: 'storeProductCategoriesState',
  default: {
    tier1: [],
    tier2: [],
    tier3: [],
  },
});

export default storeProductCategoriesState;
