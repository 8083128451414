/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import {
  useCallback,
  useMemo,
} from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Grid,
  ListItem,
} from '@mui/material';
import {
  SourceTypes,
  UserPrivateTopicStatus,
} from '../../lib/constants';

import UserAvatar from '../UserAvatar';
import MessageManageMenu from '../MessageManageMenu';

import { useTopic } from '../../models/topic/useTopic';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useMessageMetaData } from '../../hooks/useMessageMetaData';

const MAX_USERS = 3;

const MessageListItem = ({
  _id,
}) => {
  const navigate = useNavigate();

  const { data: user } = useAuthenticatedUser();
  const { data: message, model, loading } = useTopic(_id, { sourceType: SourceTypes.Message });

  const {
    latestReply,
    usersPermitted,
    isDeleted,
    isArchived,
  } = message;

  const {
    acceptanceStatus,
    role,
    viewStatus,
    timeAgoColor,
    users,
    chatTitle,
    latestReplyText,
    backgroundColor,
    isRead,
    timeAgo,
  } = useMessageMetaData({ _id });

  const handleMessageClick = useCallback(async (e) => {
    if (acceptanceStatus !== UserPrivateTopicStatus.Accepted || isArchived) return null;
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    await model.updateLatestPostInTopicToRead(false);
    navigate(`/message/${_id}`);
  }, [latestReply, acceptanceStatus, isArchived, viewStatus, model]);

  const usersAvatars = useMemo(() => (
    <>
      { !users.length && (
      <UserAvatar
        showOnline
        _id={user._id}
        borderColor='var(--color-s02dp)'
        avatarSize='45px'
        username={user.username}
        color={user.color}
        avatar={user.avatar}
      />
      )}
      { users.length === 1 && (
        <div>
          <UserAvatar
            showOnline
            _id={users[0].user._id}
            borderColor='var(--color-s02dp)'
            avatarSize='45px'
          />
        </div>
      ) }
      { users.length > 1 && (
      <div className='flex flex-col items-center'>
        <div className='flex mr-[-5px]'>
          { users.slice(0, MAX_USERS).map((u, i) => (
            <div key={u.user._id} className='inline-block h-[25px] w-[25px]' style={{ marginLeft: i > 0 ? '-10px' : '', zIndex: i + 1 }}>
              <UserAvatar
                _id={u.user._id}
                avatarSize='25px'
                styles={{ border: 'none !important' }}
              />
            </div>
          )) }
        </div>
        { users.length > MAX_USERS && (
          <span className='flex justify-center text-xs text-primary-text font-roboto'>
            {`+${users.length - MAX_USERS}`}
          </span>
        ) }
      </div>
      ) }
    </>
  ), [usersPermitted, users]);

  const messagePreview = useMemo(() => (
    <span className='flex flex-col' style={{ wordBreak: 'break-word' }}>
      <span className='text-primary-text font-roboto font-bold line-clamp-1' style={{ fontSize: '.9rem' }}>{chatTitle}</span>
      { acceptanceStatus === UserPrivateTopicStatus.Accepted && <span className='break-all text-primary-text font-roboto line-clamp-1' style={{ fontSize: '.75rem' }}>{latestReplyText}</span> }
      <span style={{ fontSize: '.75rem', color: timeAgoColor, fontWeight: (!isRead && acceptanceStatus === 'accepted') ? '700' : '' }}>{`${timeAgo} ago`}</span>
    </span>
  ), [timeAgoColor, latestReplyText, chatTitle, isRead, acceptanceStatus, timeAgo]);

  const manageButton = useMemo(() => (
    <MessageManageMenu
      _id={_id}
      userRole={role}
      isArchived={isArchived}
      messageStatus={acceptanceStatus}
      messageId={_id}
      latestReplyId={latestReply._id}
      messageReadStatus={acceptanceStatus === UserPrivateTopicStatus.Accepted ? viewStatus : false}
    />
  ), [isDeleted, latestReply._id, isArchived, _id, role, acceptanceStatus, viewStatus]);

  return (
    <ListItem disableRipple button onClick={handleMessageClick} className='relative'>
      <div
        className='absolute'
        style={{
          top: 0, left: 0, height: '100%', width: '100%', opacity: '.10', backgroundColor,
        }}
      />
      <Grid container columns={24} sx={{ zIndex: 1 }}>
        <Grid className='flex justify-start' item xs={5} sm={4}>
          { usersAvatars }
        </Grid>
        <Grid className='flex items-center' item xs={10} sm={16}>
          { messagePreview }
        </Grid>
        <Grid className='flex items-center justify-end' item xs={8} sm={4}>
          { manageButton}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default MessageListItem;
