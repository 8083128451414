// https://stackoverflow.com/questions/6393943/convert-a-javascript-string-in-dot-notation-into-an-object-reference
const index = (obj, i) => obj[i];

export const getObjectValue = (path, obj) => {
  try {
    return path.split('.').reduce(index, obj);
  } catch (e) {
    return null;
  }
};
