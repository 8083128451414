import { Modal } from '@mui/material';
import { useCallback, useState } from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

import { ThemeData } from '../../styles/themes';
import useTheme from '../../hooks/useTheme';
import './style.css';

const ThemesModal = () => {
  const [open, setOpen] = useState(false);

  const { setTheme, theme } = useTheme();

  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen = useCallback(() => setOpen(true), []);

  useCustomEvent(CustomEvents.OpenThemesModal, handleOpen);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: '992px' }} className='bg-surface px-4 py-2 rounded font-roboto text-primary-text w-[95vw]'>
        <h1 className='font-bebas text-[28px]'>Select a Theme</h1>
        <div className='flex items-start flex-wrap gap-x-4 gap-y-3'>
          {
          Object.keys(ThemeData).map((key) => {
            const {
              displayName,
              theme: _theme,
              mode,
              value,
            } = ThemeData[key];
            const handleClick = () => setTheme(value);
            return (
              <button className='hover:bg-red' key={value} type='button' onClick={handleClick} style={{ maxWidth: '50px' }}>
                <div className='h-[50px] w-[50px] relative' style={{ borderRadius: '100%' }}>
                  { theme === value && (
                  <span className='checkmark z-50'>
                    <div className='checkmark_stem bg-white' />
                    <div className='checkmark_kick bg-white' />
                  </span>
                  ) }
                  <div className='h-[50px] w-[25px] absolute top-0 left-0' style={{ borderRadius: '10rem 0 0 10rem', backgroundColor: _theme.primary }} />
                  <div className='h-[50px] w-[25px] absolute bottom-0 right-0' style={{ borderRadius: '0 10rem 10rem 0', backgroundColor: _theme.secondary }} />
                </div>
                <div className='mt-2 leading-5 text-sm font-bold mb-1'>{ displayName }</div>
                <span>{ mode === 'light' ? <WbSunnyIcon sx={{ height: '20px', width: '20px' }} /> : <DarkModeIcon sx={{ height: '20px', width: '20px' }} /> }</span>
              </button>
            );
          })
        }
        </div>
      </div>
    </Modal>
  );
};

export default ThemesModal;
