import {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useRecoilValue } from 'recoil';
import { IconButton } from '@mui/material';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import postInViewState from '../../models/postInView/atom';
import { CustomEvents } from '../../lib/constants';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { loadingPaginatedScroller, loadingNextPaginatedScroller, loadingPrevPaginatedScroller } from '../../models/paginatedScrollerState/selectors';

const PostNavigatorBar = ({
  topicId,
  navigateData,
  setPostSelectorVisibility,
  postSelectorVisible,
}) => {
  const currentPost = useRecoilValue(postInViewState(topicId));
  const [currentPostIndex, setCurrentPostIndex] = useState(null);
  const loading = useRecoilValue(loadingPaginatedScroller(topicId));
  const loadingNext = useRecoilValue(loadingNextPaginatedScroller(topicId));
  const loadingPrev = useRecoilValue(loadingPrevPaginatedScroller(topicId));

  const [top, bottom] = useMemo(() => {
    let _topPostId = '';
    let _bottomPostId = '';
    if (navigateData?.posts) {
      const postIds = Object.keys(navigateData.posts);
      for (let i = 0; i < postIds.length; i++) {
        if (_topPostId !== '' && _bottomPostId !== '') break;
        const id = postIds[i];
        const value = navigateData.posts[id];
        if (value === 0) _topPostId = id;
        if (value === postIds.length - 1) _bottomPostId = id;
      }
    }
    return [_topPostId, _bottomPostId];
  }, [navigateData]);

  const handleTopClick = useCallback(() => {
    emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: top });
  }, [top, navigateData]);

  const handleBottomClick = useCallback(() => {
    emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: bottom });
  }, [bottom, navigateData]);

  useEffect(() => {
    if (!navigateData || loadingPrev || loading || loadingNext) return;
    const index = navigateData.posts[currentPost];
    if (typeof index === 'undefined') return;
    const percentage = (currentPostIndex + 1) / navigateData.count;
    if (Number.isNaN(index) || Number.isNaN(percentage)) return;
    setCurrentPostIndex(index);
  }, [navigateData, currentPost, loadingPrev, loading, loadingNext, postSelectorVisible]);

  if (!navigateData || !currentPost || loading) {
    return (
      <div className='w-[200px] bg-surface h-[35px] rounded post-navigator-bar'>
        <div className='bg-s04dp w-[200px] h-[35px] rounded' />
      </div>
    );
  }

  return (
    <div className='w-[200px] bg-surface h-[35px] rounded post-navigator-bar'>
      <div className='bg-s04dp w-[200px] h-[35px] rounded'>
        <div className='relative bg-surface text-primary-text font-roboto'>
          <div className='h-[35px] flex items-center absolute z-10 px-2 rounded w-[100%] justify-between'>
            <IconButton
              onClick={handleTopClick}
              size='small'
              edge='start'
            >
              <ArrowUpward />
            </IconButton>
            <div
              role='button'
              tabIndex='0'
              onKeyDown={() => setPostSelectorVisibility(true)}
              onClick={() => setPostSelectorVisibility(true)}
              style={{ cursor: 'pointer', fontWeight: '500' }}
            >
              { `${currentPostIndex + 1} / ${navigateData.count}` }
            </div>
            <IconButton
              edge='end'
              onClick={handleBottomClick}
              size='small'
            >
              <ArrowDownward />
            </IconButton>
          </div>
          <div style={{ opacity: 0.4, width: `${100 * ((currentPostIndex + 1) / navigateData.count)}%` }} className='bg-primary-light h-[35px] absolute z-1 rounded' />
        </div>
      </div>
    </div>
  );
};

export default PostNavigatorBar;
