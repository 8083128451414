/* eslint-disable react/function-component-definition */
import { selectorFamily } from 'recoil';
import state from './atom';
import { lastPostInTopic } from '../paginatedScrollerState/selectors';
import { emitResetPaginatedPostList } from '../../lib/emitters/emitResetPaginatedPostList';

export const addPostToLastPage = selectorFamily({
  key: 'loadingPaginatedScrollerSelector',
  get: topicId => ({ get }) => get(state(topicId)).currentPostPages.length,
  set: topicId => ({ set, get }, post) => {
    const lastPost = get(lastPostInTopic(topicId));
    if (!lastPost) return;
    const currentPostPages = get(state(topicId));
    const pagesLength = currentPostPages.length;
    if (pagesLength === 0) return;
    const lastPage = currentPostPages[pagesLength - 1];
    const newLastPage = [...lastPage, post];
    if (pagesLength === 1) set(state(topicId), [newLastPage]);
    else set(state(topicId), [...currentPostPages.slice(0, pagesLength - 1), newLastPage]);
    const { _id } = post;
    // TODO: hacky but working
    setTimeout(() => {
      emitResetPaginatedPostList({ postId: _id });
    }, 50);
  },
});
