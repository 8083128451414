import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitConfirmationDialog = ({
  handler,
  text,
  data = {},
  component,
}) => {
  if (!handler || !text) return null;
  emitCustomEvent(CustomEvents.ConfirmationDialog, {
    handler,
    text,
    data,
    component,
  });
};
