import ReactGA from 'react-ga4';
import usePageView from '../../hooks/usePageView';
import useBreakpointName from '../../hooks/useBreakpointName';
import useHandleSearchParams from '../../hooks/useHandleSearchParams';
import useOnRedirect from '../../hooks/useOnRedirect';
import useUsersOnline from '../../hooks/useUsersOnline';
import { useReputationLevels } from '../../hooks/useReputationLevels';
import { useHandleSocketEvents } from '../../hooks/useHandleSocket';
import { useHandleInvalidateQueries } from '../../hooks/useHandleInvalidateQueries';
import { useOnAuthenticationChange } from '../../hooks/useOnAuthenticationChange';
import { useSocketConnectionSync } from '../../hooks/useSocketConnectionSync';
import { useFullTitleSync } from '../../hooks/useFullTitleSync';
import { useDeterminePlatformAndSettings } from '../../hooks/useDeterminePlatformAndSettings';
import { useNotificationUpdate } from '../../hooks/useNotificationUpdate';

const { REACT_APP_GA } = process.env;

const HooksAndAnalytics = () => {
  // Analytics
  ReactGA.initialize(REACT_APP_GA);

  // Platform and Platform State
  useDeterminePlatformAndSettings();

  // Init and Connection Syncrhonization
  useOnAuthenticationChange();
  useSocketConnectionSync();
  useReputationLevels();
  useUsersOnline();

  // Socket Events
  useHandleSocketEvents();

  // Custom Events
  useHandleInvalidateQueries();
  useOnRedirect();

  // State Syncrhonization
  usePageView();
  useBreakpointName();
  useHandleSearchParams();
  useFullTitleSync();
  useNotificationUpdate();

  return null;
};

export default HooksAndAnalytics;
