/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo } from 'react';
import { setRecoil } from 'recoil-nexus';
import ChangeMessageTitle from '../components/ChangeMessageTitle';
import ManageTopicUsers from '../components/ManageTopicUsers';
import {
  ConfirmationDialogueClasses, CustomEvents, QueryKeys,
} from '../lib/constants';
import { emitInvalidateQueries } from '../lib/emitters/emitInvalidateQueries';
import { PrivateTopicModel } from '../models/privateTopic/model';
import { addSnackbarSelector } from '../models/snackbar/selector';
import { emitCustomEvent } from './useCustomEventListener';

const addSnackbar = ({ message, color }) => setRecoil(addSnackbarSelector, { message, color });

export const useManageMessage = ({
  _id,
  acceptCallback,
  rejectCallback,
  archiveCallback,
  errorCallback,
  unarchiveCallback,
  deleteCallback,
  ownerArchiveCallback,
  ownerDeleteCallback,
  markLatestAsReadCallback,
  manageUsersCallback,
  editTitleCallback,
  leaveMessageCallback,
}) => {
  const PrivateTopic = useMemo(() => new PrivateTopicModel(_id), [_id]);

  const handleAccept = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.acceptRequest();
    if (statusRes.success) {
      await PrivateTopic.updateLatestPostInTopicToRead();
      if (acceptCallback) acceptCallback();
      return true;
    }
    addSnackbar({ message: 'Your request could not be accepted at this time.', color: 'error' });
    if (errorCallback) errorCallback();
    return false;
  }, [_id, acceptCallback, errorCallback, PrivateTopic]);

  const handleReject = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.rejectRequest();
    if (statusRes.success) {
      setRecoil(addSnackbarSelector, { message: 'Message request successully rejected.', color: 'primary' });
      if (rejectCallback) rejectCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, rejectCallback, errorCallback, PrivateTopic]);

  const handleArchive = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.archiveMessage();
    if (statusRes.success) {
      if (archiveCallback) archiveCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, archiveCallback, errorCallback, PrivateTopic]);

  const handleOwnerArchive = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.archiveForEveryone();
    if (statusRes.success) {
      if (ownerArchiveCallback) ownerArchiveCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, ownerArchiveCallback, errorCallback, PrivateTopic]);

  const handleOwnerDelete = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.deleteForEveryone();
    if (statusRes.success) {
      if (ownerDeleteCallback) ownerDeleteCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, ownerDeleteCallback, errorCallback, PrivateTopic]);

  const handleUnarchive = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.acceptRequest();
    if (statusRes.success) {
      if (unarchiveCallback) unarchiveCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, unarchiveCallback, errorCallback, PrivateTopic]);

  const handleDelete = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.deleteMessage();
    if (statusRes.success) {
      await PrivateTopicModel.loadUnreadCounts();
      if (deleteCallback) deleteCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, deleteCallback, errorCallback]);

  const handleMarkLatestAsRead = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.updateLatestPostInTopicToRead();
    if (statusRes.success) {
      if (markLatestAsReadCallback) markLatestAsReadCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, markLatestAsReadCallback, errorCallback, PrivateTopic]);

  const handleManageUsers = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const handler = async ({ usersPermitted }) => {
      const data = PrivateTopic.manageUsers(usersPermitted);
      if (data.success) {
        addSnackbar({
          message: 'Users permitted updated successfully!',
          color: 'primary',
        });
        if (manageUsersCallback) manageUsersCallback();
      } else {
        addSnackbar({
          message: `Error updating users permitted: ${data.message}`,
          severity: 'error',
        });
      }
    };
    const text = 'Manage Users';
    const confirmationData = {};
    const confirmationComponent = (props) => (
      <ManageTopicUsers
        addUserCallback={manageUsersCallback}
        _id={_id}
        {...props}
      />
    );
    const eventData = {
      handler,
      text,
      data: confirmationData,
      component: confirmationComponent,
    };
    emitCustomEvent(CustomEvents.TakeoverDialog, eventData);
  }, [_id, manageUsersCallback, errorCallback, PrivateTopic]);

  const handleEditTitle = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const handler = async ({ _title, _textContent }) => {
      const data = await PrivateTopic.updateTitle(_title, _textContent);
      if (data.success) {
        addSnackbar({
          message: 'Title updated successfully!',
          color: 'primary',
        });
        if (editTitleCallback) editTitleCallback();
      } else {
        addSnackbar({
          message: `Error updating title: ${data.message}`,
          severity: 'error',
        });
        if (errorCallback) errorCallback();
      }
    };
    const text = 'Edit Title';
    const confirmationData = {};
    const confirmationComponent = (props) => (
      <ChangeMessageTitle
        {...props}
        mainContainerClassname={ConfirmationDialogueClasses.MainDiv}
        topicId={_id}
      />
    );
    const eventData = {
      handler,
      text,
      data: confirmationData,
      component: confirmationComponent,
    };
    emitCustomEvent(CustomEvents.TakeoverDialog, eventData);
  }, [_id, editTitleCallback, errorCallback, PrivateTopic]);

  const handleLeaveMessage = useCallback(async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const statusRes = await PrivateTopic.leaveMessage();
    if (statusRes.success) {
      emitInvalidateQueries({ queryKey: QueryKeys.Messages });
      if (leaveMessageCallback) leaveMessageCallback();
      return true;
    }
    if (errorCallback) errorCallback();
    return false;
  }, [_id, leaveMessageCallback, errorCallback, PrivateTopic]);

  return {
    handleAccept,
    handleReject,
    handleArchive,
    handleUnarchive,
    handleDelete,
    handleOwnerArchive,
    handleOwnerDelete,
    handleMarkLatestAsRead,
    handleManageUsers,
    handleEditTitle,
    handleLeaveMessage,
  };
};
