import { atom } from 'recoil';
import { DEFAULT_THEME } from '../../styles/themes';

const settingsState = atom({
  key: 'settingsState',
  default: {
    theme: DEFAULT_THEME,
    notifications: true,
    random: 'false',
    breakpoint: null,
    connectionId: null,
    isOnline: true,
    reputationLevels: null,
    isPwa: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    hasNotificationsEnabled: false,
    platform: null,
    visibility: null,
    navLoader: false,
  },
  effects: [
    ({ onSet }) => {
      onSet(val => {
        // console.log('settingsState', val);
      });
    },
  ],
});

export default settingsState;
