import ApiClientInstance from '../../clients/api';
import { PinTypes } from '../../lib/constants';

export const requestTopic = (id) => ApiClientInstance.sendRequest({
  method: 'get',
  path: `/topic/id/${id}`,
  catchError: true,
});

export const unhideTopic = (id) => ApiClientInstance.sendRequest({
  path: `/topic/${id}/unhide`,
  method: 'put',
  catchError: true,
});

export const hideTopic = (id) => ApiClientInstance.sendRequest({
  path: `/topic/${id}/hide`,
  method: 'put',
  catchError: true,
});

export const saveTopic = (id) => ApiClientInstance.sendRequest({
  path: `/topic/${id}/save`,
  method: 'put',
  catchError: true,
});

export const unsaveTopic = (id) => ApiClientInstance.sendRequest({
  path: `/topic/${id}/unsave`,
  method: 'put',
  catchError: true,
});

export const requestTopicFromShortId = (shortId) => ApiClientInstance.sendRequest({
  path: `/topic/short-id/${shortId}`,
  method: 'get',
  catchError: true,
});

export const lockTopic = (id) => ApiClientInstance.sendRequest({
  path: `/admin/topic/lock/${id}`,
  method: 'put',
  catchError: true,
});

export const unlockTopic = (id) => ApiClientInstance.sendRequest({
  path: `/admin/topic/unlock/${id}`,
  method: 'put',
  catchError: true,
});

export const updateTopicPin = (id, {
  active,
  type,
  expires,
}) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/topic/${active ? 'pin' : 'unpin'}/${id}`,
  data: {
    expires: expires || null,
    type: type || PinTypes.Global,
    active: active || false,
  },
  catchError: true,
  snackbarError: 'errorMessage',
});

export const updateLastPostSeenInTopic = (id, lastPost) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/topic/${id}/last-seen/${lastPost}`,
  catchError: true,
});
