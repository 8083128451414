import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { getDateFromObjectId } from '../lib/getDateFromObjectId';
import lastSeenPostState from '../models/lastSeenPostsInTopic/atom';
import postInViewState from '../models/postInView/atom';
import { TopicModel } from '../models/topic/model';
import { loadingAnyPaginatedScroller } from '../models/paginatedScrollerState/selectors';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';
import debounce from '../lib/debounce';

export const useLastSeenPost = ({
  topicId,
  exclude = false,
}) => {
  const [lastSeenPost, setLastSeenPost] = useRecoilState(lastSeenPostState(topicId));
  const postInView = useRecoilValue(postInViewState(topicId));
  const { authenticated } = useAuthenticatedUser();
  const hasSetOnce = useRef(false);

  const setLastSeenPostInDb = useCallback(debounce((postId) => {
    if (!authenticated) return;
    TopicModel.updateLastPostSeenInTopic(topicId, postId);
    hasSetOnce.current = true;
  }, 300), [topicId, authenticated]);

  useEffect(() => {
    if (!lastSeenPost) return;
    if (authenticated) setLastSeenPostInDb(lastSeenPost);
  }, [authenticated, lastSeenPost]);

  useEffect(() => () => {
    if (authenticated && !hasSetOnce.current && !lastSeenPost) {
      const topicModel = new TopicModel(topicId);
      const mainPost = topicModel.getData().mainPost?._id || topicModel.getData().mainPost;
      if (mainPost) {
        setLastSeenPost(mainPost);
        setLastSeenPostInDb(mainPost);
      }
    }
  }, []);

  useEffect(() => {
    if (exclude || !postInView || !topicId) return;
    const isLoading = getRecoil(loadingAnyPaginatedScroller(topicId));
    if (isLoading) return;
    if (exclude || !postInView || !topicId) return;

    const currentPostTimeValue = getDateFromObjectId(postInView);
    if (!currentPostTimeValue) return null;
    if (!lastSeenPost) return setLastSeenPost(postInView);
    if (currentPostTimeValue.valueOf() > getDateFromObjectId(lastSeenPost).valueOf()) return setLastSeenPost(postInView);
  }, [lastSeenPost, postInView, topicId, exclude, setLastSeenPost, authenticated]);
};
