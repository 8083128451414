import { useCallback, useEffect, useMemo } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import { setRecoil } from 'recoil-nexus';
import { Wrapper, Content } from '../../components/Containers';
import StoreBadges from '../../components/StoreBadges';
import StorePacks from '../../components/StorePacks';
import StoreHome from '../../components/StoreHome';
import NotFound from '../NotFound';

import currencyFormat from '../../lib/currencyFormat';

import { UserProductModel } from '../../models/userProduct/model';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import ApiClientInstance from '../../clients/api';
import userProductsList from '../../models/userProductsList/atom';

const routes = [
  {
    value: '/store',
    label: 'Home',
    getDisabled: () => false,
  },
  {
    value: '/store/badges',
    label: 'Badges',
    getDisabled: () => false,
  },
  {
    value: '/store/packs',
    label: 'Packs',
    getDisabled: () => true,
  },
];

const Store = () => {
  const { data: user } = useAuthenticatedUser();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, label] = useMemo(() => {
    const route = routes.find(r => (r.value === location.pathname) || (r.additionalValue === location.pathname));
    if (route) return [route.value, route.label];
    return [routes[0].value, routes[0].label];
  }, [location.pathname]);

  const handleChange = useCallback((event, newValue) => {
    navigate(newValue);
  }, []);

  useEffect(async () => {
    if (!user) return;
    const res = await ApiClientInstance.sendRequest({
      path: '/store/user-products',
      method: 'GET',
      catchError: true,
      snackbarError: 'message',
    });
    if (res.success) {
      setRecoil(userProductsList('all'), res.data.map(userProduct => userProduct.product._id));
      for (const userProduct of res.data) {
        UserProductModel.setUserProduct(userProduct.product._id, userProduct);
      }
    }
  }, [user]);

  return (
    <Wrapper applyBreakpoints className='user-profile'>
      <div className='flex justify-between items-center pr-3 text-primary-text'>
        <h1 className='text-3xl font-bebas my-3 px-3 text-primary-text'>Store</h1>
        { user && (
        <span className='font-bold font-roboto flex items-center'>
          { currencyFormat(user.currency) }
          <img className='inline ml-2' src='/assets/brand/2kumt.svg' style={{ height: '22px' }} alt='MT icon' />
        </span>
        ) }
      </div>
      <Box sx={{
        width: '100%',
        marginBottom: '2px',
      }}
      >
        <Tabs
          variant='fullWidth'
          value={value}
          onChange={handleChange}
          textColor='primary'
          indicatorColor='primary'
          aria-label='secondary tabs example'
        >
          {routes.map(({ value: _value, label: _label, getDisabled }) => (
            <Tab disabled={getDisabled()} key={_label} value={_value} label={_label} />
          ))}
        </Tabs>
      </Box>
      <Content>

        <Routes>
          <Route path='/' element={<StoreHome />} />
          <Route path='/badges' element={<StoreBadges />} />
          <Route path='/packs' element={<StorePacks />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Content>
    </Wrapper>
  );
};

export default Store;
