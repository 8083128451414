import {
  useEffect, useMemo, useState,
} from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag/LexicalHashtag';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import UniverseLoader from '../../../Loaders/UniverseLoader';

import { CustomEvents, DisplaySourceTypes, LexicalComposerNamespaces } from '../../../../lib/constants';
import { defaultTheme } from '../../themes';
import { buildNameSpaceString } from '../../utils/buildNameSpaceString';

import { InitialStateWatcherPlugin } from '../../InitialStateWatcherPlugin';
import { EmbedNode } from '../../Nodes/DisplayEmbedNode';
import { CustomEmojiNode } from '../../Nodes/CustomEmojiNode';
import { EmojiNode } from '../../Nodes/EmojiNode';
import { MentionNode } from '../../Nodes/MentionNode';

import { emitCustomEvent } from '../../../../hooks/useCustomEventListener';
import { usePost } from '../../../../models/post/usePost';

import './style.css';

const nodes = [
  CustomEmojiNode,
  EmojiNode,
  MentionNode,
  CodeHighlightNode,
  CodeNode,
  HashtagNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  HeadingNode,
  QuoteNode,
  EmbedNode,
];

const PostQuote = ({
  post,
  topic,
  author,
  isNested,
  postQuoteNode,
  editorName,
  displaySource = DisplaySourceTypes.PostList,
  sourceType,
}) => {
  const [expanded, setExpanded] = useState(!isNested);
  const [initialConfig, setInitialConfig] = useState(null);
  const {
    data,
    loading,
    fetching,
    fetch,
    hasLoaded,
  } = usePost(post._id, { sourceType });

  useEffect(() => {
    if (!data) return;
    const { content } = data;
    const _nodes = [...nodes];
    _nodes.push(postQuoteNode);
    const _initialConfig = {
      namespace: buildNameSpaceString({
        namespace: LexicalComposerNamespaces.PostQuote,
        editorName,
        sourceType,
        displaySource,
      }),
      editorState: content,
      nodes: _nodes,
      readOnly: true,
      theme: defaultTheme,
    };
    setInitialConfig(_initialConfig);
  }, [data?.content, editorName, sourceType, displaySource]);

  useEffect(() => {
    if (data) return;
    if (!hasLoaded && expanded && !fetching) fetch();
  }, [expanded, loading, hasLoaded, fetching, fetch, data, initialConfig]);

  const title = useMemo(() => {
    const _data = data?.topic || topic;
    const _author = data?.author || author;
    if (!_author && !_data) return null;
    return (
      <div className='flex items-center'>
        <span className='line-clamp-1 text-xs font-bold'>
          <FormatQuoteIcon sx={{ height: '20px', opacity: '.8' }} />
          {`quoting ${_author.username} in ${_data.textContent}`}
        </span>
        <button type='button' className='tku-no-select' onClick={() => setExpanded(prev => !prev)}>
          { expanded ? <ArrowDropDownIcon sx={{ height: '20px' }} /> : <ArrowRightIcon sx={{ height: '20px' }} /> }
        </button>
      </div>
    );
  }, [data, expanded, topic, author]);

  const quoteContent = useMemo(() => {
    if (!initialConfig) return null;
    return (
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin contentEditable={(
          <ContentEditable
            spellCheck
            type='text/plain'
            style={{ opacity: '.96', display: expanded ? '' : 'none' }}
            className='rounded editor-root editor-display-post-quote editor-display-post-root display mb-1'
          />
        )}
        />
        <InitialStateWatcherPlugin skipFirstUpdate={false} initialState={data?.content} />
      </LexicalComposer>
    );
  }, [initialConfig, data?.content, expanded]);

  const goToPostButton = useMemo(() => {
    if (displaySource === DisplaySourceTypes.Simple) return null;
    return (
      <button type='button' className='tku-no-select' onClick={() => emitCustomEvent(CustomEvents.ResetPaginatedPostList, { _id: post._id })}>
        <ArrowUpward sx={{ height: '18px' }} />
      </button>
    );
  }, [isNested, post?._id]);

  const loader = useMemo(() => {
    if (!loading && !fetching) return null;
    return (
      <div
        style={{ backgroundImage: 'linear-gradient(var(--color-s001dp) 0 0)' }}
        className=' relative text-primary-text h-[100px] flex items-center'
      >
        <UniverseLoader boxType='fullContainer' showLogo={false} size={65} />
      </div>
    );
  }, [loading, fetching]);

  return (
    <div
      style={{
        backgroundImage: isNested ? 'linear-gradient(var(--color-s001dp) 0 0)' : 'linear-gradient(var(--color-s003dp) 0 0)',
      }}
      className='px-2 rounded py-4 post-embed mb-2'
    >
      <div className='w-[100%]'>
        <div className='flex justify-between items-center post-quote-byline'>
          { title }
          { goToPostButton}
        </div>
        { loader }
        { quoteContent }
      </div>
    </div>
  );
};

export default PostQuote;
