import { Button } from '@mui/material';
import { useCallback } from 'react';
import { setRecoil, getRecoil } from 'recoil-nexus';

import { useRecoilValue } from 'recoil';
import currencyFormat from '../../lib/currencyFormat';
import { emitConfirmationDialog } from '../../lib/emitters/emitConfirmationDialog';
import ApiClientInstance from '../../clients/api';

import authenticatedUserState from '../../models/authenticatedUser/atom';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { AuthenticatedUserModel } from '../../models/authenticatedUser/model';
import userProductState from '../../models/userProduct/atom';
import { UserProductModel } from '../../models/userProduct/model';
import { Breakpoints } from '../../lib/constants';
import { breakpointNameSelector } from '../../models/settings/selectors';

const StoreBadge = ({ item, index }) => {
  const { data } = useAuthenticatedUser();

  const { data: existingUserProduct } = useRecoilValue(userProductState(item._id));
  const breakpointName = useRecoilValue(breakpointNameSelector);

  const {
    name,
    description,
    price,
    item: _item,
  } = item;
  const { image } = _item;

  const handleClick = useCallback(() => {
    emitConfirmationDialog({
      text: `Are you sure you want to buy ${name} for ${currencyFormat(price)} MT`,
      handler: async () => {
        const res = await ApiClientInstance.sendRequest({
          method: 'POST',
          path: `/store/user-products/${item._id}`,
          snackbarError: 'errorMessage',
          catchError: true,
        });
        if (res.success) {
          const { user, userProduct } = res.data;
          setRecoil(addSnackbarSelector, { message: 'Purchase successful!', color: 'primary' });
          const authenticatedUser = getRecoil(authenticatedUserState);
          AuthenticatedUserModel.setUser(authenticatedUser.data._id, { ...authenticatedUser.data, currency: user.currency });
          UserProductModel.setUserProduct(userProduct.product, { ...userProduct, product: { ...item, item: _item } });
        }
      },
      data: {
        description: {
          text: 'Once you purchase, you cannot be refunded.',
        },
        confirm: {
          text: 'Buy',
        },
      },
    });
  }, [item, _item]);

  return (
    <div className='py-1 px-2 w-[100%]'>
      <div className='bg-s01dp px-2 py-3 rounded h-[100%]'>
        <div className='flex flex-col justify-between h-[100%]'>
          <div className='flex flex-col items-center justify-between space-y-2'>
            <img className='inline ml-2' src={image} style={{ height: '75px' }} alt={description} />
            <span style={{ lineHeight: '18px' }} className='font-bold font-roboto text-center line-clamp-2'>{ name }</span>
            <span className='font-bold font-roboto flex items-center'>
              <img className='inline mr-1' src='/assets/brand/2kumt.svg' style={{ height: '18px' }} alt='MT icon' />
              <span className='text-sm'>{ currencyFormat(price) }</span>
            </span>
          </div>

          <div className='w-[100%] pt-2 flex justify-center'>
            <Button
              style={{ minWidth: breakpointName === Breakpoints.Mobile ? '100px' : '150px' }}
              color='primary'
              variant='contained'
              disabled={data?.currency < price || !!existingUserProduct || !data}
              onClick={handleClick}
            >
              { !existingUserProduct ? 'Buy' : 'Owned' }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreBadge;
