import { useSetRecoilState } from 'recoil';
import { useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import CategorySelect from '../CategorySelect';

const ChangeTopicCategory = ({
  mainContainerClassname,
  category,
  handleConfirm,
  handleCancel,
}) => {
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const [_category, setCategory] = useState(category || null);

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          _category,
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: 'Change topic category',
        data: {
          description: { text: `Update topic category to ${_category.name}` },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, _category]);

  const _handleCancel = useCallback(async () => {
    await handleCancel();
  }, [handleCancel]);

  return (
    <div className={`${mainContainerClassname} h-[430px] tablet:h-[500px] flex flex-col justify-between`}>
      <div>
        <h1 className='mb-2'>Change Topic Category</h1>
        <CategorySelect
          category={_category}
          sx={{ marginTop: '1rem' }}
          setCategory={setCategory}
          placeholder='category'
          label='category'
        />
      </div>
      <div className='flex justify-between mt-4 space-x-5'>
        <Button
          color='secondaryLight'
          variant='outlined'
          onClick={_handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!_category || _category?._id === category?._id}
          variant='contained'
          color='primary'
          onClick={_handleSubmit}
        >
          Change Category
        </Button>
      </div>
    </div>
  );
};

export default ChangeTopicCategory;
