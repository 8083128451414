export const SUGGESTION_LIST_LENGTH_LIMIT = 5;
export const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
export const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

export const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

export const PUNC = DocumentMentionsRegex.PUNCTUATION;

export const TRIGGERS = [':'].join('');
export const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;
export const VALID_JOINS = '(?:'
  + `[${
    PUNC
  }]|` // E.g. "-' in "Salier-Hellendag"
  + ')';
export const LENGTH_LIMIT = 75;
export const ALIAS_LENGTH_LIMIT = 50;

export const ColonMentionsRegex = new RegExp(
  '(^|\\s|\\()('
    + `[${
      TRIGGERS
    }]`
    + `((?:${
      VALID_CHARS
    }${VALID_JOINS
    }){0,${
      LENGTH_LIMIT
    }})`
    + ')$',
);

export const ColonMentionsMentionsRegexAliasRegex = new RegExp(
  '(^|\\s|\\()('
    + `[${
      TRIGGERS
    }]`
    + `((?:${
      VALID_CHARS
    }){0,${
      ALIAS_LENGTH_LIMIT
    }})`
    + ')$',
);
