/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import { useState, memo } from 'react';
import { Wrapper, ContentForList } from '../../components/Containers';
import TopicList from '../../components/TopicList';
import {
  SocketRooms, SourceTypes, CardHeaderContentDetailTypes, QueryLocationKeys,
} from '../../lib/constants';
import UniverseLoader from '../../components/Loaders/UniverseLoader';
import { TopicCard } from '../../components/TopicCard';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorCard from '../../components/ErrorCard';

const MemoTopicCardComponent = memo(TopicCard);
const ItemComponent = ({ item, index }) => (
  <ErrorBoundary fallback={<ErrorCard />}>
    <MemoTopicCardComponent _id={item._id} key={item._id} sourceType={SourceTypes.Topic} detailsType={CardHeaderContentDetailTypes.Category} />
  </ErrorBoundary>
);

const Home = () => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      { loading && <UniverseLoader boxType='fullScreen' /> }
      <Wrapper applyBreakpoints className='home'>
        <ContentForList
          desktopHeightOffset={1}
          mobileHeightOffset={1}
          className='home-content'
        >
          <TopicList
            customScrollParentSelector='.home-content'
            locationKey={[QueryLocationKeys.Home]}
            rooms={[{ path: 'general', name: SocketRooms.General.Home }]}
            requestPath='/topic/category'
            loading={loading}
            setLoading={setLoading}
            ItemComponent={ItemComponent}
          />
        </ContentForList>
      </Wrapper>
    </>
  );
};
export default Home;
