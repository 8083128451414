import { SocketEffects, SocketUpdateActions } from '../../lib/constants';
import { getIsOwnerBlocked } from './utils';
import { invalidatePrivateTopicQueries } from '../../lib/queryUtils';
import { PrivatePostModel } from '../../models/privatePost/model';
import { PrivateTopicModel } from '../../models/privateTopic/model';
import { handleGlobalEffects } from './handleGlobalEffects';

const handlePrivatePostEffects = async (res) => {
  const { effects, data } = res;
  if (data?.topic) {
    const isOwnerBlocked = getIsOwnerBlocked(data?.topic);
    if (isOwnerBlocked) return;
  }
  if (effects?.length < 1) return;
  for (const effect of effects) {
    switch (effect) {
      case SocketEffects.InvalidateQueries:
        await invalidatePrivateTopicQueries();
        break;
      case SocketEffects.LoadCounts:
        await PrivateTopicModel.loadUnreadCounts();
        break;
      default:
        break;
    }
  }
};

const handlePrivatePostUpdate = (res) => {
  const { data } = res;
  const Post = new PrivatePostModel(data.post._id);
  const prevData = Post.getData() || {};
  Post.setData({ ...prevData, ...data.post });
  if (data.post.replyToPid?._id) {
    const ReplyToPost = new PrivatePostModel(data.post.replyToPid._id);
    const prevReplyToData = ReplyToPost.getData() || {};
    const replies = prevReplyToData.replies || [];
    if (!replies?.length) return;
    const newReplies = replies.map((reply) => {
      if (reply._id === data.post._id) return data.post;
      return reply;
    });
    ReplyToPost.setData({ ...prevReplyToData, replies: newReplies });
  }
};

const handleReplyPrivatePost = (res) => {
  const { data } = res;
  const isOwnerBlocked = getIsOwnerBlocked(data?.topic);
  if (isOwnerBlocked) return;
  const PrivateTopic = new PrivateTopicModel(data.topic._id);
  PrivateTopic.setData(data.topic);
  const Post = new PrivatePostModel(data.post._id);
  Post.setData(data.post);
};

export const handlePrivatePost = (res) => {
  const { action, data } = res;
  switch (action) {
    case SocketUpdateActions.Reply: {
      handleReplyPrivatePost(res);
      break;
    }
    case SocketUpdateActions.Update: {
      handlePrivatePostUpdate(res);
      break;
    }
    case SocketUpdateActions.Delete: {
      const Post = new PrivatePostModel(data.post._id);
      Post.setData({ ...data.post, isDeleted: true });
      break;
    }
    default: {
      console.log('unknown post action', action);
    }
  }
  handlePrivatePostEffects(res);
  handleGlobalEffects(res);
};
