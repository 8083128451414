import { useCallback, useMemo, useState } from 'react';

const Image = ({
  onClick,
  className = '',
  style = {},
  src,
  alt,
  onError,
  onLoad,
}) => {
  const [hiddenByError, setHidden] = useState(false);
  const [hasLoaded, setLoaded] = useState(false);

  const handleError = useCallback(() => {
    if (onError) onError();
    setHidden(true);
  }, [onError]);

  const handleLoad = useCallback(() => {
    if (hasLoaded) return;
    if (onLoad) onLoad();
    setLoaded(true);
  }, [onLoad, hasLoaded]);

  const _style = useMemo(() => {
    if (hasLoaded) return {};
    return { visibility: 'hidden' };
  }, [hasLoaded]);

  const content = useMemo(() => {
    if (onClick) {
      return (
        <div
          role='button'
          tabIndex={0}
          onKeyDown={onClick}
          onClick={onClick}
        >
          <img
            onError={handleError}
            onLoad={handleLoad}
            src={!hiddenByError ? src : '/assets/images/notFound.gif'}
            alt={alt}
            className={className ? `${className}` : ''}
            style={{ ...style, ..._style }}
          />
        </div>
      );
    }

    return (
      <img
        onError={handleError}
        onLoad={handleLoad}
        src={!hiddenByError ? src : '/assets/images/notFound.gif'}
        alt={alt}
        className={className ? `${className}` : ''}
        style={{ ...style, ..._style }}
      />
    );
  }, [src, hasLoaded, hiddenByError, onClick, style, _style]);

  return content;
};

export default Image;
