import Drawer from '@mui/material/Drawer';
import {
  useCallback, useState, useEffect,
} from 'react';
import { Button, Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import GameCard from '../GameCard';
import ApiClientInstance from '../../clients/api';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { BetStatus, CustomEvents } from '../../lib/constants';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { userCurrencySelector } from '../../models/authenticatedUser/selectors';
import currencyFormat from '../../lib/currencyFormat';
import BetStatusIcon from '../BetStatusIcon';
import getBetStatus from '../../lib/getBetStatus';

const BetDrawer = ({
  open,
  setOpen,
  game,
  betId,
  betIndex,
  isWinner,
  claimed,
  team,
  amount,
  payout,
}) => {
  const toggleOpen = useCallback(() => setOpen(prev => !prev), []);
  const addSnackbar = useSetRecoilState(addSnackbarSelector);
  const setUserCurrency = useSetRecoilState(userCurrencySelector);

  // Local State
  const [newGame, setNewGame] = useState(null);

  const handleCancel = useCallback(async () => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({ method: 'put', path: `/bet/cancel/${betId}`, snackbarError: 'errorMessage' });
      if (res.success) {
        const { bet, currencyBalance } = res.data;
        emitCustomEvent(CustomEvents.BetUpdate, { betId, betIndex, type: 'cancel' });
        setUserCurrency(currencyBalance);
        toggleOpen();
        addSnackbar({ color: 'primary', message: `Bet successfully cancelled. You've been refunded ${currencyFormat(bet.amount)} MT. Your current balance is ${currencyFormat(currencyBalance)} MT` });
      } else {
        addSnackbar({ color: 'error', message: res.message });
      }
    };
    const text = 'Are you sure you want to cancel this bet';
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text });
  }, [newGame]);

  const handleClaim = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({ method: 'post', path: `/bet/claim/${betId}`, snackbarError: 'errorMessage' });
    if (res.success) {
      const { bet: newBet, currencyBalance } = res.data;
      emitCustomEvent(CustomEvents.BetUpdate, {
        newBet, betId, betIndex, type: 'claim',
      });
      addSnackbar({ color: 'primary', message: `Bet succeessfully claimed. You've profited ${currencyFormat(newBet.amount)} MT. Your current balance is ${currencyFormat(currencyBalance)} MT` });
      setUserCurrency(currencyBalance);
      toggleOpen();
    }
  }, [newGame]);

  useEffect(async () => {
    if (open) {
      const data = await ApiClientInstance.sendRequest({ method: 'get', path: `/game/id/${game._id}` });
      if (data.success) {
        setNewGame(data.data);
      } else {
        toggleOpen();
      }
    }
  }, [open]);

  if (!newGame) return null;

  const status = getBetStatus({
    isWinner, winner: newGame.winner, claimed, hasStarted: newGame.hasStarted,
  });

  let profitColorClass = 'text-primary-text';
  if (status === BetStatus.Open) profitColorClass = 'text-primary-light';
  if (status === BetStatus.Locked) profitColorClass = 'text-primary-light';
  if (status === BetStatus.Lost) profitColorClass = 'text-error';
  if (status === BetStatus.Won) profitColorClass = 'text-success';
  if (status === BetStatus.Unclaimed) profitColorClass = 'text-success';

  return (
    <Drawer
      ModalProps={{ keepMounted: false }}
      anchor='bottom'
      open={open}
      onClose={toggleOpen}
      PaperProps={{
        elevation: 0,
      }}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <div className='bg-surface desktop:w-[755px] desktop:ml-auto desktop:mr-auto'>
        <div className='flex flex-col justify-center items-center rounded bg-s01dp'>
          <div className='w-[100%] tablet:w-[70%]'>
            <GameCard wrapperClasses='bg-surface' containerClasses='mt-0 bg-s01dp' hideBetting game={newGame} />
          </div>
          <div className='bg-surface w-[95%] tablet:w-[400px]'>
            <div className='bg-s01dp py-4 pl-6'>
              <Grid className='py-0 items-center !mt-0 !mb-0 text-primary-text px-3 text-xs font-bold' columns={12} container>
                <Grid item xs={2}>STATUS</Grid>
                <Grid item xs={2}>TEAM</Grid>
                <Grid item xs={4}>AMOUNT</Grid>
                <Grid item xs={4}>PROFIT</Grid>
              </Grid>
              <Grid className='py-0 items-center mt-2 text-primary-text px-3 text-xs font-bold' columns={12} container>
                <BetStatusIcon hasStarted={newGame.hasStarted} claimed={claimed} winner={newGame.winner} isWinner={isWinner} />
                <Grid item xs={2}>
                  <span className={profitColorClass}>
                    {team.code}
                  </span>
                </Grid>
                <Grid item xs={4}>{currencyFormat(amount)}</Grid>
                <Grid item xs={4}><span className={`${profitColorClass} ${!newGame.winner && 'italic'}`}>{(isWinner || !newGame.winner) ? currencyFormat(payout) : `(${currencyFormat(amount)})`}</span></Grid>
              </Grid>
              { status === BetStatus.Open && (
              <div className='py-4 px-10 mt-2 flex justify-center'>
                <Button onClick={handleCancel} variant='outlined' color='secondaryLight'>Cancel Bet</Button>
              </div>
              ) }
              { status === BetStatus.Unclaimed && (
              <div className='py-4 px-10 mt-2 flex justify-center'>
                <Button onClick={handleClaim} variant='contained' color='primary' fullWidth>Claim Bet</Button>
              </div>
              ) }
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default BetDrawer;
