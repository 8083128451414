import { format } from 'date-fns';
import getTitleText from '../../lib/getTitleText';
import UserAvatar from '../UserAvatar';

const PostPreview = ({
  post,
}) => {
  const {
    author,
    topic,
    createdOn,
    content,
    textContent,
  } = post;
  const parsedTitle = getTitleText(topic.title).toLowerCase();
  return (
    <div className='my-2'>
      <div style={{ fontSize: '13px' }} className='opacity-90'>{textContent}</div>
      <div className='flex space-x-2 items-center mt-1'>
        <div className='flex space-x-2 items-center'>
          <UserAvatar avatarSize='16px' username={author.username} color={author.color} avatar={author.avatar} />
          <span className='text-xs'>{`${author.username}`}</span>
        </div>
        <span className='text-xs'>{format(new Date(createdOn), 'MM/dd/yy')}</span>
        <span className='text-xs line-clamp-1'>{`in ${topic.textContent}`}</span>
      </div>
    </div>
  );
};

export default PostPreview;
