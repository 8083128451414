import { selector } from 'recoil';
import { UserEmailStatus } from '../../lib/constants';
import state from './atom';

export const authenticatedUserSelector = selector({
  key: 'authenticatedUserSelector_v2',
  get: ({ get }) => {
    const authenticatedUserState = get(state);
    return authenticatedUserState?.data;
  },
  set: ({ set, get }, data) => {
    const authenticatedUserState = get(state);
    set(state, {
      ...authenticatedUserState,
      data,
    });
  },
});

export const userCurrencySelector = selector({
  key: 'userCurrencySelector',
  get: ({ get }) => {
    const authStateV2 = get(state);
    const user = authStateV2.data;
    return user?.currency || 0;
  },
  set: ({ set, get }, newCurrency) => {
    const authStateV2 = get(state);
    const user = authStateV2.data;
    set(state, { ...authStateV2, data: { ...user, currency: newCurrency } });
  },
});

export const userVerifiedSelector = selector({
  key: 'userVerifiedSelector',
  get: ({ get }) => {
    const authStateV2 = get(state);
    const user = authStateV2.data;
    return user?.approved && user.emailStatus === UserEmailStatus.Verified;
  },
});
