import { traverseNodeTree } from './traverseNodeTree';

export const getPostEntities = (postContent) => {
  const quotes = [];
  const hashtags = [];
  const mentions = [];
  const texts = [];
  const embeds = [];
  const others = [];
  traverseNodeTree(postContent.root.children, (node) => {
    const { type, data } = node;
    switch (type) {
      case ('post-quote'): {
        const { _id } = data.post;
        if (quotes.includes(_id)) return;
        quotes.push(_id);
        break;
      }
      case ('text'): {
        const { text } = node;
        if (texts.includes(text)) return;
        texts.push(text);
        break;
      }
      case ('hashtag'): {
        const { text } = node;
        if (hashtags.includes(text)) return;
        hashtags.push(text.toLowerCase());
        break;
      }
      case ('mention'): {
        const { _id } = data;
        if (mentions.includes(_id)) return;
        mentions.push(_id);
        break;
      }
      case ('embed'): {
        const { _id } = data;
        if (embeds.includes(_id)) return;
        embeds.push(data);
        break;
      }
      case ('paragraph'):
      case ('linebreak'):
        break;
      default: {
        others.push(node);
        break;
      }
    }
  });
  return {
    quotes,
    hashtags,
    mentions,
    texts,
    embeds,
    others,
  };
};
