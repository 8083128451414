import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';

import DisplayAvatar from '../DisplayAvatar';

import ApiClientInstance from '../../clients/api';
import { CustomEvents } from '../../lib/constants';

import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const CategoryCard = ({
  name,
  color,
  slug,
  image,
  _id: categoryId,
  avatarSize = '32px',
  linkClickCallback = null,
  containerClass = 'text-primary-text flex items-center py-2 font-roboto justify-between pr-4 pl-1',
}) => {
  // State

  const {
    data: user,
    setData: setUser,
    unauthenticated,
    loading: authLoading,
  } = useAuthenticatedUser();
  const categorySubscriptions = user?.categorySubscriptions || [];
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const joinCategory = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      method: 'post',
      path: `/user/category/subscribe/${categoryId}`,
      catchError: true,
    });
    if (res.success) {
      setUser(res.data);
      addSnackbar({ message: `Successfully joined ${name}!`, color: 'primary' });
    } else {
      addSnackbar({ message: res.message, severity: 'error' });
    }
  }, []);

  const leaveCategory = useCallback(async () => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: `/user/category/unsubscribe/${categoryId}`,
        catchError: true,
      });
      if (res.success) {
        setUser(res.data);
        addSnackbar({ message: `Successfully left ${name}`, color: 'primary' });
      } else {
        addSnackbar({ message: res.message, severity: 'error' });
      }
    };
    const text = `Are you sure you want to leave ${name}`;
    const data = {
      description: {
        text: `Topics created in ${name} will no longer appear on your homepage, but you'll still be able to view them on the category page and the all page.`,
      },
      confirm: {
        text: `Leave ${name}`,
      },
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { text, handler, data });
  }, []);

  const avatar = useMemo(() => (
    <Link className='flex items-center' to={`/category/${slug}`} onClick={linkClickCallback && linkClickCallback}>
      <DisplayAvatar avatar={image} color={color} username={name} avatarSize={avatarSize} />
      <span className='font-bold ml-2 underline text-primary-light'>{name}</span>
    </Link>
  ), [image, color, name, slug]);

  const joined = categorySubscriptions?.length > 0 && !!categorySubscriptions.find(s => s._id === categoryId);

  return (
    <div className={containerClass}>
      {avatar}
      {(!joined && !authLoading) && (
      <Button
        disabled={unauthenticated}
        onClick={joinCategory}
        size='small'
        variant='contained'
        color='primary'
      >
        Join
      </Button>
      )}
      {(joined && !authLoading) && (
      <Button onClick={leaveCategory} size='small' variant='outlined' color='secondaryLight'>
        Leave
      </Button>
      )}
    </div>
  );
};

export default CategoryCard;
