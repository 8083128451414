import { useEffect } from 'react';
import SocketClient from '../clients/socket';

window.SocketClient = SocketClient;

const useSocketJoin = (path, room) => {
  useEffect(() => {
    if (!room) return;
    SocketClient.join(path, room);
    return () => {
      SocketClient.leave(path, room);
    };
  }, [SocketClient.isConnected, room]);
};

export default useSocketJoin;
