/* eslint-disable react/no-array-index-key */
import {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Popper,
  ClickAwayListener,
  Badge,
} from '@mui/material';
import { useSetRecoilState } from 'recoil';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ApiClientInstance from '../../clients/api';
import { CustomEvents, SourceTypes, UserPrivateTopicStatus } from '../../lib/constants';

import UserAvatar from '../UserAvatar';
import UniverseLoader from '../Loaders/UniverseLoader';

import { useCustomEvent, emitCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { useTopic } from '../../models/topic/useTopic';
import { UserModel } from '../../models/user/model';

const UsersOnline = ({
  topicId,
  sourceType,
  offset = [50, -5],
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const { data } = useTopic(topicId, { sourceType });

  const usersPermitted = data?.usersPermitted || [];

  const getUsers = useCallback(async () => {
    let res;
    if (sourceType === SourceTypes.Message) {
      res = await ApiClientInstance.sendRequest({
        path: `/private-topic/users-online/${topicId}`,
        method: 'get',
      });
    }
    if (sourceType === SourceTypes.Topic) {
      res = await ApiClientInstance.sendRequest({
        path: `/topic/users-online/${topicId}`,
        method: 'get',
      });
    }
    if (res.success) {
      setUsers(res.data);
      for (const user of res.data) UserModel.setUser(user?._id, user);
    } else {
      addSnackbar({
        message: `Error getting users online: ${res.message}`,
        severity: 'error',
      });
      setOpen(false);
    }
  }, [sourceType, topicId]);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClickAway = useCallback((e) => {
    if (e.target === document.body) return null;
    if (e.target.closest('.user-profile-drawer') !== null) return null;
    if (e.target.closest('.users-online-in-room-button') !== null) return null;
    handleClose();
  }, []);

  const handleEvent = useCallback(() => setOpen((prev) => !prev), []);

  useEffect(async () => {
    if (open) await getUsers();
  }, [open, getUsers]);

  useCustomEvent(CustomEvents.UsersOnlineInRoom, handleEvent, []);

  const [userCount, guestCount] = useMemo(() => {
    const _usersCount = users.filter(u => u?._id)?.length;
    const _guestCount = users.length - _usersCount;
    return [_usersCount, _guestCount];
  }, [users]);

  const bannedUsers = useMemo(() => {
    if (!usersPermitted.length) return [];
    return usersPermitted.filter(u => u.status === UserPrivateTopicStatus.Banned);
  }, [usersPermitted]);

  const pendingUsers = useMemo(() => {
    if (!usersPermitted.length) return [];
    return usersPermitted.filter(u => u.status === UserPrivateTopicStatus.Pending);
  }, [usersPermitted]);

  const allUsers = useMemo(() => {
    const usersOnline = {};
    const _users = [];
    const bannedIds = bannedUsers.map(u => u.user._id);
    const pendingIds = pendingUsers.map(u => u.user._id);
    users.forEach(u => {
      if ([...bannedIds, ...pendingIds].includes(u?._id)) return null;
      _users.push({ ...u, online: true });
      usersOnline[u._id] = true;
    });
    usersPermitted.forEach(u => {
      const _u = u.user;
      if ([...bannedIds, ...pendingIds].includes(_u?._id)) return null;
      if (!usersOnline[_u._id]) {
        _users.push({ ..._u, online: false });
      }
    });
    return _users;
  }, [users, usersPermitted, bannedUsers, pendingUsers]);

  const usersContent = useMemo(() => {
    if (allUsers.length === 0) return null;
    return (
      <>
        { [...allUsers, ...pendingUsers, ...bannedUsers].map((obj, i) => {
          const user = obj?.user ? obj.user : obj;
          if (!user?._id) return null;

          const status = obj?.user ? obj.status : UserPrivateTopicStatus.Accepted;
          const accepted = status === UserPrivateTopicStatus.Accepted;
          const pending = status === UserPrivateTopicStatus.Pending;
          const banned = status === UserPrivateTopicStatus.Banned;
          const handleClick = () => {
            if (user.username === 'anonymous') return null;
            emitCustomEvent(CustomEvents.OpenUserProfileDrawer, { sourceType: SourceTypes.User, username: user.username });
          };
          return (
            <div
              role='button'
              tabIndex={0}
              onKeyPress={handleClick}
              onClick={handleClick}
              key={user._id + i}
              className='flex space-x-2 items-center tku-no-select'
            >
              <Badge
                overlap='circular'
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                variant='dot'
                color={user.online ? 'success' : 'disabled'}
              >
                <div className='relative'>
                  <div style={{ zIndex: 10, top: 10, left: -2.5 }} className='absolute'>
                    { pending && <AccessTimeFilledIcon sx={{ height: '15px', width: '15px', color: 'var(--color-disabled)' }} /> }
                    { banned && <RemoveCircleIcon sx={{ height: '15px', width: '15px', color: 'var(--color-error)' }} /> }
                  </div>
                  {(pending || banned) && (
                  <div
                    style={{
                      borderRadius: '100%',
                      padding: 0,
                      position: 'absolute',
                      height: '9px',
                      width: '9px',
                      bottom: 0,
                      left: 0,
                      zIndex: 9,
                    }}
                    className='bg-primary-text'
                  />
                  )}
                  <UserAvatar
                    avatarSize={28}
                    avatar={user?.avatar}
                    color={user?.color}
                    username={user.username}
                    _id={user._id}
                  />
                </div>
              </Badge>
              <span className='text-sm'>{user.username}</span>
            </div>
          );
        })}
      </>
    );
  }, [allUsers, bannedUsers, pendingUsers]);

  if (!open) return null;

  const anchorEl = document.querySelector('.users-online-in-room-button');

  if (!anchorEl) return null;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        className='rounded'
        anchorEl={anchorEl}
        open={open}
        style={{ zIndex: 1000 }}
        modifiers={[{ name: 'offset', options: { offset } }]}
      >
        <div className='users-online-in-room bg-surface rounded'>
          <div
            className='bg-s06dp px-4 py-3 space-y-3 flex-col text-primary-text roboto rounded'
            style={{
              overflow: 'auto',
              maxHeight: '300px',
              minWidth: '150px',
            }}
          >
            {' '}
            <div className='flex justify-center'>
              <span className='text-sm capitalize inline-block'>{`Users In ${sourceType}`}</span>
              { sourceType === 'topic' && (
                guestCount > 0 && <span>{`${guestCount} guest${guestCount > 1 ? 's' : ''}`}</span>
              ) }
            </div>

            { usersContent || (
            <div style={{ minHeight: '50px' }} className='flex py-3 justify-center items-center relative'>
              <UniverseLoader size={50} boxType='fullContainer' showLogo={false} />
            </div>
            )}
          </div>
        </div>
      </Popper>
    </ClickAwayListener>
  );
};

export default UsersOnline;
