export const loadFromLocalStorage = (localStorageKey) => ({ setSelf }) => {
  const savedSortKey = localStorage.getItem(localStorageKey);
  if (savedSortKey) setSelf(savedSortKey);
};

export const setInLocalStorage = (localStorageKey) => ({ onSet }) => {
  onSet(val => {
    localStorage.setItem(localStorageKey, val);
  });
};
