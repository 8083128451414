import { Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { setRecoil } from 'recoil-nexus';
import { useCallback, useEffect } from 'react';

import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';
import { emitQueryListScroll } from '../../lib/emitters/emitQueryListScroll';

import { hasNewTopicsSelector } from '../../models/newTopics/selectors';
import topicListQueryKeyState from '../../models/topicListQueryKey/atom';
import topicListSortKeyState from '../../models/topicListSortKey/atom';

import newTopicsState from '../../models/newTopics/atom';

const resetNewTopicsState = () => setRecoil(newTopicsState, []);

const NewTopicButton = () => {
  const hasNewTopics = useRecoilValue(hasNewTopicsSelector);
  const topicListQueryKey = useRecoilValue(topicListQueryKeyState);
  const topicListSortKey = useRecoilValue(topicListSortKeyState);

  const handleClick = useCallback(() => {
    resetNewTopicsState();
    emitQueryListScroll({ queryKey: topicListQueryKey, options: { behavior: 'auto' } });
    emitInvalidateQueries({ queryKey: topicListQueryKey });
  }, [topicListQueryKey]);

  useEffect(() => {
    resetNewTopicsState();
    return () => resetNewTopicsState();
  }, [topicListSortKey]);

  if (!hasNewTopics) return null;

  return (
    <div style={{
      width: '100px',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
      top: '10px',
      zIndex: '10',
    }}
    >
      <Button
        size='small'
        variant='contained'
        onClick={handleClick}
      >
        New Posts
      </Button>
    </div>
  );
};

export default NewTopicButton;
