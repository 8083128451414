import { useRecoilValue } from 'recoil';

import { useCallback, useMemo } from 'react';
import { Badge } from '@mui/material';
import DisplayAvatar from '../DisplayAvatar';

import { usersOnlineSelector } from '../../models/usersOnline/selectors';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useUser } from '../../models/user/useUser';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, SourceTypes } from '../../lib/constants';

const UserAvatar = ({
  _id,
  avatarFontSize,
  avatarSize = '24px',
  styles,
  onClick,
  showOnline = false,
  shouldUseProfileDrawer = false,
}) => {
  const isOnline = useRecoilValue(usersOnlineSelector(_id));
  const { data: authenticatedUser } = useAuthenticatedUser(_id);
  const {
    data: user,
  } = useUser(_id);

  const _onClick = useCallback(() => {
    if (onClick) return onClick();
    if (shouldUseProfileDrawer) {
      emitCustomEvent(CustomEvents.OpenUserProfileDrawer, { sourceType: SourceTypes.User, username: user?.username });
    }
  }, [_id, onClick, shouldUseProfileDrawer, user?.username]);

  const avatarContents = useMemo(() => {
    if (!user?.username) return null;
    const {
      username,
      color,
      avatar,
    } = user;
    return (
      <DisplayAvatar
        onClick={_onClick}
        avatar={avatar}
        name={username}
        color={color}
        avatarSize={avatarSize}
        avatarFontSize={avatarFontSize}
        styles={styles}
      />
    );
  }, [user?.username, user?.color, user?.avatar, onClick, avatarSize, avatarFontSize, JSON.stringify(styles)]);

  const showOnlineContent = useMemo(() => {
    if (!showOnline || !user?.username) return null;
    const {
      username,
    } = user;
    return (
      <Badge
        sx={{
          height: avatarSize,
        }}
        overlap='circular'
        color={(isOnline || authenticatedUser?.username === username) ? 'success' : 'disabled'}
        variant='dot'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        { avatarContents }
      </Badge>
    );
  }, [showOnline, isOnline, avatarContents, user?.username, authenticatedUser?.username, avatarSize]);

  if (!user) return null;

  return showOnline ? showOnlineContent : avatarContents;
};

export default UserAvatar;
