import {
  useState, useEffect, useCallback, useMemo, useRef,
} from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import ApiClient from '../../clients/api';
import { Wrapper, Content } from '../../components/Containers';
import { SignUpFormClasses } from '../../lib/constants';
import { checkPassword } from '../../lib/validateUser';
import { addSnackbarSelector } from '../../models/snackbar/selector';

import PasswordInput from '../../components/PasswordInput';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const CreatePassword = () => {
  // Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const tokenRef = useRef(token);

  // Global State
  const { authenticated, loading } = useAuthenticatedUser();
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  // Local State
  const [password, setPassword] = useState('');
  const [secondPassword, setSecondPassword] = useState('');

  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [secondPasswordValid, setSecondPasswordValid] = useState(false);

  // Guard Clause: User is signed in
  useEffect(() => {
    if (authenticated) navigate('/');
  }, [authenticated]);

  useEffect(async () => {
    if (!tokenRef.current) return null;
    const res = await ApiClient.sendRequest({
      method: 'POST',
      path: '/user/token/verify',
      data: {
        value: tokenRef.current,
        type: 'password',
      },
      catchError: true,
      snackbarError: 'errorMessage',
    });
    tokenRef.current = null;
    if (!res?.success) navigate('/password-reset');
  }, [token]);

  const canSubmit = useMemo(() => (passwordValid && secondPasswordValid && passwordsMatch), [passwordValid, secondPasswordValid, passwordsMatch]);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleSecondPasswordChange = useCallback((e) => {
    setSecondPassword(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!canSubmit) return null;
    const res = await ApiClient.sendRequest({
      method: 'put',
      path: '/user/password/token',
      data: {
        newPassword: password,
        token,
      },
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) {
      addSnackbar({
        message: 'Password reset successfully',
        severity: 'success',
      });
      navigate('/login', { state: 'passwordReset' });
    }
  }, [password, canSubmit, addSnackbar, navigate, token]);

  useEffect(() => {
    if (password && checkPassword(password)) setPasswordValid(true);
    else setPasswordValid(false);
  }, [password]);

  useEffect(() => {
    if (secondPassword && checkPassword(secondPassword)) setSecondPasswordValid(true);
    else setSecondPasswordValid(false);
  }, [secondPassword]);

  useEffect(() => {
    if (password && secondPassword && password === secondPassword) setPasswordsMatch(true);
    else setPasswordsMatch(false);
  }, [password, secondPassword]);

  const isPasswordMatchError = useMemo(() => (password && secondPassword && !passwordsMatch), [password, secondPassword, passwordsMatch]);

  if (authenticated || loading) return null;

  return (
    <Wrapper className='login'>
      <Content className={SignUpFormClasses.Content}>
        <div className={SignUpFormClasses.MainDiv}>
          <h1 className='font-bebas text-4xl text-primary-text text-center'>Reset Your Password.</h1>
          <form className={SignUpFormClasses.Form}>
            <PasswordInput
              sx={{ mt: 2 }}
              handleChange={handlePasswordChange}
              value={password}
              fullWidth
              label='password'
            />
            <PasswordInput
              sx={{ mt: 2 }}
              error={isPasswordMatchError}
              handleChange={handleSecondPasswordChange}
              value={secondPassword}
              fullWidth
              label='re-enter password'
              helperText=''
              errorHelperText={isPasswordMatchError ? 'Passwords do not match' : ''}
            />
            <Button size='large' sx={{ mt: 2 }} disabled={!canSubmit} onClick={handleSubmit} fullWidth variant='contained'>Change Password</Button>
          </form>
          <p className='underline mt-5 italic text-primary-text opacity-70 text-md leading-5 text-center font-roboto'><Link to='/register'>Don&apos;t have an account? Get one.</Link></p>
        </div>
      </Content>
    </Wrapper>
  );
};

export default CreatePassword;
