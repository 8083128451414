import axios from 'axios';
import {
  setRecoil,
  getRecoil,
} from 'recoil-nexus';
import {
  SiteCookies,
  RoomNames,
  RoomPaths,
} from '../lib/constants';
import sleep from '../lib/sleep';
import SocketClient from './socket';
import Cookies from './cookies';

import authenticatedUserState from '../models/authenticatedUser/atom';
import userState from '../models/user/atom';
import usernameMappingState from '../models/usernameMapping/atom';
import { authenticatedUserSelector } from '../models/authenticatedUser/selectors';
import { addSnackbarSelector } from '../models/snackbar/selector';

const setAuthenticatedUser = (newState) => setRecoil(authenticatedUserState, prev => ({ ...prev, ...newState }));
const setUser = (data) => {
  setRecoil(userState(data._id), prev => ({
    ...prev, loading: false, hasLoaded: true, data,
  }));
  setRecoil(usernameMappingState(data.username), data._id);
};

class ApiClient {
  constructor() {
    this.client = null;
    this.apiBaseUrl = process.env.REACT_APP_API_BASE;
  }

  initClient = () => {
    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    });

    this.client.interceptors.request.use((config) => {
      const updatedConfig = { ...config };
      updatedConfig.headers.authkey = Cookies.get(SiteCookies.AuthKey);
      return updatedConfig;
    }, (err) => Promise.reject(err));

    this.client.interceptors.response.use(res => {
      if (res?.headers?.authkey && res?.headers?.authkey !== 'null') Cookies.set(SiteCookies.AuthKey, res.headers.authkey, { expires: 30 });
      else Cookies.remove(SiteCookies.AuthKey);
      return res;
    }, (err) => Promise.reject(err));
  };

  buildQuery = (params) => {
    const query = [];
    Object.entries(params).forEach(([key, value]) => {
      if (value) query.push(`${key}=${value}`);
    });
    return query.length > 0
      ? `?${query.join('&')}`
      : '';
  };

  /**
   *
   * @param {object} data - body of request
   * @param {object} headers - headers for request
   * @param {string} method - HTTP method
   * @param {string} path - path for endpoint
   * @param {boolean} catchError - if the error should be swallowed
   * @param {object} queryParams - query params+
    * @param {object} retryAttempts - retry max attempts
   * @param {boolean|string} snackbarError - errorMessage: display error, true will show generic message
   * @returns {Promise} API response
   */
  sendRequest = async ({
    data,
    method,
    retryTimes = 0,
    retryAttempts = 0,
    queryParams,
    path,
    headers,
    catchError = false,
    snackbarError = false,
    forceError = false,
  }) => {
    try {
      if (forceError) throw new Error('Forced error');
      if (!method) throw new Error('Method is required');
      if (!path) throw new Error('Path is required');
      if (!this.client) {
        return {
          success: false,
          error: 'api client not found',
        };
      }
      const query = queryParams ? this.buildQuery(queryParams) : '';
      const res = await this.client[method.toLowerCase()](`${this.apiBaseUrl}${path}${query}`, data, headers || {});
      const _res = {
        success: res.status >= 200 && res?.status < 300,
        data: res?.data,
      };
      if (!retryTimes || _res.success) return _res;
      if (!_res.success) {
        await sleep(retryAttempts * 1000);
        return this.sendRequest({
          data,
          method,
          retryAttempts: retryAttempts + 1,
          retryTimes: retryTimes - 1,
          queryParams,
          path,
          headers,
          catchError,
          snackbarError,
          forceError,
        });
      }
    } catch (e) {
      if (retryTimes) {
        await sleep(retryAttempts * 1000);
        return this.sendRequest({
          data,
          method,
          retryTimes: retryTimes - 1,
          retryAttempts: retryAttempts + 1,
          queryParams,
          path,
          headers,
          catchError,
          snackbarError,
          forceError,
        });
      }
      if (snackbarError) {
        const defaultMessage = 'It looks like something went wrong 😢';
        const message = snackbarError !== 'errorMessage' ? defaultMessage : e.response?.data?.message || defaultMessage;
        setRecoil(addSnackbarSelector, { message, severity: 'error' });
      }
      if (!catchError) throw new Error(e);
      return {
        success: false,
        error: e.response?.data?.message || 'request failed',
      };
    }
  };

  uploadAttachment = async ({ attachment, foldername, title }) => {
    let data;
    try {
      const formData = new FormData();
      formData.append('image', attachment.image);
      if (attachment?.title || title) formData.append('title', attachment?.title || title);
      if (foldername) formData.append('foldername', foldername);
      data = await this.sendRequest({
        data: formData,
        method: 'post',
        path: '/image',
        catchError: true,
      });
      if (!data.success) throw new Error(data.message);
      setRecoil(addSnackbarSelector, {
        message: 'Image uploaded successfully',
        color: 'primary',
      });
      return data;
    } catch (e) {
      setRecoil(addSnackbarSelector, {
        message: `Your attachment was not uploaded.${data?.error ? ` ${data.error}` : ''}`,
        severity: 'error',
      });
    }
  };

  updateCoverImage = async (image) => {
    const formData = new FormData();
    formData.append('image', image);
    const data = await this.sendRequest({ data: formData, method: 'put', path: '/user/cover-image' });
    return data;
  };

  updateAvatar = async (image) => {
    const formData = new FormData();
    formData.append('image', image);
    const data = await this.sendRequest({ data: formData, method: 'put', path: '/user/avatar' });
    return data;
  };

  postVote = async ({
    postId,
    delta,
    sourceType = 'Post',
    topicId,
  }) => {
    const path = sourceType.toLowerCase() === 'message' ? `/private-post/vote/id/${postId}/${topicId}` : `/post/vote/id/${postId}`;
    const { unauthenticated } = getRecoil(authenticatedUserSelector);
    if (unauthenticated) {
      setRecoil(addSnackbarSelector, {
        message: 'Sign in or Sign up. Your choice.', duration: 5000, color: 'secondary', icon: 'user',
      });
      return;
    }
    const res = await this.sendRequest({
      data: { delta }, method: 'put', path, catchError: true, snackbarError: 'errorMessage',
    });
    if (res.success) {
      return res.data;
    }
  };

  getFullProfile = async (username) => {
    const res = await this.sendRequest({ method: 'get', path: `/user/profile/${username}`, catchError: true });
    if (res.success) setRecoil(authenticatedUserSelector, res.data);
  };

  login = async ({ email, password }) => {
    setAuthenticatedUser({ loading: true });
    const res = await this.sendRequest({
      data: {
        email,
        password,
      },
      method: 'post',
      path: '/user/login',
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) {
      setAuthenticatedUser({
        data: res.data,
        loading: false,
        hasLoaded: true,
      });
      setUser(res.data);
      SocketClient.socket.disconnect();
      SocketClient.socket.auth.token = Cookies.get(SiteCookies.AuthKey);
      SocketClient.socket.connect();
      SocketClient.joinUser(res.data.username);
      SocketClient.leave(RoomPaths.General, RoomNames.Unauthenticated);
      setRecoil(addSnackbarSelector, {
        message: `Welcome back, ${res.data.username}!`,
        color: 'primary',
        icon: 'user',
      });
      return res;
    }
    setAuthenticatedUser({ loading: false });
    return res;
  };

  register = async ({ email, password, username }) => {
    setAuthenticatedUser({ loading: true });
    const res = await this.sendRequest({
      data: { email, password, username },
      method: 'post',
      path: '/user/register',
      catchError: true,
    });
    if (res.success) {
      setAuthenticatedUser({ data: res.data, hasLoaded: true, loading: false });
      setUser(res.data);
      SocketClient.socket.disconnect();
      SocketClient.socket.auth.token = Cookies.get(SiteCookies.AuthKey);
      SocketClient.socket.connect();
      SocketClient.leave(RoomPaths.General, RoomNames.Unauthenticated);
      setRecoil(addSnackbarSelector, {
        message: `Welcome, ${res.data.username}!`, icon: 'user', color: 'primary', vertical: 'top', sx: { marginTop: '45px' },
      });
      return res;
    }
    // TODO: handle unverified user (clear out anything else)
    setAuthenticatedUser({ loading: false });
    return res;
  };

  logout = async () => {
    setAuthenticatedUser({ loading: true });
    if (Cookies.get(SiteCookies.AuthKey)) {
      const res = await this.sendRequest({ method: 'post', path: '/user/logout', catchError: true });
      Cookies.remove(SiteCookies.AuthKey);
      SocketClient.logout();
      if (res.success) {
        window.location.href = window.location.origin;
        SocketClient.join(RoomPaths.General, RoomNames.Unauthenticated);
        setRecoil(addSnackbarSelector, {
          message: 'Goodbye!',
          color: 'secondary',
        });
        setAuthenticatedUser({ data: null, hasLoaded: true, loading: false });
        SocketClient.socket.disconnect();
        SocketClient.socket.connect();
      } else {
        // TODO handle unverified user (clear out anything else)
        console.log(res.error);
      }
    } else {
      setAuthenticatedUser({ data: null, hasLoaded: true, loading: false });
    }
  };

  getEmbed = async (url) => {
    const res = await this.sendRequest({
      method: 'get',
      path: '/embed',
      queryParams: { url },
      catchError: true,

    });
    if (res.success) return res.data;
  };
}

const ApiClientInstance = new ApiClient();

ApiClientInstance.initClient();

export default ApiClientInstance;
