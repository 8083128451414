import { Wrapper, Content } from '../Containers';

const AdminJobs = () => {
  console.log('/');
  return (
    <Wrapper applyBreakpoints>
      <div className='flex px-3 pt-2 space-x-2 justify-between items-center'>
        <h1 className='text-3xl font-bebas my-3 text-primary-text'>Jobs</h1>
      </div>
      <Content>
        <div style={{ height: '20%' }} className='text-primary-text flex items-center justify-center'>
          <h1>Coming Soon!</h1>
        </div>
      </Content>
    </Wrapper>
  );
};

export default AdminJobs;
