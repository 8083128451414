import ApiClientInstance from '../../clients/api';
import { NotificationStatus } from '../../lib/constants';

export const requestNotification = (id) => ApiClientInstance.sendRequest({
  method: 'get',
  path: `/notification/${id}`,
  catchError: true,
});

export const deleteNotification = (id) => ApiClientInstance.sendRequest({
  method: 'delete',
  path: `/notification/${id}`,
  catchError: true,
});

export const updateNotificationStatus = (id, status) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/notification/${id}`,
  data: { status },
  catchError: true,
});

export const getNotificationCounts = () => ApiClientInstance.sendRequest({
  method: 'get',
  path: '/notification/counts',
  catchError: true,
});

export const getNotifications = (status = NotificationStatus.All, sort = 'new') => ApiClientInstance.sendRequest({
  method: 'get',
  path: '/notification',
  queryParams: { status, sort },
  catchError: true,
});

export const getUnreadNotifications = () => getNotifications(NotificationStatus.Unread);

export const getUnseenNotifications = () => getNotifications(NotificationStatus.Unseen);

export const getNotificationsCount = (status) => ApiClientInstance.sendRequest({
  method: 'get',
  path: '/notification/count',
  queryParams: { status },
  catchError: true,
});

export const getUnseenNotificationsCount = () => getNotificationsCount(NotificationStatus.Unseen);

export const getUnreadNotificationsCount = () => getNotificationsCount(NotificationStatus.Unread);

export const updateNotificationsStatus = (
  statusFrom = NotificationStatus.Unseen,
  statusTo = NotificationStatus.Unread,
) => ApiClientInstance.sendRequest({
  method: 'put',
  path: '/notification/status',
  data: { statusFrom, statusTo },
  catchError: true,
});

export const updateAllNotificationsToRead = () => updateNotificationsStatus(NotificationStatus.All, NotificationStatus.Read);

export const updateUnseenNotificationsStatusToUnread = () => updateNotificationsStatus(NotificationStatus.Unseen);

export const updateUnreadNotificationsStatusToRead = () => updateNotificationsStatus(NotificationStatus.Unread, NotificationStatus.Read);
