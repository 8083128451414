import { useState, useEffect, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ApiClient from '../../clients/api';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

import { Wrapper, Content } from '../../components/Containers';
import { SignUpFormClasses } from '../../lib/constants';
import { checkEmail } from '../../lib/validateUser';
import { addSnackbarSelector } from '../../models/snackbar/selector';

const PasswordReset = () => {
  // Hooks
  const navigate = useNavigate();

  // Global State
  const { authenticated, loading } = useAuthenticatedUser();
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  // Local State
  const [email, setEmail] = useState('');

  // Guard Clause: User is signed in
  useEffect(() => {
    if (authenticated) navigate('/');
  }, [authenticated]);

  const handleSubmit = useCallback(async () => {
    if (!email || !checkEmail(email)) return null;
    const res = await ApiClient.sendRequest({
      method: 'POST',
      path: '/user/password/token/create',
      data: {
        email,
      },
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) {
      addSnackbar({
        message: res.data.message,
        color: 'primary',
      });
    }
    setEmail('');
  }, [email]);

  if (authenticated || loading) return null;

  return (
    <Wrapper className='login'>
      <Content className={SignUpFormClasses.Content}>
        <div className={SignUpFormClasses.MainDiv}>
          <h1 className='font-bebas text-4xl text-primary-text text-center'>Reset Your Password.</h1>
          <form className={SignUpFormClasses.Form}>
            <TextField sx={{ mt: 2 }} onChange={(e) => setEmail(e.target.value)} value={email} fullWidth label='email' />
            <Button size='large' sx={{ mt: 2 }} disabled={!email || !checkEmail(email)} onClick={handleSubmit} fullWidth variant='contained'>Send Password Reset Email</Button>
          </form>
          <p className='underline mt-5 italic text-primary-text opacity-70 text-md leading-5 text-center font-roboto'><Link to='/register'>Don&apos;t have an account? Get one.</Link></p>
        </div>
      </Content>
    </Wrapper>
  );
};

export default PasswordReset;
