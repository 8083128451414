import { useMemo } from 'react';

import {
  useLocation,
} from 'react-router-dom';
import { UserPrivateTopicStatus } from '../lib/constants';

export const MessagePathLabels = {
  Messages: 'Messages',
  MessageRequests: 'Requests',
  NewMessage: 'New Message',
  Archived: 'Archived',
};

export const useMessagesPath = () => {
  const location = useLocation();

  const routes = [
    { value: '/messages', label: MessagePathLabels.Messages, additionalValue: '/messages/list' },
    { value: '/messages/create', label: MessagePathLabels.NewMessage },
    { value: '/messages/requests', label: MessagePathLabels.MessageRequests },
    { value: '/messages/archived', label: MessagePathLabels.Archived },
  ];

  const [path, label] = useMemo(() => {
    let _label = MessagePathLabels.Messages;
    const route = routes.find(r => (r.value === location.pathname) || (r.additionalValue === location.pathname));
    if (route) _label = route.label;
    return [location.pathname, _label];
  }, [location.pathname]);

  const [filterType, queryParams] = useMemo(() => {
    let _filterType = 'acceptedMessages';
    let _queryParams = { status: UserPrivateTopicStatus.Accepted };
    if (label === MessagePathLabels.Messages) {
      _filterType = 'acceptedMessages';
    }
    if (label === MessagePathLabels.MessageRequests) {
      _filterType = 'requestedMessages';
      _queryParams = { status: UserPrivateTopicStatus.Pending };
    }
    if (label === MessagePathLabels.Archived) {
      _filterType = 'archivedMessages';
      _queryParams = { status: UserPrivateTopicStatus.Archived };
    }
    return [_filterType, _queryParams];
  }, [label]);

  return {
    path,
    label,
    filterType,
    queryParams,
  };
};
