import { useCallback } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const UsersOnlineButton = ({
  color = 'primaryLight',
  size = 'small',
}) => {
  const handleRoomInfoClick = useCallback(() => {
    emitCustomEvent(CustomEvents.UsersOnlineInRoom);
  }, []);

  return (
    <IconButton
      className='users-online-in-room-button'
      size={size}
      aria-label='room-info'
      aria-controls='room-info'
      aria-haspopup='true'
      color={color}
      onClick={handleRoomInfoClick}
    >
      <GroupIcon />
    </IconButton>
  );
};

export default UsersOnlineButton;
