import {
  useRef, useMemo,
} from 'react';
import { usePost } from '../../models/post/usePost';
import { CardHeaderContainer } from './CardHeaderContainer';
import { CardHeaderContent } from './CardHeaderContent';
import { CardPostContent } from './CardPostContent';
import { CardActions } from './CardActions';
import { CardRepliesContainer } from './CardRepliesContainer';
import { usePostInView } from './usePostInView';
import { CardHeaderContentDetailTypes } from '../../lib/constants';
import { CardContainer } from '../TopicCard/CardContainer';
import { CardTitle } from '../TopicCard/CardTitle';

const PostCard = ({
  sourceType,
  detailsType = CardHeaderContentDetailTypes.User,
  _id,
}) => {
  const containerRef = useRef(null);

  const {
    data,
    loading,
  } = usePost(_id, { sourceType });

  usePostInView({
    topicId: data?.topic._id,
    cardRef: containerRef,
    postId: _id,
  });

  const cardContent = useMemo(() => (
    <CardContainer
      isModeratorPost={data?.isModeratorPost}
      sourceType={sourceType}
      author={data?.author}
      isDeleted={data?.isDeleted}
    >
      <CardHeaderContainer
        isDeleted={data?.isDeleted}
        pinned={data?.pinned}
        isModeratorPost={data?.isModeratorPost}
        replyToPid={data?.replyToPid}
        includeReply
        includeEditMenu
        _id={_id}
        sourceType={sourceType}
      >
        <CardHeaderContent
          _id={_id}
          author={data?.author}
          createdOn={data?.createdOn}
          detailsType={detailsType}
          sourceType={sourceType}
          topicAuthor={data?.topic?.author}
          showUserHighlight
          edited={data?.edited}
        />
      </CardHeaderContainer>
      { data?.isMain && <CardTitle topicId={data?.topic._id} /> }
      <CardPostContent sourceType={sourceType} _id={_id} />
      <CardActions topicId={data.topic._id} postId={_id} />
      <CardRepliesContainer sourceType={sourceType} _id={_id} />
    </CardContainer>
  ), [JSON.stringify(data), sourceType, _id, loading, detailsType]);

  return (
    <article
      data-post-id={_id}
      ref={containerRef}
      className={`post-card font-roboto flex flex-col space-y-2 py-1 px-1 post-${_id}`}
    >
      { cardContent}
    </article>
  );
};

export default PostCard;
