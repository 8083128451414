import { Grid } from '@mui/material';
import { useCallback } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../UserAvatar';
import UserBadges from '../UserBadges';
import UserStats from '../UserStats';
import { formatDate } from '../../lib/formatDate';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, SourceTypes } from '../../lib/constants';
import { topicEditMenu } from '../../models/navigation/selectors';
import { useUser } from '../../models/user/useUser';
import { emitOpenDrawerMenu } from '../../lib/emitters/emitOpenDrawerMenu';

const UserCard = ({
  _id,
  username,
  color,
  nickname,
  dateJoined,
  avatar,
  currency,
  status,
  banned,
  reputation,
  badges,
  isAdmin = false,
  approved,
  emailStatus,
  role,
}) => {
  const { data, loading, hasLoaded } = useUser(_id);

  const toggleEditMenu = useSetRecoilState(topicEditMenu);

  const navigate = useNavigate();

  const handleAvatarClick = useCallback(() => {
    if (isAdmin) {
      navigate(`/admin/users/${username}`);
    } else {
      emitCustomEvent(CustomEvents.OpenUserProfileDrawer, { sourceType: SourceTypes.User, username });
    }
  }, [username, isAdmin]);

  const handleEditMenuClick = useCallback(() => {
    emitOpenDrawerMenu({
      sourceType: SourceTypes.User,
      authorId: _id,
    });
  }, [_id, username, nickname, color, avatar, _id, banned, emailStatus, approved, status]);

  return (
    <div className='bg-surface pl-2 pr-3 text-primary-text rounded py-[3px]'>
      <div className='pl-2 pr-1 bg-s01dp rounded'>
        <Grid className='py-2 items-center !mt-0 !mb-0' spacing={0} container>
          <Grid item xs={6}>
            <button type='button' onClick={handleAvatarClick} className='flex items-center tku-no-select'>
              <UserAvatar
                showOnline
                _id={_id}
                avatarSize='40px'
              />
              <div className='flex items-start flex-col ml-1'>
                <span className='font-bold ml-1 text-sm text-primary-light'>{username}</span>
                <UserBadges containerClassName='mt-1' _id={_id} />
                { isAdmin && <span className='ml-1 text-sm'>{role}</span> }
              </div>
            </button>
          </Grid>
          <Grid item xs={3}>
            <UserStats
              _id={_id}
              iconSide='left'
              containerClass='text-primary-text font-roboto text-sm flex flex-col justify-center space-y-1'
              iconSize='20px'
              shouldAbbreviateNumber
            />
          </Grid>
          <Grid className='font-roboto' item xs={isAdmin ? 2 : 3}>
            <div className='flex-col'>
              <span className='block font-bold text-xs opacity-80'>joined</span>
              <span className='block font-bold text-xs'>{formatDate(new Date(dateJoined), 'M/d/yy')}</span>
            </div>
          </Grid>
          { isAdmin && (
          <Grid item xs={1}>
            <MoreHorizIcon className='tku-no-select' onClick={handleEditMenuClick} />
          </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default UserCard;
