import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { themeSelector } from '../models/settings/selectors';
import applyTheme from '../styles/utils/applyTheme';

const useApplyTheme = () => {
  const theme = useRecoilValue(themeSelector);

  useEffect(() => {
    applyTheme(theme);
  }, [theme]);
};

export default useApplyTheme;
