import { useEffect } from 'react';
import { UserModel } from './model';

export const useLoadFromUsername = ({
  username,
  loadFromUsername,
  data,
  loading,
  setError,
}) => {
  useEffect(async () => {
    if (!username || !loadFromUsername || data || loading) return;
    const res = await UserModel.requestUser(username);
    if (!res.success) return setError(res.error);
    if (res.data) {
      const CurrentUserModel = new UserModel(res.data._id);
      CurrentUserModel.setState({ data: res.data, loading: false, hasLoaded: true });
    }
  }, [username, loadFromUsername, data, loading]);
};
