import {
  useMemo,
  useCallback,
} from 'react';
import {
  useNavigate,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { Wrapper, Content } from '../../components/Containers';
import SettingsAccount from '../../components/SettingsAccount';
import SettingsProfile from '../../components/SettingsProfile';
import SettingsPrivacy from '../../components/SettingsPrivacy';
import NotFound from '../NotFound';

import useRequireAuth from '../../hooks/useRequireAuth';
import useAuthStatus from '../../hooks/useAuthStatus';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const routes = [
  {
    value: '/settings/account',
    label: 'Account',
    additionalValue: '/settings',
    getDisabled: () => false,
  },
  {
    value: '/settings/profile',
    label: 'Profile',
    getDisabled: (verified) => !verified,
  },
  {
    value: '/settings/privacy',
    label: 'Privacy',
    getDisabled: (verified) => !verified,
  },
];

const Settings = () => {
  // Global State
  const { verified } = useAuthenticatedUser();
  const { authenticated } = useAuthStatus();
  useRequireAuth('/login');

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = useCallback((event, newValue) => {
    navigate(newValue);
  }, []);

  const [value, label] = useMemo(() => {
    const route = routes.find(r => (r.value === location.pathname) || (r.additionalValue === location.pathname));
    if (route) return [route.value, route.label];
    return [routes[0].value, routes[0].label];
  }, [location.pathname]);

  useDocumentTitle(label ? `${label} Settings` : 'Settings');

  if (authenticated) {
    return (
      <Wrapper applyBreakpoints className='user-profile'>
        <h1 className='text-3xl font-bebas my-3 px-3 text-primary-text'>User Settings</h1>
        <Box sx={{
          position: 'sticky',
          width: '100%',
          marginBottom: '2px',
        }}
        >
          <Tabs
            variant='fullWidth'
            value={value}
            onChange={handleChange}
            textColor='primary'
            indicatorColor='primary'
            aria-label='secondary tabs example'
          >
            {routes.map(({ value: _value, label: _label, getDisabled }) => (
              <Tab disabled={getDisabled(verified)} key={_label} value={_value} label={_label} />
            ))}
          </Tabs>
        </Box>
        <Content className='relative desktop:w-[992px] tku-custom-scroller px-3 p-2'>
          <Routes>
            <Route path='/' element={<SettingsAccount />} />
            <Route path='/account' element={<SettingsAccount />} />
            <Route path='/profile' element={<SettingsProfile />} />
            <Route path='/privacy' element={<SettingsPrivacy />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Content>
      </Wrapper>
    );
  }
  return null;
};

export default Settings;
