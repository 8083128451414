import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../constants';

export const emitInvalidateQueries = ({
  queryKey,
}) => {
  if (!queryKey) return null;
  emitCustomEvent(CustomEvents.InvalidateQueries, { queryKey });
};

window.emitInvalidateQueries = emitInvalidateQueries;
