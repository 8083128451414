import React from 'react';

const Wrapper = React.forwardRef(({
  children,
  className,
  applyBreakpoints,
}, ref) => (
  <section ref={ref} className={`tku-wrapper ${className ? `${className}` : ''} ${applyBreakpoints ? ' desktop:w-[992px] desktop:ml-auto desktop:mr-auto' : ''}`}>
    { children }
  </section>
));

export default Wrapper;
