import ApiClientInstance from '../../clients/api';
import { MessageStatus, UserPrivateTopicStatus } from '../../lib/constants';

export const requestTopic = (id) => ApiClientInstance.sendRequest({
  method: 'get',
  path: `/private-topic/${id}`,
  catchError: true,
});

export const addUser = (id, userId) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/add-user`,
  data: { userToAdd: userId },
  catchError: true,
  snackbarError: 'errorMessage',
  method: 'put',
});

export const leave = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/leave`,
  method: 'PUT',
  catchError: true,
});

export const deleteMessage = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/status`,
  method: 'PUT',
  data: { status: UserPrivateTopicStatus.Deleted },
  catchError: true,
});

export const deleteForEveryone = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/delete`,
  method: 'PUT',
  catchError: true,
});

export const archiveForEveryone = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/archive`,
  method: 'PUT',
  catchError: true,
});

export const acceptRequest = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/status`,
  method: 'PUT',
  data: { status: UserPrivateTopicStatus.Accepted },
  catchError: true,
});

export const rejectRequest = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/status`,
  method: 'PUT',
  data: { status: UserPrivateTopicStatus.Rejected },
  catchError: true,
});

export const archiveMessage = (id) => ApiClientInstance.sendRequest({
  path: `/private-topic/${id}/status`,
  method: 'PUT',
  data: { status: UserPrivateTopicStatus.Archived },
  catchError: true,
});

export const getUnreadMessagesCount = () => ApiClientInstance.sendRequest({
  path: '/private-topic/unread',
  method: 'GET',
  catchError: true,
});

export const updateLatestPostInTopicToRead = (id) => ApiClientInstance.sendRequest({
  path: '/private-post/status',
  method: 'PUT',
  data: {
    statusTo: MessageStatus.Read,
    topic: id,
    latest: true,
  },
  catchError: true,
});

export const updateTitle = (id, title, textContent) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/private-topic/${id}`,
  data: {
    title,
    textContent,
  },
  catchError: true,
});

export const manageUsers = (id, usersPermitted) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/private-topic/${id}`,
  data: {
    usersPermitted,
  },
  catchError: true,
});
