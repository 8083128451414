import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { useEffect, useRef } from 'react';

export const InitialStateWatcherPlugin = ({ initialState, skipFirstUpdate = true }) => {
  const [editor] = useLexicalComposerContext();
  const updateCount = useRef(0);

  useEffect(() => {
    // this fn must be synchronous
    if (!initialState) return;
    if (skipFirstUpdate && updateCount.current === 0) {
      updateCount.current += 1;
      return;
    }
    try {
      const newState = editor.parseEditorState(initialState);
      queueMicrotask(() => editor.setEditorState(newState));
    } catch (e) {
      console.error(e);
    }
  }, [initialState, editor]);

  return null;
};
