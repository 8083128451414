import {
  useEffect,
  useMemo,
} from 'react';
import { useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import {
  useNavigate,
  Routes,
  Route,
} from 'react-router-dom';

import { Badge, IconButton } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ArchiveIcon from '@mui/icons-material/Archive';

import { Wrapper, Content } from '../../components/Containers';
import MessagesList from '../../components/MessagesList';
import MessagesCreate from '../../components/MessagesCreate';

import { PrivateTopicModel } from '../../models/privateTopic/model';
import { AuthenticatedUserModel } from '../../models/authenticatedUser/model';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useRequireAuth from '../../hooks/useRequireAuth';
import { useRequireVerification } from '../../hooks/useRequireVerification';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import { useMessagesPath, MessagePathLabels } from '../../hooks/useMessagesPath';
import unreadMessagesCountState from '../../models/unreadMessagesCount/atom';
import messageRequestCountState from '../../models/messageRequestCount/atom';
import { userVerifiedSelector } from '../../models/authenticatedUser/selectors';

const getIsAuthenticated = () => {
  const model = new AuthenticatedUserModel();
  const data = model.getData();
  return !!data;
};

const Messages = () => {
  // Global State
  useRequireAuth('/login');
  useRequireVerification('/');
  const { authenticated, verified } = useAuthenticatedUser();
  const unreadMessagesCount = useRecoilValue(unreadMessagesCountState);
  const messageRequestCount = useRecoilValue(messageRequestCountState);
  const navigate = useNavigate();
  const { label: selectedLabel } = useMessagesPath();

  useEffect(() => {
    const isAuthenticated = getIsAuthenticated();
    if (!isAuthenticated) return;
    const isVerified = getRecoil(userVerifiedSelector);
    if (!isVerified) return;
    PrivateTopicModel.loadUnreadCounts();
    return () => {
      const _isAuthenticated = getIsAuthenticated();
      if (!_isAuthenticated) return;
      PrivateTopicModel.loadUnreadCounts();
    };
  }, []);

  const messages = useMemo(() => (
    <IconButton
      color={selectedLabel === MessagePathLabels.Messages ? 'primaryLight' : 'textPrimary'}
      size='small'
      type='button'
      onClick={() => navigate('/messages')}
    >
      <Badge
        size='small'
        badgeContent={unreadMessagesCount}
        max={50}
        color='secondary'
      >
        <ForumIcon />
      </Badge>
    </IconButton>
  ), [unreadMessagesCount, authenticated, selectedLabel]);

  const requests = useMemo(() => (
    <IconButton
      color={selectedLabel === MessagePathLabels.MessageRequests ? 'primaryLight' : 'textPrimary'}
      size='small'
      type='button'
      onClick={() => navigate('/messages/requests')}
    >
      <Badge
        size='small'
        badgeContent={messageRequestCount}
        max={50}
        color='secondary'
      >
        <AnnouncementIcon />
      </Badge>
    </IconButton>
  ), [authenticated, selectedLabel, messageRequestCount]);

  const content = useMemo(() => (
    <Content className='relative tku-custom-scroller'>
      <Routes>
        <Route path='/' element={<MessagesList type='acceptedMessages' />} />
        <Route path='/list' element={<MessagesList type='acceptedMessages' />} />
        <Route path='/create' element={<MessagesCreate />} />
        <Route path='/requests' element={<MessagesList type='requestedMessages' />} />
        <Route path='/archived' element={<MessagesList type='archivedMessages' />} />
      </Routes>
    </Content>
  ), [authenticated, selectedLabel]);

  useDocumentTitle(selectedLabel !== MessagePathLabels.Messages ? `${selectedLabel} | Messages` : 'Messages');

  if (!authenticated || !verified) return null;

  if (authenticated) {
    return (
      <Wrapper applyBreakpoints className='user-profile'>
        <div className='my-3 px-3 flex justify-between'>
          <h1 className='text-3xl font-bebas  text-primary-text'>{selectedLabel}</h1>
          <div className='flex justify-evenly'>
            { messages }
            { requests }
            <IconButton
              color={selectedLabel === MessagePathLabels.Archived ? 'primaryLight' : 'textPrimary'}
              size='small'
              type='button'
              onClick={() => navigate('/messages/archived')}
            >
              <ArchiveIcon />
            </IconButton>
            <IconButton
              color={selectedLabel === MessagePathLabels.NewMessage ? 'primaryLight' : 'textPrimary'}
              size='small'
              type='button'
              onClick={() => navigate('/messages/create')}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </div>
        { content }
      </Wrapper>
    );
  }
  return null;
};

export default Messages;
