import { getRecoil } from 'recoil-nexus';
import { TkuQueryClient } from '../providers/query';
import topicListQueryKeyState from '../models/topicListQueryKey/atom';
import { QueryKeys } from './constants';

export const getTopicListQueryKey = () => getRecoil(topicListQueryKeyState);

export const getTopicListQueryData = () => {
  const topicListQueryKey = getTopicListQueryKey();
  if (!topicListQueryKey) return null;
  const queryData = TkuQueryClient.getQueryData(topicListQueryKey);
  return queryData;
};

export const invalidateCurrentTopicQueries = () => {
  const queryKey = getTopicListQueryKey();
  if (!queryKey) return;
  TkuQueryClient.invalidateQueries(queryKey);
};

export const handleTopicListQueryUpdate = () => {
  const data = getTopicListQueryData();
  if (!data) return;
  console.log(data);
};

export const invalidatePrivateTopicQueries = () => TkuQueryClient.invalidateQueries(QueryKeys.Messages, { exact: false });

export const getActiveQueries = () => TkuQueryClient.queryCache.findAll({ active: true });

export const getActiveQueryKeys = () => getActiveQueries().map(query => query.queryKey);
