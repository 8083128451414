import { useMemo } from 'react';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PushPinIcon from '@mui/icons-material/PushPin';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { useRecoilValue } from 'recoil';
import {
  PinTypes,
  SourceTypes,
} from '../../../lib/constants';

import topicListQueryKeyState from '../../../models/topicListQueryKey/atom';
import { topicHasNewPostsSelector } from '../../../models/topic/selectors';

export const CardHeaderContainer = ({
  isDeleted,
  isLocked,
  pin,
  handleEditClick,
  sourceType,
  includeEditMenu = true,
  children,
  _id,
  showNewPostIcon = false,
  isModeratorPost,
}) => {
  const topicListQueryKey = useRecoilValue(topicListQueryKeyState);
  const hasNewPosts = useRecoilValue(topicHasNewPostsSelector(_id));

  const isPinned = useMemo(() => {
    if (!pin?.active) return false;
    if (sourceType === SourceTypes.SimpleList) return false;
    if (new Date(pin?.expires).valueOf() < Date.now()) return false;
    if (pin.type === PinTypes.Global) return true;
    if (pin.type === PinTypes.Category && topicListQueryKey.includes('category')) return true;
  }, [pin, sourceType, topicListQueryKey]);

  return (
    <section className='tku-no-select topic-card-header flex items-center relative'>
      <div className='absolute top-0 right-0 w-12 flex justify-between items-center flex-row-reverse'>
        { (includeEditMenu && handleEditClick) && (
          <IconButton size='small' onClick={handleEditClick} edge='end'>
            <MoreHorizIcon sx={{ opacity: '.8' }} color='text.main' />
          </IconButton>
        ) }
        { isModeratorPost && <LocalPoliceIcon sx={{ fontSize: '18px' }} /> }
        { isPinned && <PushPinIcon sx={{ fontSize: '18px' }} /> }
        { isLocked && <LockIcon sx={{ fontSize: '18px' }} /> }
        { isDeleted && <DeleteIcon sx={{ fontSize: '18px' }} /> }
        { (showNewPostIcon && hasNewPosts) && <FiberNewIcon sx={{ color: 'var(--color-primary-light)', fontSize: '32px' }} /> }
      </div>
      { children }
    </section>
  );
};
