import { useCallback } from 'react';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import ApiClientInstance from '../../clients/api';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import {
  ComposerNodeTypes,
  CustomEvents,
  ImageUploadModalTypes,
  SourceTypes,
} from '../../lib/constants';
import {
  editorIsEmptySelector,
  editorStateJsonSelector,
  editorIsLoadingSelector,
  editorIsExpandedSelector,
  editorIsActiveSelector,
  editorFocusSelector,
  editorTextContentSelector,
  editorCurrentReplySelector,
  editorCurrentPostEditSelector,
  editorIsModeratorPostSelector,
} from './selectors';

const _paths = {
  [SourceTypes.Post]: {
    sendEditing: {
      buildPath: (_id) => `/post/id/${_id}`,
      method: 'PUT',
    },
    sendDefault: {
      buildPath: (_id) => `/topic/reply/${_id}`,
      method: 'POST',
    },
    getEditContent: {
      buildPath: (_id) => `/post/id/${_id}`,
      method: 'GET',
    },
  },
  [SourceTypes.Message]: {
    sendEditing: {
      buildPath: (_id) => `/private-post/id/${_id}`,
      method: 'PUT',
    },
    sendDefault: {
      buildPath: (_id) => `/private-topic/${_id}/reply`,
      method: 'POST',
    },
    getEditContent: {
      buildPath: (_id) => `/private-post/id/${_id}`,
      method: 'GET',
    },
  },
};

const getRequestParams = ({
  sourceType,
  isEditing,
  isSending,
  _id,
}) => {
  const pathsObject = _paths[sourceType];
  if (!isSending) {
    return {
      path: pathsObject.getEditContent.buildPath(_id),
      method: pathsObject.getEditContent.method,
    };
  }
  if (isEditing && isSending) {
    return {
      path: pathsObject.sendEditing.buildPath(_id),
      method: pathsObject.sendEditing.method,
    };
  }
  if (!isEditing && isSending) {
    return {
      path: pathsObject.sendDefault.buildPath(_id),
      method: pathsObject.sendDefault.method,
    };
  }
  return {
    path: pathsObject.sendDefault.buildPath(_id),
    method: pathsObject.sendDefault.method,
  };
};

const emptyEditorString = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

export const useLexicalEditor = ({ editorName, sourceType }) => {
  const [isFocused, setIsFocused] = useRecoilState(editorFocusSelector(editorName));
  const isEmpty = useRecoilValue(editorIsEmptySelector(editorName));
  const [isLoading, setIsLoading] = useRecoilState(editorIsLoadingSelector(editorName));
  const [isExpanded, setExpanded] = useRecoilState(editorIsExpandedSelector(editorName));
  const [isEditorActive, setEditorIsActive] = useRecoilState(editorIsActiveSelector(editorName));
  const [currentPostEdit, setCurrentPostEdit] = useRecoilState(editorCurrentPostEditSelector(editorName));
  const [currentReply, setCurrentReply] = useRecoilState(editorCurrentReplySelector(editorName));
  const [isModeratorPost, setIsModeratorPost] = useRecoilState(editorIsModeratorPostSelector(editorName));

  // eslint-disable-next-line react/function-component-definition
  const getEditorContents = useRecoilCallback(({ snapshot }) => () => snapshot.getLoadable(editorStateJsonSelector(editorName))?.contents || null, [editorName]);
  // eslint-disable-next-line react/function-component-definition
  const getEditorTextContent = useRecoilCallback(({ snapshot }) => () => snapshot.getLoadable(editorTextContentSelector(editorName))?.contents || null, [editorName]);

  const handleUploadClick = useCallback(() => {
    const handler = ({ url, title, id }) => emitCustomEvent(CustomEvents.InsertInEditor, {
      editorName,
      nodeType: ComposerNodeTypes.Image,
      data: { url, title, id },
    });
    emitCustomEvent(CustomEvents.OpenImageUploadModal, {
      uploadFolder: 'uploads',
      type: ImageUploadModalTypes.General,
      modalTitle: 'Insert Image',
      handleConfirm: handler,
    });
  }, [editorName]);

  const handleGiphyClick = useCallback(() => {
    const finalSelectHandler = ({ url, title }) => {
      emitCustomEvent(CustomEvents.InsertInEditor, {
        editorName,
        nodeType: ComposerNodeTypes.Image,
        data: { url, title },
      });
      emitCustomEvent(CustomEvents.GiphyModalToggle);
    };
    const firstSelectHandler = ({ url, title }) => emitCustomEvent(CustomEvents.OpenImageUploadModal, {
      type: ImageUploadModalTypes.General,
      modalTitle: 'Insert GIF',
      handleConfirm: finalSelectHandler,
      options: { closeOnClear: true },
      image: { url, title },
    });
    emitCustomEvent(CustomEvents.GiphyModalToggle, { handleConfirm: firstSelectHandler });
  }, [editorName]);

  const handleClearEditor = useCallback(() => {
    setCurrentPostEdit(null);
    setCurrentReply(null);
    setExpanded(false);
    setIsModeratorPost(false);
    emitCustomEvent(CustomEvents.ReplaceEditorState, { editorName, data: { editorContents: emptyEditorString } });
  }, [editorName]);

  const resetEditorState = useCallback(() => {
    emitCustomEvent(CustomEvents.ReplaceEditorState, { editorName, data: { editorContents: emptyEditorString } });
    setCurrentPostEdit(null);
    setCurrentReply(null);
    setExpanded(false);
    setIsFocused(false);
    setEditorIsActive(false);
    setIsModeratorPost(false);
  }, [editorName]);

  const toggleReadOnly = useCallback(() => emitCustomEvent(CustomEvents.ToggleEditorReadOnly, { editorName }), [editorName]);

  const handleSend = useCallback(async () => {
    toggleReadOnly();
    setIsLoading(true);
    try {
      const content = getEditorContents();
      const textContent = getEditorTextContent();
      if (!content) return null;
      const { path, method } = getRequestParams({
        _id: currentPostEdit || editorName,
        sourceType,
        isSending: true,
        isEditing: currentPostEdit,
      });
      const data = {
        content,
        textContent,
        isModeratorPost,
      };
      if (currentReply) data.replyToPid = currentReply._id;
      const response = await ApiClientInstance.sendRequest({
        path,
        method,
        catchError: true,
        data,
        snackbarError: 'errorMessage',
      });
      if (response.success) handleClearEditor();
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    toggleReadOnly();
  }, [getEditorTextContent, getEditorContents, sourceType, editorName, toggleReadOnly, currentPostEdit, currentReply, isModeratorPost]);

  const handleExpanded = useCallback(() => {
    setExpanded(prev => !prev);
  }, [editorName]);

  const handleIsModeratorPost = useCallback(() => {
    setIsModeratorPost(prev => !prev);
  }, [editorName]);

  const handleFontFormat = useCallback(() => {
    console.log('format bar');
  }, [editorName]);

  const handlePreviewClick = useCallback(() => {
    if (isEmpty) return;
    emitCustomEvent(CustomEvents.OpenPostPreviewModal, { editorName, sourceType });
  }, [isEmpty, editorName, sourceType]);

  const handleToggleEditorState = useCallback(() => setEditorIsActive(prev => !prev), [editorName]);

  return {
    isEmpty,
    isFocused,
    isLoading,
    isExpanded,
    isEditorActive,
    currentPostEdit,
    currentReply,
    editorName,
    isModeratorPost,
    handleUploadClick,
    handleGiphyClick,
    handleClearEditor,
    handleSend,
    handleExpanded,
    handleFontFormat,
    handleToggleEditorState,
    getEditorContents,
    getEditorTextContent,
    toggleReadOnly,
    resetEditorState,
    handlePreviewClick,
    setCurrentReply,
    handleIsModeratorPost,
    setIsModeratorPost,
  };
};
