import Resizer from 'react-image-file-resizer';

const resizeFile = (file, options = {}) => new Promise((resolve) => {
  const defaults = {
    maxWidth: 900, maxHeight: 900, compressFormat: 'png', quality: 100, rotation: 0,
  };
  const values = { ...defaults, ...options };
  const {
    maxHeight, maxWidth, compressFormat, quality, rotation,
  } = values;
  Resizer.imageFileResizer(
    file,
    maxWidth,
    maxHeight,
    compressFormat,
    quality,
    rotation,
    (uri) => {
      resolve(uri);
    },
    'blob',
  );
});

export default resizeFile;
