import { useCallback } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';

const TopicSettingsButton = ({
  color = 'primaryLight',
  size = 'small',
}) => {
  const handleTopicSettingsButton = useCallback(() => {
    emitCustomEvent(CustomEvents.TopicSettings);
  }, []);

  return (
    <IconButton
      className='topic-settings-button'
      size={size}
      aria-label='topic-settings'
      aria-controls='topic-settings'
      aria-haspopup='true'
      color={color}
      onClick={handleTopicSettingsButton}
    >
      <SettingsIcon />
    </IconButton>
  );
};

export default TopicSettingsButton;
