import {
  useRecoilState,
} from 'recoil';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CommentIcon from '@mui/icons-material/Comment';
import ReorderIcon from '@mui/icons-material/Reorder';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  Popper,
} from '@mui/material';
import topicListSortKey from '../../../models/topicListSortKey/atom';
import topicListDisplayKey from '../../../models/topicListDisplayKey/atom';
import { TopicListDisplayKeys, TopicListSortKeys } from '../../../lib/constants';

const iconStyles = { height: '20px', marginRight: '5px' };

const clickawayClass = 'prevent-clickaway-topic-list-header';

const SortButtonData = {
  New: {
    sort: TopicListSortKeys.New,
    label: 'New',
    icon: <NewReleasesIcon sx={iconStyles} />,
  },
  Recent: {
    sort: TopicListSortKeys.Recent,
    label: 'Recent',
    icon: <MarkUnreadChatAltIcon sx={iconStyles} />,
  },
  Hot: {
    sort: TopicListSortKeys.Hot,
    label: 'Hot',
    icon: <LocalFireDepartmentIcon sx={iconStyles} />,
  },
  Old: {
    sort: TopicListSortKeys.Old,
    label: 'Old',
    icon: <AccessTimeFilledIcon sx={iconStyles} />,
  },
  Count: {
    sort: TopicListSortKeys.Count,
    label: 'Count',
    icon: <CommentIcon sx={iconStyles} />,
  },
};

const DisplayButtonData = {
  Compact: {
    display: TopicListDisplayKeys.Compact,
    label: 'Compact',
    icon: <ReorderIcon sx={iconStyles} />,
  },
  Full: {
    display: TopicListDisplayKeys.Full,
    label: 'Full',
    icon: <AutoAwesomeMosaicIcon sx={iconStyles} />,
  },
  Preview: {
    display: TopicListDisplayKeys.Preview,
    label: 'Preview',
    icon: <PreviewIcon sx={iconStyles} />,
  },
};

const TopicListHeaderSort = () => {
  const [sort, setSort] = useRecoilState(topicListSortKey);
  const [open, setOpen] = useState(false);

  const handleClickAway = useCallback((e) => {
    if (e?.target?.closest(`.${clickawayClass}`) !== null) return null;
    setOpen(false);
  }, []);

  const toggleSort = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const selectedButton = useMemo(() => {
    let contents;
    switch (sort) {
      case TopicListSortKeys.New: {
        contents = (
          <>
            { SortButtonData.New.icon }
            { SortButtonData.New.label}
          </>
        );
        break;
      }
      case TopicListSortKeys.Recent: {
        contents = (
          <>
            { SortButtonData.Recent.icon }
            { SortButtonData.Recent.label }
          </>
        );
        break;
      }
      case TopicListSortKeys.Hot: {
        contents = (
          <>
            { SortButtonData.Hot.icon }
            { SortButtonData.Hot.label }
          </>
        );
        break;
      }
      case TopicListSortKeys.Old: {
        contents = (
          <>
            { SortButtonData.Old.icon }
            { SortButtonData.Old.label }
          </>
        );
        break;
      }
      case TopicListSortKeys.Count: {
        contents = (
          <>
            { SortButtonData.Count.icon }
            { SortButtonData.Count.label }
          </>
        );
        break;
      }

      default: {
        contents = (
          <>
            { SortButtonData.New.icon }
            { SortButtonData.New.label }
          </>
        );
      }
    }
    return (
      <Button
        sx={{ fontSize: '14px' }}
        className='uppercase current-sort-button'
        size='small'
        variant='outlined'
        color='primaryLight'
        onClick={toggleSort}
      >
        { contents }
      </Button>
    );
  }, [sort]);

  const currentSortPopperContents = useMemo(() => {
    const buttons = Object.values(SortButtonData).map(({ sort: _sort, label, icon }) => {
      if (_sort === sort) return null;
      return (
        <ClickAwayListener
          key={_sort}
          onClickAway={handleClickAway}
        >
          <ListItem
            dense
            disablePadding
            sx={{ fontSize: '14px' }}
            className={clickawayClass}
          >
            <ListItemButton
              className='uppercase'
              size='small'
              variant='outlined'
              color={_sort === sort ? 'primaryLight' : 'textPrimary'}
              onClick={() => {
                setSort(_sort);
                toggleSort();
              }}
              label={label}
            >
              { icon }
              { label }
            </ListItemButton>
          </ListItem>
        </ClickAwayListener>
      );
    });
    return (
      <div className='bg-surface'>
        <div className='bg-s04dp rounded'>
          <div className='sort-buttons flex flex-col items-start'>
            <List
              dense
            >
              { buttons }
            </List>
          </div>
        </div>
      </div>
    );
  }, [sort]);
  return (
    <div className='current-sort'>
      { selectedButton }
      <Popper
        className='rounded text-primary-text'
        style={{
          zIndex: 9,
          maxHeight: '80%',
          overflow: 'auto',
        }}
        modifiers={[{ name: 'offset', options: { offset: [0, 4] } }]}
        placement='bottom-start'
        id='notifications-container'
        open={open}
        anchorEl={() => document.querySelector('.current-sort-button')}
        keepMounted={false}
      >
        { currentSortPopperContents }
      </Popper>
    </div>
  );
};

const TopicListHeaderDisplay = () => {
  const [display, setDisplay] = useRecoilState(topicListDisplayKey);
  const [open, setOpen] = useState(false);

  const handleClickAway = useCallback((e) => {
    if (e?.target?.closest(`.${clickawayClass}`) !== null) return null;
    setOpen(false);
  }, []);

  const toggleDisplay = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const selectedButton = useMemo(() => {
    let contents;
    switch (display) {
      case TopicListDisplayKeys.Compact: {
        contents = (
          <>
            { DisplayButtonData.Compact.icon }
            { DisplayButtonData.Compact.label}
          </>
        );
        break;
      }
      case TopicListDisplayKeys.Full: {
        contents = (
          <>
            { DisplayButtonData.Full.icon }
            { DisplayButtonData.Full.label }
          </>
        );
        break;
      }
      case TopicListDisplayKeys.Preview: {
        contents = (
          <>
            { DisplayButtonData.Preview.icon }
            { DisplayButtonData.Preview.label }
          </>
        );
        break;
      }
      default: {
        contents = (
          <>
            { DisplayButtonData.Full.icon }
            { DisplayButtonData.Full.label }
          </>
        );
      }
    }
    return (
      <Button
        sx={{ fontSize: '14px' }}
        className='uppercase current-display-button'
        size='small'
        variant='outlined'
        color='primaryLight'
        onClick={toggleDisplay}
      >
        { contents }
      </Button>
    );
  }, [display]);

  const currentSortPopperContents = useMemo(() => {
    const buttons = Object.values(DisplayButtonData).map(({ display: _display, label, icon }) => {
      if (_display === display) return null;
      return (
        <ClickAwayListener
          key={_display}
          onClickAway={handleClickAway}
        >
          <ListItem
            dense
            disablePadding
            sx={{ fontSize: '14px' }}
            className={clickawayClass}
          >
            <ListItemButton
              className='uppercase'
              size='small'
              variant='outlined'
              color={_display === display ? 'primaryLight' : 'textPrimary'}
              onClick={() => {
                setDisplay(_display);
                toggleDisplay();
              }}
              label={label}
            >
              { icon }
              { label }
            </ListItemButton>
          </ListItem>
        </ClickAwayListener>
      );
    });
    return (
      <div className='bg-surface'>
        <div className='bg-s04dp rounded'>
          <div className='display-buttons flex flex-col items-start'>
            <List
              dense
            >
              { buttons }
            </List>
          </div>
        </div>
      </div>
    );
  }, [display]);

  return (
    <div className='current-display'>
      { selectedButton }
      <Popper
        className='rounded text-primary-text'
        style={{
          zIndex: 9,
          maxHeight: '80%',
          overflow: 'auto',
        }}
        modifiers={[
          { name: 'offset', options: { offset: [0, 4] } },
          { name: 'flip', enabled: false },
        ]}
        placement='bottom-end'
        id='notifications-container'
        open={open}
        anchorEl={() => document.querySelector('.current-display-button')}
        keepMounted={false}
      >
        { currentSortPopperContents }
      </Popper>
    </div>
  );
};

const TopicListHeader = () => (
  <div className={`py-2 pl-2 pr-1 ${clickawayClass} flex justify-between mr-1`}>
    <TopicListHeaderSort />
    <TopicListHeaderDisplay />
  </div>
);

export default TopicListHeader;
