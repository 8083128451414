import {
  CustomEvents,
  QueryKeyEventTypes,
} from '../lib/constants';
import { getActiveQueryKeys } from '../lib/queryUtils';
import { TkuQueryClient } from '../providers/query';
import { useCustomEvent } from './useCustomEventListener';

export const handleInvalidateQueries = (data) => {
  const { queryKey, options = {}, type } = data;
  if (!queryKey && !type) return;
  if (queryKey) TkuQueryClient.invalidateQueries(queryKey, { exact: false, ...options });
  if (type) {
    switch (type) {
      case QueryKeyEventTypes.Active: {
        const activeQueryKeys = getActiveQueryKeys();
        for (const activeQueryKey of activeQueryKeys) {
          TkuQueryClient.invalidateQueries(activeQueryKey, { exact: false, ...options });
        }
      }
        break;
      case QueryKeyEventTypes.All:
        TkuQueryClient.invalidateQueries();
        break;
      default:
        break;
    }

    TkuQueryClient.invalidateQueries(queryKey, { exact: false, ...options });
  }
};

export const useHandleInvalidateQueries = () => {
  useCustomEvent(CustomEvents.InvalidateQueries, handleInvalidateQueries, []);
};
