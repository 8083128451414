import {
  Button,
  IconButton,
  Modal,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';
import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents, PostVoteTypes, SourceTypes } from '../../lib/constants';
import UserAvatar from '../UserAvatar';
import UniverseLoader from '../Loaders/UniverseLoader';
import { PostModel } from '../../models/post/model';
import { PrivatePostModel } from '../../models/privatePost/model';
import { UserModel } from '../../models/user/model';

// TODO: update to use querylist

const ToggleButtonData = {
  [PostVoteTypes.Upvote]: {
    Icon: FavoriteIcon,
    label: 'Upvotes',
  },
  [PostVoteTypes.Downvote]: {
    Icon: ThumbDownIcon,
    label: 'Downvotes',
  },
};

const PostVotesModal = () => {
  const [open, setOpen] = useState(false);
  const [postData, setPostData] = useState(null);
  const [voteType, setVoteType] = useState(PostVoteTypes.Upvote);
  const [votes, setVotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();

  const handleClose = useCallback(() => {
    setOpen(false);
    setPostData(null);
    setVoteType(PostVoteTypes.Upvote);
    setVotes([]);
  }, []);

  const handleOpen = useCallback(({ postId: _postId, postType = 'public' }) => {
    if (!_postId) return null;
    setOpen(true);
    setPostData({ postId: _postId, postType });
  }, []);

  useEffect(async () => {
    if (!postData) return;
    setLoading(true);
    setError(false);
    const { postId, postType } = postData;
    const model = postType === 'public' ? new PostModel(postId) : new PrivatePostModel(postId);
    const res = await model.getVotes(voteType);
    if (res.success) {
      for (const item of res.data) UserModel.setUserPartial(item.voter._id, item.voter);
      setVotes(res.data);
    }
    if (!res.success) setError(res.error);
    setLoading(false);
  }, [postData, voteType]);

  useEffect(() => handleClose(), [location]);

  useCustomEvent(CustomEvents.PostVoteModalOpen, handleOpen);

  const postVoteTypeToggle = useMemo(() => (
    <div className='vote-type-toggle mb-3 flex justify-between items-center'>
      <div className='space-x-2'>
        { Object.values(PostVoteTypes).map((type) => {
          const { Icon } = ToggleButtonData[type];
          return (
            <Button
              key={type}
              variant='outlined'
              color={voteType === type ? 'primaryLight' : 'textPrimary'}
              size='small'
              onClick={() => setVoteType(type)}
              startIcon={<Icon />}
            >
              {ToggleButtonData[type].label}
            </Button>
          );
        })}
      </div>
      <IconButton
        onClick={handleClose}
        label='close-modal'
        color='textPrimary'
      >
        <CancelIcon />
      </IconButton>
    </div>
  ), [voteType]);

  const postVotes = useMemo(() => {
    if (error) {
      return (
        <div className='px-1 py-2 space-y-2 h-[100%]'>
          <div className='flex h-[50%] justify-center items-center'>something went wrong 🫠</div>
        </div>
      );
    }
    if (loading) {
      return (
        <div className='relative px-1 py-2 space-y-2 h-[100%]'>
          <UniverseLoader size={100} showLogo={false} boxType='fullContainer' full />
        </div>
      );
    }
    return (
      <div style={{ height: 'calc(100% - 40.75px)' }} className='px-1 pt-2 space-y-2 overflow-auto'>
        { votes.length === 0 && (
          <div className='flex h-[50%] justify-center items-center'>{`there are no ${voteType}s yet ${voteType === 'downvote' ? '🥳' : '🤔'}`}</div>
        )}
        { votes.length > 0 && (
          <>
            { votes.map((vote) => {
              const {
                username,
                _id,
              } = vote.voter;
              const handleClick = () => emitCustomEvent(CustomEvents.OpenUserProfileDrawer, {
                sourceType: SourceTypes.Topic,
                username,
                zIndex: 2000,
              });
              return (
                <div
                  onClick={handleClick}
                  className='flex space-x-2'
                  key={_id}
                  role='button'
                  tabIndex={0}
                  onKeyPress={handleClick}
                >
                  <UserAvatar _id={_id} avatarFontSize='14px' avatarSize='24px' />
                  <span>{ username }</span>
                </div>
              );
            }) }
          </>
        )}
      </div>
    );
  }, [votes, voteType, loading, error]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='bg-surface px-4 py-4 rounded font-roboto text-primary-text h-[400px] w-[95vw] tablet:w-[400px] overflow-hidden'>
        { postVoteTypeToggle }
        { postVotes }
      </div>
    </Modal>
  );
};

export default PostVotesModal;
