import Cancel from '@mui/icons-material/Cancel';
import {
  Button, IconButton, TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { PopperMenu } from '../../PopperMenu';

const stringShortener = (str, length = 40, shortenedLength = 30) => (str.length < length ? str : `${str.substring(0, shortenedLength)}`);
const stringCleaner = str => str.replace(/[\W_]+/g, ' ');

const EmbedPreview = ({
  url,
  data,
  cleanup,
  anchorElement,
  setData,
  selectOptionAndCleanUp,
}) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => cleanup, []);

  const handleClose = useCallback(() => {
    cleanup();
    setData(null);
  }, []);

  const handleChange = useCallback((e) => {
    const val = e.target.value;
    if (val.length > 50) return;
    setDisplayText(e.target.value);
  }, []);

  const handleSelectedOption = useCallback(() => {
    let text = data.emoji;
    if (displayText?.length > 0) text = `${text} ${displayText}`;
    else if (data?.title) text = `${text} ${stringShortener(stringCleaner(data.title), 50, 40)}`;
    else text = `${text} Untitled Image ${nanoid(8)}`;
    selectOptionAndCleanUp({ ...data, text });
  }, [data, displayText]);

  if (!data || !url) return null;

  const _url = `${stringShortener(url)}...`;

  return (
    <PopperMenu anchorElement={anchorElement}>
      <div className='bg-surface rounded'>
        <div style={{ maxWidth: '350px' }} className='text-primary-text font-roboto bg-s03dp py-2 px-4 rounded my-2 overflow-auto relative'>
          <div className='flex items-center justify-between'>
            <div className='text-sm text-secondary-light'>{`${data.emoji} ${_url}`}</div>
            <IconButton
              size='small'
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </div>
          <div style={{ maxHeight: '200px' }} className='overflow-auto'>
            { !!data?.title && <h5 className='text-lg font-bold leading-6 my-2 text-primary-light'><a target='_blank' href={data.url} rel='noreferrer'>{data.title}</a></h5>}
            { !!data?.thumbnail_url && <img className='my-2' src={data.thumbnail_url} alt={data.title} /> }
            { !!data?.description && <p className='my-2 break-words text-sm'>{data.description}</p> }
            { data?.type === 'photo' && <img className='my-2' src={data.url} alt={data.title} /> }
          </div>
          <div className='pt-2'>
            <TextField
              sx={{ marginBottom: '16px' }}
              label='change display text (optional)'
              size='small'
              fullWidth
              color='primaryLight'
              value={displayText}
              onChange={handleChange}
            />
            <div className='flex justify-between'>
              <Button
                color='secondaryLight'
                variant='outlined'
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={handleSelectedOption}
              >
                Embed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PopperMenu>
  );
};

export const renderMenu = (data, loading, url, setData) => {
  const _renderMenu = (anchorElement, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
    const cleanup = () => document.querySelectorAll('#typeahead-menu').forEach(n => n?.remove());
    if (!anchorElement || !data) return null;
    return (
      <EmbedPreview
        selectOptionAndCleanUp={selectOptionAndCleanUp}
        url={url}
        data={data}
        cleanup={cleanup}
        setData={setData}
        anchorElement={anchorElement}
      />
    );
  };
  return _renderMenu;
};
