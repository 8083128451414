import {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Fab,
  Grid,
  Card,
  Button,
  TextField,
} from '@mui/material';

import { nanoid } from 'nanoid';
import AddIcon from '@mui/icons-material/Add';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { CustomEvents } from '../../lib/constants';
import ApiClientInstance from '../../clients/api';

import { Wrapper, Content } from '../Containers';
import DisplayAvatar from '../DisplayAvatar';
import SimpleItemList from '../SimpleItemList';

import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';
import useHandleImageUpload from '../../hooks/useHandleImageUpload';

const CategoryHeader = () => ((
  <div>
    <Grid className='items-center !mt-0 !mb-0' columnSpacing={3} container>
      <Grid item xs={6}>
        <span className='text-sm'>display</span>
      </Grid>
      <Grid item xs={4}>
        <span className='text-sm ml-4'>actions</span>
      </Grid>
    </Grid>
  </div>
));

const CategoryDisplay = () => {
  const CategoryDisplayComponent = ({ item, index }) => {
    const {
      _id,
      image,
      name,
      color,
    } = item;

    const handleEdit = useCallback(() => emitCustomEvent(CustomEvents.AdminItemEdit, { category: item }), [_id]);

    return (
      <div className='bg-surface pl-1 pr-3 py-1 rounded'>
        <div className='bg-s01dp'>
          <Grid className='py-2 items-center !mt-0 !mb-0 rounded' columnSpacing={3} container>
            <Grid item xs={6}>
              <div className='ml-1 flex items-center'>
                <DisplayAvatar avatar={image} size='22px' color={color} username={name} />
                <span className='ml-2 text-sm leading-4'>{name}</span>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Button onClick={handleEdit} color='primaryLight' size='small'>Edit</Button>
            </Grid>
            <Grid item xs={2}>
              <Button disabled color='secondaryLight' size='small'>Delete</Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
  return CategoryDisplayComponent;
};

const AdminCategory = () => {
  const [category, setCategory] = useState(null);
  const [editing, setEditing] = useState(false);
  const [originalEditingCategory, setOriginalEditingCategory] = useState(null);
  const [creating, setCreating] = useState(false);

  const handleCreateCategoryClick = useCallback(() => {
    setCreating(prev => !prev);
    setCategory({
      name: '',
      image: '',
      color: '',
    });
  }, []);

  const handleNameChange = useCallback((event) => {
    const { value } = event.target;
    setCategory(prev => ({ ...prev, name: value }));
  }, []);

  const handleEditing = useCallback(({ category: _category }) => {
    setEditing(true);
    setCategory(_category);
    setOriginalEditingCategory(_category);
  }, []);

  const handleCancel = useCallback(() => {
    setEditing(false);
    setCreating(false);
    setCategory(null);
    setOriginalEditingCategory(null);
  }, []);

  const handleImageChange = useCallback(({ url }) => {
    setCategory(prev => ({ ...prev, image: url }));
  }, []);

  const handleImageUpload = useHandleImageUpload({
    uploadFolder: 'category',
    uploadTitle: `${category?.name ? `${category.name}_` : ''}${nanoid(6)}`,
    callback: handleImageChange,
    shouldResize: false,
  });

  const handleReset = useCallback(() => {
    setCategory(originalEditingCategory);
  }, [originalEditingCategory]);

  const handleSubmit = useCallback(() => {
    let req = { data: { ...category } };
    if (creating) {
      req = {
        method: 'post',
        path: '/admin/category',
        ...req,
      };
    } else {
      req = {
        method: 'put',
        path: `/admin/category/${category._id}`,
        ...req,
      };
    }
    const handler = async () => {
      await ApiClientInstance.sendRequest(req);
      handleCancel();
    };
    const text = editing ? `Save modifications for ${category.name}` : `Create ${category.name}`;
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text });
  }, [creating, editing, JSON.stringify(category), handleCancel]);

  useCustomEvent(CustomEvents.AdminItemEdit, handleEditing);

  const categoryList = useMemo(() => (
    <div style={{ height: 'calc(100% - 80px)' }} className='category-list'>

      <SimpleItemList
        path='/category'
        ItemComponent={CategoryDisplay()}
        sort={null}
        showErrorMessage
      />
    </div>
  ), []);

  const createCategory = useMemo(() => (
    <div className='category-edit tablet:w-[550px] px-2'>
      <h2 className='text-3xl mt-2 mb-2 font-bebas'>{editing ? `Edit ${category.name} Category` : 'Create Category'}</h2>
      <Card className='px-2 py-3'>
        <div className='flex justify-between'>
          <TextField InputLabelProps={{ shrink: true }} value={category?.name} onChange={handleNameChange} size='small' label='Name' />
        </div>
        <div className='mt-5'>

          { category?.image && (
            <div className='flex items-center'>
              <DisplayAvatar avatar={category.image} size='36px' color={category.color} username={category.name} />
              <span className='ml-2'>{category.name}</span>
            </div>
          )}
          <label className='file-input-label' htmlFor='file-input'>
            <input accept='image/*' onChange={handleImageUpload} id='file-input' style={{ display: 'none' }} type='file' />
            <Button
              sx={{ marginTop: '10px' }}
              startIcon={<AddPhotoAlternateIcon />}
              size='small'
              color='primaryLight'
              component='span'
            >
              {`${category?.image ? 'change' : 'add'} image`}
            </Button>
          </label>

        </div>
        <div className='flex justify-end mt-5 space-x-3'>
          <Button color='secondaryLight' variant='outlined' size='medium' onClick={handleCancel}>Cancel</Button>
          { editing && <Button disabled={JSON.stringify(category) === JSON.stringify(originalEditingCategory)} color='primaryLight' variant='outlined' size='medium' onClick={handleReset}>Reset</Button> }
          <Button
            disabled={!category?.name || !category?.image || (JSON.stringify(category) === JSON.stringify(originalEditingCategory))}
            color='primary'
            variant='contained'
            size='medium'
            onClick={handleSubmit}
          >
            {editing ? 'Update' : 'Create'}
          </Button>
        </div>
      </Card>
    </div>
  ), [category, originalEditingCategory]);

  const createButton = useMemo(() => (
    <div className='absolute bottom-3 right-3'>
      <Fab
        onClick={handleCreateCategoryClick}
        variant='extended'
        color='primary'
      >
        Create Category
        <AddIcon />
      </Fab>
    </div>
  ), [handleCreateCategoryClick]);

  return (
    <Wrapper applyBreakpoints className='categories relative'>
      { (!creating && !editing) && (
      <div className='px-3 pt-2 space-x-2 justify-between items-center text-primary-text'>
        <h1 className='text-3xl font-bebas my-3 text-primary-text'>Categories</h1>
        <CategoryHeader />
      </div>
      ) }
      <Content className='pt-2 text-primary-text font-roboto'>
        {(!creating && !editing) && createButton}
        {(!editing && !creating) && categoryList}
        {(creating || editing) && createCategory}
      </Content>
    </Wrapper>
  );
};

export default AdminCategory;
