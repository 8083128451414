import {
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { passwordChecks } from '../../lib/validateUser';

const PasswordInput = ({
  value,
  autoComplete,
  handleChange,
  fullWidth,
  sx,
  error,
  label,
  onKeyPress,
  variant,
  helperText = 'Password must be at least 8 characters long and contain at least one number, one uppercase letter and one lowercase letter.',
  errorHelperText,
  className,
  size,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [valid, setValid] = useState(false);

  const endIcon = useMemo(() => {
    const icon = showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;

    return (
      <IconButton
        onClick={() => setShowPassword((prev) => !prev)}
      >
        { icon }
      </IconButton>
    );
  }, [showPassword]);

  useEffect(() => {
    if (!value) {
      setErrorMessage('');
      setValid(false);
      return;
    }
    const { valid: _valid, errorType, message } = passwordChecks(value);
    if (_valid) {
      setValid(true);
      setErrorMessage('');
    }
    if (!_valid) {
      setValid(false);
      setErrorMessage(message);
    }
  }, [value]);

  const _error = useMemo(() => {
    if (error) return true;
    if (errorMessage) return true;
    if (!!value && !valid) return true;
    return false;
  }, [errorMessage, error, valid, value]);

  const _helperText = useMemo(() => {
    if (errorMessage) return errorMessage;
    if (_error) return errorHelperText || errorMessage || '';
    if (!value) return helperText;
    if (!!value && valid) return '';
  }, [_error, errorMessage, errorHelperText, valid, value]);

  return (
    <TextField
      size={size}
      variant={variant}
      className={className}
      autoComplete={autoComplete}
      sx={sx}
      onKeyPress={onKeyPress}
      error={_error}
      type={showPassword ? 'text' : 'password'}
      onChange={handleChange}
      value={value}
      color={valid ? 'success' : 'primary'}
      fullWidth={fullWidth}
      label={label}
      helperText={_helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            { endIcon }
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
