/* eslint-disable react/function-component-definition */
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { SourceTypes } from '../../lib/constants';
import { useLoadIfNeeded } from '../utils/useLoadIfNeeded';
import state from './atom';
import { PostModel } from './model';
import { PrivatePostModel } from '../privatePost/model';
import { useFetchOnMount } from '../utils/useFetchOnMount';

export const usePost = (id, {
  loadIfNeeded = false,
  sourceType,
  fetchOnMount = false,
} = { }) => {
  const {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
  } = useRecoilValue(state(id));

  const ModelInstance = useMemo(() => {
    if (!sourceType && !data?.type) return null;
    if (sourceType === SourceTypes.Post || data?.type === 'public') return new PostModel(id);
    if (sourceType === SourceTypes.Message || data?.type === 'private') return new PrivatePostModel(id);
  }, [id, sourceType, data?.type]);

  useLoadIfNeeded({
    loadIfNeeded,
    data,
    hasLoaded,
    loading,
    ModelInstance,
    id,
  });

  useFetchOnMount({
    fetch: ModelInstance?.fetch,
    fetching,
    fetchOnMount,
    loading,
  });

  return {
    data,
    loading,
    error,
    fetching,
    hasLoaded,
    model: ModelInstance,
    fetch: ModelInstance?.fetch,
    reload: ModelInstance?.reload,
    setData: ModelInstance?.setData,
    loadIfNeeded: ModelInstance?.loadIfNeeded,
  };
};
