// import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Grid } from '@mui/material';
import abbreviateNumber from '../../lib/abbreviateNumber';
import { formatDate } from '../../lib/formatDate';
import BetDrawer from '../BetDrawer';
import BetStatusIcon from '../BetStatusIcon';

const Team = ({
  playingTeam, selectedTeam, winner, away,
}) => {
  const isSelected = playingTeam._id === selectedTeam._id;
  const isWinner = playingTeam._id === winner && isSelected;
  return (
    <Grid item xs={away ? 4 : 3}>
      { (!winner) && (
      <span style={{ color: isSelected ? 'var(--color-primary-light)' : 'var(--color-primary-text)' }} className='text-xs font-bold flex items-center'>
        {playingTeam.code}
      </span>
      ) }
      { (isSelected && !!winner) && (
      <span style={{ color: isWinner ? '#66bb6a' : 'var(--color-error)' }} className='text-xs font-bold flex items-center'>
        {playingTeam.code}
      </span>
      ) }
      { (!isSelected && !!winner) && (
      <span className='text-xs font-bold flex items-center'>
        {playingTeam.code}
      </span>
      ) }
    </Grid>
  );
};

const Profit = ({
  isWinner, winner, amount, payout, claimed,
}) => (
  <Grid item xs={3}>
    { (!winner) && (
    <span className='text-sm text-primary-light italic'>
      {abbreviateNumber(payout)}
    </span>
    )}
    { (isWinner && !!winner) && (
    <span className='text-sm' style={{ color: '#66bb6a' }}>
      {abbreviateNumber(payout)}
    </span>
    )}
    { (!isWinner && !!winner) && (
    <span className='text-sm text-error'>
      (
      {abbreviateNumber(amount)}
      )
    </span>
    )}
  </Grid>
);

const BetCard = ({
  game, team, amount, payout, claimed, placedTime, league, _id, index,
}) => {
  const {
    home, away, winner, hasStarted, currentTime,
  } = game;

  const [open, setOpen] = useState(false);

  const isWinner = team._id === winner;

  return (
    <>
      <div
        onClick={() => setOpen(prev => !prev)}
        role='button'
        tabIndex={0}
        onKeyDown={() => setOpen(prev => !prev)}
        className='py-1 roboto relative bg-surface text-primary-text rounded pl-1 pr-2'
      >
        <Grid className='pl-1 pr-2 py-2 items-center !mt-0 !mb-0 bg-s01dp' columns={24} container>
          <BetStatusIcon claimed={claimed} winner={game.winner} isWinner={isWinner} hasStarted={hasStarted} />
          <Grid item xs={4} className='text-xs'>{formatDate(new Date(placedTime), 'M/d/yy')}</Grid>
          <Grid item xs={4} className='text-xs'>{league.code}</Grid>
          <Team winner={game.winner} selectedTeam={team} playingTeam={home} />
          <Team away winner={game.winner} selectedTeam={team} playingTeam={away} />
          <Grid item xs={3}>
            <span className='text-sm'>{abbreviateNumber(amount)}</span>
          </Grid>
          <Profit claimed={claimed} winner={winner} isWinner={isWinner} payout={payout} amount={amount} />
        </Grid>
      </div>
      <BetDrawer amount={amount} claimed={claimed} isWinner={isWinner} betIndex={index} betId={_id} payout={payout} team={team} game={game} open={open} setOpen={setOpen} />
    </>
  );
};

export default BetCard;
