import {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useSetRecoilState,
} from 'recoil';
import {
  TextField,
  Button,
  Chip,
} from '@mui/material';

import {
  Wrapper,
  Content,
  StyledSection,
  StyledDivider,
} from '../Containers';
import PasswordInput from '../PasswordInput';

import ApiClientInstance from '../../clients/api';
import { checkEmail, checkPassword } from '../../lib/validateUser';
import { CustomEvents, UserEmailStatus } from '../../lib/constants';
import { formatDate } from '../../lib/formatDate';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

const SettingsAccount = () => {
  const {
    data: user,
    setData: setUser,
    fetch: load,
    verified,
  } = useAuthenticatedUser();

  const data = user;
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordValid, setCurrentPasswordValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmValid, setPasswordConfirmValid] = useState(false);

  const fetchUser = useCallback(async () => {
    await load();
  }, [user?.username]);

  useEffect(() => {
    if (!data) return;
    setUser(data);
  }, [data]);

  // Email

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleEmailUpdate = useCallback(() => {
    const handler = async () => {
      await ApiClientInstance.sendRequest({ method: 'put', path: '/user/email', data: { email } });
      await fetchUser();
      addSnackbar({ message: 'Email updated successfully!', color: 'primary' });
      setEmail('');
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, {
      handler,
      text: `Change your email to ${email}`,
      data: { description: { text: 'This will require you to re-verify your email address.' } },
    });
  }, [user, email, setEmail, addSnackbar]);

  useEffect(() => {
    setEmailValid(checkEmail(email));
  }, [email]);

  const handleEmailVerification = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      method: 'post',
      path: '/user/email/token/create',
      data: { email },
      snackbarError: 'errorMessage',
    });
    if (res?.success) {
      addSnackbar({ message: 'Email verification sent!', color: 'primary' });
    }
  }, [email, addSnackbar]);

  const emailUpdate = useMemo(() => (
    <StyledSection action='change-email' title='Email Address' className='tablet:w-[772px] text-primary-text font-roboto'>
      <div className='flex flex-col space-y-2 mb-2 mt-1'>
        <span className='text-sm opacity-75'>{user?.email}</span>
        <span className='text-xs opacity-75'>Updating your email address will require you to verify your new address. You will temporarily lose access to certain features before verification.</span>
      </div>
      <div className='flex justify-between space-x-6 pb-4 pt-4'>
        <TextField
          className='w-[100%] desktop:w-[372px]'
          label='Email Address'
          variant='outlined'
          size='medium'
          value={email}
          onChange={handleEmailChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />

      </div>
      <Button
        className='desktop:w-[372px]'
        variant='contained'
        color='primary'
        size='medium'
        label='update-email'
        disabled={!emailValid || email === user?.email}
        onClick={handleEmailUpdate}
      >
        Update Email Address
      </Button>
    </StyledSection>
  ), [email, handleEmailChange, handleEmailUpdate, emailValid, user?.email]);

  const emailVerification = useMemo(() => (
    <StyledSection action='verify-email' title='Email Verification' className='mt-6 tablet:w-[772px] text-primary-text font-roboto'>
      <div className='flex flex-col space-y-2'>
        <div className='flex justify-between items-center mb-1'>
          <span className='text-sm opacity-75'>{user?.email}</span>
          <Chip size='small' label={user?.emailStatus} color={user?.emailStatus === UserEmailStatus.Verified ? 'primary' : 'warning'} />
        </div>
        { user?.emailStatus !== UserEmailStatus.Verified && (

        <div className='pt-3 flex flex-col desktop:flex-row desktop:justify-between desktop:items-center space-y-3 desktop:space-y-0'>
          <span className='text-xs opacity-75'>You must verify your email address before you can use all of the features of the application.</span>
          <Button
            variant='outlined'
            color='primaryLight'
            onClick={handleEmailVerification}
          >
            Resend Email Verification
          </Button>
        </div>
        )}
      </div>
    </StyledSection>
  ), [user?.email, user?.emailStatus]);

  // Password

  const handlePasswordChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const handlePasswordConfirmChange = useCallback((event) => {
    setPasswordConfirm(event.target.value);
  }, []);

  const handleCurrentPasswordChange = useCallback((event) => {
    setCurrentPassword(event.target.value);
  }, []);

  useEffect(() => {
    setCurrentPasswordValid(checkPassword(currentPassword));
    setPasswordValid(checkPassword(password));
    setPasswordConfirmValid(password === passwordConfirm && password !== currentPassword);
  }, [currentPassword, password, passwordConfirm]);

  const handlePasswordSubmit = useCallback(() => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: '/user/password',
        data: { currentPassword, newPassword: password },
        catchError: true,
      });
      if (res?.success) {
        addSnackbar({ message: 'Password updated successfully!', color: 'primary' });
        setCurrentPassword('');
        setPassword('');
        setPasswordConfirm('');
      } else {
        setCurrentPassword('');
        setPassword('');
        setPasswordConfirm('');
        addSnackbar({ message: `Password update failed. ${res.error}`, color: 'error' });
      }
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text: 'Change your password' });
  }, [user, currentPassword, password, passwordConfirm, setCurrentPassword, setPassword, setPasswordConfirm, addSnackbar]);

  const changePassword = useMemo(() => {
    if (!verified) return null;
    return (
      <StyledSection action='update-password' title='Update Password' className='tablet:w-[772px] text-primary-text font-roboto'>
        <PasswordInput
          sx={{ marginTop: '14px' }}
          className='w-[100%] desktop:w-[372px]'
          label='Current Password'
          variant='outlined'
          size='medium'
          value={currentPassword}
          handleChange={handleCurrentPasswordChange}
          helperText={null}

        />
        <div className='pt-4 flex-col flex space-y-4'>
          <PasswordInput
            className='w-[100%] desktop:w-[372px]'
            label='New Password'
            variant='outlined'
            size='medium'
            value={password}
            handleChange={handlePasswordChange}
          />

          <PasswordInput
            className='w-[100%] desktop:w-[372px]'
            label='Confirm New Password'
            variant='outlined'
            size='medium'
            error={!!password && !!passwordConfirm && !passwordConfirmValid}
            value={passwordConfirm}
            errorHelperText={(password && passwordConfirm && !passwordConfirmValid && password !== passwordConfirm) ? 'Passwords do not match' : (password && passwordConfirm && password === passwordConfirm) ? 'New password cannot be current password' : null}
            handleChange={handlePasswordConfirmChange}
            helperText=''
          />
        </div>
        <div className='flex w-100 mt-5'>
          <Button
            className='desktop:w-[372px]'
            variant='contained'
            disabled={!currentPasswordValid || !passwordValid || !passwordConfirmValid}
            onClick={handlePasswordSubmit}
          >
            Update Password
          </Button>
        </div>
      </StyledSection>
    );
  }, [currentPassword, password, passwordConfirm, passwordValid, passwordConfirmValid, currentPasswordValid, verified]);

  // Delete Account

  const handleDeleteRequest = useCallback(() => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({
        method: 'put',
        path: '/user/profile',
        data: { deleteAccountRequested: !user?.deleteAccountRequested?.status },
        catchError: true,
      });
      if (res?.success) {
        const message = user?.deleteAccountRequested.status ? 'Account deletion request revoked.' : 'Account deletion request sent.';
        await fetchUser();
        addSnackbar({ message, color: 'primary' });
      } else {
        addSnackbar({ message: `Account deletion request failed. ${res.error}`, color: 'error' });
      }
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, {
      handler,
      text: !user?.deleteAccountRequested.status ? 'Are you sure you want to request for your account to be deleted' : 'Are you sure you want to revoke your account deletion request',
      data: {
        confirm: {
          color: 'error',
          text: !user?.deleteAccountRequested.status ? 'Request Account Deletion' : 'Revoke Account Deletion Request',
        },
        cancel: {
          color: 'textPrimary',
        },
        description: {
          html: !user?.deleteAccountRequested.status ? '<p class=\'text-sm opacity-75\'>Please note that account deletion is <span class=\'font-bold text-error\'>irreversible</span> and you will not be able to recover your account or any associate data after it has been deleted.</p>' : '',
        },
      },
    });
  }, [user?.deleteAccountRequested?.status, addSnackbar]);

  const deleteAccount = useMemo(() => (
    <StyledSection action='delete-account' title='Delete Account' className='tablet:w-[772px] text-primary-text font-roboto'>
      <div className='flex flex-col space-y-2 mb-4 mt-1'>
        <p className='text-sm opacity-75'>
          You may request to delete your account and all data associated with it. Your request will be handled in a timely manner, usually within 24-48 hours.
        </p>
        <p className='text-sm opacity-75'>
          Please note that account deletion is
          {' '}
          <span className='font-bold text-error'>irreversible</span>
          {' '}
          and you will not be able to recover your account or any associate data after it has been deleted.
        </p>
        <p className='text-sm opacity-75'>
          If your request is still pending, you may cancel your request and no changes will be made to your account.
        </p>
      </div>

      { user?.deleteAccountRequested?.status && (
        <div className='desktop:block flex flex-col space-y-2 mt-2 mb-4'>
          <Chip className='' label='Your account deletion request is pending.' color='error' />
          <span className='desktop:block text-xs opacity-80'>{`requested on ${formatDate(new Date(user?.deleteAccountRequested.dateRequested), 'M/dd/yyyy h:mm aaaa')}`}</span>
        </div>
      )}

      <Button
        className='desktop:w-[372px] w-[100%]'
        size='large'
        variant={user?.deleteAccountRequested.status ? 'outlined' : 'contained'}
        color='error'
        onClick={handleDeleteRequest}
      >
        { user?.deleteAccountRequested.status ? 'Revoke Account Deletion Request' : 'Request Account Deletion' }
      </Button>
    </StyledSection>
  ), [user?.deleteAccountRequested?.status, handleDeleteRequest]);

  return (
    <Wrapper applyBreakpoints>
      <div className='flex space-x-2 justify-between items-center'>
        <h1 className='text-2xl font-bebas my-3 text-primary-text'>Account Settings</h1>
      </div>
      <Content className='pb-3 tku-custom-scroller'>
        <div className='px-2 py-2 relative'>
          { emailUpdate }
          {emailVerification}
          <StyledDivider className='tablet:w-[772px] py-2' />
          { changePassword }
          <StyledDivider className='tablet:w-[772px] py-2' />
          { deleteAccount }
        </div>
      </Content>
    </Wrapper>
  );
};

export default SettingsAccount;
