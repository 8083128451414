import {
  Button,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { emitCustomEvent, useCustomEvent } from '../../hooks/useCustomEventListener';
import {
  CustomEvents,
  SourceTypes,
  UserRoles,
} from '../../lib/constants';
import DrawerMenu from '../DrawerMenu';
import UniverseLoader from '../Loaders/UniverseLoader';

import UserProfile from '../UserProfile';
import UserStatusDisplay from '../UserStatusDisplay';
import SimpleItemList from '../SimpleItemList';
import { StyledSection, StyledDivider } from '../Containers';
import { useUser } from '../../models/user/useUser';
import usernameMappingState from '../../models/usernameMapping/atom';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { emitOpenDrawerMenu } from '../../lib/emitters/emitOpenDrawerMenu';
import { UserModel } from '../../models/user/model';

const badgeSize = '18px';

const BadgeDisplay = ({ userBadges, userId }) => {
  const BadgeDisplayComponent = ({ item, index }) => {
    const hasBadge = !!userBadges.find(b => item._id === b._id);

    const handleClick = useCallback(async () => {
      const userModel = new UserModel(userId);
      let badgeHandler;
      if (hasBadge) badgeHandler = userModel.removeBadge;
      else badgeHandler = userModel.addBadge;
      await badgeHandler(item._id);
    }, [hasBadge]);

    const {
      _id,
      image,
      name,
    } = item;
    return (
      <div className='text-primary-text font-roboto flex items-center'>
        <Checkbox sx={{ padding: '2px 2px' }} onClick={handleClick} checked={hasBadge} />
        <div className='ml-3 flex space-x-2 items-center'>
          <span key={_id} className='ml-1 text-xs opacity-90'><img style={{ height: badgeSize, width: badgeSize }} src={image} alt={`${name}-badge-icon`} /></span>
          <span>{item?.name}</span>
        </div>
      </div>
    );
  };
  return BadgeDisplayComponent;
};

const AdminUser = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const [mtIncrement, setMtIncrement] = useState(0);
  const userIdFromUsername = useRecoilValue(usernameMappingState(username));

  const {
    data: user,
    fetch: fetchUser,
    error,
    model,
    loading: isLoading,
    setData: setUser,
  } = useUser(userIdFromUsername, {
    username,
    loadFromUsername: true,
  });

  useDocumentTitle(user ? `${user.username} - Admin` : 'Admin');

  useCustomEvent(CustomEvents.Rerender, fetchUser, []);

  const handleMtIncrementChange = useCallback((e) => {
    if (e.target.value > 100000) return;
    setMtIncrement(e.target.value);
  }, []);

  const handleMtIncrement = useCallback(async () => {
    if (!mtIncrement) return;
    const handler = async () => {
      await model.incrementMt(mtIncrement);
      setMtIncrement(0);
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text: `Add ${mtIncrement} MT to ${username}'s account` });
  }, [mtIncrement, model]);

  const handleEditMenuClick = useCallback(() => {
    if (!user?._id && !userIdFromUsername) return;
    emitOpenDrawerMenu({
      sourceType: SourceTypes.User,
      authorId: userIdFromUsername || user._id,
    });
  }, [userIdFromUsername, user?._id]);

  const handleRoleChange = useCallback((e) => {
    const _role = Object.values(UserRoles).find(r => r === e.target.value);
    if (!_role) return;
    const handler = async () => {
      await model.updateUserRole(_role);
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { handler, text: `Change ${username}'s role from ${user.role} to ${_role}` });
  }, [user]);

  useEffect(() => {
    if (error) navigate('/admin/users');
  }, [error]);

  const addMt = useMemo(() => (
    <StyledSection username={username} action='Add MT to'>
      <div className='flex justify-start space-x-6 py-3'>
        <TextField
          label='Amount'
          value={mtIncrement}
          onChange={handleMtIncrementChange}
          type='number'
          variant='outlined'
          size='small'
          sx={{
            width: '150px',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />

        <Button
          sx={{ width: '100px' }}
          variant='contained'
          color='primary'
          size='small'
          onClick={handleMtIncrement}
          disabled={!mtIncrement}
        >
          Confirm
        </Button>
      </div>
    </StyledSection>
  ), [mtIncrement, handleMtIncrement]);

  const badge = useMemo(() => {
    if (!user || !username) return null;
    console.log('username', username);
    return (
      <StyledSection username={username} action='Manage Badges for'>
        <div className='mt-2 relative' style={{ overflow: 'auto', height: '200px', width: '100%' }}>
          <SimpleItemList
            path='/badge'
            ItemComponent={BadgeDisplay({
              userBadges: user.badges,
              fetchUser,
              userId: user._id,
            })}
            loaderProps={{
              boxType: 'fullContainer',
              size: '100',
              showLogo: false,
            }}
            queryParams={{ config: 'manage' }}
            sort={null}
            showErrorMessage
          />
        </div>
      </StyledSection>
    );
  }, [username, user?.badges]);

  const role = useMemo(() => {
    if (!user || !username || !user?.role) return null;
    return (
      <StyledSection username={username} action='Change User Role for'>
        <div className='mt-4'>
          <FormControl sx={{ width: '200px' }}>
            <InputLabel id='user-role-select-label'>Role</InputLabel>
            <Select
              size='small'
              labelId='user-role-select-label'
              id='user-role-select'
              value={user.role}
              label='role'
              onChange={handleRoleChange}
            >
              {Object.values(UserRoles).map((r) => (
                <MenuItem key={r} value={r}>{r}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </StyledSection>
    );
  }, [user?.role, username, handleRoleChange]);

  return (
    <>
      { (isLoading && !user) && <UniverseLoader />}
      { user && (
      <div className='px-2 py-2 relative desktop:w-[992px] desktop:ml-auto desktop:mr-auto'>
        <UserProfile _id={userIdFromUsername} />
        <UserStatusDisplay user={user} emailStatus={user.emailStatus} userStatus={user.status} />
        <div className='flex justify-center pt-2'>
          <Button variant='outlined' color='primaryLight' onClick={handleEditMenuClick}>
            Manage User
          </Button>
        </div>
        <StyledDivider />
        {role}
        <StyledDivider />
        {badge}
        <StyledDivider />
        {addMt}
        <StyledDivider />

        <DrawerMenu sourceType={SourceTypes.User} />
      </div>
      ) }
    </>
  );
};

export default AdminUser;
