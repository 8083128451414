/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    this.setState(prevState => ({
      ...prevState,
      error,
      errorInfo,
    }));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.fallback) {
        return this.props.fallback;
      }
      if (this.props.hideError) {
        return null;
      }
      if (this.props.redirect) {
        return <Navigate to={this.props.redirect} />;
      }
      return (
        <div className='flex flex-col justify-center items-center py-2 px-2 bg-s02dp rounded'>
          <span className='text-primary-text font-roboto text-xs'>something went wrong. sorry about that.</span>
          <span className='text-primary-text font-roboto text-xs'>{this.state.error && `error: ${this.state.error.message.toLowerCase()}`}</span>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
