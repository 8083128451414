import { atomFamily } from 'recoil';
import { defaultLoadableAtom } from '../../lib/constants';

const topicState = atomFamily({
  key: 'topicState',
  default: {
    ...defaultLoadableAtom,
  },
});

export default topicState;

// using this didn't allow me to setRecoil outside of React which limited ability for setting the topic via lists
/*
  effects: (param) => [
    ({
      setSelf,
      trigger,
      getLoadable,
      node,
    }) => {
      if (trigger === 'set' || trigger === 'get') return;
      const currentState = getLoadable(node).contents;
      if (currentState.hasLoaded || currentState.loading) return;
      const fetchData = async () => {
        setSelf({
          loading: true,
          error: null,
          data: null,
          fetching: false,
          hasLoaded: false,
        });
        const res = await requestTopic(param);
        if (res.success) {
          setSelf({
            loading: false,
            error: null,
            data: res.data,
            fetching: false,
            hasLoaded: true,
          });
        } else {
          setSelf({
            loading: false,
            error: res.error,
            data: null,
            fetching: false,
            hasLoaded: false,
          });
        }
      };
      fetchData();
    },
    // ({ onSet }) => {
    //   onSet((newVal, prev) => {
    //     console.log(newVal, prev);
    //   });
    // },
  ],
*/
