import { atom } from 'recoil';
import { TopicListSortKeys } from '../../lib/constants';
import { loadFromLocalStorage, setInLocalStorage } from '../utils/atomUtills';

const key = 'topicListSortKey';

const topicListSortKey = atom({
  key: 'topicListSortKeyState',
  default: TopicListSortKeys.New,
  effects: [
    loadFromLocalStorage(key),
    setInLocalStorage(key),
  ],
});

export default topicListSortKey;
