import { useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { setRecoil } from 'recoil-nexus';

import SocketClientInstance from '../clients/socket';

import {
  editorIsEmptySelector,
  editorFocusSelector,
  editorIsActiveSelector,
} from '../models/editor/selectors';
import { useAuthenticatedUser } from '../models/authenticatedUser/useAuthenticatedUser';
import { SocketEvents, SocketUpdateTypes } from '../lib/constants';
import ApiClientInstance from '../clients/api';
import usersWritingState from '../models/usersWriting/atom';
import { UserModel } from '../models/user/model';

const USER_WRITING_EVENT = `${SocketEvents.Update}/${SocketUpdateTypes.UserWriting}`;

const handleWritingCheck = ({
  user,
  editorName,
  isTyping,
  hasStartedTyping,
  checkNotWriting = true,
}) => {
  if (!user) return;
  // used for first iteration when shortId request is going through
  if (editorName === 'false') return;
  if (isTyping && hasStartedTyping) {
    SocketClientInstance.emit(USER_WRITING_EVENT, {
      type: SocketUpdateTypes.UserWriting,
      topic: editorName,
      user: user._id,
      isWriting: true,
    });
  }
  if (checkNotWriting && !isTyping && hasStartedTyping) {
    SocketClientInstance.emit(USER_WRITING_EVENT, {
      type: SocketUpdateTypes.UserWriting,
      topic: editorName,
      user: user._id,
      isWriting: false,
    });
  }
};

const updateUsersWriting = async (editorName) => {
  if (editorName === 'false') return;
  const { data } = await ApiClientInstance.sendRequest({
    method: 'GET',
    path: `/topic/${editorName}/users-writing`,
    catchError: true,
  });
  if (!data) return;
  const { users, userIds } = data;
  if (!users || !users?.length) {
    setRecoil(usersWritingState(editorName), []);
    return;
  }
  for (const userWriting of users) UserModel.setUser(userWriting._id, userWriting);
  setRecoil(usersWritingState(editorName), userIds);
};

export const useUserIsWriting = (editorName) => {
  const hasStartedTyping = useRef(false);
  const isEmpty = useRecoilValue(editorIsEmptySelector(editorName));
  const isFocused = useRecoilValue(editorFocusSelector(editorName));
  const isTyping = !isEmpty && isFocused;
  const { data: user } = useAuthenticatedUser();

  // flag to prevent sending typing event on first iteration
  if (!isEmpty && isFocused && !hasStartedTyping.current) hasStartedTyping.current = true;

  useEffect(async () => {
    updateUsersWriting(editorName);
  }, [editorName]);

  // check if typing at interval
  useEffect(() => {
    // checks to see if a user is still typiing and therefore keeps server state in sync
    const interval = setInterval(() => {
      const _isActive = editorIsActiveSelector(editorName);
      if (!_isActive) return;
      handleWritingCheck({
        user,
        editorName,
        isTyping,
        hasStartedTyping: hasStartedTyping.current,
        checkNotWriting: false,
      });
    }, 1000 * 120);
    return () => {
      clearInterval(interval);
    };
  }, [editorName, isTyping, user]);

  useEffect(() => () => {
    if (hasStartedTyping.current) {
      SocketClientInstance.emit(USER_WRITING_EVENT, {
        type: SocketUpdateTypes.UserWriting,
        topic: editorName,
        user: user._id,
        isWriting: false,
      });
    }
  }, [editorName]);

  useEffect(() => {
    handleWritingCheck({
      user,
      editorName,
      isTyping,
      hasStartedTyping: hasStartedTyping.current,
    });
  }, [editorName, isTyping, user]);
};
