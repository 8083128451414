import { useSetRecoilState } from 'recoil';
import { Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import ApiClientInstance from '../../clients/api';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { CustomEvents } from '../../lib/constants';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';
import DisplayAvatar from '../DisplayAvatar';

const CategoryHeader = ({ category }) => {
  const {
    data: user,
    setData: setUser,
    unauthenticated,
    loading: authLoading,
  } = useAuthenticatedUser();

  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const categorySubscriptions = user?.categorySubscriptions || [];

  const joinedCategory = useMemo(() => {
    if (!categorySubscriptions || !category || unauthenticated) return false;
    return !!categorySubscriptions.find(cat => cat?._id === category?._id);
  }, [categorySubscriptions, category._id, unauthenticated]);

  const joinCategory = useCallback(async () => {
    const res = await ApiClientInstance.sendRequest({
      method: 'post',
      path: `/user/category/subscribe/${category._id}`,
      catchError: true,
    });
    if (res.success) {
      setUser(res.data);
      addSnackbar({ message: `Successfully joined ${category.name}!`, color: 'primary' });
    } else {
      addSnackbar({ message: res.message, severity: 'error' });
    }
  }, [category?.name, category?._id]);

  const leaveCategory = useCallback(async () => {
    const handler = async () => {
      const res = await ApiClientInstance.sendRequest({ method: 'put', path: `/user/category/unsubscribe/${category._id}` });
      if (res.success) {
        setUser(res.data);
        addSnackbar({ message: `Successfully left ${category.name}`, color: 'primary' });
      } else {
        addSnackbar({ message: res.message, severity: 'error' });
      }
    };
    const text = `Are you sure you want to leave ${category.name}`;
    const data = {
      description: {
        text: `Topics created in ${category?.name} will no longer appear on your homepage, but you'll still be able to view them on the category page and the all page.`,
      },
      confirm: {
        text: `Leave ${category?.name}`,
      },
    };
    emitCustomEvent(CustomEvents.ConfirmationDialog, { text, handler, data });
  }, [category?.name, category?._id]);

  if (!category) return null;

  return (
    <section className='category-header px-2 py-3'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center space-x-3'>
          <DisplayAvatar avatarSize='30px' color={category.color} avatar={category.image} name={category.name} />
          <h1 className='text-2xl font-bebas text-primary-text'>{category.name}</h1>
        </div>
        { (!authLoading && !unauthenticated) && (
        <div className='category-cta'>
          <Button
            color={joinedCategory ? 'secondaryLight' : 'primary'}
            variant={joinedCategory ? 'outlined' : 'contained'}
            size='small'
            onClick={joinedCategory ? leaveCategory : joinCategory}
          >
            {joinedCategory ? 'Leave' : 'Join'}
          </Button>
        </div>
        ) }
      </div>
    </section>
  );
};

export default CategoryHeader;
