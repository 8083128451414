import { memo } from 'react';
import { Wrapper, Content } from '../../components/Containers';
import CategoryCard from '../../components/CategoryCard';
import QueryList from '../../components/QueryList';
import { QueryKeys, SocketRooms } from '../../lib/constants';
import useSocketJoin from '../../hooks/useSocketJoin';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const MemoCategoryCard = memo(CategoryCard);

// eslint-disable-next-line react/jsx-props-no-spreading
const itemComponent = ({ item }) => <MemoCategoryCard {...item} />;

const Categories = () => {
  useSocketJoin(SocketRooms.General, 'category');

  useDocumentTitle('Categories');

  return (
    <Wrapper applyBreakpoints className='categories'>
      <h1 className='pl-1 text-3xl font-bebas my-3 text-primary-text'>Categories</h1>
      <Content className='py-1 categories-content'>
        <QueryList
          requestPath='/category'
          ItemComponent={itemComponent}
          queryKey={[QueryKeys.Categories]}
          queryEnabled
          customScrollParentSelector='.categories-content'
          shouldShowBackButton={false}
        />
      </Content>
    </Wrapper>
  );
};

export default Categories;
