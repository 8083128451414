import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ApiClientInstance from '../../clients/api';
import {
  latestQueriesSelector,
  searchQuerySelector,
  lastSearchQuerySelector,
} from './selectors';
import debounce from '../../lib/debounce';
import { UserStatus } from '../../lib/constants';
import { useAuthenticatedUser } from '../authenticatedUser/useAuthenticatedUser';

export const useLatestQueries = ({ limit = 10, debounceTime = 0 }) => {
  const [latestQueries, setLatestQueries] = useRecoilState(latestQueriesSelector);
  const searchQuery = useRecoilValue(searchQuerySelector);
  const lastQuery = useRecoilValue(lastSearchQuerySelector);
  const { data, unauthenticated, loading: authLoading } = useAuthenticatedUser();

  const getLatestQueries = useCallback(debounce(async () => {
    if (unauthenticated || authLoading || data?.status !== UserStatus.Good) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'GET',
      path: '/search-query',
      queryParams: { limit },
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) setLatestQueries(res.data);
  }, debounceTime), [unauthenticated, authLoading, data?.status]);

  const deleteAllSearchQueries = useCallback(async () => {
    if (unauthenticated || authLoading) return;
    if (!latestQueries.length) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'put',
      path: '/search-query',
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) setLatestQueries([]);
  }, [latestQueries, unauthenticated, authLoading]);

  const deleteQuery = useCallback(async (query) => {
    if (unauthenticated || authLoading) return;
    if (!query._id) return;
    const res = await ApiClientInstance.sendRequest({
      method: 'put',
      path: `/search-query/${encodeURIComponent(query._id)}`,
      catchError: true,
      snackbarError: 'errorMessage',
    });
    if (res.success) {
      const newLatestQueries = latestQueries.filter(q => q._id !== query._id);
      setLatestQueries(newLatestQueries);
      await getLatestQueries();
    }
  }, [latestQueries, unauthenticated, authLoading]);

  useEffect(async () => {
    if (unauthenticated || authLoading) return;
    if (lastQuery !== searchQuery) return;
    await getLatestQueries();
  }, [searchQuery, lastQuery, unauthenticated, authLoading]);

  return {
    latestQueries,
    deleteAllSearchQueries,
    deleteQuery,
  };
};
