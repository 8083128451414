import { useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import SocketClient from '../clients/socket';

window.SocketClient = SocketClient;
const log = (data) => console.log(data);

/**
 * Subscribes to socket even and executes the given handler. A listener ID is created and stored in the socket client
 *
 * @param {string} eventName Socket event to subscribe to
 * @param {Function} handler Callback to handle event
 * @param {any} dependencies to use as dependency in useEffect
 * @returns {void}
 */
const useSocketOn = (eventName, handler, dependencies) => {
  const id = useRef();

  useEffect(() => {
    if (!eventName) return null;
    id.current = nanoid(8);
    SocketClient.on(eventName, handler);
    SocketClient.addToListeners({ id: id.current, eventName, handler });
    return () => {
      SocketClient.off(eventName, handler);
      SocketClient.removeFromListeners(id.current);
    };
  }, dependencies ? [dependencies] : []);
};

export default useSocketOn;
