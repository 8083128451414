import { useSetRecoilState } from 'recoil';
import { useState, useCallback, useEffect } from 'react';
import debounce from '../lib/debounce';
import { breakpointNameSelector } from '../models/settings/selectors';
import { BreakpointValues, Breakpoints } from '../lib/constants';

const useBreakpointName = () => {
  const setBreakpointName = useSetRecoilState(breakpointNameSelector);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => setWidth(window.innerWidth), []);

  const handleResize = useCallback(debounce(() => {
    setWidth(window.innerWidth);
  }, 100), []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const keys = Object.keys(Breakpoints);
    for (const key of keys) {
      const value = Breakpoints[key];
      if (width < BreakpointValues[key].Max && width >= BreakpointValues[key].Min) {
        setBreakpointName(value);
        break;
      }
    }
  }, [width]);
};

export default useBreakpointName;
