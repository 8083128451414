import { Wrapper, Content } from '../../components/Containers';
import ULogo from '../../components/Svg/uLogo';

export const DisconnectedNav = () => (
  <nav className='w-full primary bg-surface h-12 tku-box-shadow z-10'>
    <div className='flex items-center justify-between desktop:w-[992px] desktop:ml-auto desktop:mr-auto relative h-[48px]'>
      <div className='flex px-3 main-nav  text-primary-text items-center absolute justify-center h-full w-full'>
        <ULogo containerStyles={{ height: '25px' }} />
      </div>
    </div>
  </nav>
);

export const DisconnectedFooter = () => (
  <footer className='tku-main-footer tku-box-shadow w-full h-12 bg-s03dp px-6 z-10'>
    <div className='flex items-center justify-between h-12 desktop:w-[992px] desktop:ml-auto desktop:mr-auto' />
  </footer>
);

const Disconnected = () => (
  <Wrapper applyBreakpoints>
    <DisconnectedNav />
    <Content>
      <div className='h-[300px] flex flex-col justify-center items-center text-primary-text px-2 text-center space-y-4'>
        <h1 className='text-xl my-4'>It looks like you&apos;ve gone offline 😱</h1>
        <p className='max-w-[992px] px-4'>There appears to be problem with your connection to 2KU servers. Please verify your internet connection and reload the page. If the problem persists, please contact us.</p>
      </div>
    </Content>
    <DisconnectedFooter />
  </Wrapper>

);

export default Disconnected;
