import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSetRecoilState } from 'recoil';
import { addDays, format } from 'date-fns';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { CustomEvents, PinTypes } from '../../lib/constants';
import getUtcDate from '../../lib/getUtcDate';

import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { useTopic } from '../../models/topic/useTopic';

const TopicPin = ({
  topicId,
  mainContainerClassname,
  handleConfirm,
  handleCancel,
}) => {
  const { data } = useTopic(topicId);
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const [active, setActive] = useState(false);
  const [type, setType] = useState(PinTypes.Global);
  const [expires, setExpires] = useState(data?.pin?.expires || Date.now());
  const dateRef = useRef(null);

  const _handleCancel = useCallback(async () => {
    await handleCancel();
  }, [handleCancel]);

  const _handleSubmit = useCallback(async () => {
    const handler = async () => {
      try {
        await handleConfirm({
          active,
          type,
          expires,
        });
      } catch (e) {
        addSnackbar({ message: e.message, severity: 'error' });
      }
      handleCancel();
    };
    emitCustomEvent(
      CustomEvents.ConfirmationDialog,
      {
        text: `${active ? 'Update pin for ' : 'Unpin '} ${data?.slug}`,
        data: {
          description: { text: active ? `This action will pin ${data.slug} ${type === PinTypes.Global ? ' globally ' : ' in its category '} until ${format(expires, 'M/d/yy')}` : '' },
        },
        handler,
      },
    );
  }, [handleConfirm, handleCancel, data, active, type, expires]);

  useEffect(() => {
    setActive(Boolean(data?.pin?.active));
    setType(data?.pin?.active ? data.pin.type : PinTypes.Global);
    if (data?.pin?.expires) {
      const date = getUtcDate(data.pin.expires);
      dateRef.current = date;
      setExpires(date);
    } else {
      setExpires(addDays(getUtcDate(), 1));
    }
  }, [data]);

  const hasChanged = useMemo(() => {
    if (!active) return active !== data?.pin?.active;
    return active !== data?.pin?.active || type !== data?.pin?.type || expires !== dateRef.current;
  }, [
    data?.pin?.active,
    data?.pin?.type,
    data?.pin?.expires,
    active,
    type,
    expires,
  ]);

  const expiresInvalid = useMemo(() => {
    if (!active) return false;
    if (!expires) return true;
    return !!expires && (expires.valueOf() <= Date.now().valueOf());
  }, [expires, active]);

  return (
    <div className={`${mainContainerClassname} flex flex-col justify-between text-primary-text`}>
      <div>
        <h1 className='mb-2'>Update Topic Pin</h1>
        <div className='flex items-center mb-3'>
          <h3>Pinned</h3>
          <Switch
            checked={active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </div>
        <FormControl fullWidth>
          <InputLabel>Pin Type</InputLabel>
          <Select
            className='text-primary-text'
            label='pin type'
            value={type}
            size='small'
            onChange={(e) => setType(e.target.value)}
            disabled={!active}
          >
            <MenuItem value={PinTypes.Global}>Global</MenuItem>
            <MenuItem value={PinTypes.Category}>Category</MenuItem>
          </Select>
        </FormControl>
        <div className='mt-3'>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={!active}
              className='w-[100%]'
              disableCloseOnSelect={false}
              label='Expiration Date'
              value={expires}
              minDate={addDays(getUtcDate(), 1)}
              onChange={(date) => setExpires(getUtcDate(date))}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField fullWidth size='small' {...params} />}
            />
            { expiresInvalid && (
              <div className='text-xs text-error mt-1'>
                expiration date must be at least 1 day from now
              </div>
            ) }
          </LocalizationProvider>
        </div>
      </div>
      <div className='flex justify-between mt-4 space-x-5'>
        <Button
          color='secondaryLight'
          variant='outlined'
          onClick={_handleCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={!hasChanged || expiresInvalid}
          variant='contained'
          color='primary'
          onClick={_handleSubmit}
        >
          Update Topic Pin
        </Button>
      </div>
    </div>
  );
};

export default TopicPin;
