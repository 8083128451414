import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useRecoilValue } from 'recoil';
import { getRecoil, resetRecoil } from 'recoil-nexus';
import { Breakpoints, CustomEvents } from '../../lib/constants';

import { ContentForList } from '../Containers';
import PaginatedItemPage from '../PaginatedItemPage';

import { useCustomEvent } from '../../hooks/useCustomEventListener';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { breakpointNameSelector } from '../../models/settings/selectors';
import { editorIsActiveSelector } from '../../models/editor/selectors';
import { usePaginatedScroller } from '../../models/paginatedScrollerState/usePaginatedScroller';
import paginatedScrollerState from '../../models/paginatedScrollerState/atom';

const MemoizedPaginatedItemPage = memo(PaginatedItemPage);

const PaginatedScroller = ({
  getPrev,
  getNext,
  pages,
  setPages,
  containerStyles,
  firstItemId,
  renderItem,
  init,
  initialItemId,
  setInitialItemId,
  useInitialItemId,
  containerProps,
  topicId,
  topic,
  setParentLoading,
}) => {
  const {
    loading,
    loadingPrev,
    loadingNext,
    cursorState,
    pageStatus,
    scrollLock,
    setLoading: _setLoading,
    setLoadingPrev,
    setLoadingNext,
    setCursorState,
    setPageStatus: _setPageStatus,
    setScrollLock,
  } = usePaginatedScroller({ topicId });

  useEffect(() => () => resetRecoil(paginatedScrollerState(topicId)), []);

  const setPageStatus = useCallback((postId, isIntersecting) => _setPageStatus(prev => ({ ...prev, [postId]: isIntersecting })), [topicId]);
  const editorIsActive = useRecoilValue(editorIsActiveSelector(topicId));

  const setLoading = useCallback((val) => {
    _setLoading(val);
    setParentLoading(val);
  }, [_setLoading, setParentLoading]);

  const scrollerRef = useRef(null);
  const cursorStateRef = useRef({});
  const componentStateRef = useRef({ mounted: false });

  const breakpointName = useRecoilValue(breakpointNameSelector);

  const _containerStyles = useMemo(() => ({
    overflowAnchor: 'none',
    overflow: (breakpointName === Breakpoints.Mobile && (loadingPrev || scrollLock)) ? 'hidden' : 'auto',
    pointerEvents: (loadingPrev || scrollLock) ? 'none' : '',
  }), [loadingPrev, breakpointName, scrollLock]);

  const footerRef = useRef();
  const headerRef = useRef();
  const footerEntry = useIntersectionObserver(footerRef, {});
  const headerEntry = useIntersectionObserver(headerRef, {});

  const scrollToItem = useCallback((postId, options) => {
    const el = document.querySelector(`.item-${postId}`);
    if (!el) return;
    el.scrollIntoView(options);
  }, []);

  const clear = useCallback(() => {
    componentStateRef.current = { mounted: false };
    setLoadingPrev(false);
    setLoadingNext(false);
    setCursorState({});
    _setPageStatus({});
    setPages([]);
    if (useInitialItemId) setInitialItemId({});
    cursorStateRef.current = { nextBefore: null, nextAfter: null };
  }, [topicId, setPages, setCursorState, setLoadingPrev, setLoadingNext, setLoading, setInitialItemId, useInitialItemId]);

  const _init = useCallback(async (_initialItemId) => {
    setLoading(true);
    const res = await init(_initialItemId);
    if (res?.success) {
      setPages([res.data.docs]);
      setCursorState({
        nextAfter: res.data.nextAfter,
        nextBefore: res.data.nextBefore,
      });
      cursorStateRef.current.nextBefore = res.data.nextBefore;
      if (useInitialItemId) setInitialItemId({ [topicId]: _initialItemId });
    }
  }, [setCursorState, setPages, setInitialItemId, init, topicId]);

  const _reset = useCallback(({ _id }) => {
    if (!_id) return;
    const el = document.querySelector(`.item-${_id}`);
    if (el) return scrollToItem(_id, { behavior: 'smooth' });
    clear();
    _init(_id);
  }, [clear, _init, scrollToItem]);

  useCustomEvent(CustomEvents.ResetPaginatedPostList, _reset, []);

  const _getPrev = useCallback(async () => {
    if (!cursorState.nextBefore || !componentStateRef.current.mounted) return;
    componentStateRef.current.maintainTopScroll = true;
    setLoadingPrev(true);
    const res = await getPrev(cursorState.nextBefore);
    if (res.success) {
      if (res.data.docs.length > 0) {
        const nextBefore = (firstItemId === res.data.docs[0]._id) ? null : res.data.nextBefore;
        if (pages.length < 4) {
          setPages(prev => [res.data.docs, ...prev]);
          setCursorState(prev => ({
            ...prev,
            nextBefore,
          }));
          cursorStateRef.current.nextBefore = nextBefore;
        } else {
          const _cursorState = {
            nextBefore,
            nextAfter: pages[2][pages[2].length - 1]._id,
          };
          setCursorState(_cursorState);
          cursorStateRef.current = _cursorState;
          setPages(prev => [res.data.docs, ...prev.slice(0, 3)]);
        }
      } else {
        setCursorState(prev => ({
          ...prev,
          nextBefore: null,
        }));
        cursorStateRef.current.nextBefore = '';
        cursorStateRef.current.prevPagePostCount = -1;
        setLoadingPrev(false);
      }
    }
  }, [cursorState, pages, getPrev, setPages, setCursorState, setLoadingPrev, firstItemId]);

  const _getNext = useCallback(async () => {
    if (!cursorState.nextAfter || !componentStateRef.current.mounted || loadingNext) return;
    setLoadingNext(true);
    componentStateRef.current.maintainTopScroll = false;
    const res = await getNext(cursorState.nextAfter, 'after');
    if (res.success) {
      if (res.data.docs.length > 0) {
        if (pages.length < 4) {
          setPages(prev => [...prev, res.data.docs]);
          setCursorState(prev => ({
            ...prev,
            nextAfter: res.data.nextAfter,
          }));
        } else {
          componentStateRef.current.maintainBottomScroll = true;
          setScrollLock(true);
          const _cursorState = {
            nextBefore: pages[1][0]._id,
            nextAfter: res.data.nextAfter,
          };
          setCursorState(_cursorState);
          cursorStateRef.current = { ...cursorStateRef.current, ..._cursorState };
          setPages(prev => [...prev.slice(1, 4), res.data.docs]);
        }
      } else {
        setLoadingNext(false);
        setCursorState(prev => ({
          ...prev,
          nextAfter: null,
        }));
      }
    }
  }, [cursorState, loadingNext, pages, pageStatus, getNext, setLoadingNext, setPages, setCursorState]);

  // Watcher for previous posts
  useEffect(() => {
    if (!headerEntry || loading || loadingPrev || loadingNext) return;
    const { isIntersecting } = headerEntry;
    if (isIntersecting) _getPrev();
  }, [headerEntry, loading, loadingPrev, loadingNext, _getPrev]);

  // Watcher for next posts
  useEffect(() => {
    if (!footerEntry || loading || loadingPrev || loadingNext) return;
    const { isIntersecting } = footerEntry;
    if (isIntersecting) _getNext();
  }, [footerEntry, loading, loadingPrev, loadingNext, _getNext]);

  // previous pages scroll handler
  useLayoutEffect(() => {
    if (!componentStateRef.current.maintainTopScroll) return;
    if (loadingPrev || cursorStateRef.current.prevPagePostCount === -1) return;
    const hasNextBefore = !!getRecoil(paginatedScrollerState(topicId)).cursorState.nextBefore;
    const newestPageHeight = document.querySelectorAll('.items-content-page-container')[0].getBoundingClientRect().height;
    const currentScrollTop = scrollerRef.current.scrollTop;
    const scrollTop = newestPageHeight + currentScrollTop;
    scrollerRef.current.scrollTop = scrollTop - (hasNextBefore ? 163 : 326);
    // This was throwing an error: maybe you cant assign ref in layout effect
    try {
      if (!loadingPrev && cursorStateRef.current?.nextBefore !== cursorState.nextBefore) {
        cursorStateRef.current.nextBefore = cursorState.nextBefore;
      }
    } catch (e) {
      console.log(e);
    }
  }, [loadingPrev, cursorState, topicId]);

  // next posts scroll handler
  useLayoutEffect(() => {
    if (!componentStateRef.current.maintainBottomScroll) return;
    if (!loadingNext) setScrollLock(false);
  }, [loadingNext]);

  // only run on the initial mount. Scrolls to intial post if it exists.
  useLayoutEffect(() => {
    if ((useInitialItemId && !initialItemId[topicId]) || loading) return;
    if (initialItemId[topicId]) scrollToItem(initialItemId[topicId]);
    componentStateRef.current.mounted = true;
  }, [loading, initialItemId, useInitialItemId, topicId]);

  // Initial component for mount
  useEffect(async () => {
    if ((useInitialItemId && !initialItemId[topicId]) || loading) return;
    await _init(initialItemId[topicId]);
  }, [useInitialItemId, initialItemId[topicId], _init, topicId]);

  return (
    <ContentForList
      ref={scrollerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...containerProps}
      mobileHeightOffset={editorIsActive ? 124 : 52.5}
      desktopHeightOffset={editorIsActive ? 124 : 52.5}
      style={{ ..._containerStyles, ...containerStyles }}
      className='item-content item-list-scroller relative'

    >
      <div style={{ display: (loadingPrev || loading) ? 'none' : '' }} ref={headerRef} className='item-content-header h-[1px]' />
      { pages.length > 0 && (
      <>
        { pages.map((page, index) => (
          <MemoizedPaginatedItemPage
            index={index}
            isFirstPaginatedPage={index === 0 && !cursorState.nextBefore}
            isFirstPage={index === 0}
            isLastPage={index === pages.length - 1}
            isLastPaginatedPage={index === pages.length - 1 && !cursorState.nextAfter}
            key={page[0]._id}
            items={page}
            itemsStringified={JSON.stringify(page)}
            setLoading={setLoading}
            setPageStatus={setPageStatus}
            renderItem={renderItem}
            hideHeader={cursorState.nextBefore === null}
            topicId={topicId}
          />
        ))}
      </>
      ) }
      <div style={{ display: (loadingNext || loading) ? 'none' : '' }} ref={footerRef} className='item-content-footer h-[100px]' />
    </ContentForList>
  );
};

export default PaginatedScroller;
