import {
  getRecoil,
  setRecoil,
} from 'recoil-nexus';
import {
  CustomEvents,
  QueryLocationKeys,
  SocketEffects,
  SocketUpdateActions,
  TopicListSortKeys,
} from '../../lib/constants';
import {
  getActiveQueryKeys,
  invalidateCurrentTopicQueries,
} from '../../lib/queryUtils';
import { handleGlobalEffects } from './handleGlobalEffects';

import { TopicModel } from '../../models/topic/model';
import { PostModel } from '../../models/post/model';
import { UserModel } from '../../models/user/model';
import { authenticatedUserSelector } from '../../models/authenticatedUser/selectors';
import { addPostToLastPage } from '../../models/currentPostsPages/selectors';
import newTopicsState from '../../models/newTopics/atom';
import topicListSortKeyState from '../../models/topicListSortKey/atom';
import { emitCustomEvent } from '../useCustomEventListener';

const handleTopicEffects = (res) => {
  const { effects } = res;
  if (effects?.length < 1) return;
  for (const effect of effects) {
    switch (effect) {
      case SocketEffects.InvalidateQueries:
        invalidateCurrentTopicQueries();
        break;
      default:
        break;
    }
  }
};

const getShouldUpdateNewTopics = (res) => {
  const { data } = res;
  const user = getRecoil(authenticatedUserSelector);
  const topicListSortKey = getRecoil(topicListSortKeyState);
  // in other sorts than new topic list wouldn't have the new topic at the top of the list
  if (topicListSortKey !== TopicListSortKeys.New) return false;
  const categoryId = data.topic?.category?._id;
  const activeQuery = getActiveQueryKeys()?.[0];
  if (!activeQuery) return true;
  if (activeQuery.includes(QueryLocationKeys.All)) return true;
  if (activeQuery.includes(QueryLocationKeys.Category) && activeQuery.includes(categoryId)) return true;
  if (activeQuery.includes(QueryLocationKeys.Home) && !user) return true;
  const userCategorySubscriptions = user?.categorySubscriptions || [];
  if (activeQuery.includes(QueryLocationKeys.Home) && userCategorySubscriptions.find(cs => cs._id === categoryId)) return true;
  return false;
};

const handleNewTopic = (res) => {
  const { data } = res;
  const newTopicId = data?.topic?._id;
  if (!newTopicId) return;
  const shouldUpdateNewTopics = getShouldUpdateNewTopics(res);
  if (!shouldUpdateNewTopics) return;
  setRecoil(newTopicsState, prev => {
    if (prev.includes(newTopicId)) return prev;
    return [...prev, newTopicId];
  });
};

const handleAddingNewPost = (res) => {
  const { data } = res;
  console.log('reply topic', data);
  const { topic, post } = data;
  TopicModel.setTopicWithoutMainPost(topic._id, topic);
  const Post = new PostModel(post._id);
  Post.setData(post);
  // handles adding replies
  const replyToPid = post?.replyToPid ? post?.replyToPid?._id : null;
  if (replyToPid) {
    const PostRepliedTo = new PostModel(replyToPid);
    const postRepliedToData = PostRepliedTo.getData(replyToPid);
    const newReplies = postRepliedToData?.replies ? [...postRepliedToData.replies, post] : [post];
    PostRepliedTo.setData({ ...postRepliedToData, replies: newReplies });
  }
  const User = new UserModel(post.author._id);
  User.setData(post.author);
  emitCustomEvent(CustomEvents.RefreshNavigateData);
};

const handleNewPostInTopic = (res) => {
  handleAddingNewPost(res);
  const topicListSortKey = getRecoil(topicListSortKeyState);
  if (topicListSortKey === TopicListSortKeys.Recent) invalidateCurrentTopicQueries();
};

const handleReplyTopic = (res) => {
  const { data } = res;
  handleAddingNewPost(res);
  setRecoil(addPostToLastPage(data.topic._id), data.post);
};

const handleDeleteTopic = (res) => {
  const { data } = res;
  const Topic = new TopicModel(data.topic._id);
  Topic.setData({ ...data.topic, isDeleted: true });
};

const handleUpdateTopic = (res) => {
  const { data } = res;
  const Topic = new TopicModel(data.topic._id);
  Topic.setData(data.topic);
};

export const handleTopic = (res) => {
  const { action } = res;
  switch (action) {
    case SocketUpdateActions.Reply: {
      handleReplyTopic(res);
      break;
    }
    case SocketUpdateActions.New: {
      handleNewTopic(res);
      break;
    }
    case SocketUpdateActions.Update: {
      handleUpdateTopic(res);
      break;
    }
    case SocketUpdateActions.Delete: {
      handleDeleteTopic(res);
      break;
    }
    case SocketUpdateActions.NewPostInTopic: {
      handleNewPostInTopic(res);
      break;
    }
    default: {
      console.log('unknown topic action', action);
    }
  }
  handleTopicEffects(res);
  handleGlobalEffects(res);
};
