/* eslint-disable react/jsx-closing-tag-location */
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { $getRoot } from 'lexical';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { useRef } from 'react';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { setRecoil } from 'recoil-nexus';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { nanoid } from 'nanoid';
import { OnChangePlugin } from './OnChangePlugin';
import { InitialStateWatcherPlugin } from './InitialStateWatcherPlugin';
import { MentionsPlugin } from './MentionPlugin';
import { EmojiPlugin } from './EmojiPlugin';
import { EmbedPlugin } from './EmbedPlugin';
import { InsertPlugin } from './InsertPlugin';
import defaultNodes from './nodes';
import {
  editorStateJsonSelector,
  editorIsEmptySelector,
  editorTextContentSelector,
} from '../../models/editor/selectors';
import resizeFile from '../../lib/resizeFile';
import ApiClientInstance from '../../clients/api';
import { addSnackbarSelector } from '../../models/snackbar/selector';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';
import {
  ComposerNodeTypes,
  CustomEvents,
  ImageUploadModalTypes,
  LexicalComposerNamespaces,
  SourceTypes,
} from '../../lib/constants';
import { breakpointNameSelector } from '../../models/settings/selectors';
import FocusPlugin from './FocusPlugin';
import { CustomEmojiNode } from './Nodes/CustomEmojiNode';
import { SelectionQuotePlugin } from './SelectionQuotePlugin';
import { buildNameSpaceString } from './utils/buildNameSpaceString';
import { defaultTheme } from './themes';
import './style.css';

// https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/Editor.tsx

const getEditedRootText = (root) => {
  let text = '';
  for (const child of root.children) {
    for (const _child of child.children) {
      const _text = _child.text;
      if (_text === 'undefined' || _text === undefined) continue;
      if (_text === '🙂' && _child?.data !== undefined && _child.data.shortname !== 'slightly_smiling_face') {
        text += ` ${_child.data.shortname} `;
      } else {
        text += _text;
      }
    }
  }
  return text.replace(/(\s\s)/gi, ' ').trim();
};

const onChange = (editorState, editor, editorName) => {
  editorState.read(() => {
    localStorage.setItem(`${editorName}-editorState`, JSON.stringify(editorState));
    setRecoil(editorStateJsonSelector(editorName), JSON.stringify(editorState));
    const root = $getRoot();
    const json = editorState.toJSON();
    const textContent = getEditedRootText(json.root);
    setRecoil(editorIsEmptySelector(editorName), root.getTextContent().length === 0);
    setRecoil(editorTextContentSelector(editorName), textContent);
  });
};

const uploadImage = async (image) => {
  const data = await ApiClientInstance.uploadAttachment({ attachment: { image } });
  if (data?.error) {
    setRecoil(addSnackbarSelector, ({
      message: 'Upload failed. Please try again.',
      severity: 'error',
    }));
    return;
  }
  return {
    type: 'photo',
    customType: 'upload',
    url: data.data.url,
    id: nanoid(8),
  };
};

const handlePaste = (editorName) => async (e) => {
  const { items } = e.clipboardData || e.originalEvent.clipboardData;
  const handler = ({ url, title, id }) => emitCustomEvent(CustomEvents.InsertInEditor, {
    editorName,
    nodeType: ComposerNodeTypes.Image,
    data: { url, title, id },
  });
  for (const file of items) {
    if (file.kind === 'file') {
      if (file.type.includes('image')) {
        try {
          const blob = file.getAsFile();
          const image = await resizeFile(blob);
          const imageUploadData = await uploadImage(image);
          emitCustomEvent(CustomEvents.OpenImageUploadModal, {
            type: ImageUploadModalTypes.General,
            modalTitle: 'Insert Image',
            handleConfirm: handler,
            image: { url: imageUploadData.url },
          });
        } catch (_e) {
          // swallow error
        }
      }
    }
  }
};

const _onError = (error) => console.error(error);

// editorStyle ={ maxHeight: maxHeight ? `calc(${maxHeight} - 1rem)` : '600px' }

const NewComposer = ({
  namespace = LexicalComposerNamespaces.Editor,
  initialConfig,
  nodes = defaultNodes,
  theme = defaultTheme,
  initialEditorState = null,
  getInitialEditorFromEditorName = true,
  editorName = '2kuEditor',
  sourceType = SourceTypes.Post,
  displaySource,
  editorClassName = 'editor-root',
  outerContainerClassName = '',
  innerContainerClassName = '',
  editorStyles = {},
  outerContainerStyles = {},
  innerContainerStyles = {},
  includePlugins = true,
  excludePlugins = {},
  watchInitialState = false,
  excludedNodes = [],
  selectionQuotePlugin = false,
  onError = _onError,
}) => {
  const containerRef = useRef();
  const wrapperRef = useRef();
  const breakpointName = useRecoilValue(breakpointNameSelector);

  const _nodes = excludedNodes.length > 0 ? nodes.filter((node) => !excludedNodes.find(excludedNode => excludedNode.getType() === node.getType())) : nodes;

  const excludeCustomEmojis = excludedNodes.find(node => node.getType() === CustomEmojiNode.getType());

  const _initialConfig = {
    namespace: buildNameSpaceString({
      namespace,
      editorName,
      displaySource,
      sourceType,
    }),
    theme,
    nodes: _nodes,
    onError,
    ...initialConfig,
  };
  // eslint-disable-next-line react/function-component-definition
  const getInitialComposer = useRecoilCallback(({ snapshot }) => () => snapshot.getLoadable(editorStateJsonSelector(editorName))?.contents || null, []);

  if (getInitialEditorFromEditorName) {
    const __initialEditorState = getInitialComposer();
    if (__initialEditorState) _initialConfig.editorState = __initialEditorState;
  }

  if (initialEditorState) _initialConfig.editorState = initialEditorState;

  return (
    <div
      ref={containerRef}
      className={`editor-container-outer editor-${breakpointName}${outerContainerClassName ? ` ${outerContainerClassName}` : ''}`}
      style={outerContainerStyles}
    >
      <div
        className={`editor-container-inner ${innerContainerClassName ? ` ${innerContainerClassName}` : ''}`}
        style={innerContainerStyles}
        onPaste={handlePaste(editorName)}
        ref={wrapperRef}
      >
        <LexicalComposer initialConfig={_initialConfig}>
          <RichTextPlugin
            contentEditable={(
              <ContentEditable
                type='text/plain'
                style={editorStyles}
                className={`editor-root${editorClassName ? ` ${editorClassName}` : ''}`}
              />
              )}
          />

          { watchInitialState && <InitialStateWatcherPlugin initialState={initialEditorState} /> }
          <HistoryPlugin />
          { selectionQuotePlugin && <SelectionQuotePlugin editorName={editorName} />}
          { includePlugins && (
            <>
              <OnChangePlugin editorName={editorName} onChange={onChange} />
              { !excludePlugins.focus && <FocusPlugin editorName={editorName} />}
              { !excludePlugins.mentions && <MentionsPlugin /> }
              { !excludePlugins.emoji && <EmojiPlugin excludeCustomEmojis={excludeCustomEmojis} editorName={editorName} /> }
              { !excludePlugins.embed && <EmbedPlugin editorName={editorName} /> }
              { !excludePlugins.insert && <InsertPlugin sourceType={sourceType} editorName={editorName} /> }
              { !excludePlugins.hashtag && <HashtagPlugin />}
              { !excludePlugins.markdown && <MarkdownShortcutPlugin /> }
            </>
          ) }
        </LexicalComposer>
      </div>
    </div>
  );
};

export default NewComposer;
