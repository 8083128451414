import { Routes, Route } from 'react-router-dom';
import useProtectedRequirements from '../../hooks/useProtectedRequirements';
import Users from '../Users';
import { Wrapper, Content } from '../../components/Containers';
import AdminNavbar from '../../components/AdminNavbar';
import AdminUsers from '../../components/AdminUser';
import AdminBadge from '../../components/AdminBadge';
import { UserRoles } from '../../lib/constants';
import AdminCategory from '../../components/AdminCategory';
import AdminJobs from '../../components/AdminJobs';
import AdminAnalytics from '../../components/AdminAnalytics';
import AdminDeveloper from '../../components/AdminDeveloper';
import NotFound from '../NotFound';

const Admin = () => {
  const { loading } = useProtectedRequirements({ roles: [UserRoles.Admin, UserRoles.SuperAdmin] });
  if (loading) return null;
  return (
    <Wrapper>
      <AdminNavbar />
      <Content>
        <Routes>
          <Route path='/' element={<Users isAdmin />} />
          <Route path='/users' element={<Users isAdmin />} />
          <Route path='/users/:username' element={<AdminUsers />} />
          <Route path='/badges' element={<AdminBadge />} />
          <Route path='/categories' element={<AdminCategory />} />
          <Route path='/jobs' element={<AdminJobs />} />
          <Route path='/analytics' element={<AdminAnalytics />} />
          <Route path='/developer' element={<AdminDeveloper />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Content>
    </Wrapper>
  );
};

export default Admin;
