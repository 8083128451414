import { selector } from 'recoil';
import state from './atom';

export const searchQuerySelector = selector({
  key: 'searchQuerySelector',
  get: ({ get }) => {
    const searchState = get(state);
    return searchState?.query || '';
  },
  set: ({ set, get }, query) => {
    const searchState = get(state);
    set(state, { ...searchState, query });
  },
});

export const lastSearchQuerySelector = selector({
  key: 'lastSearchQuerySelector',
  get: ({ get }) => {
    const searchState = get(state);
    return searchState?.lastQuery || '';
  },
  set: ({ set, get }, query) => {
    const searchState = get(state);
    set(state, { ...searchState, lastQuery: query });
  },
});

export const latestQueriesSelector = selector({
  key: 'latestQueriesSelector',
  get: ({ get }) => {
    const searchState = get(state);
    return searchState?.latestQueries || [];
  },
  set: ({ set, get }, latestQueries) => {
    const searchState = get(state);
    set(state, { ...searchState, latestQueries });
  },
});

export const searchResultsSelector = selector({
  key: 'searchResultsSelector',
  get: ({ get }) => {
    const searchState = get(state);
    return {
      topics: searchState.topics,
      categories: searchState.categories,
      posts: searchState.posts,
      users: searchState.users,
      meta: searchState.meta,
    };
  },
  set: ({ set, get }, results) => {
    const searchState = get(state);
    set(state, { ...searchState, ...results });
  },
});

export const navSearchActiveSelector = selector({
  key: 'navSearchActiveSelector',
  get: ({ get }) => {
    const searchState = get(state);
    return searchState.navSearchActive;
  },
  set: ({ set, get }, navSearchActive) => {
    const searchState = get(state);
    set(state, { ...searchState, navSearchActive });
  },
});

export const searchStatusSelector = selector({
  key: 'searchStatusSelector',
  get: ({ get }) => {
    const searchState = get(state);
    return searchState.status;
  },
  set: ({ set, get }, status) => {
    const searchState = get(state);
    set(state, { ...searchState, status: { ...searchState.status, ...status } });
  },
});
