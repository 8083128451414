import { getRecoil, setRecoil } from 'recoil-nexus';
import * as PrivateTopicApi from './api';
import { defaultLoadableAtom } from '../../lib/constants';

import { BaseModel } from '../BaseModel';
import { UserModel } from '../user/model';

import state from '../topic/atom';
import postState from '../post/atom';
import unreadMessagesCount from '../unreadMessagesCount/atom';
import messageRequestCount from '../messageRequestCount/atom';
import { authenticatedUserSelector, userVerifiedSelector } from '../authenticatedUser/selectors';

const getTime = () => new Date().getTime();
export class PrivateTopicModel extends BaseModel {
  id = '';
  constructor(id) {
    const request = () => PrivateTopicModel.requestTopic(id);
    super(id, state, request);
    this.id = id;
  }

  static setUsersPermittedPartials(data) {
    if (!data?.usersPermitted) return null;
    for (const userPermitted of data.usersPermitted) {
      const { user } = userPermitted;
      if (user?.username && user?._id) UserModel.setUserPartial(user._id, user);
    }
  }

  static setLatestReplyInState(data) {
    if (!data) return;
    const post = { ...data?.latestReply };
    const topic = { ...data, latestReply: post._id };
    post.topic = topic;
    return setRecoil(postState(post._id), {
      ...defaultLoadableAtom, hasLoaded: true, data: post, lastModified: getTime(),
    });
  }

  static setTopic(id, data) {
    if (!id || !data) return;
    PrivateTopicModel.setLatestReplyInState(data);
    if (data.author?.username && data.author?._id) UserModel.setUserPartial(data.author._id, data.author);
    if (data.usersPermitted) PrivateTopicModel.setUsersPermittedPartials(data);
    return setRecoil(state(id), prev => ({ ...prev, data, lastModified: getTime() }));
  }

  static getTopic(id) {
    if (!id) return;
    return getRecoil(state(id)).data;
  }

  static requestTopic(id) {
    if (!id) return;
    return PrivateTopicApi.requestTopic(id);
  }

  static loadUnreadCounts = async () => {
    const authenticatedUser = getRecoil(authenticatedUserSelector);
    const verified = getRecoil(userVerifiedSelector);
    if (!authenticatedUser || !verified) return;
    const res = await PrivateTopicApi.getUnreadMessagesCount();
    if (res?.success) {
      const { requests, unread } = res.data;
      setRecoil(unreadMessagesCount, unread);
      setRecoil(messageRequestCount, requests);
    }
  };

  updateLatestPostInTopicToRead = async (loadUnread = true) => {
    if (!this.id) return;
    const res = await PrivateTopicApi.updateLatestPostInTopicToRead(this.id);
    if (res.success && loadUnread) {
      PrivateTopicModel.loadUnreadCounts();
      const prevData = this.getData();
      this.setData({ ...prevData, latestReply: res.data });
    }
    return { success: res.success, data: res.data };
  };

  addUser(userId) {
    if (!this.id || !userId) return;
    return PrivateTopicApi.addUser(this.id, userId);
  }

  leaveMessage() {
    if (!this.id) return;
    return PrivateTopicApi.leave(this.id);
  }

  deleteMessage() {
    if (!this.id) return;
    return PrivateTopicApi.deleteMessage(this.id);
  }

  archiveMessage() {
    if (!this.id) return;
    return PrivateTopicApi.archiveMessage(this.id);
  }

  acceptRequest() {
    if (!this.id) return;
    return PrivateTopicApi.acceptRequest(this.id);
  }

  rejectRequest() {
    if (!this.id) return;
    return PrivateTopicApi.rejectRequest(this.id);
  }

  deleteForEveryone() {
    if (!this.id) return;
    return PrivateTopicApi.deleteForEveryone(this.id);
  }

  archiveForEveryone() {
    if (!this.id) return;
    return PrivateTopicApi.archiveForEveryone(this.id);
  }

  updateTitle(title, textContent) {
    if (!this.id) return;
    return PrivateTopicApi.updateTitle(this.id, title, textContent);
  }

  manageUsers(users) {
    if (!this.id) return;
    return PrivateTopicApi.manageUsers(this.id, users);
  }

  onSetState = (topicState, prevTopicState) => {
    if (!topicState?.data?.latestReply) return;
    PrivateTopicModel.setLatestReplyInState(topicState.data);
    if (topicState?.data?.usersPermitted) PrivateTopicModel.setUsersPermittedPartials(topicState.data);
    if (topicState.data.author?.username && topicState.data.author?._id) UserModel.setUserPartial(topicState.data.author._id, topicState.data.author);
  };
}
