import { Button, IconButton } from '@mui/material';
import {
  useState, useCallback, useMemo, useEffect,
} from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import GifBoxIcon from '@mui/icons-material/GifBox';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import Visibility from '@mui/icons-material/Visibility';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Breakpoints, SourceTypes } from '../../lib/constants';
import { Content, Wrapper } from '../Containers';
import LexicalComposer from '../LexicalComposer';
import { useLexicalEditor } from '../../models/editor/useLexicalEditor';
import { breakpointNameSelector } from '../../models/settings/selectors';
import ApiClientInstance from '../../clients/api';
import UserSelect from '../UserSelect';

import { addSnackbarSelector } from '../../models/snackbar/selector';
import { CustomEmojiNode } from '../LexicalComposer/Nodes/CustomEmojiNode';
import { useAuthenticatedUser } from '../../models/authenticatedUser/useAuthenticatedUser';

export const EDITOR_NAME_TOPIC = 'new-message-topic';
export const EDITOR_NAME_POST = 'new-message-post';

const MessagesCreate = () => {
  const breakpoint = useRecoilValue(breakpointNameSelector);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const { data: user } = useAuthenticatedUser();
  const [usersLoading, setUsersLoading] = useState(false);
  const uids = searchParams.get('uids');
  const addSnackbar = useSetRecoilState(addSnackbarSelector);

  const postFns = useLexicalEditor(({
    editorName: EDITOR_NAME_POST,
    sourceType: SourceTypes.Message,
  }));

  const {
    getEditorContents: getPostEditor,
    getEditorTextContent: getPostEditorTextContent,
    isEmpty: isPostEmpty,
    handleClearEditor: handlePostClear,
    isLoading: postLoading,
    toggleReadOnly: togglePostReadOnly,
  } = postFns;

  const topicFns = useLexicalEditor(({
    editorName: EDITOR_NAME_TOPIC,
    sourceType: SourceTypes.Message,
  }));

  const {
    getEditorContents: getTopicEditor,
    getEditorTextContent: getTopicEditorTextContent,
    isEmpty: isTopicEmpty,
    handleClearEditor: handleTopicClear,
    isLoading: topicLoading,
    toggleReadOnly: toggleTopicReadOnly,
  } = topicFns;

  useEffect(async () => {
    if (!uids) return;
    setUsersLoading(true);
    const res = await ApiClientInstance.sendRequest({
      path: '/user/exist',
      method: 'GET',
      queryParams: { uids },
      catchError: true,
    });
    if (res.success) setUsers(res.data);
    setUsersLoading(false);
    searchParams.delete('uids');
  }, [uids]);

  const isEmpty = useMemo(() => !users.length && isPostEmpty && isTopicEmpty, [isPostEmpty, isTopicEmpty, users]);

  const isLoading = useMemo(() => postLoading || topicLoading, [postLoading, topicLoading]);

  const canSend = useMemo(() => !isPostEmpty && !isTopicEmpty && users.length, [isPostEmpty, users, isTopicEmpty]);

  const handleClear = useCallback(() => {
    handlePostClear();
    handleTopicClear();
    setUsers([]);
  }, []);

  const toggleReadOnly = useCallback(() => {
    togglePostReadOnly();
    toggleTopicReadOnly();
  }, [togglePostReadOnly, toggleTopicReadOnly]);

  const handleSend = useCallback(async () => {
    // change this to send topic (title/users permitted)
    // then req to send message
    if (!canSend) return;
    toggleReadOnly();

    try {
      const postContent = getPostEditor();
      const topicContent = getTopicEditor();
      const postText = getPostEditorTextContent();
      const topicText = getTopicEditorTextContent();
      const topicRes = await ApiClientInstance.sendRequest({
        method: 'POST',
        path: '/private-topic',
        data: {
          title: topicContent,
          textContent: topicText,
          usersPermitted: users.map(u => u._id),
        },
        snackbarError: 'errorMessage',
        catchError: true,
      });
      if (!topicRes.success) addSnackbar({ message: 'Failed to create message', severity: 'error' });
      const postRes = await ApiClientInstance.sendRequest({
        method: 'post',
        path: `/private-topic/${topicRes.data._id}/reply`,
        data: { content: postContent, textContent: postText },
        catchError: true,
      });
      if (!postRes.success) addSnackbar({ message: 'Failed to create message', severity: 'error' });
      handleClear();
      toggleReadOnly();
      navigate(`/message/${topicRes.data._id}`);
    } catch (e) {
      handleClear();
      toggleReadOnly();
    }
  }, [getTopicEditorTextContent, getPostEditorTextContent, canSend, getPostEditor, getTopicEditor, users, toggleReadOnly]);

  const userSelect = useMemo(() => (
    <UserSelect
      label='select users'
      usersToExclude={[user]}
      users={users}
      setUsers={setUsers}
      selectType='multiple'
      externalLoading={usersLoading}
    />
  ), [users, usersLoading]);

  const topicEditor = useMemo(() => (
    <div className='mt-3'>
      <h2 className='font-bebas text-xl my-1'>Title</h2>
      <LexicalComposer
        editorName={EDITOR_NAME_TOPIC}
        outerContainerClassName='bg-s01dp'
        editorClassName='editor-root h-[56px]'
        excludePlugins={{
          mentions: true,
          embeds: true,
          markdown: true,
        }}
        excludedNodes={[CustomEmojiNode]}
      />
    </div>
  ), [topicFns]);

  const clearAndSendButtons = useMemo(() => (
    <div className='space-x-2'>
      <Button variant='outlined' endIcon={<ClearIcon />} color='disabledText' disabled={isEmpty || isLoading} onClick={handleClear} size='small'>Clear</Button>
      <Button variant='contained' endIcon={<SendIcon />} color='primary' disabled={!canSend || isLoading} onClick={handleSend} size='small' edge='start'>Create</Button>
    </div>
  ), [postFns, topicFns, handleClear, handleSend, isEmpty, isLoading, users]);

  const toolbar = useMemo(() => {
    const {
      handleUploadClick,
      handleGiphyClick,
      handleFontFormat,
      handlePreviewClick,
    } = postFns;
    return (
      <div className='flex items-center justify-between mt-1'>
        <div>
          <IconButton disabled={isLoading} onClick={handleUploadClick} size='small' edge='start'><AddCircleIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          <IconButton disabled={isLoading} onClick={handleGiphyClick} size='small' edge='start'><GifBoxIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          <IconButton disabled={isLoading} onClick={handleFontFormat} size='small' edge='start'><TextFormatIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
          <IconButton disabled={isLoading} onClick={handlePreviewClick} size='small' edge='start'><Visibility sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
        </div>
        { clearAndSendButtons }
      </div>
    );
  }, [isLoading, postFns, clearAndSendButtons, users]);

  const postEditor = useMemo(() => (
    <div className='mt-3'>
      <h2 className='font-bebas text-xl my-1'>Post Content</h2>
      <LexicalComposer
        editorName={EDITOR_NAME_POST}
        outerContainerClassName='bg-s01dp'
        editorStyles={{
          minHeight: '200px',
          height: breakpoint === Breakpoints.Mobile ? '' : 'calc(100vh - 450px)',
          maxHeight: breakpoint === Breakpoints.Mobile ? '300px' : 'calc(100vh - 450px)',
        }}
      />
      { toolbar}
    </div>
  ), [postFns, clearAndSendButtons, isLoading, toolbar, users]);

  return (
    <Wrapper applyBreakpoints>
      <div className='flex flex-col justify-center text-primary-text px-2'>
        <h1 className='text-2xl font-bebas my-3 text-primary-text'>Start a New Message</h1>
      </div>
      <Content>
        <div className='flex flex-col justify-between text-primary-text w-full font-roboto py-2 px-3' style={{ maxWidth: '100vw' }}>

          { userSelect }
          { topicEditor }
          { postEditor }
        </div>
      </Content>
    </Wrapper>
  );
};

export default MessagesCreate;
