import { DecoratorNode } from 'lexical';
import { LexicalComposerNamespaces } from '../../../lib/constants';
import PostQuote from '../DisplayComponents/PostQuote';
import { parseNameSpaceString } from '../utils/parseNameSpaceString';

const NAME = 'post-quote';
const CLASS_NAME = `display-${NAME}`;

export class PostQuoteNode extends DecoratorNode {
  __data;

  static getType() {
    return NAME;
  }

  static clone(node) {
    return new PostQuoteNode(node.__data, node.__key);
  }

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    const node = new PostQuoteNode(serializedNode.data);
    if (serializedNode?.canEdit) node.setCanEdit(serializedNode.canEdit);
    return node;
  }

  constructor(data, key) {
    super(key);
    this.__data = {
      author: {
        username: data.author.username,
        avatar: data.author.avatar,
        color: data.author.color,
        _id: data.author._id,
      },
      topic: {
        _id: data.topic._id,
        textContent: data.topic.textContent,
        category: data.topic.category?._id || data.topic.category,
      },
      post: {
        _id: data.post._id,
        content: data.post.content,
      },
    };
  }

  exportJSON() {
    return {
      data: this.__data,
      type: NAME,
      version: 1,
    };
  }

  createDOM(config, editor) {
    const el = document.createElement('div');
    el.className = CLASS_NAME;
    return el;
  }

  updateDOM() {
    return false;
  }

  decorate(editor, config) {
    if (!this.__data) return null;
    // console.log({ config, editor });
    const { namespace: namespaceString } = config;

    const {
      namespace,
      editorName,
      displaySource,
      sourceType,
    } = parseNameSpaceString(namespaceString);

    const isNested = namespace === LexicalComposerNamespaces.PostQuote;
    const canEdit = [LexicalComposerNamespaces.Editor, LexicalComposerNamespaces.PostQuoteEditor].includes(namespace);
    const {
      editorContents,
      post,
      topic,
      author,
    } = this.__data;
    return (
      <PostQuote
        displaySource={displaySource}
        sourceType={sourceType}
        editorName={editorName}
        namespace={namespace}
        canEdit={canEdit}
        isNested={isNested}
        post={post}
        topic={topic}
        author={author}
        postQuoteNode={PostQuoteNode}
      />
    );
  }

  isTextEntity() {
    return false;
  }
}

export const $createPostQuoteNode = (data, canEdit) => {
  const postQuoteNode = new PostQuoteNode(data, data.text, canEdit);
  return postQuoteNode;
};

export const $isPostQuoteNode = (node) => node instanceof PostQuoteNode;
