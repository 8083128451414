import { useRecoilValue } from 'recoil';
import {
  TopicListDisplayKeys,
} from '../../../lib/constants';
import topicListDisplayKeyState from '../../../models/topicListDisplayKey/atom';
import PostContent from '../../PostContent';
import PostPreviewContent from '../../PostPreviewContent';
import { usePost } from '../../../models/post/usePost';
import { getPostEntities } from '../../LexicalComposer/utils/getPostEntities';

export const CardPostContent = ({
  _id,
  sourceType,
  displaySource,
}) => {
  const { data } = usePost(_id, { sourceType });
  const topicListDisplayKey = useRecoilValue(topicListDisplayKeyState);

  if (topicListDisplayKey === TopicListDisplayKeys.Compact) return null;
  if (!data) return null;

  if (topicListDisplayKey === TopicListDisplayKeys.Preview) {
    const entities = getPostEntities(JSON.parse(data?.content));
    return (
      <section style={{ fontSize: '13px', lineHeight: '16px' }} className='topic-card-content'>
        <PostPreviewContent
          texts={entities.texts}
          embeds={entities.embeds}
          mentions={entities.mentions}
          hashtags={entities.hashtags}
        />
      </section>
    );
  }

  return (
    <section style={{ fontSize: '13px', lineHeight: '16px' }} className='topic-card-content'>
      <PostContent sourceType={sourceType} displaySource={displaySource} content={data?.content} />
    </section>
  );
};
