/* eslint-disable react/no-danger */
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Grid,
  ListItem,
} from '@mui/material';

import { CustomEvents, NotificationStatus } from '../../lib/constants';
import { getDistance } from '../../lib/formatDate';

import UserAvatar from '../UserAvatar';
import NotificationMenu from '../NotificationMenu';

import { useNotification } from '../../models/notification/useNotification';
import { NotificationModel } from '../../models/notification/model';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';

const createPath = (type, entity) => {
  switch (type) {
    case 'reply':
    case 'topicPost':
    case 'quote':
    case 'mention':
      return `/topic/${entity?.topic?.shortId}/${entity?.topic?.slug}?pid=${entity?.post?._id}`;
    default:
      return null;
  }
};

const Notification = ({
  _id,
  gridClassName,
}) => {
  const navigate = useNavigate();
  const { data, model } = useNotification(_id);
  const path = createPath(data?.type, data?.entity);

  const backgroundColor = useMemo(() => {
    if (data?.status === NotificationStatus.Unseen) return 'var(--color-secondary-light)';
    if (data?.status === NotificationStatus.Unread) return 'var(--color-primary-light)';
    return null;
  }, [data?.status]);

  const timeAgoColor = useMemo(() => {
    if (data?.status === NotificationStatus.Read) return 'var(--color-text-primary)';
    return 'var(--color-primary-light)';
  }, [data?.status]);

  const handleClick = useCallback(() => {
    model.updateNotificationStatus(NotificationStatus.Read);
    NotificationModel.updateUnseenNotificationsStatusToUnread();
    emitCustomEvent(CustomEvents.ToggleNotifications, { close: true });
    if (path) navigate(path);
  }, [path]);

  if (!data) return null;

  const isRead = [NotificationStatus.Unseen, NotificationStatus.Unread].indexOf(data?.status) === -1;

  const { entity, createdOn, message } = data;

  return (
    <ListItem disableRipple button onClick={handleClick} className='relative'>
      <div
        className='absolute'
        style={{
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor,
          opacity: '.10',
        }}
      />
      <Grid className={gridClassName || ''} container columns={24} sx={{ zIndex: 1 }}>
        <Grid item xs={4} sm={4}>
          <UserAvatar
            showOnline
            avatarSize='45px'
            _id={entity.user._id}
          />
        </Grid>
        <Grid className='flex items-center' item xs={16} sm={14}>
          <span className='flex flex-col'>
            <span className='text-primary-text font-roboto' style={{ fontSize: '.88rem' }} dangerouslySetInnerHTML={{ __html: message }} />
            <span style={{ fontSize: '.75rem', color: timeAgoColor, fontWeight: !isRead ? '700' : '' }}>{`${getDistance(createdOn)} ago`}</span>
          </span>
        </Grid>
        <Grid item xs={1} sm={1} />
        <Grid className='flex items-center justify-start' item xs={2}>
          <NotificationMenu _id={_id} />
          {/* {(!isRead) && (
          <button
            type='button'
            aria-label='Mark as read'
            style={{
              zIndex: 3,
              flexShrink: 0,
              borderRadius: '100%',
              backgroundColor: 'var(--color-primary)',
              height: '14px',
              width: '14px',
              padding: '4px',
              border: '2px solid var(--color-primary-light)',
            }}
          />
          )} */}
        </Grid>

      </Grid>
    </ListItem>
  );
};

export default Notification;
