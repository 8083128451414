import { memo } from 'react';
import {
  CardHeaderContentDetailTypes, SourceTypes,
} from '../../../lib/constants';
import { CardContainer } from '../../TopicCard/CardContainer';
import { CardHeaderContainer } from '../../TopicCard/CardHeaderContainer';
import { CardHeaderContent } from '../../TopicCard/CardHeaderContent';
import { CardTitle } from '../../TopicCard/CardTitle';
import { CardPostContent } from '../../TopicCard/CardPostContent';
import { CardActions } from '../../TopicCard/CardActions';
import { useTopic } from '../../../models/topic/useTopic';

const detailsType = CardHeaderContentDetailTypes.User;
const sourceType = SourceTypes.Topic;

export const TopicCard = ({
  _id,
  saved,
  hidden,
}) => {
  const {
    loading,
    data,
  } = useTopic(_id, { sourceType });

  if (loading) return null;

  return (
    <article className='topic-card font-roboto flex flex-col space-y-2 py-1 px-1'>
      <CardContainer
        author={data?.author}
        isDeleted={data?.isDeleted}
        isModeratorPost={data?.mainPost?.isModeratorPost}
      >
        <CardHeaderContainer
          pin={data?.pin}
          sourceType={sourceType}
          isDeleted={data?.isDeleted}
          isLocked={data?.isLocked}
          includeEditMenu={false}
          showNewPostIcon={false}
          _id={_id}
          isModeratorPost={data?.mainPost?.isModeratorPost}
        >
          <CardHeaderContent
            category={data?.category}
            author={data?.author}
            createdOn={data?.createdOn}
            detailsType={detailsType}
            sourceType={sourceType}
            disableUserProfileDrawer
          />
        </CardHeaderContainer>
        <CardTitle topicId={data?._id} />
        <CardPostContent sourceType={sourceType} _id={data?.mainPost._id} />
        <CardActions
          topicId={data?._id}
          postId={data?.mainPost?._id}
          vote={data?.mainPost?.vote}
          score={data?.mainPost?.score}
          shortId={data?.shortId}
          slug={data?.slug}
          stats={data?.stats}
          showIsSaved={saved}
          showIsHidden={hidden}
          saved={saved}
          hidden={hidden}
        />
      </CardContainer>
    </article>
  );
};

export default memo(TopicCard);
