import { useMemo } from 'react';
import { Button, IconButton } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import GifBoxIcon from '@mui/icons-material/GifBox';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import LocalPolice from '@mui/icons-material/LocalPolice';

import { useLexicalEditor } from '../../../models/editor/useLexicalEditor';
import { useAuthenticatedUser } from '../../../models/authenticatedUser/useAuthenticatedUser';
import { SourceTypes, UserRoles } from '../../../lib/constants';

const BottomToolbar = ({ editorName, sourceType }) => {
  const { data: user } = useAuthenticatedUser();

  const {
    isEmpty,
    isLoading,
    isExpanded,
    isModeratorPost,
    handleUploadClick,
    handleGiphyClick,
    handleFontFormat,
    handleExpanded,
    handleToggleEditorState,
    handleIsModeratorPost,
    handleClearEditor,
    handleSend,
  } = useLexicalEditor({ editorName, sourceType });

  const isModeratorPostIcon = useMemo(() => {
    if (!user || ![UserRoles.Admin, UserRoles.SuperAdmin, UserRoles.Moderator].includes(user.role) || sourceType === SourceTypes.Message) return null;
    return (
      <IconButton disabled={isLoading} onClick={handleIsModeratorPost} size='small' edge='start'>
        <LocalPolice sx={{ color: !isModeratorPost ? 'var(--color-s24dp)' : 'var(--color-primary-light)' }} />
      </IconButton>
    );
  }, [sourceType, user, handleIsModeratorPost, isModeratorPost, isLoading]);

  return (
    <div className={`flex items-center justify-between ${isExpanded ? 'mb-1' : 'mt-1'}`}>
      <div>
        <IconButton disabled={isLoading} onClick={handleUploadClick} size='small' edge='start'><AddCircleIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
        <IconButton disabled={isLoading} onClick={handleGiphyClick} size='small' edge='start'><GifBoxIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
        <IconButton disabled={isLoading} onClick={handleFontFormat} size='small' edge='start'><TextFormatIcon sx={{ color: 'var(--color-s24dp)' }} /></IconButton>
        { isModeratorPostIcon }
      </div>

      <div className='space-x-2'>
        <Button variant='outlined' endIcon={<ClearIcon />} color='disabledText' disabled={isLoading} onClick={handleClearEditor} size='small'>Clear</Button>
        <Button variant='contained' endIcon={<SendIcon />} color='primary' disabled={isEmpty || isLoading} onClick={handleSend} size='small' edge='start'>Send</Button>
      </div>
    </div>
  );
};

export default BottomToolbar;
