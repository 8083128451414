/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import {
  Menu,
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  Breakpoints, CustomEvents, NotificationStatus, QueryKeys,
} from '../../lib/constants';

import { useNotification } from '../../models/notification/useNotification';
import { breakpointNameSelector } from '../../models/settings/selectors';
import { emitInvalidateQueries } from '../../lib/emitters/emitInvalidateQueries';
import { emitCustomEvent } from '../../hooks/useCustomEventListener';

const NotificationMenu = ({
  _id,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const breakpoint = useRecoilValue(breakpointNameSelector);
  const { data, model } = useNotification(_id);

  const isMobile = breakpoint === Breakpoints.Mobile;
  const open = !!anchorEl;

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setAnchorEl(null);
  }, []);

  const handleDelete = useCallback((e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    model.deleteNotification();
    handleClose();
  }, []);

  const handleMarkAsRead = useCallback(async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    await model.markAsRead();
    emitInvalidateQueries({ queryKey: QueryKeys.Notifications });
    handleClose();
  }, [model]);

  if (!data) return null;

  const { status } = data;

  return (
    <div>
      { isMobile && (
      <IconButton
        color={open ? 'primaryLight' : 'textPrimary'}
        id='notification-manage-menu-button'
        aria-controls={open ? 'notification-manage-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      )}
      { !isMobile && (
      <Button
        variant='outlined'
        color={open ? 'primaryLight' : 'textPrimary'}
        id='notification-manage-menu-button'
        aria-controls={open ? 'notification-manage-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<SettingsIcon />}
      >
        Manage
      </Button>
      )}
      <Menu
        id='message-request-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'message-request-menu-button',
        }}
      >
        { status !== NotificationStatus.Read && (
        <MenuItem dense onClick={handleMarkAsRead}>
          <ListItemIcon>
            <CheckCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Mark as Read</ListItemText>
        </MenuItem>
        )}
        <MenuItem dense onClick={handleDelete}>
          <ListItemIcon>
            <RemoveCircleIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>

      </Menu>
    </div>
  );
};

export default NotificationMenu;
