import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import StarIcon from '@mui/icons-material/Star';

import { Tooltip } from '@mui/material';
import { formatDate, getDistance } from '../../../lib/formatDate';
import { emitOpenUserProfileDrawer } from '../../../lib/emitters/emitOpenUserProfileDrawer';

import { breakpointNameSelector } from '../../../models/settings/selectors';

import UserAvatar from '../../UserAvatar';
import UserBadges from '../../UserBadges';
import { Breakpoints, CustomEvents } from '../../../lib/constants';
import { emitCustomEvent } from '../../../hooks/useCustomEventListener';

const userNameSize = { fontSize: '13px', lineHeight: '16px' };

export const CardHeaderContent = ({
  _id,
  author,
  createdOn,
  sourceType,
  showUserHighlight = true,
  disableUserProfileDrawer = false,
  topicAuthor,
  edited,
}) => {
  const breakpoint = useRecoilValue(breakpointNameSelector);
  const isMoble = breakpoint === Breakpoints.Mobile;

  const userHighlight = topicAuthor && topicAuthor?._id === author?._id && showUserHighlight;

  const handleOpenUserProfileDrawer = useCallback(() => {
    if (disableUserProfileDrawer) return null;
    return emitOpenUserProfileDrawer({ sourceType, username: author?.username });
  }, [author, sourceType, disableUserProfileDrawer]);

  const handleOpenEditsModal = useCallback(() => emitCustomEvent(CustomEvents.OpenPostEditsModal, { postId: _id }), [_id]);

  const userButton = useMemo(() => (
    <span style={userNameSize} className='flex items-center space-x-1'>
      <button
        type='button'
        onClick={handleOpenUserProfileDrawer}
        disabled={disableUserProfileDrawer}
      >
        <span
          style={{ letterSpacing: '.07ch' }}
          className={`font-bold ${userHighlight ? 'text-secondary-brand-large-text' : 'text-primary-text opacity-90'}`}
        >
          {`@${author.username}`}
        </span>
      </button>
      { userHighlight && <StarIcon sx={{ height: '16px', width: '16px', color: 'var(--color-text-secondary-brand-large)' }} /> }
      <Tooltip enterTouchDelay={1} title={formatDate(new Date(createdOn), 'M/d/yyyy - h:mm:ss aa')}>
        <span style={{ cursor: 'pointer' }} className='opacity-90'>{` ${getDistance(createdOn)}`}</span>
      </Tooltip>
      { edited && (
        <button
          type='button'
          className='opacity-90 italic'
          onClick={handleOpenEditsModal}
        >
          edited
        </button>
      ) }
    </span>
  ), [handleOpenUserProfileDrawer, createdOn, userHighlight, disableUserProfileDrawer]);

  return (
    <>
      <UserAvatar
        avatarSize={isMoble ? '45px' : '50px'}
        onClick={handleOpenUserProfileDrawer}
        _id={author?._id}
        showOnline
      />
      <div className='flex flex-col ml-3'>
        { userButton }
        <UserBadges _id={author._id} badgeSize={isMoble ? '22px' : '25px'} containerClassName='mt-1' />
      </div>
    </>
  );
};
