import { DecoratorNode } from 'lexical';
import Image from '../DisplayComponents/Image';
import Embed from '../DisplayComponents/Embed';

const NAME = 'embed';
const CLASS_NAME = `display-${NAME}`;

export class EmbedNode extends DecoratorNode {
  __data;

  static getType() {
    return NAME;
  }

  static clone(node) {
    return new EmbedNode(node.__data, node.__key);
  }

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    const node = new EmbedNode(serializedNode.data);
    return node;
  }

  constructor(data, key) {
    super(key);
    this.__data = data;
  }

  exportJSON() {
    return {
      data: this.__data,
      type: NAME,
      version: 1,
    };
  }

  createDOM() {
    const el = document.createElement('div');
    el.className = CLASS_NAME;
    return el;
  }

  updateDOM() {
    return false;
  }

  isTextEntity() {
    return false;
  }

  decorate() {
    if (!this.__data) return null;
    if (this.__data.type === 'image') {
      return (
        <Image url={this.__data.url} text={this.__data.text} />
      );
    }
    return (
      <Embed embedObject={this.__data} />
    );
  }
}

export const $createEmbedNode = (data) => {
  const embedNode = new EmbedNode(data, data.text);
  return embedNode;
};

export const $isEmbedNode = (node) => node instanceof EmbedNode;
