import { useEffect } from 'react';
import UniverseLoader from '../../../Loaders/UniverseLoader';
import { PopperMenu } from '../../PopperMenu';
import { MentionsTypeaheadMenuItem } from './MenuItem';

const Menu = ({
  options,
  loading,
  selectedIndex,
  setHighlightedIndex,
  selectOptionAndCleanUp,
  cleanup,
}) => {
  useEffect(() => () => cleanup(), []);
  return (
    <div className='bg-surface'>
      <ul style={{ width: '200px' }} className='rounded bg-s04dp z-10 py-2'>
        { loading && (
          <div className='flex justify-center items-center h-[100px]'>
            <UniverseLoader boxType='fullContainer' size={75} showLogo={false} />
          </div>
        )}
        { (options.length > 0 && !loading) && options.map((option, i) => (
          <MentionsTypeaheadMenuItem
            index={i}
            isSelected={selectedIndex === i}
            onClick={() => {
              setHighlightedIndex(i);
              selectOptionAndCleanUp(option);
            }}
            onMouseEnter={() => setHighlightedIndex(i)}
            key={option.key}
            option={option}
          />
        ))}
      </ul>
    </div>
  );
};

export const renderMenu = (options, results, loading) => {
  const _renderMenu = (anchorElement, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
    const cleanup = () => document.querySelector('#typeahead-menu')?.remove();
    if (!anchorElement) return null;
    return (
      <PopperMenu anchorElement={anchorElement}>
        <Menu cleanup={cleanup} loading={loading} options={options} selectedIndex={selectedIndex} setHighlightedIndex={setHighlightedIndex} selectOptionAndCleanUp={selectOptionAndCleanUp} />
      </PopperMenu>
    );
  };
  return _renderMenu;
};
