import {
  useEffect,
} from 'react';
import { useSetRecoilState } from 'recoil';
import documentTitle from '../models/documentTitle/atom';

const useDocumentTitle = (title) => {
  const setDocumentTitle = useSetRecoilState(documentTitle);

  useEffect(() => {
    setDocumentTitle(title);
    return () => setDocumentTitle('');
  }, [title]);
};

export default useDocumentTitle;
