import ApiClientInstance from '../../clients/api';
import { UserStatus } from '../../lib/constants';

export const requestUser = (id) => ApiClientInstance.sendRequest({
  method: 'get',
  path: `/user/profile/${id}`,
  catchError: true,
});

export const ban = (id) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/ban/${id}`,
  catchError: true,
});

export const unban = (id) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/unban/${id}`,
  catchError: true,
});

export const approveUser = (id) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/approve/${id}`,
  catchError: true,
});

export const updateUserStatus = (id, status = UserStatus.Good, duration = {}) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/status/${id}`,
  data: { status, ...duration },
});

export const reinstateUser = (id) => updateUserStatus(id, UserStatus.Good);

export const verifyOtherUserEmail = (id) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/email/verify/${id}`,
  catchError: true,
});

export const silenceUser = (id, minutes) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/silence/${id}`,
  data: {
    status: UserStatus.Silenced,
    minutes,
  },
  catchError: true,
  snackbarError: 'errorMessage',
});

export const suspendUser = (id, date) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/status/${id}`,
  data: {
    status: UserStatus.Suspended,
    date,
  },
  catchError: true,
  snackbarError: 'errorMessage',
});

export const resetUserLoginAttempts = (id) => ApiClientInstance.sendRequest({
  path: `/admin/user/reset-login-attempts/${id}`,
  method: 'put',
  snackbarError: 'errorMessage',
  catchError: true,
});

export const incrementMt = (uid, amount) => ApiClientInstance.sendRequest({
  method: 'put',
  path: '/admin/user/currency',
  data: { amount, uid },
});

export const updateUserRole = (id, role) => ApiClientInstance.sendRequest({ method: 'put', path: `/admin/user/role/${id}`, data: { role } });

export const addBadge = (id, badgeId) => ApiClientInstance.sendRequest({
  method: 'post',
  path: `/admin/user/${id}/badge/${badgeId}`,
  catchError: true,
});

export const removeBadge = (id, badgeId) => ApiClientInstance.sendRequest({
  method: 'put',
  path: `/admin/user/${id}/badge/${badgeId}`,
  catchError: true,
});
